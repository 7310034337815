import { FC } from "react";

const SentPolicy: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7806_23005)">
        <path
          d="M13.5 20H19.6289L17.6894 21.9394L18.75 23L22.5 19.25L18.75 15.5L17.6894 16.5606L19.6289 18.5H13.5V20Z"
          fill="black"
        />
        <path d="M11.25 16.25H7.5V17.75H11.25V16.25Z" fill="black" />
        <path d="M15 12.5H7.5V14H15V12.5Z" fill="black" />
        <path
          d="M18.525 7.475L13.275 2.225C13.125 2.075 12.975 2 12.75 2H5.25C4.425 2 3.75 2.67492 3.75 3.5V21.5C3.75 22.3272 4.42313 23 5.25 23H11.25V21.5H5.25V3.5H11.25V8C11.25 8.82508 11.925 9.5 12.75 9.5H17.25V14H18.75V8C18.75 7.775 18.675 7.625 18.525 7.475ZM12.75 8V3.79992L16.95 8H12.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7806_23005">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SentPolicy;
