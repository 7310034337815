/** @jsxImportSource @emotion/react */

import AuthLayout from "../../components/AuthLayout";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { FC, useEffect, useState } from "react";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useCreateFormResponseV2Mutation } from "../../graphql/generated/types";
import { useAuth } from "../../hooks/useAuth";
import { tratorArando } from "../../s3/bgImages";
import FormV2 from "../../modules/Forms/form.v2";
import { FormSuccess } from "../../views/Register/components/FormSuccess";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  bgImage: {
    backgroundImage: `url(${tratorArando})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100%",
    width: "100%",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      minHeight: 250,
    },
  },
  leftSideWrapper: {
    padding: "20px",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: "0",
    },
  },
});

export const DefaultPublicFormLayout: FC<{
  formId: string;
  successMessage: string;
  children?: React.ReactNode;
}> = ({ formId, successMessage, children }) => {
  const { errorHandler } = useErrorHandler();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [resId, setResId] = useState<string>("");
  const [showMessage, setShowMessage] = useState(false);
  const [createFormResponse] = useCreateFormResponseV2Mutation();
  const { loaded: authIsLoaded } = useAuth();

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponse({
        variables: {
          formId,
          metadata: {},
        },
      })
        .then(({ data }) => setResId(data!.createFormResponseV2))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = (id: string) => {
    if (id) {
      setShowMessage(true);
    }
  };

  return (
    <AuthLayout rightSideContent={<div css={styles.bgImage} />}>
      <div css={styles.leftSideWrapper}>
        {children}
        {showMessage && <FormSuccess successMessage={successMessage} />}
        {!showMessage && resId && (
          <FormV2
            formId={formId}
            onSubmit={handleSubmit}
            formResponseId={resId}
          />
        )}
      </div>
    </AuthLayout>
  );
};
