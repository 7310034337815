/** @jsxImportSource @emotion/react */
import Step from "./Step";
import { CSSProperties, FC } from "react";

type StepperProps = {
  steps: Array<string>;
  maxStep: number;
  activeStepIndex: number;
  handleStepClick: (step: number) => void;
};

const styles = {
  stepper: {
    display: "flex",
    flexWrap: "wrap" as CSSProperties["flexWrap"],
    width: "100%",
    marginBottom: 10,
    justifyContent: "center",
  },
};

const Stepper: FC<StepperProps> = ({
  steps,
  maxStep,
  activeStepIndex,
  handleStepClick,
}) => {
  return (
    <div css={styles.stepper}>
      {steps.map((step, index) => (
        <Step
          key={step}
          index={index}
          activeStepIndex={activeStepIndex}
          label={step}
          isSelectable={index <= maxStep}
          isLast={index === steps.length - 1}
          handleClick={() => handleStepClick(index)}
        />
      ))}
    </div>
  );
};

export default Stepper;
