import SectionDivider from "../../../../../components/EditableSectionV2/components/SectionDivider";
import ClaimList from "./sections/ClaimList";
import ComplementaryData from "./sections/ComplementaryData";
import Inspections from "./sections/Inspections";

export const Claims: React.FC = () => {
  return (
    <>
      <ClaimList />
      <SectionDivider />
      <Inspections />
      <SectionDivider />
      <ComplementaryData />
    </>
  );
};
