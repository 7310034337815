/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { useAuth } from "../../../hooks/useAuth";
import {
  GetCreateOrgMembersFormResponseDocument,
  useArchiveOrgMemberMutation,
  useDowngradeOrgAdminToOrgMemberMutation,
  useTransferOrgOwnershipMutation,
  useUpgradeOrgMemberToOrgAdminMutation,
} from "../../../graphql/generated/types";
import EditableTable from "../../../components/EditableTable";
import { formatPhone } from "../../../utils/formatNumber";
import { contactTagLabels } from "../../../shared/contacts/contactTags";
import { isOrgAdmin, isOrgMember, isOrgOwner } from "../../Contact/utils/tags";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { useUIStore } from "../../../hooks/useUIStore";
import { TableAction } from "../../../components/Table/TableRow";

export const MyOrgMembers: FC = () => {
  const { user, loadCurrentUser } = useAuth();
  const archiveOrgMember = useArchiveOrgMemberMutation()[0];
  const upgradeToAdminMutation = useUpgradeOrgMemberToOrgAdminMutation()[0];
  const downgradeToMemberMutation =
    useDowngradeOrgAdminToOrgMemberMutation()[0];
  const transferOwnershipMutation = useTransferOrgOwnershipMutation()[0];

  const { openConfirmationModal } = useUIStore();
  const { withErrorHandler } = useErrorHandler();

  const handleArchiveOrgMember = (userId: string) =>
    openConfirmationModal("Tem certeza que deseja desabilitar esse contato?", {
      onConfirm: [
        {
          onClick: async () => {
            withErrorHandler(async () => {
              await archiveOrgMember({ variables: { userId } });
              await loadCurrentUser();
              toast.success("Membro desabilitado com sucesso");
            }, "Não foi possível desabilitar esse contato")();
          },
        },
      ],
    });

  const handleUpgradeToAdmin = (userId: string) =>
    openConfirmationModal(
      "Tem certeza que deseja tornar esse contato um administrador?",
      {
        onConfirm: [
          {
            onClick: async () => {
              withErrorHandler(async () => {
                await upgradeToAdminMutation({ variables: { userId } });
                await loadCurrentUser();
                toast.success("Membro promovido a administrador com sucesso");
              }, "Não foi possível promover esse contato a administrador")();
            },
          },
        ],
      }
    );

  const handleDowngradeToMember = (userId: string) =>
    openConfirmationModal(
      "Tem certeza que deseja rebaixar esse administrador a membro?",
      {
        onConfirm: [
          {
            onClick: async () => {
              withErrorHandler(async () => {
                await downgradeToMemberMutation({ variables: { userId } });
                await loadCurrentUser();
                toast.success("Administrador rebaixado a membro com sucesso");
              }, "Não foi possível rebaixar esse administrador a membro")();
            },
          },
        ],
      }
    );

  const handleTransferOwnership = (userId: string) =>
    openConfirmationModal(
      "Tem certeza que deseja transferir a titularidade da organização para esse usuário?",
      {
        onConfirm: [
          {
            onClick: async () => {
              withErrorHandler(async () => {
                await transferOwnershipMutation({ variables: { userId } });
                await loadCurrentUser();
                toast.success(
                  "Titularidade da organização transferida com sucesso"
                );
                window.location.reload();
              }, "Não foi possível transferir a titularidade da organização para esse usuário")();
            },
          },
        ],
      }
    );

  const members = (user?.org!.members || []).map((m) => ({
    name: m.name,
    email: m.email!,
    phone: formatPhone(m.mobilePhone!),
    tags: contactTagLabels[m.tags![0]],
    isOnlyMember: isOrgMember(m),
    userId: m.userId!,
    isOwner: isOrgOwner(m),
    isAdmin: isOrgAdmin(m),
  }));

  type Member = (typeof members)[0];

  const actions: TableAction<Member>[] = [
    {
      label: "Remover",
      onClick: (member) => {
        handleArchiveOrgMember(member.userId);
      },
      disabled(data) {
        return !data.isOnlyMember;
      },
    },
    {
      label: "Promover a administrador",
      onClick: (member) => {
        handleUpgradeToAdmin(member.userId);
      },
      disabled(data) {
        return !data.isOnlyMember;
      },
    },
  ];
  if (isOrgOwner(user.contact!)) {
    actions.push({
      label: "Rebaixar a membro",
      onClick: (member) => {
        handleDowngradeToMember(member.userId);
      },
      disabled(data) {
        return data.isOwner;
      },
    });
    actions.push({
      label: "Transferir titularidade da organização",
      onClick: (member) => {
        handleTransferOwnership(member.userId);
      },
      disabled(data) {
        return !data.isAdmin;
      },
    });
  }

  return (
    <EditableTable
      withBorder
      formTitle="Adicionar membro"
      data={members}
      createQuery={GetCreateOrgMembersFormResponseDocument}
      createPayload={{ orgId: user.orgId! }}
      formDisplay="modal"
      onSubmit={() => {
        toast.success("Membro adicionado com sucesso");
        loadCurrentUser();
      }}
      actions={actions}
      columns={[
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "E-mail",
          key: "email",
        },
        {
          label: "Telefone",
          key: "phone",
        },
        {
          label: "Permissão",
          key: "tags",
        },
      ]}
      useV2
    />
  );
};
