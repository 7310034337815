import { FC } from "react";
import {
  FormFieldV2,
  FormInputTypes,
} from "../../../../graphql/generated/types";
import { TInputMasks } from "../../models/formInterfaces";
import useFormField from "../../hooks/useFormField";
import FloatInput from "../../../../components/Input/FloatInput";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import IntegerInput from "../../../../components/Input/IntegerInput";
import DefaultFieldWrapper from "../utils/DefaultFieldWrapper";

interface NumberFieldProps {
  field: FormFieldV2;
}

const NumberField: FC<NumberFieldProps> = ({ field }) => {
  const { name, label, value, disabled, inputMask, type, inputType, unit } =
    field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { localValue, handleInputChange, forceDebounce } =
    useDebouncedMaskedValue(
      value,
      editing,
      (v: string) => handleChange(v ? parseFloat(v) : ""),
      inputMask as TInputMasks
    );

  const props = {
    placeholder: label.toLowerCase(),
    disabled: !!disabled,
    type,
    name,
    value: localValue,
    onChange: handleInputChange,
    onFocus: startEditing,
    onBlur: () => {
      forceDebounce();
      finishEditing();
    },
    errorMessage: error,
    unit: unit || undefined,
  };
  return (
    <DefaultFieldWrapper field={field}>
      {inputType === FormInputTypes.Float && <FloatInput {...props} />}
      {inputType === FormInputTypes.Integer && <IntegerInput {...props} />}
    </DefaultFieldWrapper>
  );
};

export default NumberField;
