import { useState } from "react";
import { IconNames } from "../components/Icons/styles/iconNames";
import {
  ContactsLocation,
  CultureListLocation,
  HomeLocation,
  PaymentsLocation,
} from "../Locations";
import { useFlags } from "./useFlags";
import { MenuItem } from "../components/Menu/LeftMenu/LeftMenu";
import { useLocation } from "react-router-dom";

export const useDefaultMenuItems = () => {
  const { isAdmin } = useFlags();
  const location = useLocation();
  const [isSupportMaterialOpen, setIsSupportMaterialOpen] = useState(false);

  const isActive = (itemLocation: string) => {
    // no caso da home o menu fica ativo também quando está nas páginas /seguros
    if (itemLocation === "/") {
      return location.pathname === "/" || !!location.pathname.match("/seguros");
    } else if (location.pathname.match(itemLocation)) {
      return true;
    }
  };

  const items: MenuItem[] = [
    {
      title: "Seguros",
      location: HomeLocation,
      icon: IconNames.Documents,
      active: isActive(HomeLocation),
    },
  ];

  if (isAdmin()) {
    items.push(
      {
        title: "Contatos",
        location: ContactsLocation,
        icon: IconNames.Organization,
        active: isActive(ContactsLocation),
      },
      {
        title: "Culturas",
        location: CultureListLocation,
        icon: IconNames.Agriculture,
        active: isActive(CultureListLocation),
      },
      {
        title: "Pagamentos",
        location: PaymentsLocation,
        icon: IconNames.Money,
        active: isActive(PaymentsLocation),
      }
    );
  }
  const submenus = [
    {
      title: "Material Prisma",
      location: process.env.REACT_APP_SUPPORT_MATERIAL_URL,
      newTab: true,
    },
    {
      title: "Mapa/ZARC",
      location:
        "https://mapa-indicadores.agricultura.gov.br/publico/extensions/Zarc/Zarc.html",
      newTab: true,
    },
    {
      title: "Histórico de Culturas",
      location:
        "https://cidades.ibge.gov.br/brasil/pesquisa/14/10193?tipo=grafico",
      newTab: true,
    },
    {
      title: "MAPA - Portarias",
      location:
        "https://www.gov.br/agricultura/pt-br/assuntos/riscos-seguro/programa-nacional-de-zoneamento-agricola-de-risco-climatico/portarias/",
      newTab: true,
    },
  ];
  items.push({
    title: "Material de Apoio",
    icon: IconNames.Scrutiny,
    // submenus: isSupportMaterialOpen ? submenus : [],
    submenus: isSupportMaterialOpen ? submenus : [],
    onClick: () => setIsSupportMaterialOpen(!isSupportMaterialOpen),
  });

  return items;
};
