/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/index";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import Column from "../../components/Grid/Column";
import Container from "../../components/Grid/Container";
import Row from "../../components/Grid/Row";
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { HomeLocation } from "../../Locations";
import Checkbox from "../../components/Input/CheckboxInput";
import TermsAndConditonsText from "./components/TermsAndConditionsText";
import ScrollContainer from "../../components/ScrollContainer";
import { useSignUserTermsMutation } from "../../graphql/generated/types";

const getCSSRules: CSSRulesResolver = ({ ...props }) => ({
  main: {
    padding: "0px 20px",
    boxSizing: "border-box",
    border: `2px solid ${props.colors.Green[100]}`,
    borderRadius: 10,
    backgroundColor: `${props.colors.White[100]}`,
    overflow: "hidden",
    margin: 20,
  },
  drawerHeader: {
    position: "absolute",
    width: 20,
    height: 20,
    right: 0,
    top: 24,
    color: "#BAD804",
    paddingTop: 2,
    cursor: "pointer",
  },
  closeIcon: {
    height: 14,
    width: 14,
  },
});

export default function Index() {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const navigate = useNavigate();
  const { user, loadCurrentUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const signTermsById = useSignUserTermsMutation()[0];

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);

  const handleAcceptConditions = () => {
    !isSigned && setIsButtonDisabled((isButtonDisabled) => !isButtonDisabled);
  };

  const isSigned = !!user.signedDate;

  const signDate = user.signedDate ? new Date(user.signedDate!) : new Date();

  const formattedSignDate = signDate.toLocaleString("pt-BR", {
    dateStyle: "short",
  });

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (
      (target.scrollHeight - target.clientHeight) * 0.8 < target.scrollTop &&
      !isSigned
    ) {
      setIsCheckboxDisabled(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await signTermsById({});
      setIsCheckboxDisabled(true);
      setIsButtonDisabled(true);
      await loadCurrentUser();
      navigate(HomeLocation, { replace: true });
    } catch (e) {
      errorHandler(
        new Error("Nao foi possivel assinar os Termos e Condições"),
        e
      );
    }
  };

  const checkboxLabel = `Ciente e de acordo em ${formattedSignDate}`;

  if (!user._id) {
    return null;
  }

  return (
    <Layout>
      <div css={styles.main}>
        <Container>
          <Row>
            <Column>
              <Typography
                variant="textSmall"
                align="left"
                uppercase
                weight="bold"
                component="div"
                color="Green"
                margin="sm"
              >
                Termos e Condições
              </Typography>
            </Column>
          </Row>
          <Row>
            <Column>
              <ScrollContainer
                height={"calc(100vh - 360px)"}
                onScroll={(e) => handleScroll(e)}
              >
                <TermsAndConditonsText />
              </ScrollContainer>
            </Column>
          </Row>
          <Row>
            <Column>
              <Checkbox
                checked={!!isSigned}
                disabled={isCheckboxDisabled}
                label={checkboxLabel}
                onChange={handleAcceptConditions}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={4}>
              <Button
                disabled={isButtonDisabled}
                text={"Enviar"}
                style={"outlinePrimary"}
                onClick={handleSubmit}
              ></Button>
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
