import { FC, useMemo } from "react";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { formatISODate } from "../../../../../../utils/dateUtils";
import DetailsSectionColumns from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowValueVariants } from "../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import {
  Services,
  TransactionalEvents,
} from "../../../../../../graphql/generated/types";
import useConfirmedAction from "../../../../../../hooks/useConfirmedAction";
import EditableSectionV2 from "../../../../../../components/EditableSectionV2";
import Icon from "../../../../../../components/Icons";

const ComplementaryData: FC = () => {
  const {
    handleCopyComplementaryFormLink,
    handleCopyNfeUploadFormLink,
    handleSendTransactionalEvent,
    documentData: { crop, farmer, _id, visitPerson, costNFFiles },
  } = useDocumentDetails();

  const handleComplementaryDataEmail = useConfirmedAction(
    "Deseja enviar o email com o formulário de Datas Complementares para o produtor?",
    {
      onConfirm: [
        {
          onClick: () =>
            handleSendTransactionalEvent(TransactionalEvents.ComplementaryData),
        },
      ],
    }
  );

  const handleInvoicesEmail = useConfirmedAction(
    "Deseja enviar o email com o formulário de Notas Fiscais para o produtor?",
    {
      onConfirm: [
        {
          onClick: () =>
            handleSendTransactionalEvent(TransactionalEvents.InvoicesForm),
        },
      ],
    }
  );

  const footerButtons = useMemo(() => {
    return [
      {
        text: "Copiar link do Formulário",
        iconName: IconNames.Share,
        onClick: handleCopyComplementaryFormLink,
        flag: "admin-only",
      },
      {
        text: "Enviar Form Dados Complementares",
        iconName: IconNames.Send,
        onClick: handleComplementaryDataEmail,
        flag: "admin-only",
      },
      {
        text: "Formulário Notas Fiscais",
        iconName: IconNames.Share,
        onClick: handleCopyNfeUploadFormLink,
        flag: "admin-only",
      },
      {
        text: "Enviar Form Notas Fiscais",
        iconName: IconNames.Send,
        onClick: handleInvoicesEmail,
        flag: "admin-only",
      },
    ];
  }, []);

  const complementaryDataInfo: DetailsRowProps[][] = useMemo(() => {
    return [
      [
        {
          label: "Documento",
          variant: DetailsRowValueVariants.File,
          value: {
            file: farmer?.documentFile,
            objectId: farmer?._id,
            fieldPath: "documentFile",
            serviceName: Services.Contacts,
          },
        },
        {
          label: "Comprovante de Residência",
          variant: DetailsRowValueVariants.File,
          value: {
            file: farmer?.addressProofFile,
            objectId: farmer?._id,
            fieldPath: "documentFile",
            serviceName: Services.Contacts,
          },
        },
        (costNFFiles?.length || 0) > 0 && {
          label: "Notas Fiscais",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: "Notas Fiscais.zip",
            },
            objectId: _id,
            fieldPath: "costNFFiles",
            serviceName: Services.Documents,
          },
        },
      ].filter(Boolean) as DetailsRowProps[],
      [
        {
          label: "Acompanhamento da Vistoria",
          value: visitPerson?.name,
        },
        {
          label: "Data de Início da Colheita",
          value: formatISODate(crop?.harvestStartDate),
        },
        {
          label: "Data do Fim Colheita",
          value: formatISODate(crop?.harvestEndDate),
        },
      ],
    ];
  }, []);

  return (
    <EditableSectionV2
      title="Dados Complementares"
      icon={<Icon name={IconNames.Documents} />}
      actions={footerButtons}
    >
      <DetailsSectionColumns columns={complementaryDataInfo} />
    </EditableSectionV2>
  );
};

export default ComplementaryData;
