import { FC } from "react";
import Typography from "../../../../components/Typography";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
import ObjectListItemFieldSummary from "./ObjectListItemFieldSummary";
import { theme } from "../../../../theme";

type ObjectListFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectListFieldSummary: FC<ObjectListFieldSummaryProps> = ({
  field: { fields, label },
}) => {
  return (
    <Column xs={12}>
      {fields!.map((itemField, index) => (
        <div
          key={itemField.fieldPath}
          style={{
            borderRadius: theme.borderRadius.lg,
            border: `1px solid ${theme.colors.Grey[20]}`,
            padding: "12px",
            margin: "12px 0",
            boxSizing: "border-box",
          }}
        >
          <Typography component="div" align="left" weight="bold">
            {`${label} ${index + 1}`}:
          </Typography>
          <ObjectListItemFieldSummary field={itemField} />
        </div>
      ))}
    </Column>
  );
};

export default ObjectListFieldSummary;
