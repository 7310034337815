import SectionDivider from "../../../../../components/EditableSectionV2/components/SectionDivider";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import ArchivedProposals from "./ArchivedProposals";
import ProposalDetails from "./ProposalDetails";
import SignedProposal from "./SignedProposal";

export const Proposal = () => {
  const {
    documentData: { proposalResult },
  } = useDocumentDetails();
  return (
    <>
      <ProposalDetails proposalResult={proposalResult || undefined} />
      <SectionDivider />
      <SignedProposal />
      <SectionDivider />
      <ArchivedProposals />
    </>
  );
};
