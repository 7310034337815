import { UserRoles } from "../../graphql/generated/types";
import { useAuth } from "../../hooks/useAuth";
import { MyProfileLocation } from "../../Locations";
import CommissionPaymentData from "./components/CommissionPaymentData";

export const useProfileTabs = () => {
  const { user } = useAuth();
  if (!user || ![UserRoles.Consultant].includes(user.role as UserRoles))
    return [];
  return [
    {
      title: "Pagamento",
      location: MyProfileLocation,
      element: CommissionPaymentData,
      isIndex: true,
    },
  ];
};
