/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import {
  DocumentClaimStatus,
  DocumentRejectReason,
  DocumentStatus,
  Services,
  useModelTasksQuery,
} from "../../../../../../graphql/generated/types";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import {
  formatDateWithTime,
  formatISODate,
} from "../../../../../../utils/dateUtils";
import { concatArrayOfStrings } from "../../../../../../utils/stringUtils";
import { useFlags } from "../../../../../../hooks/useFlags";
import { salesChannelLabels } from "../../../../../../shared/salesChannel";

const claimStatusLabels = {
  [DocumentClaimStatus.Closed]: "Fechado",
  [DocumentClaimStatus.Open]: "Aberto",
};

const InsuranceInfo = () => {
  const {
    documentData: {
      stage,
      status,
      createdAt,
      archived,
      rejectReason,
      provider,
      claimStatus,
      updatedAt,
      policy,
      insuranceTypeGroup,
      _id,
      name,
      salesChannel,
    },
  } = useDocumentDetails();

  const DocumentRejectReasonLabels = {
    [DocumentRejectReason.NotAvailable]: "Produto indisponível",
    [DocumentRejectReason.OutOfCoveragePeriod]: "Fora da época de contratação",
    [DocumentRejectReason.PriceTooHigh]: "Valor muito alto",
    [DocumentRejectReason.ContactLost]: "Contato perdido",
    [DocumentRejectReason.Other]: "Outra",
  };

  const { data: tasksData } = useModelTasksQuery({
    variables: {
      serviceName: Services.Documents,
      objectId: _id,
    },
  });

  const insuranceInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      status === DocumentStatus.Rejected && {
        label: "Motivo da Recusa",
        value:
          rejectReason?.rejectReason === DocumentRejectReason.Other
            ? `${DocumentRejectReasonLabels[DocumentRejectReason.Other]}: ${
                rejectReason.other
              }`
            : rejectReason &&
              `${DocumentRejectReasonLabels[rejectReason.rejectReason]}`,
      },
      {
        label: "Criado em",
        value: formatDateWithTime(createdAt),
      },
      {
        label: "Atualizado em",
        value: formatDateWithTime(updatedAt),
      },
      policy && {
        label: "Vigência da apólice",
        value: `${formatISODate(policy.startDate)} - ${formatISODate(
          policy.endDate
        )}`,
      },
      claimStatus && {
        label: "Sinistro",
        value: claimStatusLabels[claimStatus],
      },
      {
        label: "Canal de vendas",
        value: salesChannelLabels[salesChannel!],
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [
    archived,
    stage,
    status,
    policy,
    claimStatus,
    rejectReason,
    createdAt,
    updatedAt,
    insuranceTypeGroup,
    name,
    salesChannel,
  ]);

  const approvedInsuranceInfo = useMemo(() => {
    if (provider) {
      return [
        {
          label: "Seguradora",
          value: provider.toUpperCase(),
        },
      ];
    }
    return [];
  }, [provider]);

  const { isFlagEnabled } = useFlags();

  const taskInfo = useMemo(() => {
    if (!isFlagEnabled("admin-only")) return [];
    return [
      {
        label: "Próxima(s) Tarefa(s)",
        value:
          tasksData?.modelTasks &&
          concatArrayOfStrings(
            tasksData?.modelTasks?.map((task) => task.message)
          ),
      },
    ];
  }, [tasksData, isFlagEnabled]);

  return (
    <DetailsSectionColumn
      xs={12}
      sm={6}
      rows={[...insuranceInfo, ...taskInfo, ...approvedInsuranceInfo]}
    />
  );
};

export default InsuranceInfo;
