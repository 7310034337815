import { useState } from "react";
import useDebounce from "./useDebounce";

export const useConversationFilter = () => {
  const [onlySubscribeds, setOnlySubscribeds] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedValue: debouncedSearchTerm } = useDebounce(searchTerm, 500);
  const limit = 50;
  const skip = (page - 1) * limit;
  return {
    skip,
    page,
    setPage,
    limit,
    onlySubscribeds,
    setOnlySubscribeds,
    searchTerm: debouncedSearchTerm,
    setSearchTerm,
  };
};
