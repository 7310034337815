import { useEffect, useState } from "react";
import { useFlags } from "./useFlags";
import { useLocation } from "react-router-dom";
import { IconNames } from "../components/Icons/styles/iconNames";

type LayoutContent = "chat" | "notes" | "main";

export const useChatAndNotesActions = () => {
  const { isAdmin } = useFlags();
  const [content, setContent] = useState<LayoutContent>("main");
  const location = useLocation();

  useEffect(() => {
    setContent("main");
  }, [location]);

  const tabs = [
    {
      id: "notes" as LayoutContent,
      label: "NOTAS",
      icon: IconNames.Notes,
    },
  ];

  if (isAdmin()) {
    tabs.unshift({
      id: "chat" as LayoutContent,
      label: "CHAT",
      icon: IconNames.Chat,
    });
  }

  const handleClick = (clickedTab: LayoutContent) => {
    if (clickedTab === content) {
      setContent("main");
    } else {
      setContent(clickedTab);
    }
  };

  return { content, tabs, handleClick, setContent };
};
