/** @jsxImportSource @emotion/react */

import React, { CSSProperties, ReactElement, ReactNode } from "react";
import { Row as GridRow, useScreenClass } from "react-grid-system";
import { Align, Direction, Justify, ScreenClassMap } from "./Container";
import { css } from "@emotion/react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";

export interface RowProps extends ScreenClassMap<boolean> {
  align?: Align;
  justify?: Justify;
  direction?: Direction;
  noGutters?: boolean;
  noWrap?: boolean;
  gutterWidth?: number;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  noMargins?: boolean;
  noBottomMargin?: boolean;
  onClick?: React.MouseEventHandler;
}

const getCSSRules = ({ ...props }) => {
  if (props.noMargins) return {};
  return {
    xs: {
      marginBottom: props.gutters.xs,
    },
    sm: {
      marginBottom: props.gutters.sm,
    },
    md: {
      marginBottom: props.gutters.md,
    },
    lg: {
      marginBottom: props.gutters.lg,
    },
    xl: {
      marginBottom: props.gutters.xl,
    },
    xxl: {
      marginBottom: props.gutters.xxl,
    },
  };
};

const getMarginCSSRules = ({ ...props }) => {
  const margins: Record<string, string> = {};
  if (props.noMargins) {
    margins.marginLeft = "0 !important";
    margins.marginRight = "0 !important";
  }

  if (props.noBottomMargin) {
    margins.marginBottom = "0 !important";
  }

  return margins;
};

//@ts-nocheck

const Row = (props: RowProps): ReactElement => {
  const styles = useCSSRulesWithTheme(getCSSRules, props);
  const marginStyles = useCSSRulesWithTheme(getMarginCSSRules, props);
  const screenClass = useScreenClass();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { noGutters, noWrap, noMargins: _, ...rest } = props;

  return (
    <GridRow
      {...rest}
      nogutter={noGutters}
      wrap={noWrap ? "nowrap" : "wrap"}
      //@ts-ignore
      css={css(styles[screenClass], marginStyles)}
    />
  );
};

export default Row;
