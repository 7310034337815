import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import PropertyImprovementData from "./PropertyImprovementData";
import { PropertyImprovementItemLabels } from "../../../../../shared/propertyImprovements/propertyImprovementLabels";
import EditableTable from "../../../../../components/EditableTable";
import { GetCreatePropertyImprovementOnDocumentFormResponseDocument } from "../../../../../graphql/generated/types";

const PropertyImprovementsTable: FC = () => {
  const {
    documentData: { propertyImprovementItems, _id },
    refetch,
  } = useDocumentDetails();

  const data = useMemo(
    () =>
      propertyImprovementItems?.map((improvementItem) => ({
        ...improvementItem,
        improvement: {
          ...improvementItem.improvement,
          name:
            improvementItem.manualImprovement ||
            improvementItem.improvement.name,
        },
      })),
    [propertyImprovementItems]
  );

  return (
    <DetailsSection title="Benfeitorias">
      <EditableTable
        createQuery={GetCreatePropertyImprovementOnDocumentFormResponseDocument}
        createPayload={{ documentId: _id }}
        data={data || []}
        onSubmit={refetch}
        useV2
        formDisplay="modal"
        columns={[
          {
            label: PropertyImprovementItemLabels.improvement,
            key: "improvement",
            special: "many2one",
          },
          {
            label: PropertyImprovementItemLabels.property,
            key: "property",
            special: "many2one",
          },
          {
            label: PropertyImprovementItemLabels.riskValue,
            key: "riskValue",
            special: "monetary",
          },
          {
            label: PropertyImprovementItemLabels.isRenewal,
            key: "isRenewal",
            special: "boolean",
          },
          {
            label: PropertyImprovementItemLabels.hasGoodsInsurance,
            key: "hasGoodsInsurance",
            special: "boolean",
          },
        ]}
        DetailsComponent={PropertyImprovementData}
      />
    </DetailsSection>
  );
};

export default PropertyImprovementsTable;
