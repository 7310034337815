import React, { FC } from "react";
import { CSSObjectWithLabel } from "react-select";
import Select, { SingleValue } from "react-select";
import { theme } from "../../../theme";

interface FilterSelectProps {
  value: FilterOption | null;
  options: FilterOption[];
  onChange: (option: SingleValue<FilterOption>) => void;
  placeholder?: string;
  disabled: boolean;
}

const customSelectStyles = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: "inline-flex",
    alignItems: "center",
    padding: "4px 12px",
    paddingLeft: "20px",
    borderRadius: "24px",
    border: "1px solid transparent",
    width: "100%",
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    minHeight: "auto",
    width: "100%",
    "&:hover": {
      border: "none",
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 4,
    fontWeight: "bold",
    color: "#000",
    textAlign: "left",
    fontSize: "24px",
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "#000",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    textAlign: "left",
    fontSize: "24px",
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "#000",
    margin: 0,
    padding: 0,
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    padding: 0,
    transition: "transform 0.2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
    color: "#000",
    "> svg": {
      width: "22px",
      height: "22px",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    border: `1px solid ${theme.colors.Grey[20]}`,
    borderRadius: "16px",
    overflow: "hidden",
    boxShadow: theme.boxShadow.default,
    marginTop: "4px",
    minWidth: "200px",
    zIndex: "1000",
    left: 0,
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    padding: "10px 20px",
    fontSize: "16px",
    color: "#000",
    fontWeight: state.isSelected ? "bold" : "normal",
    backgroundColor: state.isFocused ? theme.colors.Green[20] : "#fff",
    cursor: "pointer",
    textAlign: "left",
    "&:hover": {
      backgroundColor: state.isFocused
        ? theme.colors.Green[20]
        : theme.colors.LightGrey[80],
    },
  }),
};

export type FilterOption = {
  value: string;
  label: string;
};

export const FilterSelect: FC<FilterSelectProps> = ({
  value,
  options,
  onChange,
  placeholder,
  disabled,
}) => {
  return (
    <Select
      placeholder={placeholder || "Selecione um filtro rápido"}
      styles={customSelectStyles as any}
      options={options}
      value={value}
      onChange={onChange}
      // We'll disable the built-in clear:
      isClearable={false}
      isSearchable={false}
      className="select"
      isDisabled={disabled}
    />
  );
};
