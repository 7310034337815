/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import Typography from "../../../../../components/Typography";
import ResponsibleModalSelect from "./components/ResponsibleModalSelect";
import Button from "../../../../../components/Button";

interface UpdateResponsiblesModalProps {
  label: string;
  onClick: (id: string) => void;
}

const UpdateResponsiblesModal: React.FC<UpdateResponsiblesModalProps> = ({
  label,
  onClick,
}) => {
  const [selected, setSelected] = useState("");
  return (
    <div>
      <Typography style={{ marginBottom: 30 }} variant="h2" margin="none">
        ALTERAR RESPONSÁVEL {label}
      </Typography>
      <ResponsibleModalSelect
        value={selected}
        onChange={(id: string) => setSelected(id)}
      />
      <Button text="Salvar" onClick={() => onClick(selected)} />
    </div>
  );
};

export default UpdateResponsiblesModal;
