/** @jsxImportSource @emotion/react */

import React from "react";
import { CSSObject } from "@emotion/react";
import { DoughnutChart } from "./DoughnutChart";
import { useMetricsContext } from "../MetricsContext";

const styles: CSSObject = {
  aggregationWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
    gap: "30px",
    paddingBottom: "30px",
    padding: "auto",
  },
};

const ChartsList: React.FC = () => {
  const { chartData } = useMetricsContext();

  return (
    <div css={styles.aggregationWrapper}>
      {chartData.map((props) => (
        <DoughnutChart key={props.chartId} {...props} />
      ))}
    </div>
  );
};

export default ChartsList;
