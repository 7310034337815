import { FC } from "react";

const Return: FC = () => {
  return (
    <svg
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="m22,8v2c2.2061,0,4,1.7944,4,4s-1.7939,4-4,4h-12v-5l-6,6,6,6v-5h12c3.3086,0,6-2.6914,6-6s-2.6914-6-6-6Z" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&amp;lt;Transparent Rectangle&amp;gt;"
        style={{ fill: "none" }}
        width="32"
        height="32"
      />
    </svg>
  );
};

export default Return;
