/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { CustomTheme } from "../../../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { InputVariant, Maybe } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";

type DefaultFieldSummaryProps = {
  label: string;
  value: string;
  variant?: Maybe<InputVariant>;
};

const getStyles: CSSRulesResolver = ({ colors }: CustomTheme) => ({
  formField: {
    color: colors.Black[100],
    width: "100%",
    textAlign: "start",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "4px",
    boxSizing: "border-box",
  },
});

const DefaultFieldSummary: FC<DefaultFieldSummaryProps> = ({
  label,
  value,
}) => {
  const styles = useCSSRulesWithTheme(getStyles);
  return (
    <Column xs={12} md={6}>
      <div css={styles.formField}>
        {label}: {value}
      </div>
    </Column>
  );
};

export default DefaultFieldSummary;
