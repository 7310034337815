/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { useParams } from "react-router-dom";
import { PaymentSummary } from "./PaymentSummary";
import {
  BasePaymentFieldsFragment,
  useGetPaymentQuery,
} from "../../graphql/generated/types";
import Loading from "../../components/Loading";
import { PaymentsWrapper } from "./PaymentsWrapper";
import { CSSObject } from "@emotion/react";
import { DetailsHeader } from "../../components/Details/components/Header/DetailsHeader";
import ScrollContainer from "../../components/ScrollContainer";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    height: "100%",
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    flex: 1,
    padding: "0 12px",
  },
};

const PaymentDetails: FC = () => {
  const { paymentId } = useParams();
  const { loading, data, refetch } = useGetPaymentQuery({
    variables: {
      paymentId: paymentId as string,
    },
  });

  if (loading || !data) return <Loading size="extraLarge" />;
  return (
    <PaymentsWrapper>
      <div css={styles.wrapper}>
        <DetailsHeader title={data.payment.name} />
        <ScrollContainer css={styles.pageContent}>
          <PaymentSummary
            payment={data?.payment as BasePaymentFieldsFragment}
            refetch={refetch}
          />
        </ScrollContainer>
      </div>
    </PaymentsWrapper>
  );
};

export default PaymentDetails;
