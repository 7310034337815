import { FC, useMemo } from "react";
import {
  GetCreateSignedProposalOnFormResponseDocument,
  GetEditSignedProposalOnFormResponseDocument,
  Services,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Typography from "../../../../../components/Typography";
import DetailsSectionColumn from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import EditableSectionV2 from "../../../../../components/EditableSectionV2";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import Icon from "../../../../../components/Icons";
import { ColorNames } from "../../../../../theme";

const SignedProposal: FC = () => {
  const {
    documentData: { proposalResult },
    refetch,
  } = useDocumentDetails();

  const signedProposalInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Proposta Assinada",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: proposalResult?.signedProposalFile?.fileName,
          },
          objectId: proposalResult?._id!,
          fieldPath: "signedProposalFile",
          serviceName: Services.Results,
        },
      },
    ];
  }, [proposalResult]);

  return (
    <EditableSectionV2
      title="Proposta Assinada"
      icon={<Icon name={IconNames.SignedProposal} />}
      createPayload={{ proposalResultId: proposalResult?._id! }}
      createQuery={GetCreateSignedProposalOnFormResponseDocument}
      editQuery={GetEditSignedProposalOnFormResponseDocument}
      addFlag="admin-only"
      editFlag="admin-only"
      objectId={
        proposalResult?.signedProposalFile ? proposalResult._id! : undefined
      }
      actions={[]}
      addText={"Adicionar Proposta Assinada"}
      onSubmit={() => refetch()}
    >
      {!proposalResult?.signedProposalFile?.fileKey ? (
        <Typography variant="textMedium" weight="bold" color={ColorNames.GREY}>
          Proposta não assinada
        </Typography>
      ) : (
        <DetailsSectionColumn rows={signedProposalInfo} />
      )}
    </EditableSectionV2>
  );
};

export default SignedProposal;
