/** @jsxImportSource @emotion/react */
import TableCell, { TableCellOptions } from "./TableCell";
import Row from "../Grid/Row";
import { CSSObject } from "@emotion/react";
import { ColorNames, theme } from "../../theme";
import OverflowMenu, { TOverflowMenuOptions } from "../OverflowMenu";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import Column from "../Grid/Column";
import { IconNames } from "../Icons/styles/iconNames";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import useDetailsColor from "../../hooks/useDetailsColor";
import { useModalStore } from "../Modal/context/ModalStore";
import { useFlags } from "../../hooks/useFlags";

export interface TableRowColumn<T> extends TableCellOptions {
  key: keyof T;
}

export interface TableAction<T> {
  flag?: string;
  label: string;
  onClick: (data: T) => void;
  iconName?: IconNames;
  disabled?: (data: T) => boolean;
}

interface TableRowProps<T> extends Partial<PropsWithChildren> {
  columns: TableRowColumn<T>[];
  data: T;
  actions?: TableAction<T>[];
  borderColor?: ColorNames;
  DetailsComponent?: FC<{ data: T }>;
  forceActionsColumn?: boolean;
}

const getStyles = ({
  props,
}: {
  props: Partial<TableRowProps<any>>;
}): CSSObject => ({
  row: {
    borderBottom: `1px solid ${theme.colors.Grey[20]}`,
    "&:hover": {
      backgroundColor: theme.colors.Grey[20],
    },
    margin: 0,
    cursor: props.DetailsComponent ? "pointer" : undefined,
  },
});

const TableRow = <T,>(props: TableRowProps<T>) => {
  const {
    columns,
    data,
    actions = [],
    children,
    borderColor,
    DetailsComponent,
  } = props;
  const { isFlagEnabled } = useFlags();

  const actionOptions: TOverflowMenuOptions[] = useMemo(() => {
    return actions
      .map((action) => {
        const disabled = action.disabled ? action.disabled(data) : false;
        return {
          text: action.label,
          onClick: () => action.onClick(data),
          iconName: action.iconName,
          flag: action.flag,
          disabled: disabled,
        };
      })
      .filter((action) => !action.flag || isFlagEnabled(action.flag));
  }, [data, ...actions]);

  const { openModal } = useModalStore();

  const styles = useCSSRulesWithTheme(getStyles, { props });
  const { color } = useDetailsColor();

  const onClick = useCallback(() => {
    if (DetailsComponent) {
      openModal(<DetailsComponent data={data} />);
    }
  }, [data, openModal, DetailsComponent]);

  if (children) {
    return (
      <Row css={styles.row} align="center">
        {children}
      </Row>
    );
  }
  return (
    <Row align="center" css={styles.row} onClick={onClick}>
      {columns.map((column, i) => (
        <TableCell
          {...column}
          key={`cell-${i}`}
          value={data[column.key] as string}
          borderColor={borderColor}
        />
      ))}
      {(actions.length > 0 || props.forceActionsColumn) && (
        <Column xs={1}>
          {actionOptions.length > 0 && (
            <OverflowMenu options={actionOptions} vertical hoverColor={color} />
          )}
        </Column>
      )}
    </Row>
  );
};

export default TableRow;
