/** @jsxImportSource @emotion/react */

import { FC, useMemo, useState } from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames, theme } from "../../theme";
import { headerHeight } from "../Layout/components/Header";
import { totalBreadcrumbsHeight } from "../Breadcrumbs";
import useOutsideClick from "../../hooks/useOutsideClick";
import BasicSearch from "./BasicSearch";
import { Tags } from "../Tags";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import useTags from "../../hooks/useTags";

const getStyles: CSSRulesResolver<{ open: boolean; hasFilters: boolean }> = ({
  open,
  hasFilters,
}) => ({
  container: {
    minHeight: 42,
    position: "relative",
    backgroundColor: theme.colors.LightGrey[100],
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: hasFilters ? 0 : 12,
    borderBottomRightRadius: hasFilters ? 0 : 12,
    padding: 6,
    boxSizing: "border-box",
  },
  filters: {
    position: "absolute" as const,
    top: totalBreadcrumbsHeight,
    right: 0,
    backgroundColor: theme.colors.White[100],
    zIndex: 100,
    width: "100%",
    // fix this properly later plz
    height: `calc(100vh - ${headerHeight + totalBreadcrumbsHeight + 110}px)`,
    padding: 24,
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box",
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.colors.Black[100],
  },
  filterCount: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.colors.Green[100],
    boxShadow: theme.boxShadow.default,
    color: theme.colors.White[100],
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
  dropdown: {
    position: "absolute",
    display: "flex",
    zIndex: 11000,
    flexDirection: "column",
    gap: 8,
    left: 0,
    right: 0,
    height: !hasFilters && !open ? 0 : "auto",
    maxHeight: open ? "80vh" : 32,
    transition: "max-height .1s ease",
    overflow: "hidden",
    backgroundColor: theme.colors.LightGrey[100],
    padding: "0 6px",
    borderBottomLeftRadius: open ? 18 : 12,
    borderBottomRightRadius: open ? 18 : 12,
  },
});

interface SearchBarProps {
  filter: TModelFilter;
  dropdown: React.ReactNode;
}

const SearchBar: FC<SearchBarProps> = ({ filter, dropdown }) => {
  const [showFilters, setShowFilters] = useState(false);
  const { tags } = useTags(filter);

  const ref = useOutsideClick(() => {
    setShowFilters(false);
  });
  const filterCount = useMemo(() => {
    return filter.filters.filter((filter) => filter.value.length > 0).length;
  }, [filter.filters]);

  const styles = useCSSRulesWithTheme(getStyles, {
    open: showFilters,
    hasFilters: filterCount > 0,
  });

  return (
    <div ref={ref} css={styles.container}>
      {filterCount > 0 && <div css={styles.filterCount}>{filterCount}</div>}
      <BasicSearch
        searchTerm={filter.searchTerm}
        setSearchTerm={filter.setSearchTerm}
      >
        <Icon
          name={IconNames.Filter}
          color={ColorNames.GREY}
          onClick={() => setShowFilters(!showFilters)}
        />
      </BasicSearch>
      <div css={styles.dropdown}>
        <Tags
          open={showFilters}
          tags={tags}
          removeCallback={(tag) => {
            const currentValue = filter.getFilter(tag.filterName).value;
            let newValue = [...currentValue];
            newValue = currentValue.filter((value) => value !== tag.value);
            filter.setFilterValue(tag.filterName, newValue);
          }}
        />
        {dropdown}
      </div>
    </div>
  );
};

export default SearchBar;
