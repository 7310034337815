import { FC, useMemo } from "react";
import { useFlags } from "../../../../../hooks/useFlags";
import {
  DocumentStages,
  DocumentStatus,
  GetCreateResultOnDocumentFormResponseDocument,
  GetEditResultOnDocumentFormResponseDocument,
} from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import FailedQuotations from "./components/FailedQuotations";
import useQuotationFooter from "./hooks/useQuotationsFooter";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import ValidQuotations from "./components/ValidQuotations";
import QuotationComment from "./components/QuotationComment";
import EditableSectionV2 from "../../../../../components/EditableSectionV2";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

const Quotations: FC = () => {
  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");

  const {
    refetch,
    documentData: { status, _id, stage },
    editingQuotationResultId,
  } = useDocumentDetails();

  const footerButtons = useQuotationFooter();

  const currentStageStatus = useDocumentOrder(stage!, status!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);
  const simulationPending = useDocumentOrder(
    DocumentStages.Simulation,
    DocumentStatus.Pending
  );

  const createQuery = useMemo(
    () =>
      currentStageStatus === simulationPending
        ? GetCreateResultOnDocumentFormResponseDocument
        : undefined,
    [currentStageStatus]
  );

  const editQuery = useMemo(
    () =>
      currentStageStatus < policyStage
        ? GetEditResultOnDocumentFormResponseDocument
        : undefined,
    [currentStageStatus]
  );

  return (
    <EditableSectionV2
      title="Todas as Cotações"
      icon={<Icon name={IconNames.Quotation} />}
      createPayload={{ documentId: _id! }}
      createQuery={createQuery}
      editQuery={editQuery}
      editingObjectId={editingQuotationResultId}
      actions={footerButtons}
      addText={"Adicionar Cotação"}
      addFlag={"admin-only"}
      onSubmit={refetch}
      useV2
    >
      {isAdmin && <FailedQuotations />}
      <ValidQuotations />
      <QuotationComment />
    </EditableSectionV2>
  );
};

export default Quotations;
