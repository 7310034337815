import { ListPaymentsQuery } from "../../graphql/generated/types";
import Card, { CardFC } from "../../components/CardList/components/Card";
import { ColorNames } from "../../theme";
import { formatPrice } from "../../utils/formatNumber";
import { PaymentStatusLabels } from "../Documents/DocumentDetails/sections/Payments/PaymentList";
import { formatISODate } from "../../utils/dateUtils";
import Responsibles from "../Documents/DocumentCards/components/Responsibles";

const PaymentCard: CardFC<ListPaymentsQuery["payments"][number]> = ({
  data,
  active,
  onClick,
}) => {
  const cardData = {
    line1: data.name || "",
    line2: formatPrice(data.amount),
    line3: PaymentStatusLabels[data.status],
    line4: formatISODate(data.dateDue),
  };

  return (
    <Card
      cardData={cardData}
      color={ColorNames.GREEN}
      active={active}
      onClick={onClick}
      detailBottom={
        <Responsibles
          responsibles={[data.responsible?.name!]}
          color={ColorNames.VALID_GREEN}
          docId={data._id!}
        />
      }
    />
  );
};

export default PaymentCard;
