import { DefaultPublicFormLayout } from "../../components/DefaultPublicFormLayout";
import Typography, { FontSizes } from "../../components/Typography";

export const ClaimNotice = () => {
  return (
    <DefaultPublicFormLayout
      formId="claimNotice"
      successMessage="Entraremos em contato em breve para dar continuidade ao processo do seu sinistro."
    >
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <Typography
          variant="textMedium"
          weight="bold"
          size={FontSizes.ExtraLarge}
        >
          Informe de sinistro
        </Typography>
      </div>
    </DefaultPublicFormLayout>
  );
};
