import React from "react";
import { DocumentStatus } from "../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import ArchivedPolicies from "./ArchivedPolicies";
import PolicyData from "./PolicyData";
import SectionDivider from "../../../../../components/EditableSectionV2/components/SectionDivider";

export const Policy: React.FC = () => {
  const {
    documentData: { policy, status },
  } = useDocumentDetails();

  return (
    <>
      <PolicyData
        cancelled={status === DocumentStatus.Cancelled}
        policy={policy || undefined}
      />
      <SectionDivider />
      <ArchivedPolicies />
    </>
  );
};
