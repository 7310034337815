/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import QuotationCard, {
  sortResults,
  useQuotationCardColumns,
} from "../../../components/QuotationCard/index";
import Row from "../../../../../../components/Grid/Row";
import Column from "../../../../../../components/Grid/Column";
import { useFlags } from "../../../../../../hooks/useFlags";
import Typography from "../../../../../../components/Typography";
import Observations from "../../../components/Observations";
import { DetailsDivision } from "../../../../../../components/ModelDetails/DetailsDivision";

const ValidQuotations: FC = () => {
  const {
    documentData: { selectedSimulationResults, validSimulationResults },
    observations,
  } = useDocumentDetails();

  const styles = {
    row: {
      marginBottom: 0,
    },
    col: {
      marginBottom: 20,
    },
  };

  const { isFlagEnabled } = useFlags();

  const resultsToShow = useMemo(() => {
    return sortResults(
      isFlagEnabled("admin-only")
        ? validSimulationResults || []
        : selectedSimulationResults || []
    );
  }, [validSimulationResults, selectedSimulationResults, isFlagEnabled]);

  if (!resultsToShow?.length) {
    if (!isFlagEnabled("admin-only")) {
      return (
        <Typography variant="textMedium" uppercase>
          Suas informações foram registradas! Entraremos em contato em breve
        </Typography>
      );
    }
    return (
      <>
        <DetailsDivision />
        <Typography variant="textMedium" uppercase>
          Não há resultados automáticos para a cotação
        </Typography>
      </>
    );
  }

  return (
    <>
      <Row css={styles.row}>
        {resultsToShow.map((result) => (
          <Column
            xs={12}
            xl={useQuotationCardColumns(result!) ? 12 : 6}
            xxl={useQuotationCardColumns(result!) ? 12 : 4}
            key={`quotationCard-${result?._id}`}
            css={styles.col}
          >
            <QuotationCard result={result!} />
          </Column>
        ))}
      </Row>
      <Row>
        <Column>
          <Observations observations={observations} />
        </Column>
      </Row>
    </>
  );
};

export default ValidQuotations;
