/** @jsxImportSource @emotion/react */

import { FC, Fragment } from "react";
import QuotationCardRow, { IQuotationCardRow } from "./QuotationCardRow";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import Row from "../../../../../components/Grid/Row";
import Typography from "../../../../../components/Typography";
import Column from "../../../../../components/Grid/Column";

export type QuotationCardSectionRow =
  | IQuotationCardRow
  | { text: string }
  | { break: boolean };

interface QuotationCardSectionProps {
  rows: QuotationCardSectionRow[];
  backgroundColor?: string;
}

const getCSSRules: CSSRulesResolver<{ backgroundColor: string }> = ({
  backgroundColor,
}) => ({
  container: {
    backgroundColor: backgroundColor,
    padding: 20,
    margin: 6,
    borderRadius: 15,
  },
  row: {
    marginBottom: 5,
    ":last-of-type": {
      marginBottom: 0,
    },
  },
  break: {
    marginTop: 5,
  },
});

const QuotationCardSection: FC<QuotationCardSectionProps> = ({
  rows,
  backgroundColor = "transparent",
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { backgroundColor });
  return (
    <div css={styles.container}>
      {rows.map((row, index) => (
        <Fragment key={`quotationCardRow-${index}`}>
          {"label" in row && (
            <Row
              css={styles.row}
              key={`quotationCardRow-${index}`}
              align="center"
            >
              <QuotationCardRow row={row} key={index} />
            </Row>
          )}
          {"text" in row && (
            <Row css={styles.row} key={`quotationCardRow-${index}`}>
              <Column xs={12}>
                <Typography variant="textMedium" component="div" align="left">
                  {row.text}
                </Typography>
              </Column>
            </Row>
          )}
          {"break" in row && (
            <Row css={styles.break} key={`quotationCardRow-${index}`}></Row>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default QuotationCardSection;
