/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import {
  BasicCoverageTypes,
  DocumentStages,
  DocumentStatus,
  Maybe,
  Result,
  ResultStatus,
  ResultTags,
} from "../../../../../graphql/generated/types";
import QuotationCardContent from "./QuotationCardContent";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import Icon from "../../../../../components/Icons";
import { IconSizes } from "../../../../../components/Icons/styles/iconSizes";
import { ColorNames, theme } from "../../../../../theme";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import OverflowMenu from "../../../../../components/OverflowMenu";
import { useFlags } from "../../../../../hooks/useFlags";
import PrismaRecommendation, {
  recommendationHeight,
} from "./PrismaRecommendation";
import CardTags from "./CardTags";
import { FontSizes } from "../../../../../components/Typography";

// Tratando paramétrico como vários itens
export const useQuotationCardColumns = (result: Result) =>
  (!!result.itens?.length && result.itens.length > 1) ||
  (result.basicCoverageType === BasicCoverageTypes.Parametrico &&
    result.itens![0].coverages.length > 1);

export const sortResults = (results: Maybe<Result>[]) => {
  return [...(results || [])].sort((a, b) => {
    if (a?.basicCoverageType === b?.basicCoverageType) {
      return 0;
    }
    if (a?.basicCoverageType === BasicCoverageTypes.Parametrico) {
      return -1;
    }
    return 1;
  });
};

interface QuotationCardProps {
  result: Result;
  directDocument?: boolean;
}

const getCSSRules: CSSRulesResolver<{
  active: boolean;
  selectable: boolean;
  recommended?: boolean;
  recommendation?: boolean;
}> = ({ active, selectable, recommended, recommendation }) => ({
  card: {
    height: "100%",
    marginTop: recommended || !recommendation ? 0 : recommendationHeight,
    "&:hover": selectable && {
      "& > div:first-of-type": {
        borderColor: theme.colors.Blue[100],
        backgroundColor: recommended ? theme.colors.Blue[100] : "transparent",
        transition: "border-color 0.3s, background-color 0.3s",
      },
      "& > div:last-of-type": {
        border: "3px solid",
        borderColor: theme.colors.Blue[100],
        backgroundColor: theme.colors.LightGrey[60],
        transition: "border-color 0.3s, background-color 0.3s",
      },
    },
  },
  container: {
    padding: 20,
    height: !recommendation ? "100%" : `calc(100% - ${recommendationHeight}px)`,
    position: "relative" as const,
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box" as const,
    cursor: selectable ? "pointer" : "auto",
    border: selectable && active ? "3px solid" : "2px solid",
    borderColor:
      selectable && active ? theme.colors.Blue[100] : theme.colors.Black[100],
    borderRadius: recommended ? "0 0 15px 15px" : "15px",
  },
  status: {
    backgroundColor:
      selectable && active ? theme.colors.Blue[100] : theme.colors.Green[100],
    borderRadius: "50%",
    position: "absolute" as const,
    width: 24,
    height: 24,
    top: 10,
    left: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.White[100],
    fontSize: FontSizes.Small,
  },
  topRight: {
    position: "absolute" as const,
    top: 10,
    right: 10,
    display: "flex",
    gap: 5,
  },
});

const QuotationCard: FC<QuotationCardProps> = ({ result, directDocument }) => {
  const {
    documentData: {
      crop,
      stage,
      status,
      recommendedSimulationResultId,
      selectedResultIds,
    },
    handleEditQuotationResult,
    handleDeleteResult,
    handleRecommendSimulationResult,
    handleRemoveRecommendedSimulationResult,
    handleSelectResults,
  } = useDocumentDetails();
  const statusIconName = useMemo(() => {
    if (result.status === ResultStatus.Sent) return IconNames.Sent;
    if (result.status === ResultStatus.Approved) return IconNames.Valid;
    return;
  }, [result.status]);

  const { isFlagEnabled } = useFlags();

  const editable = useMemo(() => {
    return (
      stage === DocumentStages.Simulation &&
      status === DocumentStatus.Pending &&
      !directDocument &&
      isFlagEnabled("admin-only")
    );
  }, [stage, status, isFlagEnabled, directDocument]);

  const selectable = useMemo(() => {
    return (
      stage === DocumentStages.Simulation &&
      status === DocumentStatus.Done &&
      !directDocument &&
      isFlagEnabled("admin-only")
    );
  }, [stage, status, isFlagEnabled, directDocument]);

  const approvable = useMemo(() => {
    return (
      stage === DocumentStages.Simulation &&
      (status === DocumentStatus.Sent || status === DocumentStatus.Done) &&
      !directDocument &&
      isFlagEnabled("admin-only")
    );
  }, [stage, status, isFlagEnabled, directDocument]);

  const active = useMemo(
    () => !!selectedResultIds?.includes(result._id!),
    [result._id, selectedResultIds]
  );

  const handleSelect = () => {
    if (!selectable) return;
    const newSelectedResultIds = active
      ? selectedResultIds!.filter((id) => id !== result._id)
      : [...selectedResultIds!, result._id!];
    handleSelectResults(newSelectedResultIds);
  };

  const resultOrder = useMemo(() => {
    if (!selectedResultIds) return;
    return selectedResultIds?.indexOf(result._id!) + 1;
  }, [selectedResultIds, result._id]);

  const statusContent = useMemo(() => {
    if (resultOrder && selectable) {
      return <span>{resultOrder}</span>;
    }
    if (statusIconName) {
      return (
        <Icon
          name={statusIconName!}
          size={IconSizes.Small}
          color={ColorNames.WHITE}
        />
      );
    }
    return;
  }, [resultOrder, statusIconName]);

  const recommended = useMemo(
    () => recommendedSimulationResultId === result._id,
    [recommendedSimulationResultId, result._id]
  );

  const overflowMenuOptions = useMemo(() => {
    if (editable) {
      return [
        {
          text: "Editar",
          onClick: () => handleEditQuotationResult(result._id!),
          iconName: IconNames.Edit,
        },
        {
          text: "Excluir",
          onClick: () => handleDeleteResult(result._id!),
          iconName: IconNames.Delete,
        },
      ];
    }
    if (approvable) {
      return [
        {
          text: recommended ? "Remover indicação" : "Indicação Prisma",
          onClick: () =>
            recommended
              ? handleRemoveRecommendedSimulationResult()
              : handleRecommendSimulationResult(result._id!),
          iconName: recommended ? IconNames.Delete : IconNames.Star,
        },
      ];
    }
  }, [editable, approvable, result._id, recommendedSimulationResultId]);

  const tags = useMemo(() => {
    if (!result.tags) return [];
    return result.tags?.map((tag) => tag) as ResultTags[];
  }, [result.tags]);

  const styles = useCSSRulesWithTheme(getCSSRules, {
    active,
    selectable,
    recommended,
    recommendation: recommendedSimulationResultId !== null,
  });

  return (
    <div css={styles.card}>
      {recommended && (
        <PrismaRecommendation active={active} selectable={selectable} />
      )}
      <div css={styles.container} onClick={handleSelect}>
        {(!directDocument || result.status === ResultStatus.Approved) &&
          statusContent && <div css={styles.status}>{statusContent}</div>}
        <div css={styles.topRight}>
          {!!tags.length && <CardTags tags={tags} />}
          {overflowMenuOptions && (
            <OverflowMenu options={overflowMenuOptions} />
          )}
        </div>
        <QuotationCardContent
          result={result}
          directDocument={directDocument}
          crop={crop!}
        />
      </div>
    </div>
  );
};

export default QuotationCard;
