/** @jsxImportSource @emotion/react */
import React from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";

interface StyleParams {
  selected: boolean;
}

interface PanelButtonProps extends StyleParams {
  icon: IconNames;
  onClick: () => void;
}

const getCSSRules: CSSRulesResolver<StyleParams> = ({ selected }) => ({
  button: {
    width: "100%",
    backgroundColor: selected ? "white" : "transparent",
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const PanelButton: React.FC<PanelButtonProps> = ({
  icon,
  selected,
  onClick,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { selected });
  return (
    <div onClick={onClick} css={styles.button}>
      <Icon name={icon} />
    </div>
  );
};

export default PanelButton;
