/** @jsxImportSource @emotion/react */

import { FC, useRef } from "react";
import { CSSObject } from "@emotion/react";
import Typography, { BaseFontSize } from "../../Typography";
import { ColorNames, theme } from "../../../theme";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import MediaInput from "./MediaInput";
import FileList from "./FileList";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import LoadingOverlay from "../../Loading/LoadingOverlay";

const styles: CSSObject = {
  chatInputContainer: {
    marginTop: "10px",
    backgroundColor: theme.colors.LightGrey[100],
    padding: "40px",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: theme.borderRadius.xxl,
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  textarea: {
    borderRadius: theme.borderRadius.xxl,
    border: "none",
    backgroundColor: theme.colors.White[100],
    fontSize: BaseFontSize,
    fontFamily: "inherit",
    // minHeight: "40px",
    width: "100%",
    resize: "none",
    overflowY: "auto",
    maxHeight: "150px",
    padding: "36px 24px",
    boxSizing: "border-box",
    outline: "none",
    marginRight: 10,
    boxShadow: `0px 2px 2px 0px ${theme.colors.Grey[20]}`,
    "&::placeholder": {
      color: theme.colors.Grey[100],
    },
  },
  uploadButton: {
    cursor: "pointer",
    marginRight: 10,
  },
  loadingMsg: {
    marginRight: 10,
  },
  loadingOverlay: {
    borderRadius: theme.borderRadius.xxl,
  },
};

interface ChatInputProps {
  sendMessage: () => Promise<void>;
  setMessage: (value: React.SetStateAction<string>) => void;
  message: string;
  loading: boolean;
  onFilesAccepted: (files: File[]) => void;
  files: File[];
  setFiles: (files: File[]) => void;
}

const ChatInput: FC<ChatInputProps> = ({
  setMessage,
  sendMessage,
  message,
  loading,
  onFilesAccepted,
  files,
  setFiles,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to the scroll height
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const disabled = loading || (!files.length && message.trim().length === 0);

  return (
    <MediaInput onFilesAccepted={onFilesAccepted}>
      {({ open }) => (
        <div css={styles.chatInputContainer}>
          {loading && (
            <LoadingOverlay css={styles.loadingOverlay}>
              <>
                <Typography variant="textMedium" css={styles.loadingMsg}>
                  Enviando...{" "}
                </Typography>
                <LoadingSpinner />
              </>
            </LoadingOverlay>
          )}
          {Boolean(files.length) && (
            <FileList files={files} handleRemoveFile={handleRemoveFile} />
          )}
          <div css={styles.flexRow}>
            <span css={styles.uploadButton}>
              <Icon
                name={IconNames.Upload}
                color={ColorNames.GREY}
                onClick={open}
              />
            </span>
            <textarea
              ref={textareaRef}
              css={styles.textarea}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                handleInput();
              }}
              onKeyDown={handleKeyDown}
              placeholder="Digite sua mensagem..."
              rows={1}
            />
            <Icon
              name={IconNames.Send}
              color={ColorNames.BLACK}
              onClick={() => {
                if (!disabled) {
                  sendMessage();
                }
              }}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    </MediaInput>
  );
};

export default ChatInput;
