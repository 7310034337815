/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import DetailsSectionColumn, {
  DetailsSectionColumnProps,
} from "./DetailsSectionColumn";
import Row from "../../../../Grid/Row";

interface DetailsSectionColumnsProps {
  columns: DetailsSectionColumnProps["rows"][];
  noMargins?: boolean;
}

const styles = {
  wrapper: {
    padding: "0 12px",
  },
};

const DetailsSectionColumns: FC<DetailsSectionColumnsProps> = ({
  columns,
  noMargins,
}) => {
  const colSpan = useMemo(() => 12 / (columns.length || 1), [columns.length]);
  return (
    <div css={styles.wrapper}>
      <Row noMargins={noMargins}>
        {columns.map((column, index) => {
          return (
            <DetailsSectionColumn
              xs={12}
              xl={colSpan}
              key={index}
              rows={column}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default DetailsSectionColumns;
