/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import Column from "../Grid/Column";
import Typography, { FontWeight, TextAlign } from "../Typography";
import { formatISODate } from "../../utils/dateUtils";
import { ColorNames } from "../../theme";
import { CSSObject } from "@emotion/react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { formatPrice } from "../../utils/formatNumber";

export interface TableCellOptions {
  special?:
    | "monetary"
    | "date"
    | "boolean"
    | "many2one"
    | "many2many"
    | "multiSelect"
    | "selectionOption";
  options?: Record<string, string>;
  textAlign?: TextAlign;
  fontWeight?: FontWeight;
}

type SelectionOption = { label?: string };

interface TableCellProps extends TableCellOptions {
  value:
    | string
    | number
    | { name?: string; displayName?: string }
    | string[]
    | SelectionOption;
  borderColor?: ColorNames;
}

const getStyles = (): CSSObject => ({
  cell: {
    textAlign: "left",
    padding: "10px 0",
  },
});

const TableCell: FC<TableCellProps> = ({
  special,
  options,
  value,
  textAlign,
  fontWeight,
}) => {
  const displayValue = useMemo(() => {
    if (options) return options[value as string];
    if (!special) return value;
    if (special === "monetary") return formatPrice(value as number);
    if (special === "boolean")
      return value === null ? "-" : value ? "Sim" : "Não";
    if (special === "many2one")
      return (
        (value as { displayName: string })?.displayName ||
        (value as { name: string })?.name
      );
    if (special === "many2many")
      return (value as { name: string }[])?.map((item) => item.name).join(", ");
    if (special === "date") return formatISODate(value as string);
    if (special === "selectionOption") return (value as SelectionOption)?.label;
    if (special === "multiSelect") return (value as string[]).join(", ");
  }, [special, options, value]);

  const styles = useCSSRulesWithTheme(getStyles);

  return (
    <Column css={styles.cell}>
      <Typography
        align={textAlign}
        weight={fontWeight}
        variant="textMedium"
        component="div"
      >
        {(displayValue as string) || "-"}
      </Typography>
    </Column>
  );
};

export default TableCell;
