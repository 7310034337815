import { FC, useMemo } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import { SelectInputOptionInterface } from "../../../../components/Input/SelectInputs/SelectInput";
import DefaultFieldWrapper from "../utils/DefaultFieldWrapper";
import ChipSelectInput from "../../../../components/Input/SelectInputs/ChipSelectInput/ChipSelectInput";
import FormWarning from "../FormWarning";

interface RadioFieldProps {
  field: FormFieldV2;
}

const RadioField: FC<RadioFieldProps> = ({ field }) => {
  const { options, value, name, saveAs, disabled, warning } = field;
  const { handleChange, finishEditing, error } = useFormField(field);

  const handleSelect = ({ value, label }: SelectInputOptionInterface) => {
    handleChange(saveAs === "option" ? { value, label } : value);
    finishEditing();
  };

  const inputValue = useMemo(
    () => (saveAs === "option" ? value?.value : value),
    [value, saveAs]
  );

  return (
    <DefaultFieldWrapper field={field}>
      <ChipSelectInput
        options={options!}
        containerId={name}
        values={inputValue ? [inputValue] : []}
        onChange={([newValue]) => {
          handleSelect({
            value: newValue,
            label: options?.find((o) => o.value === newValue)?.label!,
          });
        }}
        isSingleOption
        error={error}
        disabled={disabled || false}
      />
      <FormWarning warning={warning} />
    </DefaultFieldWrapper>
  );
};

export default RadioField;
