/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";

export interface BreadcrumbItem {
  label: string;
  value: string;
  active?: boolean;
}

const Breadcrumb: FC<BreadcrumbItem> = ({ label, value, active }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Button
        text={label}
        onClick={() => navigate(value)}
        width="auto"
        selected={active}
      />
    </div>
  );
};

export default Breadcrumb;
