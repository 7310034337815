import { FC } from "react";
import {
  FormFieldV2,
  FormInputTypes,
} from "../../../../graphql/generated/types";
import TextInput from "../../../../components/Input/TextInput";
import { TInputMasks } from "../../models/formInterfaces";
import useFormField from "../../hooks/useFormField";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import DefaultFieldWrapper from "../utils/DefaultFieldWrapper";
import FormWarning from "../FormWarning";

interface TextFieldProps {
  field: FormFieldV2;
}

const TextField: FC<TextFieldProps> = ({ field }) => {
  const {
    name,
    label,
    value,
    disabled,
    inputMask,
    type,
    warning,
    placeholder,
    inputType,
  } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { maskedValue, handleInputChange, forceDebounce } =
    useDebouncedMaskedValue(
      value,
      editing,
      handleChange,
      inputMask as TInputMasks
    );

  return (
    <DefaultFieldWrapper field={field}>
      <TextInput
        placeholder={placeholder || label.toLowerCase()}
        disabled={disabled!}
        type={inputType === FormInputTypes.Email ? "email" : type}
        name={name}
        value={maskedValue}
        onChange={handleInputChange}
        onFocus={startEditing}
        onBlur={() => {
          finishEditing();
          forceDebounce();
        }}
        errorMessage={error}
      />
      <FormWarning warning={warning} />
    </DefaultFieldWrapper>
  );
};

export default TextField;
