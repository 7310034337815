/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import Header, { headerHeight } from "./components/Header";
import { useAuth } from "../../hooks/useAuth";
import { isOrgOwnerOrAdmin } from "../../views/Contact/utils/tags";

interface LayoutProps {
  children: ReactNode;
  useFullHeight?: boolean;
  padding?: number;
}

const Layout: FC<LayoutProps> = ({ children, useFullHeight, padding = 20 }) => {
  const { isLoggedIn, user, signOut } = useAuth();

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        name={user.name!}
        email={user.email!}
        signOut={signOut}
        isOrgOwnerOrAdmin={isOrgOwnerOrAdmin(user.contact!)}
      />
      <div
        css={{
          height: useFullHeight ? "100%" : `calc(100vh - ${headerHeight}px)`,
          padding: padding,
          boxSizing: "border-box",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Layout;
