import { InsuranceTypeGroupEnum } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const insuranceTypeGroupLabels: TModelOptionLabels<InsuranceTypeGroupEnum> = {
  [InsuranceTypeGroupEnum.Agricultural]: 'Agrícola',
  [InsuranceTypeGroupEnum.Machinery]: 'Máquinas',
  [InsuranceTypeGroupEnum.Forest]: 'Florestal',
  [InsuranceTypeGroupEnum.PropertyImprovement]: 'Benfeitorias',
  [InsuranceTypeGroupEnum.Livestock]: 'Pecuário',
};

export const insuranceTypeGroupSlugs: Record<InsuranceTypeGroupEnum, string> = {
  [InsuranceTypeGroupEnum.Agricultural]: 'agricola',
  [InsuranceTypeGroupEnum.Machinery]: 'equipamentos',
  [InsuranceTypeGroupEnum.Forest]: 'florestas',
  [InsuranceTypeGroupEnum.PropertyImprovement]: 'benfeitorias',
  [InsuranceTypeGroupEnum.Livestock]: 'pecuario',
};
