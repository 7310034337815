/** @jsxImportSource @emotion/react */
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { ColorNames } from "../../theme";
import { BaseFontSize } from "../Typography";
import { useLayoutEffect, useRef, useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";

export type Tag = {
  label: string;
  filterName: string;
  value: string;
};

type TagsProps = {
  open: boolean;
  tags: Tag[];
  removeCallback: (tag: Tag) => void;
};

interface StylesParams {
  maskPosition: number;
}

const getStyles: CSSRulesResolver<StylesParams> = ({
  maskPosition,
  colors,
}) => ({
  container: {
    width: "100%",
    position: "relative",
    paddingTop: "6px",
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "6px",
  },
  tagContainer: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: 6,
    overflow: "hidden", // ensures corners look clean
  },
  textSection: {
    backgroundColor: colors.White[100],
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    padding: "3px 6px",
    color: "black",
    fontSize: "12px",
    fontWeight: "bold",
    userSelect: "none",
  },
  closeSection: {
    backgroundColor: colors.Green[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 3px",
    cursor: "pointer",
    height: "100%",
    "&:hover": {
      backgroundColor: colors.Green[80],
    },
  },
  mask: {
    fontSize: 14,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: maskPosition,
    right: 0,
    top: 0,
    height: 32,
    color: colors.Grey[100],
    backgroundColor: colors.LightGrey[100],
    textAlign: "left",
    zIndex: 1000,
  },
});

export const Tags: React.FC<TagsProps> = ({ tags, open, removeCallback }) => {
  const tagsRef = useRef<HTMLDivElement>(null);
  const [maskPosition, setMaskPosition] = useState<number>(-1);
  const [hiddenCount, setHiddenCount] = useState<number>(0);
  const styles = useCSSRulesWithTheme(getStyles, { maskPosition });

  useLayoutEffect(() => {
    const el = tagsRef.current;
    if (el && el.children.length) {
      if (el.clientHeight <= 20) {
        setMaskPosition(-1);
        return;
      }
      const availableWidth = Number(el.parentElement?.clientWidth) - 20;
      let currentWidth = 0;
      let showMask = false;
      let i;

      for (i = 0; i < el.children.length; i++) {
        const child = el.children[i];
        const tagWidth = child.clientWidth + 6;
        if (currentWidth + tagWidth > availableWidth) {
          showMask = true;
          break;
        } else {
          currentWidth += tagWidth;
        }
      }
      setHiddenCount(el.children.length - i);
      setMaskPosition(showMask ? currentWidth - 2 : -1);
    }
  }, [tags]);

  if (!tags.length) return null;

  return (
    <div css={styles.container}>
      {maskPosition >= 0 && !open ? (
        <div css={styles.mask}>+{hiddenCount}</div>
      ) : null}
      <div css={styles.wrapper} ref={tagsRef}>
        {tags.map((tag, index) => (
          <div css={styles.tagContainer} key={index}>
            <div css={styles.textSection}>{tag.label}</div>
            <div css={styles.closeSection} onClick={() => removeCallback(tag)}>
              <Icon
                name={IconNames.Close}
                size={0.8 * BaseFontSize}
                color={ColorNames.WHITE}
                pointer
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
