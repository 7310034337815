import Table from "../../../../components/Table";
import {
  InsuranceDocument,
  Property,
  useListContactDocumentsQuery,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToDocumentDetailsLocation } from "../../../../Locations";
import { formatDateWithTime, formatISODate } from "../../../../utils/dateUtils";
import { getDocumentContactRoles } from "../../utils/documentRoles";
import { getArea } from "../../../../utils/formatNumber";
import DetailsSection from "../../../../components/EditableSectionV2/components/DetailSection";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { documentStageLabels } from "../../../../shared/documents/documentStage";
import { documentStatusLabels } from "../../../../shared/documents/documentStatus";
import { consultantDocumentStatusLabels } from "../../../../shared/documents/consultantDocumentStatus";
import { useFlags } from "../../../../hooks/useFlags";

type TableDocumentData = {
  description: string;
  _id: string;
  updatedAt: string;
  name: string;
  stage: string;
  status: string;
  consultantStatus: string;
  area: string | null;
  propertyDescription: string | null;
  roleOnDocument: string;
  policyEndDate: string | null;
};

const ContactDocuments = () => {
  const { contactData } = useContactDetails();
  const { isAdmin } = useFlags();

  const { data } = useListContactDocumentsQuery({
    variables: {
      filter: [[{ anyContactId: contactData._id }]],
    },
  });

  const getPropertyDescription = (properties: Property[]) => {
    if (properties?.length === 1) {
      return properties[0].displayName!;
    }
    return `${properties?.length} propriedades`;
  };

  const tableDocumentData: TableDocumentData[] = (data?.documents || []).map(
    (doc) => {
      return {
        ...doc,
        description: doc?.description || "",
        roleOnDocument: getDocumentContactRoles(doc!, contactData._id!),
        area: getArea(doc as InsuranceDocument),
        propertyDescription: getPropertyDescription(doc!.properties || []),
        updatedAt: formatDateWithTime(doc!.updatedAt!),
        policyEndDate: formatISODate(doc!.policy?.endDate),
        stage: documentStageLabels[doc!.stage!] || "",
        status: doc!.status ? documentStatusLabels[doc!.status] : "",
        consultantStatus: doc!.consultantStatus
          ? consultantDocumentStatusLabels[doc!.consultantStatus]
          : "",
      };
    }
  );

  return (
    <DetailsSection
      title="Documentos"
      icon={<Icon name={IconNames.Documents} />}
    >
      <Table<TableDocumentData>
        data={tableDocumentData}
        withBorder
        color={ColorNames.BLACK}
        rowLink={(doc: TableDocumentData) =>
          ToDocumentDetailsLocation(doc._id!)
        }
        columns={[
          { label: "Código", key: "name" },
          {
            label: "Papel no documento",
            key: "roleOnDocument",
          },
          ...(isAdmin()
            ? [
                { label: "Etapa", key: "stage" as const },
                {
                  label: "Status",
                  key: "status" as const,
                },
              ]
            : [
                {
                  label: "Status",
                  key: "consultantStatus" as const,
                },
              ]),
          {
            label: "Descrição",
            key: "description",
          },
          {
            label: "Localização",
            key: "propertyDescription",
          },
          {
            label: "Area",
            key: "area",
          },
          {
            label: "Atualizado em",
            key: "updatedAt",
          },
          { label: "Vigência", key: "policyEndDate" },
        ]}
      />
    </DetailsSection>
  );
};

export default ContactDocuments;
