/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from "react";
import {
  ContactTags,
  GetApproveOrgFormResponseDocument,
  Services,
  UserRoles,
  UserStatus,
} from "../../../../graphql/generated/types";
import useDebounce from "../../../../hooks/useDebounce";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import Typography from "../../../../components/Typography";
import Icon from "../../../../components/Icons";
import UserSelect from "../../../../components/Details/components/Main/UserSelect";
import RatingStars from "../../../../components/RatingStars";
import BooleanInput from "../../../../components/Input/BooleanInput";
import TextAreaInput from "../../../../components/Input/TextAreaInput";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { SectionFooterButton } from "../../../Documents/DocumentDetails/components/SectionFooter";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { ColorNames } from "../../../../theme";
import { getStatusIcon } from "../../../../filters/users";
import { formatDateWithTime } from "../../../../utils/dateUtils";
import useContactDetails from "../../../../hooks/useContactDetails";
import { userStatusLabels } from "../../../../shared/user/userStatus";
import {
  isConsultant,
  isFarmer,
  isOrg,
  isOrgUser,
  isOrgOrConsultant,
} from "../../utils/tags";
import { EditableCommission } from "./EditableCommission";
import ContactSelect from "../../../../components/Details/components/Main/ContactSelect";
import { ToContactDetailsLocation } from "../../../../Locations";
import Many2ManyInput from "../../../../components/Input/SelectInputs/Many2ManyInput";
import { toast } from "react-toastify";
import EditableSectionV2 from "../../../../components/EditableSectionV2";
import { DetailsWrapper } from "../../../../components/ModelDetails/DetailsWrapper";
import { contactTagLabels } from "../../../../shared/contacts/contactTags";
import CustomLink from "../../../../components/CustomLink";

const ContactSummary = () => {
  const {
    contactData,
    handleAddComment,
    handleSetResponsible,
    handleSetAgronomistResponsible,
    handleAddRating,
    handleApprovePartner,
    handleRejectPartner,
    handleArchiveUser,
    handleUnarchiveUser,
    handleAddCultureToPartner,
    handleAddCourse,
    handleUpgradeToAdmin,
    handleSetOriginator,
    refetch,
  } = useContactDetails();
  const { user } = contactData;
  const [comment, setComment] = useState<string>(contactData.comments || "");
  const { debouncedValue, cancelDebounce } = useDebounce(comment, 1000);

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleAddCulture = (value: string[]) => {
    handleAddCultureToPartner(value);
  };

  useEffect(() => {
    if (debouncedValue !== contactData.comments) {
      handleAddComment(debouncedValue);
    }
  }, [debouncedValue]);

  const handleBlur = () => {
    cancelDebounce(), handleAddComment(comment);
  };

  const leffColumn = useMemo(() => {
    const { status } = contactData;
    const isArchivedUser = !!user?.archived;
    const data: DetailsRowProps[] = [
      {
        label: "Tipo(s)",
        value: (contactData.tags || [])
          .map((t: ContactTags) => contactTagLabels[t])
          .join(", "),
      },
      {
        label: "Avaliação de potencial",
        value: (
          <RatingStars
            rating={contactData.rating!}
            onClick={(option) => handleAddRating(option)}
            color={ColorNames.GREEN}
          />
        ),
      },
      {
        label: "Culturas Relacionadas",
        value: (
          <Many2ManyInput
            onSelect={handleAddCulture}
            serviceName={Services.Cultures}
            domain={{}}
            value={contactData.cultureIds}
            placeholder="Culturas do parceiro"
            inputName="culture"
            multiValueDisplay="all"
          />
        ),
      },
    ];
    if (status) {
      data.push({
        label: "Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={isArchivedUser ? IconNames.Archive : getStatusIcon(status)!}
              color={isArchivedUser ? ColorNames.ERROR_RED : ColorNames.GREEN}
            />
            {isArchivedUser
              ? "Desabilitado"
              : userStatusLabels[status as UserStatus]}
          </Typography>
        ),
      });
    }
    if (user) {
      data.push(
        {
          label: "Assinatura",
          value: formatDateWithTime(user?.signedDate),
        },
        {
          label: "Ultimo acesso",
          value: formatDateWithTime(user?.lastAccessAt),
        }
      );
    }
    if (isConsultant(contactData) || isOrg(contactData)) {
      data.push({
        label: "Comissão",
        value: <EditableCommission />,
      });
    }
    const { leadContact } = contactData;
    if (leadContact) {
      data.push({
        label: "Contato Original/Lead",
        value: (
          <CustomLink to={ToContactDetailsLocation(leadContact?._id!)}>
            <Typography variant="link">{leadContact?.name}</Typography>
          </CustomLink>
        ),
      });
    }

    return data;
  }, [contactData]);

  const rightColumn = useMemo(() => {
    const rightcolumnData: DetailsRowProps[] = [
      {
        label: "Data de criação",
        value: formatDateWithTime(contactData.createdAt),
      },
      {
        label: "Comentários",
        value: (
          <TextAreaInput
            value={comment}
            onChange={handleComment}
            onBlur={handleBlur}
            name="comments"
          />
        ),
      },
      {
        label: "Responsável",
        value: (
          <UserSelect
            domain={{ role: UserRoles.Admin }}
            value={contactData.responsible?._id || ""}
            placeholder=""
            onSelect={(option) => handleSetResponsible(option?.value)}
            inputName="responsavel-contato"
            isClearable
          />
        ),
      },
      ...(contactData.tags?.includes(ContactTags.Farmer)
        ? [
            {
              label: "Agrônomo Responsável",
              value: (
                <ContactSelect
                  domain={{ tags: ContactTags.Agronomist }}
                  value={contactData.agronomistResponsibleId || ""}
                  placeholder=""
                  onSelect={(option) =>
                    handleSetAgronomistResponsible(option?.value)
                  }
                  inputName="agronomo-responsavel-contato"
                  isClearable
                />
              ),
            },
          ]
        : []),
      {
        label: "Parceiro",
        value: (
          <ContactSelect
            domain={{
              tags: { $in: [ContactTags.Consultant, ContactTags.Organization] },
            }}
            value={contactData.originatorId || ""}
            placeholder=""
            onSelect={(option) => handleSetOriginator(option?.value)}
            inputName="parceiro-contato"
            isClearable
            disabled={!isFarmer(contactData)}
          />
        ),
      },
    ];
    if (user) {
      rightcolumnData.push({
        label: "Usuário fez o curso?",
        value: (
          <BooleanInput
            name="finishedCourse"
            label=""
            value={user.finishedCourse!}
            handleChange={(e) => handleAddCourse(e.target.value)}
          />
        ),
      });
    }
    return rightcolumnData;
  }, [comment, contactData]);

  const actions: SectionFooterButton[] = useMemo(() => {
    const { status } = contactData;
    const contactIsOrg = isOrg(contactData);
    const approveAndRejectButtons =
      status == UserStatus.Registered && isOrgOrConsultant(contactData)
        ? [
            {
              text: "Rejeitar",
              iconName: IconNames.Rejected,
              onClick: () => handleRejectPartner(),
            },
            {
              text: "Aprovar",
              iconName: IconNames.Valid,
              onClick: contactIsOrg ? undefined : () => handleApprovePartner(),
              formQuery: contactIsOrg
                ? {
                    query: GetApproveOrgFormResponseDocument,
                    variables: { orgId: contactData._id! },
                  }
                : undefined,
              useV2: true,
            } as SectionFooterButton,
          ]
        : [];
    if (user)
      return [
        !user.archived && {
          text: "Desabilitar",
          iconName: IconNames.Archive,
          onClick: () => handleArchiveUser(),
        },
        user.archived && {
          text: "Reabilitar",
          iconName: IconNames.Unarchive,
          onClick: () => handleUnarchiveUser(),
        },
        user.role === UserRoles.Consultant &&
          user.status === UserStatus.Active && {
            text: "Tornar admin",
            iconName: IconNames.User,
            onClick: () => handleUpgradeToAdmin(),
          },
        ...approveAndRejectButtons,

        isOrgUser(contactData) && {
          text: "Ver organização",
          link: ToContactDetailsLocation(contactData.orgId!),
          iconName: IconNames.Share,
        },
      ].filter(Boolean) as SectionFooterButton[];
    if (status) {
      return approveAndRejectButtons;
    }

    return [];
  }, [contactData, user]);
  return (
    <DetailsWrapper>
      <EditableSectionV2
        title="DADOS GERAIS"
        actions={actions}
        onSubmit={() => {
          toast.success("Operação realizada com sucesso");
          refetch();
        }}
        useV2
      >
        <DetailsSectionColumns columns={[leffColumn, rightColumn]} />
      </EditableSectionV2>
    </DetailsWrapper>
  );
};

export default ContactSummary;
