import { useMemo } from "react";
import { DocumentStages, DocumentStatus } from "../graphql/generated/types";
import { ToDocumentDetailsLocation } from "../Locations";
import useDocumentOrder from "./useDocumentOrder";
import { IconNames } from "../components/Icons/styles/iconNames";
import { DetailsTab } from "../components/Tabs/DetailsTabs";

interface UseSubmenusArgs {
  _id?: string;
  stage?: DocumentStages | null;
  status?: DocumentStatus | null;
}

export function useDocumentDetailsTabs({
  _id: documentId,
  stage,
  status,
}: UseSubmenusArgs): DetailsTab[] {
  if (!documentId) {
    return [];
  }

  // Get numeric/ordinal values of different stages
  const currentStage = useDocumentOrder(stage!, status!);
  const quotationStage = useDocumentOrder(DocumentStages.Simulation);
  const proposalStage = useDocumentOrder(DocumentStages.Proposal);
  const sentProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Sent
  );
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const baseUrl = ToDocumentDetailsLocation(documentId);

  return useMemo(() => {
    const tabs: DetailsTab[] = [
      {
        title: "Geral",
        location: baseUrl,
      },
      {
        title: "Cotação",
        location: baseUrl + "/cotacao",
        disabled: currentStage < quotationStage,
      },
      {
        title: "Proposta",
        location: baseUrl + "/proposta",
        disabled: currentStage < proposalStage,
      },
      {
        title: "Apólice",
        location: baseUrl + "/apolice",
        disabled: currentStage < sentProposalStage,
      },
      {
        title: "Endosso",
        location: baseUrl + "/endosso",
        disabled: currentStage < policyStage,
      },
      {
        title: "Sinistro",
        location: baseUrl + "/sinistro",
        disabled: currentStage < policyStage,
      },
      {
        location: baseUrl + "/anexos",
        icon: IconNames.Attachments,
      },
      {
        location: baseUrl + "/pagamento",
        disabled: currentStage < proposalStage,
        icon: IconNames.Money,
      },
    ];

    return tabs;
  }, [
    baseUrl,
    currentStage,
    quotationStage,
    proposalStage,
    sentProposalStage,
    policyStage,
  ]);
}
