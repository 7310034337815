/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC } from "react";
import Typography from "../../Typography";
import { theme } from "../../../theme";

interface TotalCardProps {
  label: string;
  value: string | number;
  selected?: boolean;
  onClick?: () => void;
}

const styles: CSSObject = {
  wrapper: {
    border: "1px solid #e7e7e7",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
    borderRadius: 10,
    padding: "10px 15px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    color: theme.colors.Black[80],
  },
  label: {
    margin: 0,
    width: "100%",
    fontWeight: "bold",
    fontSize: 14,
  },
  value: {
    width: "100%",
    margin: "0",
    marginTop: "5px",
    fontWeight: "bold",
    fontSize: 20,
  },
  selected: {
    borderColor: theme.colors.Black[80],
    borderWidth: 2,
  },
};

const TotalCard: FC<TotalCardProps> = ({ label, value, selected, onClick }) => {
  return (
    <div
      css={[styles.wrapper, selected ? styles.selected : undefined]}
      onClick={onClick}
    >
      <Typography css={styles.value}>{value}</Typography>
      <Typography css={styles.label}>{label}</Typography>
    </div>
  );
};

export default TotalCard;
