/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC, useEffect, useState, KeyboardEvent } from "react";

import { TModelFilter } from "../../hooks/useModelFilter";
import { useUpsertViewFilterMutation } from "../../graphql/generated/types";
import { IconNames } from "../Icons/styles/iconNames";
import { ColorNames, theme } from "../../theme";
import { FilterOption, FilterSelect } from "./components/FilterSelect";
import ControlButton from "./components/ControlButton";
import FilterControlInput from "./components/FilterControlInput";
import { useTableContext } from "../../contexts/table";
import { SingleValue } from "react-select";

interface ViewFilterControlProps {
  filters: TModelFilter;
  placeholder?: string;
}

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "8px",
    color: "#000",
    width: "100%",
  },
  input: {
    width: "200px",
  },
  inputIconsWrapper: {
    display: "flex",
    gap: "6px",
    justifyContent: "end",
    marginTop: "2px",
  },
  inputIcon: {
    backgroundColor: ColorNames.WHITE,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
    padding: 3,
    borderRadius: 6,
    transition: "all 0.2s ease",

    "&:hover": {
      backgroundColor: "#f8f8f8",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
    },
    "&:active": {
      boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f1f1f1",
    },
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    ".control": {
      position: "absolute",
      top: 46,
      left: 20,
      right: 0,
      display: "flex",
      justifyContent: "flex-start",
      padding: "5px 20px 5px 0",
      gap: 4,
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        visibility: "hidden",
      },
    },
    ".select": {
      border: "1px solid #dddddd",
    },
  },
};

type EditModeType = "rename" | "create" | null;

export const allDocsFilter = "all" as const;

export const ViewFilterControl: FC<ViewFilterControlProps> = ({
  filters,
  placeholder,
}) => {
  const [filterName, setFilterName] = useState<string>("");
  const {
    viewFilterId,
    setViewFilterId,
    serviceName: service,
    viewFilterQuery: { data, refetch },
  } = filters;
  const [editMode, setEditMode] = useState<EditModeType>(null);
  const {
    actions: { selectedColumns, setSelectedColumns, allColumns },
  } = useTableContext();

  const [upsertFilterMutation, { loading: upserting }] =
    useUpsertViewFilterMutation();

  const filterList = data?.viewFilters || [];
  const selectedFilter = filterList.find((f) => f._id === viewFilterId) || null;

  // Build the dropdown options
  const filterOptions: FilterOption[] = filterList.map((f) => ({
    value: f._id,
    label: f.name,
  }));
  filterOptions.unshift({
    value: allDocsFilter,
    label: placeholder || "",
  });

  // Reset filter name if we're exiting create/rename
  useEffect(() => {
    if (editMode === null) {
      setFilterName("");
    }
  }, [editMode]);

  // Sync parent's filters when we pick a different filter
  useEffect(() => {
    if (selectedFilter) {
      const newFiltersValues = filters.filters.map((currentFilter) => {
        const filterValue = selectedFilter.filters.find(
          (ff) => ff.name === currentFilter.name
        )?.value;
        return {
          ...currentFilter,
          value: filterValue || [],
        };
      });
      filters.setFilters(newFiltersValues);
      if (selectedFilter.columns?.length) {
        setSelectedColumns(
          allColumns.filter((col) =>
            selectedFilter.columns?.includes(col.id.toString())
          )
        );
      }
    }
  }, [selectedFilter]);

  const onSelectChange = (option: SingleValue<FilterOption>) => {
    if (!option) return;
    setViewFilterId(option.value);
    if (option.value === allDocsFilter) {
      filters.resetFilters();
    }
  };

  // Build payload for upserting
  const getFiltersPayload = () => {
    return filters.filters
      .filter((currentFilter) => currentFilter.value.length > 0)
      .map((currentFilter) => ({
        name: currentFilter.name,
        value: currentFilter.value,
      }));
  };

  // "Novo filtro"
  const handleEnterCreateMode = () => {
    setFilterName("");
    setEditMode("create");
  };

  // "Rename" on double-click
  const handleEnterRenameMode = () => {
    if (!selectedFilter) return;
    setFilterName(selectedFilter.name);
    setEditMode("rename");
  };

  const handleSave = async () => {
    let newFilterId: string | undefined;

    if (editMode === "create") {
      // Create new filter
      const result = await upsertFilterMutation({
        variables: {
          input: {
            _id: undefined,
            filters: getFiltersPayload(),
            name: filterName || "Sem título",
            service,
            columns: selectedColumns.map((col) => col.id.toString()),
          },
        },
      });
      newFilterId = result.data?.upsertViewFilter?._id;
    } else if (editMode === "rename" && selectedFilter) {
      // Rename existing
      const result = await upsertFilterMutation({
        variables: {
          input: {
            _id: selectedFilter._id,
            filters: getFiltersPayload(),
            name: filterName || selectedFilter.name,
            service,
          },
        },
      });
      newFilterId = result.data?.upsertViewFilter?._id;
    }

    await refetch();

    if (newFilterId) {
      setViewFilterId(newFilterId);
    }
    setEditMode(null);
  };

  const handleSkip = () => {
    setEditMode(null);
  };

  const handleUpdateSelectedFilter = async () => {
    if (!selectedFilter) return;
    await upsertFilterMutation({
      variables: {
        input: {
          _id: selectedFilter._id,
          filters: getFiltersPayload(),
          name: selectedFilter.name,
          service,
          columns: selectedColumns.map((col) => col.id.toString()),
        },
      },
    });
    await refetch();
  };

  const handleDelete = async () => {
    if (!selectedFilter) return;

    await upsertFilterMutation({
      variables: {
        input: {
          _id: selectedFilter._id,
          name: selectedFilter.name,
          service,
          archived: true,
        },
      },
    });
    await refetch();
    setViewFilterId(allDocsFilter);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      handleSkip();
    }
  };

  const renderControlButtons = () => {
    if (editMode === null) {
      if (selectedFilter === null) {
        return (
          <ControlButton
            icon={IconNames.Add}
            tooltipText="Novo"
            onClick={handleEnterCreateMode}
          />
        );
      } else {
        return (
          <>
            <ControlButton
              icon={IconNames.Add}
              tooltipText="Novo"
              onClick={handleEnterCreateMode}
            />
            <ControlButton
              icon={IconNames.Edit}
              tooltipText="Renomear"
              onClick={handleEnterRenameMode}
            />
            <ControlButton
              icon={IconNames.Save}
              tooltipText="Salvar"
              onClick={handleUpdateSelectedFilter}
            />
            <ControlButton
              icon={IconNames.Delete}
              tooltipText="Excluir"
              onClick={handleDelete}
            />
          </>
        );
      }
    } else {
      return (
        <>
          <ControlButton
            icon={IconNames.Delete}
            tooltipText="Cancelar"
            onClick={handleSkip}
          />
          <ControlButton
            icon={IconNames.Save}
            tooltipText="Salvar"
            onClick={handleSave}
          />
        </>
      );
    }
  };

  return (
    <div css={styles.wrapper}>
      <div
        css={styles.inputContainer}
        onDoubleClick={() => {
          if (viewFilterId) {
            handleEnterRenameMode();
          }
        }}
      >
        {editMode === null ? (
          <FilterSelect
            options={filterOptions}
            onChange={onSelectChange}
            value={
              filterOptions.find((fo) => fo.value === viewFilterId) || null
            }
            placeholder={placeholder}
            disabled={upserting}
          />
        ) : (
          <FilterControlInput
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder={
              editMode === "create" ? "Novo filtro" : "Renomear filtro"
            }
            width="100%"
            autoFocus
            onKeyDown={handleKeyDown}
            disabled={upserting}
          />
        )}
        <div className="control">{renderControlButtons()}</div>
      </div>
    </div>
  );
};
