/** @jsxImportSource @emotion/react */

import { CSSProperties, FC, PropsWithChildren } from "react";
import { CSSObject } from "@emotion/react";
import { theme } from "../../theme";

const styles: CSSObject = {
  wrapper: {
    width: "100%",
    borderRadius: theme.borderRadius.xxl,
    border: `1px solid ${theme.colors.Grey[20]}`,
    boxSizing: "border-box",
    padding: theme.spacings.md,
  },
};

interface DetailsWrapperProps extends PropsWithChildren {
  style?: CSSProperties;
  className?: string;
}

export const DetailsWrapper: FC<DetailsWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <div css={styles.wrapper} {...rest}>
      {children}
    </div>
  );
};
