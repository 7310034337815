/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorNames } from "../../../theme";
import Typography from "../../../components/Typography";
import Icon from "../../../components/Icons";
import { IconNames } from "../../../components/Icons/styles/iconNames";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  info: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    boxSizing: "border-box",
    gap: 8,
    width: "fit-content",
  },
  infoText: {
    flex: 1,
  },
  iconWrapper: {
    paddingTop: 3,
  },
};

export const FormInfo: FC<{ info: string }> = ({ info }) => {
  return (
    <div css={styles.info}>
      <div css={styles.iconWrapper}>
        <Icon name={IconNames.Information} size={18} color={ColorNames.GREY} />
      </div>
      <Typography color={ColorNames.GREY} css={styles.infoText} align="left">
        {info}
      </Typography>
    </div>
  );
};
