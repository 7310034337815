/** @jsxImportSource @emotion/react */
import { toCultureDetailsLocation } from "../../../Locations";
import { Services } from "../../../graphql/generated/types";
import ModalStoreProvider from "../../../components/Modal/context/ModalStore";
import AppLayout from "../../../components/AppLayout/AppLayout";
import { FC, ReactNode } from "react";
import RightMenu from "../../../components/Menu/RightMenu/RightMenu";

export const CulturesWrapper: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <AppLayout
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(cultureId) =>
            toCultureDetailsLocation(cultureId)
          }
          serviceName={Services.Cultures}
        />
      }
    >
      <ModalStoreProvider>{children}</ModalStoreProvider>
    </AppLayout>
  );
};
