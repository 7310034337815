/** @jsxImportSource @emotion/react */

import { Row } from "react-grid-system";
import { Task as ObjectTasks } from "../../../../../../graphql/generated/types";
import { FC } from "react";
import { theme } from "../../../../../../theme";
import { formatISODate } from "../../../../../../utils/dateUtils";
import Avatar from "../../../../../Avatar";
import CustomLink from "../../../../../CustomLink";
import Column from "../../../../../Grid/Column";
import { IconSizes } from "../../../../../Icons/styles/iconSizes";
import Typography from "../../../../../Typography";

interface TaskProps {
  task: ObjectTasks;
  redirectLocation: (docId: string) => string;
}

const styles = {
  container: {
    width: "100%",
    marginBottom: 6,
    cursor: "pointer",
    transition: "background-color 0.5s ease",
    ":hover": {
      backgroundColor: theme.colors.Grey[20],
    },
    borderRadius: 5,
    padding: 12,
  },
};

const Task: FC<TaskProps> = ({ task, redirectLocation }) => {
  return (
    <CustomLink to={redirectLocation(task.docId)}>
      <Row justify="between" align="center" css={styles.container}>
        <Column xs={2}>
          <Avatar size={IconSizes.Large} name={task.responsible?.name || ""} />
        </Column>
        <Column xs={7}>
          <Typography
            variant="textMedium"
            weight="bold"
            component="div"
            align="left"
          >
            {task.message}
          </Typography>
          <Typography variant="textMedium" component="div" align="left">
            {task.originInfo}
          </Typography>
        </Column>
        <Column xs={3}>
          <Typography variant="textSmall">
            {formatISODate(task.dateDue)}
          </Typography>
        </Column>
      </Row>
    </CustomLink>
  );
};

export default Task;
