import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import { contactFilters } from "../filters/contacts";
import { Services } from "../graphql/generated/types";

const useContactsFilter = () => {
  const filters = useModelFilter(contactFilters, Services.Contacts, []);

  useUrlFilter(filters);

  return filters;
};

export default useContactsFilter;
