import ChatList from "./ChatList";
import { useGetTwilioConversationsQuery } from "../../../graphql/generated/types";
import { useFlags } from "../../../hooks/useFlags";
import { useConversationFilter } from "../../../hooks/useConversationFilter";

const ChatListQueryWrapper = () => {
  const {
    onlySubscribeds,
    searchTerm,
    limit,
    skip,
    setOnlySubscribeds,
    setPage,
    page,
    setSearchTerm,
  } = useConversationFilter();
  const { isFlagEnabled } = useFlags();
  const query = useGetTwilioConversationsQuery({
    variables: { filter: { onlySubscribeds, searchTerm, limit, skip } },
    skip: !isFlagEnabled("admin-only"),
  });

  const totalPages = Math.ceil(
    (query.data?.getTwilioConversations?.numberOfRecords || 0) / limit
  );
  return (
    <ChatList
      query={query}
      setOnlySubscribeds={async (value: boolean) => setOnlySubscribeds(value)}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      onlySubscribeds={onlySubscribeds}
    />
  );
};

export default ChatListQueryWrapper;
