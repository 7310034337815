import {
  ConsultantRejectionLocation,
  ContactConversationLocation,
  ContactDetailsLocation,
  ContactsLocation,
  MyOrgLocation,
  NewContactLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import ContactDetails from "./ContactDetails";
import Contacts from "./Contacts";
import { NewContact } from "./NewContact";
import useContactDetails from "../../hooks/useContactDetails";
import { Routes, Route } from "react-router-dom";
import { ContactDetailsLayout } from "./ContactDetailsLayout";
import ContactMeetings from "./ContactDetails/components/ContactMeetings";
import { isConsultant, isDocumentRelatedContact, isOrg } from "./utils/tags";
import ContactClaims from "./ContactDetails/components/ContactClaims";
import ContactDocuments from "./ContactDetails/components/ContactDocuments";
import ContactPayments from "./ContactDetails/components/ContactPayments";
import Subcontacts from "./ContactDetails/components/Subcontacts";
import ContactConversation from "./Conversation";
import PartnerRejection from "../../components/Reject/partner";
import { ContactsWrapper } from "./ContactsWrapper";
import ContactPersonalData from "./ContactDetails/components/ContactPersonalData";
import ContactBankAccountData from "./ContactDetails/components/ContactBankAccountData";
import { MyOrg } from "../Org";

export const useGetContactDetailsTabs = () => {
  const { contactData, refetch } = useContactDetails();
  const shouldShowDocumentRelatedTabs = isDocumentRelatedContact(
    contactData.tags || []
  );
  return [
    { title: "Detalhes", Component: ContactPersonalData, path: "" },
    {
      title: "Documentos",
      path: "documentos",
      Component: ContactDocuments,
      disabled: !shouldShowDocumentRelatedTabs,
    },
    {
      title: "Contatos",
      path: "contatos",
      disabled: !shouldShowDocumentRelatedTabs,
      Component: Subcontacts,
    },
    {
      title: "Parcelas",
      path: "parcelas",
      disabled: !shouldShowDocumentRelatedTabs,
      Component: ContactPayments,
    },
    {
      title: "Sinistros",
      path: "sinistros",
      disabled: !shouldShowDocumentRelatedTabs,
      Component: ContactClaims,
    },
    {
      title: "Conversas",
      path: "conversas",
      Component: ContactMeetings,
    },
    {
      title: "Pagamento",
      path: "pagamento",
      Component: () => (
        <ContactBankAccountData contact={contactData} onSubmit={refetch} />
      ),
      disabled: !isConsultant(contactData) && !isOrg(contactData),
    },
  ] as {
    title: string;
    path: string;
    Component: any;
    disabled?: boolean;
  }[];
};

export const ContactDetailsRoutes = () => {
  const tabs = useGetContactDetailsTabs();

  return (
    <Routes>
      <Route element={<ContactDetailsLayout />}>
        {tabs
          .filter((tab) => !tab.disabled)
          .map((tab) => (
            <Route
              key={tab.title}
              path={tab.path}
              index={!tab.path}
              element={<tab.Component />}
            />
          ))}
      </Route>
    </Routes>
  );
};

export const contactRoutes: RouteObject[] = [
  {
    path: ContactsLocation,
    element: <Contacts />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: NewContactLocation,
    element: <NewContact />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: ContactDetailsLocation + "/*",
    element: <ContactDetails />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: ContactConversationLocation,
    element: <ContactConversation />,
    withAuth: true,
    flag: "admin-only",
  },
  {
    path: MyOrgLocation + "/*",
    element: <MyOrg />,
    withAuth: true,
  },
  {
    path: ConsultantRejectionLocation,
    element: (
      <ContactsWrapper>
        <PartnerRejection />
      </ContactsWrapper>
    ),
    withAuth: true,
  },
];
