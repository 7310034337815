/** @jsxImportSource @emotion/react */

import React, { FC } from "react";
import { css } from "@emotion/react";
import Typography, { BaseFontSize } from "../../../../components/Typography";
import { ColorNames, theme } from "../../../../theme";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";

type StepProps = {
  index: number;
  activeStepIndex: number;
  label: string;
  isLast: boolean;
  handleClick: () => void;
  isSelectable: boolean;
};

const Step: FC<StepProps> = ({
  index,
  activeStepIndex,
  label,
  isLast,
  handleClick,
  isSelectable,
}) => {
  const isCompleted = index < activeStepIndex;
  const isSelected = index === activeStepIndex;
  return (
    <div
      css={[styles.stepContainer, !isSelectable && styles.notAllowed]}
      onClick={isSelectable ? handleClick : undefined}
    >
      <div
        css={[
          styles.circle,
          isCompleted && styles.completed,
          isSelected && styles.selected,
        ]}
      >
        {isCompleted ? (
          <Icon
            name={IconNames.Check}
            size={BaseFontSize * 0.7}
            color={ColorNames.GREEN}
          />
        ) : (
          index + 1
        )}
      </div>
      <Typography variant="textSmall" component="span" css={styles.label}>
        {label}
      </Typography>
      {!isLast && <div css={styles.line} />}
    </div>
  );
};

const styles = {
  stepContainer: css`
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 4px;
    cursor: pointer;
  `,
  notAllowed: css`
    cursor: not-allowed;
  `,
  circle: css`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.Grey[20]};
    font-size: 12px;
    font-weight: bold;
    color: ${theme.colors.Grey[100]};
    box-sizing: border-box;
  `,
  completed: css`
    background-color: ${theme.colors.Green[20]};
    color: white;
  `,
  selected: css`
    border: 1px solid ${theme.colors.Green[100]};
    color: ${theme.colors.Green[100]};
    background-color: white;
  `,
  label: css`
    margin-left: 8px;
    white-space: nowrap;
    color: ${theme.colors.Black[100]};
  `,
  line: css`
    height: 2px;
    background-color: ${theme.colors.Green[60]};
    margin: 0 8px;
    width: 20px;
  `,
};

export default Step;
