import { FC } from "react";

const Inspection: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="proposal" clipPath="url(#clip0_7758_30679)">
        <path
          id="Vector"
          d="M19.0713 7.71447L14.0713 2.71454C14.0085 2.64474 13.9312 2.58943 13.8449 2.55243C13.7585 2.51543 13.6652 2.49763 13.5713 2.50025H6.42855C6.05002 2.50137 5.68731 2.65223 5.41964 2.9199C5.15198 3.18756 5.00111 3.55027 5 3.92881V21.0714C5.00111 21.45 5.15198 21.8127 5.41964 22.0804C5.68731 22.348 6.05002 22.4989 6.42855 22.5H17.857C18.2355 22.4989 18.5982 22.348 18.8659 22.0804C19.1336 21.8127 19.2844 21.45 19.2855 21.0714V8.21447C19.2882 8.12058 19.2704 8.02724 19.2334 7.94091C19.1964 7.85458 19.141 7.77732 19.0713 7.71447ZM13.5713 4.21452L17.5713 8.21447H13.5713V4.21452ZM17.857 21.0714H6.42855V3.92881H12.1428V8.21447C12.1439 8.593 12.2947 8.95571 12.5624 9.22338C12.8301 9.49104 13.1928 9.64191 13.5713 9.64302H17.857V21.0714Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7758_30679">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Inspection;
