import SectionDivider from "../../../../../components/EditableSectionV2/components/SectionDivider";
import PaymentInfo from "./PaymentData";
import PaymentList from "./PaymentList";

export const DocumentPayments = () => {
  return (
    <>
      <PaymentInfo />
      <SectionDivider />
      <PaymentList />
    </>
  );
};
