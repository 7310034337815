import { useEffect, useState } from "react";
import { TModelFilter } from "./useModelFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { allDocsFilter } from "../components/ViewFilterControl";

const useUrlFilter = ({
  filterValues,
  filters,
  setFilterValue,
  searchTerm,
  setSearchTerm,
  viewFilterId,
  setViewFilterId,
  viewFilterQuery,
}: TModelFilter) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) return;
    const existingParams = new URLSearchParams(location.search);
    const paramKeys = Array.from(existingParams.keys());
    const otherParams = paramKeys.filter(
      (key) =>
        !filters.some(({ urlParam }) => urlParam === key) &&
        key !== "busca" &&
        key !== "viewFilter"
    );
    const newSearchParams = new URLSearchParams();
    otherParams.forEach((key) => {
      existingParams.getAll(key).forEach((value) => {
        newSearchParams.append(key, value);
      });
    });
    filters.forEach((filter) => {
      if (!filter.value.length) return;
      filter.value.forEach((val) => {
        newSearchParams.append(filter.urlParam, val);
      });
    });
    if (searchTerm) {
      newSearchParams.set("busca", searchTerm);
    }
    if (viewFilterId && viewFilterId !== allDocsFilter) {
      newSearchParams.set("filtro", viewFilterId);
    }
    const updatedSearch = newSearchParams.toString();
    if (location.search !== `?${updatedSearch}`) {
      navigate({
        pathname: location.pathname,
        search: updatedSearch,
      });
    }
  }, [...filterValues, searchTerm, viewFilterId, mounted, location.pathname]);

  useEffect(() => {
    if (viewFilterQuery.loading) return;
    filters.forEach((filter) => {
      const queryValue = queryParams.getAll(filter.urlParam);
      setFilterValue(filter.name, queryValue);
    });
    const searchParam = queryParams.get("busca");
    if (searchParam) {
      setSearchTerm(searchParam);
    }
    const viewParam = queryParams.get("filtro");
    if (
      viewParam &&
      viewParam !== allDocsFilter &&
      viewFilterQuery.data?.viewFilters.find((vf) => vf._id === viewParam)
    ) {
      setViewFilterId(viewParam);
    }
    setMounted(true);
  }, [viewFilterQuery.loading]);

  useEffect(() => {
    if (viewFilterQuery.loading) return;
    const viewParam = queryParams.get("filtro");
    if (viewParam && viewParam !== allDocsFilter) {
      if (
        !viewFilterQuery.data?.viewFilters.find((vf) => vf._id === viewParam)
      ) {
        // remove this view filter from the url
        const newSearchParams = new URLSearchParams();
        const existingParams = new URLSearchParams(location.search);
        const paramKeys = Array.from(existingParams.keys());
        const otherParams = paramKeys.filter((key) => key !== "filtro");
        otherParams.forEach((key) => {
          existingParams.getAll(key).forEach((value) => {
            newSearchParams.append(key, value);
          });
        });
        const updatedSearch = newSearchParams.toString();
        navigate({
          pathname: location.pathname,
          search: updatedSearch,
        });
      }
    }
  }, [viewFilterQuery.loading]);
};

export default useUrlFilter;
