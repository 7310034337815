/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { IconNames } from "../../Icons/styles/iconNames";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { theme, ColorNames } from "../../../theme";
import CustomLink from "../../CustomLink";
import Icon from "../../Icons";
import { IconSizes } from "../../Icons/styles/iconSizes";
import ScrollContainer from "../../ScrollContainer";
import ActionButtons from "./components/ActionButtons";
import Navigation from "../components/Navigation";

export interface MenuItem {
  title: string;
  icon?: IconNames;
  location?: string;
  disabled?: boolean;
  flags?: string[];
  submenus?: MenuItem[];
  onClick?: () => void;
  newTab?: boolean;
  active?: boolean;
}

interface LeftMenuProps {
  menuItems: MenuItem[];
  initialCollapsed?: boolean;
}

interface StyleParams {
  collapseVisible: boolean;
  collapsed: boolean;
}

const getCSSRules: CSSRulesResolver<StyleParams> = ({
  collapseVisible,
  collapsed,
}) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: collapsed ? 66 : 240,
    transition: "width 0.1s ease",
  },
  logo: {
    marginTop: 12,
    height: 66,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    a: {
      display: "inline-block",
    },
    img: {
      maxHeight: 42,
      maxWidth: "100%",
    },
  },
  separator: {
    position: "relative",
    height: 1,
    width: "100%",
    backgroundColor: theme.colors.Grey[40],
    margin: "24px 0",
  },
  collapse: {
    position: "absolute",
    top: -13,
    right: -13,
    height: 26,
    width: 26,
    backgroundColor: theme.colors.White[100],
    borderRadius: "50%",
    cursor: "pointer !important",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    border: `1px solid ${theme.colors.Grey[20]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: collapseVisible ? 1 : 0,
    "> span": {
      cursor: "pointer !important",
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "0 12px",
    boxSizing: "border-box",
  },
});

const LeftMenu: React.FC<LeftMenuProps> = ({
  menuItems,
  initialCollapsed = false,
}) => {
  const [collapseVisible, setCollapseVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const styles = useCSSRulesWithTheme(getCSSRules, {
    collapseVisible,
    collapsed,
  });

  const onMenuMouseEnter = () => setCollapseVisible(true);
  const onMenuMouseLeave = () => setCollapseVisible(false);
  const toggleCollapse = () => setCollapsed(!collapsed);

  return (
    <div
      css={styles.container}
      onMouseEnter={onMenuMouseEnter}
      onMouseLeave={onMenuMouseLeave}
    >
      <div css={styles.logo}>
        <CustomLink to="/">
          <img src={collapsed ? "/logo512.png" : "/logo-full.png"} alt="Logo" />
        </CustomLink>
      </div>

      <ActionButtons />

      <div css={styles.separator}>
        <div css={styles.collapse} onClick={toggleCollapse}>
          <Icon
            name={collapsed ? IconNames.ChevronRight : IconNames.ChevronLeft}
            color={ColorNames.GREEN}
            size={IconSizes.Medium}
          />
        </div>
      </div>

      <ScrollContainer
        css={{
          overflow: "auto !important",
        }}
        padding={0}
      >
        <div id="leftMenu" css={styles.menu}>
          <Navigation menuItems={menuItems} collapsed={collapsed} />
        </div>
      </ScrollContainer>
    </div>
  );
};

export default LeftMenu;
