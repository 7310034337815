import { FC } from "react";

const Excelsior: FC = () => {
  return (
    <svg
      width="194"
      height="68"
      viewBox="0 0 194 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5159 50.0312C16.9706 49.3617 17.4113 48.7103 17.852 48.0608C28.5085 32.3598 39.171 16.6629 49.8054 0.947842C50.2721 0.258198 50.7489 -0.0172625 51.5822 0.000833102C54.2102 0.0571306 56.8383 0.0209406 59.4463 0.0209406C59.6126 0.417034 59.3141 0.567831 59.1779 0.768894C48.207 16.9424 37.228 33.1118 26.269 49.2933C25.8323 49.9388 25.3856 50.2263 24.5804 50.2041C22.2468 50.1438 19.9092 50.1881 17.5735 50.178C17.259 50.174 16.9205 50.2725 16.5159 50.0312Z"
        fill="black"
      />
      <path
        d="M112.765 29.9632C114.586 30.8217 116.324 31.5697 118.173 32.01C120.194 32.4925 122.227 32.8585 124.315 32.5971C124.741 32.5428 125.176 32.4564 125.577 32.3056C126.492 31.9658 127.101 31.3465 127.113 30.303C127.125 29.2514 126.53 28.6422 125.599 28.2984C124.18 27.7756 122.674 27.6288 121.214 27.287C119.427 26.8708 117.652 26.4164 116.006 25.57C113.764 24.4179 112.534 22.6304 112.512 20.0508C112.49 17.5013 113.476 15.5148 115.663 14.1657C117.538 13.0096 119.623 12.5773 121.777 12.5049C125.042 12.3963 128.223 12.891 131.295 14.0491C131.874 14.2662 132.131 14.5497 132.107 15.2072C132.055 16.7312 132.091 18.2573 132.091 19.9322C129.659 18.5891 127.225 17.7225 124.631 17.3525C123.538 17.1977 122.436 17.1213 121.34 17.3023C120.038 17.5174 119.313 18.2151 119.251 19.2425C119.187 20.288 119.698 20.9515 120.98 21.3838C122.692 21.9629 124.495 22.1519 126.252 22.5379C127.656 22.8456 129.028 23.2437 130.302 23.9273C134.823 26.3501 135.117 32.963 130.821 35.7699C128.03 37.5935 124.884 37.8589 121.683 37.7202C119.004 37.6036 116.406 37.0406 113.899 36.0493C113.089 35.7296 112.667 35.3657 112.739 34.3926C112.843 32.965 112.765 31.5214 112.765 29.9632Z"
        fill="black"
      />
      <path
        d="M71.0222 29.0886C71.0222 31.1957 71.0162 33.337 71.0282 35.4783C71.0302 35.8664 70.88 36.0815 70.5375 36.2544C65.8682 38.633 58.2364 38.4923 54.062 34.0146C50.5906 30.2889 49.9196 25.8595 51.2596 21.1144C52.5536 16.5342 55.7706 13.8581 60.3357 12.8729C63.7409 12.139 67.1001 12.4124 70.3151 13.8923C70.8299 14.1295 71.0523 14.4231 71.0402 15.0283C70.9982 17.0952 71.0242 19.1621 71.0242 21.418C69.1052 20.0387 67.0741 19.52 64.9248 19.3672C61.5135 19.1279 58.8995 20.9476 58.3766 23.9394C57.6956 27.838 60.0432 30.7815 63.9793 30.9263C66.2969 31.0107 68.4823 30.5302 70.4453 29.2233C70.5956 29.1228 70.7298 28.9157 71.0222 29.0886Z"
        fill="black"
      />
      <path
        d="M108.344 18.4825C108.344 24.3394 108.326 30.1964 108.364 36.0534C108.37 36.8656 108.138 37.0526 107.372 37.0285C105.674 36.9722 103.971 36.9883 102.271 37.0245C101.702 37.0365 101.545 36.8677 101.547 36.2966C101.564 24.4822 101.566 12.6678 101.545 0.853329C101.543 0.183791 101.738 -0.00922719 102.391 0.00685781C104.091 0.0470703 105.794 0.0591302 107.493 0.00283263C108.224 -0.0212949 108.362 0.234058 108.36 0.911639C108.334 6.76859 108.344 12.6255 108.344 18.4825Z"
        fill="black"
      />
      <path
        d="M178.547 25.013C178.547 21.3617 178.567 17.7104 178.529 14.0612C178.521 13.3514 178.721 13.1564 179.416 13.1745C181.217 13.2227 183.02 13.2087 184.822 13.1805C185.375 13.1725 185.561 13.3212 185.561 13.9043C185.543 21.3738 185.543 28.8433 185.561 36.3107C185.563 36.8878 185.387 37.0466 184.83 37.0366C183.03 37.0064 181.225 36.9944 179.424 37.0406C178.739 37.0587 178.523 36.8838 178.531 36.164C178.567 32.4483 178.547 28.7307 178.547 25.013Z"
        fill="black"
      />
      <path
        d="M144.676 24.7054C144.676 28.5175 144.66 32.3317 144.692 36.1438C144.698 36.8355 144.528 37.0627 143.813 37.0426C142.014 36.9923 140.213 37.0084 138.415 37.0365C137.856 37.0446 137.601 36.9722 137.605 36.2966C137.635 30.6769 137.597 25.0593 137.633 19.4396C137.655 15.9391 140.267 13.1 143.729 12.5713C144.6 12.4386 144.696 12.714 144.69 13.466C144.658 17.2138 144.676 20.9596 144.676 24.7054Z"
        fill="black"
      />
      <path
        d="M94.2161 54.5753C94.2161 53.4372 94.2322 52.2992 94.2081 51.1612C94.1981 50.6827 94.3784 50.5419 94.8431 50.5078C97.4992 50.3087 97.4972 50.2966 97.4992 52.9165C97.5012 54.2877 97.4972 55.661 97.5273 57.0322C97.5673 58.882 98.3205 59.7044 99.9149 59.6822C101.415 59.6601 102.19 58.7734 102.202 57.0242C102.215 55.1845 102.247 53.3407 102.188 51.503C102.164 50.7229 102.429 50.558 103.154 50.5641C105.534 50.5842 105.538 50.554 105.534 52.9748C105.532 54.3802 105.494 55.7856 105.522 57.1911C105.608 61.6607 101.908 63.0661 98.7972 62.6198C95.7205 62.1794 94.2221 60.3377 94.2181 57.1871C94.2161 56.3145 94.2161 55.4459 94.2161 54.5753Z"
        fill="black"
      />
      <path
        d="M60.3197 53.8052C58.9676 53.1437 57.6956 52.6652 56.3235 52.5204C55.0315 52.3857 54.2162 52.6993 54.1682 53.4292C54.088 54.6456 55.1036 54.6376 55.8788 54.8306C56.8483 55.0699 57.8499 55.1865 58.7913 55.5464C60.3658 56.1496 61.135 57.2152 61.151 58.7976C61.167 60.3699 60.4218 61.5541 58.9015 62.1392C56.3976 63.1043 53.9278 62.7625 51.496 61.8035C51.1295 61.6587 50.9912 61.4074 51.0073 61.0274C51.0233 60.6594 51.0393 60.2874 51.0033 59.9235C50.9231 59.0992 51.0994 58.8438 51.9527 59.2741C53.0584 59.833 54.2743 60.0964 55.5163 60.195C56.1192 60.2432 56.7221 60.2372 57.277 59.9557C57.6456 59.7687 57.938 59.5194 57.944 59.0409C57.95 58.5302 57.6896 58.1884 57.2549 58.0416C56.626 57.8305 55.971 57.6917 55.322 57.547C54.3765 57.3358 53.431 57.1308 52.5757 56.6462C50.3362 55.3795 50.2681 52.2349 52.5276 50.9823C54.9774 49.6251 57.5013 50.1338 59.9892 50.9863C60.3137 51.0969 60.3217 51.3643 60.3197 51.6397C60.3177 52.3093 60.3197 52.9788 60.3197 53.8052Z"
        fill="black"
      />
      <path
        d="M144.266 53.8132C142.948 53.1377 141.732 52.6973 140.428 52.5425C139.929 52.4842 139.432 52.454 138.939 52.6048C138.447 52.7556 138.11 53.0431 138.1 53.5941C138.092 54.1007 138.384 54.4204 138.835 54.5551C139.474 54.7441 140.125 54.8929 140.776 55.0276C141.561 55.1905 142.343 55.3574 143.078 55.6912C144.352 56.2682 145.041 57.2373 145.099 58.6588C145.159 60.1427 144.602 61.3169 143.258 61.9482C140.632 63.1827 138 62.8047 135.402 61.7813C135.113 61.6687 134.995 61.4637 135.001 61.16C135.007 60.8585 134.999 60.5569 134.999 60.2553C134.999 58.9363 134.999 58.9343 136.177 59.4048C137.269 59.8411 138.397 60.1186 139.57 60.203C140.139 60.2432 140.708 60.2271 141.233 59.9617C141.605 59.7747 141.882 59.5133 141.89 59.0388C141.898 58.5281 141.642 58.1823 141.207 58.0355C140.578 57.8244 139.923 57.6837 139.272 57.547C138.386 57.362 137.515 57.1388 136.702 56.7367C135.524 56.1516 134.857 55.2327 134.867 53.8735C134.877 52.5123 135.444 51.4548 136.67 50.8677C139.07 49.7216 141.479 50.1398 143.871 50.9521C144.228 51.0727 144.276 51.3402 144.27 51.6659C144.258 52.3354 144.266 53.0029 144.266 53.8132Z"
        fill="black"
      />
      <path
        d="M43.4376 25.8655C46.0276 29.6395 48.5635 33.337 51.2096 37.1974C48.7478 37.1974 46.4682 37.1974 44.1867 37.1974C42.0915 37.1974 41.7509 36.9722 41.0779 34.9596C40.4289 33.0193 40.4029 31.0972 41.3744 29.2273C41.9713 28.0812 42.5442 26.9272 43.4376 25.8655Z"
        fill="black"
      />
      <path
        d="M24.8968 12.9995C27.8474 12.9995 30.5816 13.0076 33.3139 12.9915C33.7545 12.9895 33.9749 13.2187 34.1772 13.5464C35.5153 15.7179 35.9399 17.9879 34.9684 20.4308C34.4957 21.6191 33.8046 22.6807 33.1416 23.7624C32.8772 24.1927 32.6789 24.3555 32.3103 23.8127C29.8926 20.2579 27.4508 16.7192 24.8968 12.9995Z"
        fill="black"
      />
      <path
        d="M112.899 56.5075C112.899 58.1441 112.873 59.7808 112.913 61.4174C112.929 62.0809 112.779 62.3503 112.046 62.3564C109.45 62.3785 109.45 62.4066 109.45 59.7647C109.45 57.0262 109.472 54.2857 109.436 51.5472C109.426 50.8033 109.576 50.4917 110.409 50.4977C112.897 50.5158 112.897 50.4796 112.897 53.0009C112.897 54.1691 112.897 55.3373 112.899 56.5075Z"
        fill="black"
      />
      <path
        d="M137.621 9.56737C137.621 8.63042 137.641 7.69347 137.613 6.75853C137.599 6.26794 137.709 6.03672 138.268 6.04476C140.199 6.07492 142.132 6.06888 144.065 6.04878C144.578 6.04275 144.67 6.24984 144.634 6.71228C144.38 9.86695 141.627 12.6879 138.481 13.0257C137.872 13.09 137.557 13.0035 137.607 12.2757C137.669 11.3749 137.621 10.4701 137.621 9.56737Z"
        fill="black"
      />
      <path
        d="M189.838 20.1392C188.905 20.1392 187.971 20.1191 187.038 20.1473C186.537 20.1634 186.437 19.9663 186.455 19.4918C186.571 16.4015 189.76 13.2468 192.823 13.1885C193.372 13.1785 193.504 13.3896 193.498 13.8902C193.478 15.7299 193.474 17.5697 193.5 19.4094C193.508 19.9864 193.293 20.1694 192.737 20.1493C191.771 20.1151 190.804 20.1392 189.838 20.1392Z"
        fill="black"
      />
      <path
        d="M116.835 51.7483C116.835 53.4915 116.835 53.4915 115.221 53.4915C115.086 53.4915 114.95 53.5137 114.822 53.4875C114.361 53.393 113.706 53.7831 113.466 53.2483C113.226 52.7175 113.614 52.1525 113.919 51.6739C114.095 51.3965 114.321 51.1391 114.568 50.9179C115.155 50.3912 115.92 49.8885 116.621 50.168C117.158 50.3831 116.711 51.2618 116.835 51.7483Z"
        fill="black"
      />
      <path
        d="M21.2332 15.3218C16.7502 11.3308 8.37527 11.5821 4.0666 15.8888C1.14207 18.8103 0.236673 22.4877 0.563178 26.4828C0.923736 30.8841 2.86073 34.4389 6.97911 36.2987C12.1731 38.6431 17.4153 38.2349 22.5512 35.9086C22.8637 35.7679 23.1141 35.6171 23.1081 35.1848C23.086 33.4235 23.1001 31.6622 23.1001 29.8265C22.8817 29.8989 22.7535 29.925 22.6413 29.9813C19.8811 31.3405 16.9485 31.8371 13.9018 31.6642C11.4801 31.5255 9.28468 30.876 7.99468 28.5538C7.43982 27.5545 7.50592 27.454 8.60963 27.454C13.379 27.4519 18.1484 27.4379 22.9158 27.4721C23.6028 27.4761 23.8973 27.2609 24.1196 26.6155C25.4697 22.7109 24.2979 18.0503 21.2332 15.3218ZM16.8444 22.4716C15.5103 22.4716 14.1742 22.4716 12.8402 22.4716C11.3058 22.4716 9.76943 22.4576 8.23505 22.4797C7.69622 22.4877 7.60007 22.2887 7.77033 21.8202C8.5255 19.7513 9.96373 18.4745 12.1491 18.193C14.2944 17.9176 16.1974 18.4222 17.5455 20.2841C17.6817 20.4711 17.7838 20.6882 17.876 20.9013C18.4729 22.2846 18.3487 22.4716 16.8444 22.4716Z"
        fill="black"
      />
      <path
        d="M94.4445 15.0424C90.7448 12.0988 84.475 11.6343 80.1463 14.025C75.9939 16.3171 74.1951 20.0709 74.0529 24.625C73.8967 29.6717 75.6213 33.9402 80.3927 36.2183C85.5888 38.6994 90.877 38.243 96.057 35.9207C96.3634 35.784 96.6419 35.6452 96.6359 35.2089C96.6178 33.4416 96.6279 31.6742 96.6279 29.8305C96.3875 29.917 96.2292 29.9572 96.085 30.0275C93.3167 31.3747 90.3782 31.8431 87.3335 31.6622C85.0179 31.5234 82.9206 30.874 81.6246 28.7226C80.9376 27.5826 80.9997 27.4962 82.2897 27.4962C87.027 27.4962 91.7663 27.4861 96.5037 27.5082C97.0786 27.5102 97.377 27.3675 97.5994 26.7884C99.1197 22.7993 97.7636 17.6823 94.4445 15.0424ZM90.8028 22.4897C89.3426 22.4153 87.8763 22.4696 86.412 22.4716C84.8817 22.4716 83.3513 22.4656 81.8209 22.4756C81.3923 22.4776 81.1219 22.4575 81.2821 21.8745C81.7889 20.0227 83.5877 18.4363 85.713 18.189C87.8863 17.9356 89.8253 18.4182 91.1334 20.3745C91.3337 20.6741 91.4458 21.034 91.58 21.3738C91.8745 22.1278 91.7924 22.542 90.8028 22.4897Z"
        fill="black"
      />
      <path
        d="M173.064 18.9711C170.41 14.3045 165.182 11.8877 159.405 12.6537C153.36 13.456 148.554 18.7178 148.462 24.6351C148.346 32.1508 153.905 37.7745 161.594 37.7805C162.291 37.8268 163.115 37.7102 163.94 37.5895C172.571 36.3369 177.371 26.5411 173.064 18.9711ZM161.53 31.4792C157.965 31.4772 155.255 28.7066 155.263 25.0714C155.273 21.4784 158.037 18.7238 161.614 18.7439C165.142 18.762 167.846 21.5186 167.844 25.0935C167.844 28.6965 165.09 31.4833 161.53 31.4792Z"
        fill="black"
      />
      <path
        d="M89.597 61.6406C88.6094 60.7921 88.5253 60.2995 89.4988 59.5495C89.5249 59.5294 89.5389 59.4952 89.5569 59.4691C91.0753 57.2373 90.939 55.0216 89.3185 52.635C90.7247 52.9165 90.4583 52.0459 90.4743 51.3844C90.4843 50.9501 90.5665 50.4977 89.8674 50.5138C87.8322 50.558 85.7891 50.4575 83.7659 50.6143C80.6491 50.8556 78.3055 53.4915 78.4097 56.4411C78.5138 59.4249 81.0798 61.8457 84.2407 61.9301C84.8737 61.9482 85.5086 61.9361 86.1276 62.105C86.6544 62.2478 87.023 62.5614 87.1211 63.1144C87.2253 63.6975 86.9969 64.1699 86.5322 64.5158C86.1777 64.7792 85.763 64.9038 85.3224 64.9481C83.5216 65.129 81.8069 64.8113 80.1483 64.1056C79.92 64.0091 79.5013 63.6371 79.4372 64.1016C79.3171 64.9541 79.277 65.8589 79.4312 66.6993C79.5454 67.3226 80.3366 67.3045 80.8514 67.4412C83.4535 68.1309 86.0655 68.3762 88.5473 67.027C90.5284 65.9493 91.1694 62.9877 89.597 61.6406ZM84.461 59.1313C82.8806 59.1132 81.6046 57.8425 81.5926 56.2742C81.5806 54.6456 82.8666 53.3689 84.5131 53.3729C86.1797 53.3769 87.4236 54.6235 87.4156 56.2762C87.4076 57.9109 86.1216 59.1514 84.461 59.1313Z"
        fill="black"
      />
      <path
        d="M125.775 50.2303C122.25 50.2303 119.479 52.9366 119.465 56.3949C119.451 59.9517 122.175 62.6861 125.739 62.6881C129.31 62.6901 132.055 59.9677 132.051 56.423C132.045 52.9386 129.3 50.2303 125.775 50.2303ZM125.693 59.5958C123.974 59.5556 122.656 58.13 122.7 56.3607C122.744 54.5974 124.156 53.2281 125.863 53.2965C127.6 53.3649 128.866 54.7823 128.796 56.5778C128.73 58.315 127.371 59.636 125.693 59.5958Z"
        fill="black"
      />
      <path
        d="M73.0173 50.9059C70.3732 49.4964 66.9279 50.1639 65.2192 52.4178C63.4084 54.8085 63.5627 58.7996 65.6219 60.8605C67.7291 62.9716 70.3392 62.9736 73.0073 62.3825C74.8161 61.9824 74.8521 61.8256 74.8261 59.9537C74.8181 59.3525 74.7059 59.1957 74.101 59.4369C72.7108 59.9939 71.2546 60.1366 69.7763 59.9054C68.6666 59.7325 67.7592 59.2319 67.2624 58.1501C67.034 57.6555 67.1102 57.4042 67.7191 57.4142C68.8529 57.4344 69.9886 57.4203 71.1224 57.4203C72.2561 57.4203 73.3919 57.4062 74.5256 57.4263C75.0204 57.4344 75.3129 57.3117 75.4591 56.7668C76.06 54.523 75.0184 51.9735 73.0173 50.9059ZM71.9857 55.2951C71.2926 55.2448 70.5936 55.285 69.8985 55.285C69.1693 55.285 68.4402 55.2729 67.7111 55.289C67.2965 55.2991 67.0901 55.2026 67.2043 54.72C67.4567 53.6564 68.6245 52.7335 69.8664 52.6511C71.0923 52.5706 72.2702 53.2905 72.6507 54.342C72.8831 54.9834 72.803 55.3534 71.9857 55.2951Z"
        fill="black"
      />
    </svg>
  );
};

export default Excelsior;
