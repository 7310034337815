import { FC } from "react";
import { useAuth } from "../../../hooks/useAuth";
import ContactBankAccountData from "../../Contact/ContactDetails/components/ContactBankAccountData";

const CommissionPaymentData: FC = () => {
  const { user, loadCurrentUser } = useAuth();
  return (
    <ContactBankAccountData
      contact={user.contact!}
      onSubmit={loadCurrentUser}
    />
  );
};

export default CommissionPaymentData;
