import {
  ClaimStatus,
  ConsultantDocumentStatus,
  DocumentsCsvDataQuery,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  ListDocumentsQuery,
} from "../../../graphql/generated/types";
import { TableColumn } from "../../../hooks/useResizeableTable";
import { NullPartial } from "../../../shared/types";
import { getDocumentPropertyDescription } from "../utils";
import { documentStageLabels } from "../../../shared/documents/documentStage";
import Chip from "../../../components/Chip";
import documentColors from "../../../utils/documentColors";
import { documentStatusLabels } from "../../../shared/documents/documentStatus";
import IconLabel from "../../../components/IconLabel";
import {
  consultantDocumentStatusIcons,
  documentStatusIcons,
  insuranceTypeGroupsIcons,
} from "../../../utils/icons";
import { insuranceTypeGroupLabels } from "../../../shared/insurance/insuranceTypeGroup";
import { claimStatusLabels } from "../../../shared/claims/claimStatus";
import { formatDate, formatDateWithTime } from "../../../utils/dateUtils";
import Avatar from "../../../components/Avatar";
import { IconSizes } from "../../../components/Icons/styles/iconSizes";
import { consultantDocumentStatusLabels } from "../../../shared/documents/consultantDocumentStatus";

type DocumentsTableColumns =
  | "name"
  | "id"
  | "farmerName"
  | "farmerCpfCnpj"
  | "stage"
  | "status"
  | "consultantStatus"
  | "claimStatus"
  | "insuranceTypeGroup"
  | "detail"
  | "area"
  | "premium"
  | "city"
  | "uf"
  | "insuranceCompany"
  | "date"
  | "policyEndDate"
  | "partner"
  | "ope"
  | "com"
  | "sin"
  | "propertyDescription"
  | "harvest"
  | "policyExternalId"
  | "proposalExternalId"
  | "claimExternalIds";

export interface DocumentsTableData
  extends NullPartial<Record<DocumentsTableColumns, string>> {
  id: string;
}

const documentStatusColumn = (
  isAdmin: boolean
): TableColumn<DocumentsTableData> => {
  if (isAdmin) {
    return {
      id: "status",
      label: "STATUS",
      cell: (value) => {
        const status = (value as DocumentStatus) || null;
        const label = status ? documentStatusLabels[status] : "-";
        return status
          ? [
              <IconLabel label={label!} icon={documentStatusIcons[status]} />,
              label,
            ]
          : [];
      },
      width: 120,
    };
  }
  return {
    id: "consultantStatus",
    label: "STATUS",
    cell: (value) => {
      const status = (value as ConsultantDocumentStatus) || null;
      const label = status ? consultantDocumentStatusLabels[status] : "-";
      return status
        ? [
            <IconLabel
              label={label!}
              icon={consultantDocumentStatusIcons[status]}
            />,
            label,
          ]
        : [];
    },
    width: 120,
  };
};

const adminExclusiveColumns: TableColumn<DocumentsTableData>[] = [
  {
    id: "partner",
    label: "PARCEIRO",
    sortKey: "originator.name",
  },
  {
    id: "ope",
    label: "OPE",
    cell: (value) => [
      <Avatar name={value || ""} size={IconSizes.Large} />,
      value || "-",
    ],
    width: 70,
    disableSort: true,
  },
  {
    id: "com",
    label: "COM",
    cell: (value) => [
      <Avatar name={value || ""} size={IconSizes.Large} />,
      value || "-",
    ],
    width: 70,
    disableSort: true,
  },
  {
    id: "sin",
    label: "SIN",
    cell: (value) => [
      <Avatar name={value || ""} size={IconSizes.Large} />,
      value || "-",
    ],
    width: 70,
    disableSort: true,
    hiddenByDefault: true,
  },
];

const documentsTableColumns = (
  isAdmin: boolean
): TableColumn<DocumentsTableData>[] => [
  { id: "name", label: "CÓDIGO", width: 110 },
  { id: "farmerName", label: "PRODUTOR", width: 200, sortKey: "farmer.name" },
  {
    id: "farmerCpfCnpj",
    label: "CPF/CNPJ",
    sortKey: "farmer.cpfCnpj",
    hiddenByDefault: true,
  },
  {
    id: "stage",
    label: "ETAPA",
    cell: (value) => {
      const stage = (value as DocumentStages) || null;
      const label = stage ? documentStageLabels[stage].toUpperCase() : "-";
      const color = documentColors[stage];
      return stage ? [<Chip color={color} label={label} />, label] : [];
    },
    width: 120,
  },
  documentStatusColumn(isAdmin),
  { id: "proposalExternalId", label: "Nº PROPOSTA", hiddenByDefault: true },
  {
    id: "insuranceTypeGroup",
    label: "SEGURO",
    cell: (value) => {
      const insuranceType = (value as InsuranceTypeGroupEnum) || null;
      const label = insuranceType
        ? insuranceTypeGroupLabels[insuranceType].toUpperCase()
        : "-";
      return insuranceType
        ? [
            <IconLabel
              label={label!}
              icon={insuranceTypeGroupsIcons[insuranceType]}
            />,
            label,
          ]
        : [];
    },
    width: 140,
  },
  {
    id: "claimStatus",
    label: "STATUS SINISTRO",
    cell: (value) => {
      const claimStatus = (value as ClaimStatus) || null;
      const label = claimStatus ? claimStatusLabels[claimStatus] : "-";
      return claimStatus ? [label, label] : [];
    },
    hiddenByDefault: true,
  },
  { id: "claimExternalIds", label: "Nº SINISTROS", hiddenByDefault: true },
  { id: "detail", label: "DETALHE", disableSort: true },
  { id: "area", label: "ÁREA (HA)" },
  {
    id: "premium",
    label: "PRÊMIO (R$)",
    emptyValue: "-",
  },
  { id: "city", label: "CIDADE", sortKey: "property.address.city" },
  {
    id: "uf",
    label: "UF",
    width: 55,
    sortKey: "property.address.state",
    hiddenByDefault: true,
  },
  { id: "insuranceCompany", label: "SEGURADORA", sortKey: "provider" },
  {
    id: "date",
    label: "CRIADO EM",
    sortKey: "createdAt",
    hiddenByDefault: true,
  },
  { id: "policyEndDate", label: "VIGÊNCIA", sortKey: "policy.endDate" },
  { id: "policyExternalId", label: "Nº APÓLICE", hiddenByDefault: true },
  {
    id: "propertyDescription",
    label: "PROPRIEDADE",
    disableSort: true,
  },
  {
    id: "harvest",
    label: "SAFRA",
    sortKey: "harvest.name",
    hiddenByDefault: true,
  },
  ...(isAdmin ? adminExclusiveColumns : []),
];

export const mapTableData = (
  documents?: ListDocumentsQuery["documents"]
): DocumentsTableData[] => {
  return (
    documents?.map((doc) => ({
      name: doc.name,
      id: doc._id!,
      farmerName: doc.farmer?.name,
      farmerCpfCnpj: doc.farmer?.cpfCnpj,
      stage: doc.stage,
      status: doc.status,
      proposalExternalId: doc.proposalResult?.externalId,
      consultantStatus: doc.consultantStatus,
      claimStatus: doc.claimStatus,
      claimExternalIds: doc.claims?.map((claim) => claim.externalId).join("/"),
      insuranceTypeGroup: doc.insuranceTypeGroup,
      detail: doc.description,
      area: doc.area?.toString() || undefined,
      premium: doc.premium?.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      city: doc.properties?.[0]?.address?.city,
      uf: doc.properties?.[0]?.address?.state,
      insuranceCompany: doc.provider?.toUpperCase(),
      date: formatDate(doc.createdAt),
      policyEndDate: doc.policy?.endDate
        ? formatDate(doc.policy.endDate)
        : undefined,
      policyExternalId: doc.policy?.externalId,
      partner: doc.originator?.name?.toUpperCase(),
      ope: doc.operationalResponsible?.name,
      com: doc.commercialResponsible?.name,
      sin: doc.claimResponsible?.name,
      propertyDescription: getDocumentPropertyDescription(doc.properties),
      harvest: doc.crop?.harvest?.name,
    })) || []
  );
};

export const mapCsvData = (
  documents?: DocumentsCsvDataQuery["documents"]
): NullPartial<Record<string, any>>[] => {
  return (
    documents?.map((doc) => ({
      id: doc._id!,
      "Nome Produtor": doc.farmer?.name,
      "E-mail Produtor": doc.farmer?.email,
      "Telefone Produtor": doc.farmer?.mobilePhone,
      "CPF/CNPJ Produtor": doc.farmer?.cpfCnpj,
      "Nome Beneficiário": doc.beneficiary?.name,
      "CPF/CNPJ Beneficiário": doc.beneficiary?.cpfCnpj,
      Etapa: doc.stage,
      Status: doc.status,
      Sinistro: doc.claimStatus || undefined,
      "Tipo de Seguro": doc.insuranceTypeGroup,
      Descrição: doc.description,
      Área: doc.area || undefined,
      Prêmio: doc.premium?.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      Cidade: doc.properties?.[0]?.address?.city,
      UF: doc.properties?.[0]?.address?.state,
      Seguradora: doc.provider?.toUpperCase(),
      "Criado Em": formatDateWithTime(doc.createdAt),
      "Início da Vigência": doc.policy?.startDate
        ? formatDate(doc.policy.startDate)
        : undefined,
      "Fim da Vigência": doc.policy?.endDate
        ? formatDate(doc.policy.endDate)
        : undefined,
      Parceiro: doc.originator?.name?.toUpperCase(),
      "Resp. Operacional": doc.operationalResponsible?.name,
      "Resp. Comercial": doc.commercialResponsible?.name,
      "Resp. Sinistro": doc.claimResponsible?.name,
      "Descrição Propriedade": getDocumentPropertyDescription(doc.properties),
      "Sub. Federal": String(doc.paymentData?.federalSubvention),
      "Sub. Estadual": String(doc.paymentData?.stateSubvention),
      "Valor do Produtor":
        doc.proposalResult?.amounts?.finalPremium?.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      "Forma de Pagamento": doc.paymentData?.method,
      "Número de Parcelas": doc.paymentData?.installments,
      LMI: doc.lmi,
    })) || []
  );
};

export default documentsTableColumns;
