/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC } from "react";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { ColorNames, theme } from "../../../theme";
import { IconSizes } from "../../Icons/styles/iconSizes";
import Typography from "../../Typography";

type SubscriptionToggleProps = {
  isSubscribed: boolean;
  onToggle: () => void;
};

const styles: Record<string, CSSObject> = {
  toggleBase: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    padding: "8px 12px",
    transition: "background-color 0.2s ease-in-out",
    textAlign: "center",
    justifyContent: "space-between",
    color: theme.colors.Green[100],
    borderRadius: theme.borderRadius.xl,
    ":hover": {
      backgroundColor: theme.colors.Green[20],
    },
  },
};

export const SubscriptionToggle: FC<SubscriptionToggleProps> = ({
  isSubscribed,
  onToggle,
}) => {
  return (
    <div
      css={[
        styles.toggleBase,
        isSubscribed ? styles.subscribed : styles.notSubscribed,
      ]}
      onClick={onToggle}
    >
      <Icon
        name={isSubscribed ? IconNames.Check : IconNames.Add}
        color={ColorNames.GREEN}
        size={isSubscribed ? IconSizes.Small : IconSizes.Medium}
        pointer
      />
      <Typography align="center" weight="bold">
        {isSubscribed ? "Seguindo" : "Seguir"}
      </Typography>
    </div>
  );
};
