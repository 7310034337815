/** @jsxImportSource @emotion/react */

import moment from "moment";
import { FC } from "react";
import { Note as TNote } from "../../../graphql/generated/types";
import Avatar from "../../Avatar";
import Typography, { BaseFontSize } from "../../Typography";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "16px 0",
    gap: "16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
  },
  userWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    width: "100%",
    alignItems: "center",
    marginBottom: 8,
  },
  noteText: {
    marginLeft: 36,
  },
};

type NoteProps = TNote;

const Note: FC<NoteProps> = (note) => {
  const date = moment(note.createdAt).format("D [de] MMMM");
  const time = moment(note.createdAt).format("HH:mm");
  let author = note.authorId?.name;
  if (author === "Prisma Bot") {
    author = "";
  }

  return (
    <div css={styles.wrapper}>
      {/* Right side */}
      <div>
        <div css={styles.userWrapper}>
          <Avatar name={author!} size={BaseFontSize * 1.8} filled />
          <Typography component="p" align="left" weight="bold">
            {author || "Nota automática"}
          </Typography>
        </div>
        <Typography
          variant="textMedium"
          component="p"
          align="left"
          css={styles.noteText}
        >
          {note.text}
        </Typography>
      </div>
      {/* Left side */}
      <div>
        <Typography variant="textMedium" component="p" align="left">
          {date}
        </Typography>
        <Typography variant="textMedium" component="p" align="right">
          {time}
        </Typography>
      </div>
    </div>
  );
};

export default Note;
