import { useAuth } from "../../hooks/useAuth";
import ContactBankAccountData from "../Contact/ContactDetails/components/ContactBankAccountData";
import { MyOrgMembers } from "./components/MyOrgMembers";

export const useMyOrgTabs = () => {
  const { user, loadCurrentUser } = useAuth();
  return [
    {
      title: "Membros",
      path: "membros",
      element: MyOrgMembers,
    },
    {
      title: "Pagamentos",
      path: "pagamentos",
      element: () => (
        <ContactBankAccountData
          contact={user.org!}
          onSubmit={loadCurrentUser}
        />
      ),
    },
  ];
};
