/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { theme } from "../../../theme";
import Typography from "../../Typography";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { IconSizes } from "../../Icons/styles/iconSizes";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  mediaItem: {
    margin: "8px",
    padding: "12px 24px",
    borderRadius: theme.borderRadius.xxl,
    // border: `1px solid ${theme.colors.Grey[100]}`,
    border: `1px solid ${theme.colors.LightGrey[100]}`,
    backgroundColor: "white",
    boxShadow: theme.boxShadow.default,
    minWidth: "120px",
    textAlign: "center",
    cursor: "pointer",
  },
  downloadIcon: {
    marginLeft: 10,
  },
};

const Media: FC<{
  getMediaUrl: () => void;
  fileName: string;
}> = ({ getMediaUrl, fileName }) => {
  return (
    <div css={styles.mediaItem} onClick={getMediaUrl}>
      <Typography variant={"textSmall"}>{fileName}</Typography>
      <span css={styles.downloadIcon}>
        <Icon name={IconNames.Download} size={IconSizes.Small} pointer />
      </span>
    </div>
  );
};

export default Media;
