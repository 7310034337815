/** @jsxImportSource @emotion/react */

import React from "react";
import { theme } from "../../../theme";

const styles = {
  divider: {
    marginTop: 64,
    marginBottom: 4,
    width: "100%",
    height: 1,
    backgroundColor: theme.colors.Grey[20],
  },
};

const SectionDivider: React.FC = () => {
  return <div css={styles.divider} />;
};

export default SectionDivider;
