import Documents from ".";
import {
  DocumentsLocation,
  DocumentDetailsLocation,
  SimulationLocation,
} from "../../Locations";
import { RouteObject } from "../../types";
import DocumentDetails from "./DocumentDetails";
import { DocumentsWrapper } from "./DocumentWrapper";
import NewSimulation from "../Insurances/drawer/NewSimulation";
import { Routes, Route } from "react-router-dom";
import useDocumentDetails from "../../hooks/useDocumentDetails";
import { useDocumentDetailsTabs } from "../../hooks/useDocumentDetailsTabs";
import { Claims } from "./DocumentDetails/sections/Claim";
import Endorsements from "./DocumentDetails/sections/Endorsements";
import { Policy } from "./DocumentDetails/sections/Policy";
import { Proposal } from "./DocumentDetails/sections/Proposal";
import DocumentSummary from "./DocumentDetails/sections/Summary";
import Quotations from "./DocumentDetails/sections/Quotations";
import { DocumentPayments } from "./DocumentDetails/sections/Payments";
import { Attachments } from "./DocumentDetails/sections/Attachments";
import { DocumentDetailsLayout } from "./DocumentDetails/components/DocumentDetailsLayout";

export const documentRoutes: RouteObject[] = [
  {
    path: DocumentsLocation,
    element: <Documents />,
    withAuth: true,
  },
  {
    path: DocumentDetailsLocation + "/*",
    element: <DocumentDetails />,
    withAuth: true,
  },
  {
    path: SimulationLocation,
    element: (
      <DocumentsWrapper>
        <NewSimulation />
      </DocumentsWrapper>
    ),
    withAuth: true,
  },
];

export const DocumentDetailsRoutes = () => {
  const { documentData } = useDocumentDetails();
  const tabs = useDocumentDetailsTabs(documentData);
  const isDisabled = (path: string) => {
    return tabs.find((tab) => tab.location?.endsWith(path))?.disabled;
  };
  return (
    <Routes>
      <Route element={<DocumentDetailsLayout />}>
        <Route index element={<DocumentSummary />} />
        {!isDisabled("cotacao") && (
          <Route path="cotacao" element={<Quotations />} />
        )}
        {!isDisabled("proposta") && (
          <Route path="proposta" element={<Proposal />} />
        )}
        {!isDisabled("pagamento") && (
          <Route path="pagamento" element={<DocumentPayments />} />
        )}
        {!isDisabled("apolice") && (
          <Route path="apolice" element={<Policy />} />
        )}
        {!isDisabled("endosso") && (
          <Route path="endosso" element={<Endorsements />} />
        )}
        {!isDisabled("sinistro") && (
          <Route path="sinistro" element={<Claims />} />
        )}
        <Route path="anexos" element={<Attachments />} />
      </Route>
    </Routes>
  );
};
