/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ContactsWrapper } from "../../Contact/ContactsWrapper";
import { CSSObject } from "@emotion/react";
import { DetailsHeader } from "../../../components/Details/components/Header/DetailsHeader";
import ScrollContainer from "../../../components/ScrollContainer";
import { DetailsTabs } from "../../../components/Tabs/DetailsTabs";
import { Outlet } from "react-router-dom";
import { MyProfileSummary } from "./MyProfileSummary";
import { useProfileTabs } from "../useProfileTabs";
import PageTitle from "../../../components/PageTitle";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    height: "100%",
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    flex: 1,
    padding: "0 12px",
  },
};

export const MyProfileLayout: FC = () => {
  const tabs = useProfileTabs();
  return (
    <ContactsWrapper>
      <PageTitle title={"Meu perfil"} />
      <div css={styles.wrapper}>
        <DetailsHeader title={"Meu perfil"} />
        <ScrollContainer css={styles.pageContent}>
          <MyProfileSummary />
          {!!tabs.length && (
            <DetailsTabs tabs={tabs}>
              <Outlet />
            </DetailsTabs>
          )}
        </ScrollContainer>
      </div>
    </ContactsWrapper>
  );
};
