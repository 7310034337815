/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css, CSSObject } from "@emotion/react";
import useDocumentDetails from "../../hooks/useDocumentDetails";
import Avatar from "../Avatar";
import { theme } from "../../theme";
import UserSelect from "../Details/components/Main/UserSelect";
import { UserRoles } from "../../graphql/generated/types";
import Typography, { BaseFontSize } from "../Typography";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";

type ResponsibleTypes = "commercial" | "claim" | "operational";

// Helper to dynamically set z-index for each avatar
const dynamicZIndex = (index: number) =>
  css({
    zIndex: index + 1,
  });

const styles: Record<string, CSSObject> = {
  wrapper: {
    display: "flex",
  },
  responsibleWrapper: {
    position: "relative",
    marginLeft: "-6px",
    ":first-of-type": {
      marginLeft: 0,
    },
    "> div": {
      backgroundColor: "white",
    },
  },
  popover: {
    position: "absolute",
    top: "100%",
    right: 0,
    backgroundColor: "#fff",
    padding: "20px",
    boxSizing: "border-box",
    marginTop: "4px",
    border: `1px solid ${theme.colors.Grey[100]}`,
    borderRadius: theme.borderRadius.xxl,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "14px",
  },
  popoverContent: {
    minWidth: "250px",
  },
  // New style for the close button
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "14px",
    fontWeight: 600,
    cursor: "pointer",
    // You can add hover/focus styles if needed
  },
};

export const ResponsiblesSelects = () => {
  const {
    documentData: {
      commercialResponsible,
      claimResponsible,
      operationalResponsible,
    },
    handleSetOperationalResponsible,
    handleSetClaimResponsible,
    handleSetCommercialResponsible,
  } = useDocumentDetails();

  const [openResponsible, setOpenResponsible] =
    useState<ResponsibleTypes | null>(null);

  const responsibles = [
    {
      name: commercialResponsible?.name,
      label: "Responsável Comercial",
      type: "commercial" as ResponsibleTypes,
      value: commercialResponsible?._id,
    },
    {
      name: claimResponsible?.name,
      label: "Responsável pelo Sinistro",
      type: "claim" as ResponsibleTypes,
      value: claimResponsible?._id,
    },
    {
      name: operationalResponsible?.name,
      label: "Responsável Operacional",
      type: "operational" as ResponsibleTypes,
      value: operationalResponsible?._id,
    },
  ];

  const onSelect = (responsible: (typeof responsibles)[0], value: string) => {
    switch (responsible.type) {
      case "commercial":
        handleSetCommercialResponsible(value);
        break;
      case "claim":
        handleSetClaimResponsible(value);
        break;
      case "operational":
        handleSetOperationalResponsible(value);
        break;
    }
    // Close the popover
    setOpenResponsible(null);
  };

  const openSelectResponsiblePortal = (type: ResponsibleTypes) => {
    setOpenResponsible((prev) => (prev === type ? null : type));
  };

  return (
    <div css={styles.wrapper}>
      {responsibles.map((responsible, i) => (
        <div key={i} css={[styles.responsibleWrapper, dynamicZIndex(i)]}>
          <Avatar
            id={`responsible-avatar-${responsible.type}`}
            name={responsible.name || ""}
            filled={true}
            onClick={() => openSelectResponsiblePortal(responsible.type)}
            size={BaseFontSize * 1.8}
          />

          {openResponsible === responsible.type && (
            <div css={styles.popover}>
              <Icon
                css={styles.closeButton}
                onClick={() => setOpenResponsible(null)}
                name={IconNames.Close}
                size={BaseFontSize * 1.2}
              />

              <Typography weight="bold" align="left" variant="textLarge">
                {responsible.label}
              </Typography>

              <div css={styles.popoverContent}>
                <UserSelect
                  value={responsible.value!}
                  onSelect={(option) => onSelect(responsible, option?.value!)}
                  inputName={responsible.type}
                  placeholder={`Selecione um ${responsible.label}`}
                  domain={{ role: UserRoles.Admin }}
                  isClearable={true}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
