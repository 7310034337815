import { IconNames } from "../components/Icons/styles/iconNames";
import { ContactTags, UserStatus } from "../graphql/generated/types";
import { ContactsLocation } from "../Locations";
import { contactTagLabels } from "../shared/contacts/contactTags";
import { responsibleFilter, stateFilter } from "./common";
import { TFilter } from "./types";

const contactTagFilter: TFilter = {
  label: "Tipo",
  value: [],
  urlParam: "tipo",
  name: "tipo",
  selectMultiValueDisplay: "all",
  options: Object.values(ContactTags).map((tag) => {
    const label = contactTagLabels[tag];
    return {
      value: label.toLowerCase(),
      name: tag,
      label,
      filter: {
        tags: tag,
      },
      isDefaultOption: tag !== ContactTags.Lead,
    };
  }),
};

export const contactStatusFilter: TFilter = {
  label: "Status de usuario",
  value: [],
  urlParam: "status",
  name: "status",
  selectOptionDisplay: "icon",
  selectMenuListDisplay: "buttons",
  selectMultiValueDisplay: "all",
  options: [
    {
      value: "cadastrado",
      label: "Cadastrados",
      filter: {
        status: UserStatus.Registered,
      },
      iconName: IconNames.Pending,
    },
    {
      value: "aprovado",
      label: "Aprovados",
      filter: {
        status: UserStatus.Approved,
      },
      iconName: IconNames.Done,
    },
    {
      value: "ativo",
      label: "Ativos",
      filter: {
        status: UserStatus.Active,
      },
      iconName: IconNames.Valid,
    },
    {
      value: "recusado",
      label: "Recusados",
      filter: {
        status: UserStatus.Rejected,
      },
      iconName: IconNames.Rejected,
    },
  ],
};

export const getFilterLink = (
  contactTag: ContactTags,
  contactStatus?: UserStatus
) => {
  const params = new URLSearchParams();
  const contactTagFilterValue = contactTagFilter.options.find(
    (opt) => opt.name === contactTag
  )!.value;
  params.append(contactTagFilter.urlParam, contactTagFilterValue);
  if (contactStatus) {
    const contactStagFilterValue = contactStatusFilter.options.find(
      (opt) => opt.filter.status === contactStatus
    )!.value;
    params.append(contactStatusFilter.urlParam, contactStagFilterValue);
  }
  const tagFilterUrl = `${ContactsLocation}?${params.toString()}`;
  return tagFilterUrl;
};

const hasDocumentFilter: TFilter = {
  label: "Tem documentos?",
  value: [],
  urlParam: "documentos",
  name: "hasDocuments",
  options: [
    {
      value: "sim",
      label: "Sim",
      filter: {
        hasDocs: true,
      },
    },
    {
      value: "nao",
      label: "Não",
      filter: {
        hasDocs: false,
      },
    },
  ],
};

export const contactFilters = (): TFilter[] => [
  contactTagFilter,
  stateFilter,
  contactStatusFilter,
  responsibleFilter,
  hasDocumentFilter,
];
