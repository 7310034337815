/** @jsxImportSource @emotion/react */

import { FC, PropsWithChildren } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import FormLabel from "../FormLabel";
import { FormInfo } from "../FormInfo";
import VariableFieldColumn from "./VariableFieldColumn";

interface DefaultFieldWrapperProps extends PropsWithChildren {
  field: FormFieldV2;
}

const DefaultFieldWrapper: FC<DefaultFieldWrapperProps> = ({
  field: { variant, info, label },
  children,
}) => {
  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      {info && <FormInfo info={info} />}
      {children}
    </VariableFieldColumn>
  );
};

export default DefaultFieldWrapper;
