import { FC } from "react";

const Calendar: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="calendar--heat-map 1" clipPath="url(#clip0_6353_6971)">
        <path
          id="Vector"
          d="M26 4H22V2H20V4H12V2H10V4H6C5.46977 4.00066 4.96145 4.21159 4.58652 4.58652C4.21159 4.96145 4.00066 5.46977 4 6V26C4.00066 26.5302 4.21159 27.0386 4.58652 27.4135C4.96145 27.7884 5.46977 27.9993 6 28H26C26.5302 27.9993 27.0386 27.7884 27.4135 27.4135C27.7884 27.0386 27.9993 26.5302 28 26V6C27.9993 5.46977 27.7884 4.96145 27.4135 4.58652C27.0386 4.21159 26.5302 4.00066 26 4ZM6 6H10V8H12V6H20V8H22V6H26V10H6V6ZM6 12H11V18H6V12ZM19 26H13V20H19V26ZM19 18H13V12H19V18ZM21 26V20H26L26.0012 26H21Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6353_6971">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calendar;
