/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import CustomLink from "../../CustomLink";
import { theme } from "../../../theme";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import MobileMenuDropdown from "./components/MobileMenuDropdown";
import { MenuItem } from "../LeftMenu/LeftMenu";
import { useLocation } from "react-router-dom";

interface MobileMenuProps {
  menuItems: MenuItem[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: theme.colors.Grey[20],
    zIndex: 1000,
  },
  menuButton: {
    position: "absolute",
    right: 0,
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const MobileMenu: React.FC<MobileMenuProps> = ({ menuItems }) => {
  const styles = useCSSRulesWithTheme(getCSSRules, {});
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <div css={styles.container}>
      <CustomLink to="/">
        <img src="/logo-full.png" alt="Logo" />
      </CustomLink>
      <div css={styles.menuButton} onClick={() => setOpen(!open)}>
        <Icon name={IconNames.OverflowMenuVertical} size={30} />
      </div>
      <MobileMenuDropdown menuItems={menuItems} open={open} />
    </div>
  );
};

export default MobileMenu;
