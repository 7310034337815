/** @jsxImportSource @emotion/react */
import { GetDocumentDetailsDocument } from "../../../graphql/generated/types";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import DocumentDetailsProvider from "../../../contexts/documents";
import { FC } from "react";
import SkeletonDetails from "../../../components/Details/components/SkeletonDetails";
import { DocumentsWrapper } from "../DocumentWrapper";
import { DocumentDetailsRoutes } from "../Routes";

const DocumentDetails: FC = () => {
  const { docId: paramsId } = useParams();
  const { data, loading, refetch } = useQuery(GetDocumentDetailsDocument, {
    variables: {
      docId: paramsId,
    },
  });

  if (loading) return <SkeletonDetails />;

  return (
    <DocumentDetailsProvider documentData={data?.document} refetch={refetch}>
      <DocumentsWrapper>
        <DocumentDetailsRoutes />
      </DocumentsWrapper>
    </DocumentDetailsProvider>
  );
};

export default DocumentDetails;
