/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { CropInsuranceQuestions } from "../../../../../graphql/generated/types";
import { CSSObject } from "@emotion/react";
import Typography from "../../../../../components/Typography";
import { DetailsWrapper } from "../../../../../components/ModelDetails/DetailsWrapper";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import { IconSizes } from "../../../../../components/Icons/styles/iconSizes";
import { ColorNames } from "../../../../../theme";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  title: {
    marginBottom: "10px",
  },
  field: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
    gap: "12px",
  },
  booleanValueWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    alignItems: "center",
  },
};

export const CropQuestions: FC<{ questions: CropInsuranceQuestions }> = ({
  questions,
}) => {
  const fields = [
    {
      label: "Cultura plantada",
      value: !!questions.isPlanted,
    },
    {
      label:
        "Primeiro ano de plantio pós cerrado, canavial, pastagem, mata nativa?",
      value: !!questions.firstCrop,
    },
    {
      label: "Houve ocorrência de sinsitro na safra corrente?",
      value: !!questions.recentIncident,
    },
    {
      label: "Há outros seguros sobre este item?",
      value: !!questions.otherInsurances,
    },
    {
      label: "Possui irrigação noturna?",
      value: !!questions.nightIrrigation,
    },
    {
      label: "Produtor pratica cultivo orgânico da cultura?",
      value: !!questions.organicCulture,
    },
    {
      label: "O produtor é beneficiário do PRONAMP?",
      value: !!questions.pronampBeneficiary,
    },
    {
      label:
        "Declaro que o plantio da cultura segurada foi ou será realizado conforme indicação do zoneamento agrícola do MAPA, considerando o local de risco, período de plantio para cada tipo de solo e semente utilizada.",
      value: !!questions.zarcPolicy,
    },
    {
      label: "Plantação financiada por crédito rural?",
      value: !!questions.ruralCredit,
    },
    ...(!questions.ruralCreditContractNumber
      ? []
      : [
          {
            label: "Número do contrato de financiamento por crédito rural",
            value: questions.ruralCreditContractNumber,
          },
        ]),
    {
      label: "Valor do prêmio será financiado?",
      value: !!questions.financedPremium,
    },
    ...(!questions.financedPremium
      ? []
      : [
          {
            label: "Número do contrato de financiamento por prêmio reduzido",
            value: questions.financedPremiumContractNumber,
          },
        ]),
  ];
  return (
    <DetailsWrapper>
      <div css={styles.wrapper}>
        <Typography
          weight="bold"
          align="left"
          variant="textLarge"
          css={styles.title}
        >
          Perguntas da Lavoura
        </Typography>
        {fields.map((field) => {
          const fieldIsBoolean = typeof field.value === "boolean";
          return (
            <div key={field.label} css={styles.field}>
              <Typography weight="bold" align="left">
                {field.label}
              </Typography>
              <div css={styles.booleanValueWrapper}>
                {fieldIsBoolean && (
                  <Icon
                    name={field.value ? IconNames.Check : IconNames.Close}
                    size={IconSizes.Small}
                    color={
                      field.value ? ColorNames.GREEN : ColorNames.ERROR_RED
                    }
                  />
                )}
                {!fieldIsBoolean && <Typography>{field.value}</Typography>}
                {fieldIsBoolean && (
                  <Typography>{field.value ? "Sim" : "Não"}</Typography>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </DetailsWrapper>
  );
};
