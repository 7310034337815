/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Typography from "../../../components/Typography";
import { ColorNames } from "../../../theme";
import { FormSection } from "../models/formInterfaces";
import { FormSectionV2 } from "../../../graphql/generated/types";
import Icon from "../../../components/Icons";
import { IconNames } from "../../../components/Icons/styles/iconNames";

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    lineHeight: "20px",
    justifyContent: "space-between",
  },
  icon: {
    marginLeft: "1em",
    cursor: "pointer",
  },
  editWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};

interface SectionTitleProps {
  sectionData: FormSection | FormSectionV2;
  handleEdit?: () => void;
}
const SectionTitle: FC<SectionTitleProps> = ({ sectionData, handleEdit }) => {
  return (
    <div css={styles.wrapper}>
      <Typography
        variant="textMedium"
        align="left"
        component="span"
        color={ColorNames.BLACK}
      >
        <b>{sectionData.title}</b>
      </Typography>
      {handleEdit && (
        <div style={styles.editWrapper} onClick={handleEdit}>
          <Typography color={ColorNames.GREEN}>Editar</Typography>
          <Icon
            name={IconNames.Edit}
            css={styles.icon}
            onClick={handleEdit}
            color={ColorNames.GREEN}
          />
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
