import { PropostaProviders } from '../../graphql/generated/types';
import { TModelOptionLabels } from '../types';

export const providerLabels: TModelOptionLabels<PropostaProviders> = {
  [PropostaProviders.Agrobrasil]: 'Agrobrasil',
  [PropostaProviders.Allianz]: 'Allianz',
  [PropostaProviders.Btg]: 'Btg',
  [PropostaProviders.Essor]: 'Essor',
  [PropostaProviders.Fairfax]: 'Fairfax',
  [PropostaProviders.Kovr]: 'Kovr',
  [PropostaProviders.Mapfre]: 'Mapfre',
  [PropostaProviders.Porto]: 'Porto',
  [PropostaProviders.Sancor]: 'Sancor',
  [PropostaProviders.Sombrero]: 'Sombrero',
  [PropostaProviders.Sompo]: 'Sompo',
  [PropostaProviders.Swiss]: 'SwissRe',
  [PropostaProviders.Tokyo]: 'Tokyo',
  [PropostaProviders.Ezze]: 'Ezze',
  [PropostaProviders.Excelsior]: 'Excelsior',
};
