/** @jsxImportSource @emotion/react */

import { FC, useCallback } from "react";
import Reject from ".";
import { ConsultantsLocation } from "../../Locations";
import { useHandleSignupRejectionMutation } from "../../graphql/generated/types";
import { rejectReasons } from "../../shared/contact/rejectReason";
import { useParams } from "react-router-dom";

const PartnerRejection: FC = () => {
  const params = useParams();
  const { contactId } = params as { contactId: string };
  const [handleSignupRejectionMutation] = useHandleSignupRejectionMutation();

  const rejectPartnerById = useCallback(
    async (_: any, __: string, rejectReason: string) => {
      await handleSignupRejectionMutation({
        variables: { contactId, rejectReason },
      });
    },
    [contactId]
  );

  return (
    <Reject
      contactId={contactId}
      callbackLocation={ConsultantsLocation}
      confirmationMessage={"Cadastro recusado!"}
      errorMessage={"Não foi possível recusar o parceiro."}
      rejectReasons={rejectReasons}
      deletePartnerOrQuotation={rejectPartnerById}
    />
  );
};

export default PartnerRejection;
