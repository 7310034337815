/** @jsxImportSource @emotion/react */
import React from "react";
import { useTableContext } from "../../../../contexts/table";
import { documentsTableId } from "../../../../views/Documents";
import { Switcher } from "../../../Switcher";
import useIsMobile from "../../../../hooks/useIsMobile";

export interface TableSwitchProps {
  active: string;
  onClick: (id: string) => void;
}

export enum TableView {
  Table = "table",
  Card = "card",
  Metrics = "metrics",
}

const TableSwitch: React.FC<TableSwitchProps> = ({ active, onClick }) => {
  const { tableId } = useTableContext();
  const isMobile = useIsMobile();

  const switcherOptions = [
    { label: "CARD", value: TableView.Card },
    ...(tableId === documentsTableId
      ? [{ label: "ANÁLISE", value: TableView.Metrics }]
      : []),
  ];

  if (!isMobile)
    switcherOptions.unshift({ label: "TABELA", value: TableView.Table });

  return (
    <Switcher
      options={switcherOptions}
      active={active}
      onChange={(val) => onClick(val)}
    />
  );
};

export default TableSwitch;
