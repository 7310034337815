/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import React from "react";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";

interface ChatListPaginatorProps {
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
}

const styles: CSSObject = {
  paginator: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
};

const ChatListPaginator: React.FC<ChatListPaginatorProps> = ({
  page,
  setPage,
  totalPages,
}) => {
  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div css={styles.paginator}>
      <Icon
        name={IconNames.ChevronLeft}
        onClick={handlePrevious}
        disabled={page === 1}
      />
      <span>
        Página <b>{page}</b> de <b>{totalPages}</b>
      </span>
      <Icon
        name={IconNames.ChevronRight}
        onClick={handleNext}
        disabled={page === totalPages}
      />
    </div>
  );
};

export default ChatListPaginator;
