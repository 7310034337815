/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC } from "react";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";
import Typography, { FontSizes } from "../../../Typography";
import HR from "../../../HorizontalLine";
import { ColorNames } from "../../../../theme";
import { useNavigate } from "react-router-dom";

interface DetailsHeaderProps {
  title: string;
  actions?: React.ReactNode;
}

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    minHeight: "56px",
  },
  leftContent: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    alignItems: "center",
  },
  rightContent: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
};

export const DetailsHeader: FC<DetailsHeaderProps> = ({ title, actions }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div css={styles.wrapper}>
        <div css={styles.leftContent}>
          <Icon name={IconNames.Return} onClick={() => navigate(-1)} />
          <Typography variant="h1" margin="none" size={FontSizes.ExtraLarge}>
            {title}
          </Typography>
        </div>
        <div css={styles.rightContent}>{actions}</div>
      </div>
      <HR color={ColorNames.LIGHTGREY} borderWidth={2} spacingSize="none" />
    </div>
  );
};
