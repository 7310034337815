/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import {
  ContactTags,
  Services,
} from "../../../../../../graphql/generated/types";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import ContactSelect from "../../../../../../components/Details/components/Main/ContactSelect";
import { useAuth } from "../../../../../../hooks/useAuth";
import { UserRoles } from "../../../../../../graphql/generated/types";
import Many2OneInput from "../../../../../../components/Input/SelectInputs/Many2OneInput";
import { useFlags } from "../../../../../../hooks/useFlags";
import { Maybe } from "graphql/jsutils/Maybe";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { ColorNames } from "../../../../../../theme";

interface SelectInput {
  inputName: string;
  onSelect: (value: string) => void;
  value?: Maybe<string> | undefined;
  domain?: Record<string, any>;
  disabled?: boolean;
  flag?: string;
  isClearable?: boolean;
}

const Selects = () => {
  const {
    documentData: {
      farmerId,
      commercialResponsibleId,
      operationalResponsibleId,
      claimResponsibleId,
      originatorId,
      contactId,
      specialOperationId,
      crop,
      beneficiaryId,
    },
    handleSetContact,
    handleSetSpecialOperation,
    handleSetBeneficiary,
  } = useDocumentDetails();

  const auth = useAuth();
  const { isFlagEnabled } = useFlags();

  const selectInputs: SelectInput[] = useMemo(() => {
    return [
      {
        inputName: "Contato",
        value: contactId,
        onSelect: handleSetContact,
        disabled: false,
        isClearable: false,
      },
      beneficiaryId && {
        inputName: "Beneficiário",
        value: beneficiaryId,
        onSelect: handleSetBeneficiary,
        domain: {
          $or: [
            { tags: ContactTags.Beneficiary, parentId: farmerId },
            { _id: farmerId },
          ],
        },
      },
    ].filter(Boolean) as SelectInput[];
  }, [
    commercialResponsibleId,
    operationalResponsibleId,
    claimResponsibleId,
    originatorId,
    contactId,
    beneficiaryId,
    farmerId,
  ]);

  const selectsInfo: DetailsRowProps[] = useMemo(() => {
    return selectInputs
      .map((select) => {
        if (select.flag && !isFlagEnabled(select.flag)) return;
        return {
          label: select.inputName,
          value: (
            <ContactSelect
              value={select.value}
              onSelect={(option) => select.onSelect(option?.value!)}
              inputName={select.inputName}
              placeholder={select.inputName}
              disabled={select.disabled}
              domain={select.domain}
              isClearable={select.isClearable}
            />
          ),
        };
      })
      .filter(Boolean) as DetailsRowProps[];
  }, [selectInputs, isFlagEnabled]);

  const specialOperationInfo = useMemo(
    () =>
      crop?.cultureId && isFlagEnabled("admin-only")
        ? [
            {
              label: "Operação Especial",
              value: (
                <Many2OneInput
                  serviceName={Services.SpecialOperations}
                  optionDisplay="avatar"
                  domain={{ cultureId: crop?.cultureId }}
                  value={specialOperationId}
                  placeholder="Operação Especial"
                  onSelect={(option) =>
                    handleSetSpecialOperation(option?.value)
                  }
                  inputName="operationalResponsbibleId"
                  isClearable
                  disabled={auth.user.role !== UserRoles.Admin}
                  color={ColorNames.BLACK}
                />
              ),
            },
          ]
        : [],
    [specialOperationId, crop]
  );

  return (
    <DetailsSectionColumn
      xs={12}
      sm={6}
      rows={[...selectsInfo, ...specialOperationInfo]}
    />
  );
};

export default Selects;
