/** @jsxImportSource @emotion/react */
import { useDocumentsCsvDataLazyQuery } from "../../graphql/generated/types";
import useDocumentsFilter from "../../hooks/useDocumentsFilter";
import documentsTableColumns, {
  mapCsvData,
} from "./DocumentsTable/tableColumns";
import TableProvider from "../../contexts/table";
import { DocumentsWrapper } from "./DocumentWrapper";
import DocumentsTable from "./DocumentsTable";
import { useFlags } from "../../hooks/useFlags";
export const documentsTableId = "documents";

const Documents = () => {
  const documentFilters = useDocumentsFilter();
  const [documentsCsvData] = useDocumentsCsvDataLazyQuery();

  const fetchSheetData = async () => {
    const result = await documentsCsvData({
      variables: {
        filter: documentFilters.composeQuery(),
      },
    });

    return mapCsvData(result.data?.documents);
  };

  const { isFlagEnabled } = useFlags();

  return (
    <DocumentsWrapper>
      <TableProvider
        tableId="documents"
        allColumns={documentsTableColumns(isFlagEnabled("admin-only"))}
        initialSort={{ id: "date", sortKey: "createdAt", direction: -1 }}
        fetchSheetData={fetchSheetData}
      >
        <DocumentsTable filter={documentFilters} />
      </TableProvider>
    </DocumentsWrapper>
  );
};

export default Documents;
