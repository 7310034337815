import { TModelOptionLabels } from '../types';

import { ConsultantDocumentStatus } from '../../graphql/generated/types';

export const consultantDocumentStatusLabels: TModelOptionLabels<ConsultantDocumentStatus> = {
  [ConsultantDocumentStatus.WaitingApproval]: 'Aguardando Aprovação',
  [ConsultantDocumentStatus.WaitingData]: 'Aguardando Preenchimento',
  [ConsultantDocumentStatus.WaitingPolicy]: 'Aguardando Apólice',
  [ConsultantDocumentStatus.UnderAnalysis]: 'Em Análise',
  [ConsultantDocumentStatus.Rejected]: 'Rejeitado',
  [ConsultantDocumentStatus.Cancelled]: 'Cancelado',
  [ConsultantDocumentStatus.Effective]: 'Vigente',
  [ConsultantDocumentStatus.NonEffective]: 'Não Vigente',
};
