/** @jsxImportSource @emotion/react */
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import Typography from "../Typography";
import { ColorNames, getThemeColorFromAlias, theme } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import useOutsideClick from "../../hooks/useOutsideClick";
import { IconSizes } from "../Icons/styles/iconSizes";
import { css } from "@emotion/react";
import useDetailsColor from "../../hooks/useDetailsColor";
import { createPortal } from "react-dom";

export type TOverflowMenuOptions = {
  text: string;
  iconName?: IconNames;
  onClick?: () => void;
  disabled?: boolean;
};

interface OverflowMenuProps {
  hoverColor?: ColorNames;
  options: TOverflowMenuOptions[];
  vertical?: boolean;
}

const getCSSStyles = (
  { borderColor }: { borderColor: string },
  { backgroundColor }: { backgroundColor: string }
) => ({
  container: {},
  optionsList: {
    display: "block",
    position: "absolute" as const,
    height: "fit-content",
    right: "50%",
    backgroundColor: theme.colors.White[100],
    borderRadius: 5,
    boxShadow: theme.boxShadow.default,
    padding: 10,
    minWidth: 180,
    border: `1px solid ${borderColor}`,
    zIndex: 100,
  },
  option: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    "&:hover": {
      backgroundColor: backgroundColor,
    },
    "& > span": {
      marginRight: 6,
    },
  },
});

const OverflowMenu: FC<OverflowMenuProps> = ({
  options,
  hoverColor,
  vertical,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  // Instead of storing "top: 100%" or "right: 50%", we'll store actual page coords:
  const [menuPos, setMenuPos] = useState<{
    top: number | "auto";
    bottom: number | "auto";
    left: number | "auto";
    right: number | "auto";
  }>({ top: "auto", bottom: "auto", left: "auto", right: "auto" });

  const triggerRef = useRef<HTMLDivElement>(null); // The button container
  const menuRef = useOutsideClick(() => setShowOptions(false)); // For the dropdown

  const { color } = useDetailsColor();
  const borderColor = getThemeColorFromAlias(color, theme.colors)!;
  const backgroundColor = getThemeColorFromAlias(color, theme.colors, 20)!;
  const styles = getCSSStyles({ borderColor }, { backgroundColor });

  // Toggle the menu
  const onIconClick = () => setShowOptions((prev) => !prev);

  // Option click
  const handleOptionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    option: TOverflowMenuOptions
  ) => {
    if (option.disabled || !option.onClick) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    option.onClick();
    setShowOptions(false);
  };

  const adjustMenuPosition = () => {
    if (!triggerRef.current) return;

    const { left, bottom, width } = triggerRef.current.getBoundingClientRect();
    const menuWidth = 180; // or any known minWidth (your original is 180)
    const spaceFromBottom = window.innerHeight - bottom;

    if (spaceFromBottom < 200) {
      setMenuPos({
        top: "auto",
        bottom: window.innerHeight - (bottom - 2), // 2px offset above
        left,
        right: "auto",
      });
    } else {
      setMenuPos({
        top: bottom + 2,
        bottom: "auto",
        // If left < 200, push it to the right, else keep alignment
        left: left < 200 ? left : left + width - menuWidth,
        right: "auto",
      });
    }
  };

  // Whenever we show the dropdown, measure its position
  useEffect(() => {
    if (showOptions) {
      adjustMenuPosition();
    }
  }, [showOptions]);

  return (
    <div ref={triggerRef} css={styles.container}>
      <Icon
        name={
          vertical ? IconNames.OverflowMenuVertical : IconNames.OverflowMenu
        }
        color={ColorNames.GREY}
        hoverColor={hoverColor ? hoverColor : ColorNames.BLACK}
        onClick={onIconClick}
      />

      {showOptions && (
        <Portal>
          <div
            ref={menuRef}
            css={css(styles.optionsList)}
            style={{
              position: "absolute",
              top: menuPos.top === "auto" ? undefined : menuPos.top,
              bottom: menuPos.bottom === "auto" ? undefined : menuPos.bottom,
              left: menuPos.left === "auto" ? undefined : menuPos.left,
              right: menuPos.right === "auto" ? undefined : menuPos.right,
            }}
          >
            {options.map((option, i) => (
              <div
                css={css(styles.option, {
                  cursor: option.disabled ? "not-allowed" : "pointer",
                })}
                key={`overflow-item-${i}`}
                onClick={(e) => handleOptionClick(e, option)}
              >
                {option.iconName && (
                  <Icon
                    name={option.iconName}
                    size={IconSizes.Small}
                    color={option.disabled ? ColorNames.GREY : color}
                  />
                )}
                <Typography
                  variant="textMedium"
                  component="div"
                  color={option.disabled ? ColorNames.GREY : ColorNames.BLACK}
                >
                  {option.text}
                </Typography>
              </div>
            ))}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default OverflowMenu;

interface PortalProps {
  children: ReactNode;
}

const Portal: FC<PortalProps> = ({ children }) => {
  return createPortal(children, document.body);
};
