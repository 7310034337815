import { FC } from "react";
import { Outlet } from "react-router-dom";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import { DetailsTabs } from "../../../../components/Tabs/DetailsTabs";
import { useDocumentDetailsTabs } from "../../../../hooks/useDocumentDetailsTabs";

export const DocumentTabs: FC = () => {
  const { documentData } = useDocumentDetails();
  const tabs = useDocumentDetailsTabs(documentData);
  return (
    <>
      <DetailsTabs tabs={tabs}>
        <Outlet />
      </DetailsTabs>
    </>
  );
};
