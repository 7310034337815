import { FC, useMemo } from "react";

import DefaultFieldSummary from "./DefaultFieldSummary";
import { formatInputMask, formatPrice } from "../../../../utils/formatNumber";
import { TInputMasks } from "../../models/formInterfaces";
import ObjectFieldSummary from "./ObjectFieldSummary";
import ObjectListFieldSummary from "./ObjectListFieldSummary";
import Many2XFieldSummary from "./Many2XFieldSummary";
import { formatISODate } from "../../../../utils/dateUtils";

import {
  FormFieldV2,
  FormInputTypes,
} from "../../../../graphql/generated/types";
import SelectionFieldSummary from "./SelectionFieldSummary";
import BooleanFieldSummary from "./BooleanFieldSummary";
import { GeoLocationFieldSummary } from "./GeoLocationFieldSummary";

type FormFieldSummaryProps = {
  field: FormFieldV2;
};

const FormFieldSummary: FC<FormFieldSummaryProps> = ({ field }) => {
  const { inputType, invisible, value, inputMask, variant, label } = field;
  if (invisible) {
    return null;
  }
  const summaryField = useMemo(() => {
    switch (inputType) {
      case FormInputTypes.Integer:
      case FormInputTypes.Float:
      case FormInputTypes.TextField:
      case FormInputTypes.TextArea:
      case FormInputTypes.Email:
        return (
          <DefaultFieldSummary
            label={label}
            value={formatInputMask(value, inputMask as TInputMasks)}
            variant={variant}
          />
        );
      case FormInputTypes.Monetary:
        return (
          <DefaultFieldSummary
            label={label}
            value={formatPrice(value)}
            variant={variant}
          />
        );
      case FormInputTypes.Date:
        return (
          <DefaultFieldSummary
            label={label}
            value={value ? formatISODate(value) : ""}
            variant={variant}
          />
        );
      case FormInputTypes.File:
        return (
          <DefaultFieldSummary
            label={label}
            value={value?.fileName || ""}
            variant={variant}
          />
        );
      case FormInputTypes.Object:
        return <ObjectFieldSummary field={field} />;
      case FormInputTypes.ObjectList:
        return <ObjectListFieldSummary field={field} />;
      case FormInputTypes.Dropdown:
      case FormInputTypes.Select:
      case FormInputTypes.Radio:
        return <SelectionFieldSummary field={field} />;
      case FormInputTypes.CityList:
        return <DefaultFieldSummary label={label} value={value?.join(" / ")} />;
      case FormInputTypes.Many2one:
      case FormInputTypes.Many2many:
        return <Many2XFieldSummary field={field} />;
      case FormInputTypes.Boolean:
        return <BooleanFieldSummary field={field} />;
      case FormInputTypes.GeoLocation:
        return <GeoLocationFieldSummary field={field} />;
      case FormInputTypes.Polygon:
        return (
          <DefaultFieldSummary
            label={label}
            value={`${value?.length} pontos`}
          />
        );
      // criar summary pra polygon dps
      default:
        return null;
    }
  }, [inputType, invisible, value, inputMask]);

  return summaryField;
};

export default FormFieldSummary;
