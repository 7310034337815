import { FC, useMemo } from "react";
import {
  FormFieldV2,
  FormInputOptions,
} from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import FormLabel from "../FormLabel";
import MultiSelectInput from "../../../../components/Input/SelectInputs/MultiSelectInput";
import VariableFieldColumn from "../utils/VariableFieldColumn";

interface MultiSelectFieldProps {
  field: FormFieldV2;
}

const MultiSelectField: FC<MultiSelectFieldProps> = ({ field }) => {
  const { options, value, name, label, disabled, saveAs, variant } = field;
  const { handleChange, startEditing, finishEditing, error } =
    useFormField(field);
  const handleSelect = (values: string[]) => {
    handleChange(
      saveAs === "option"
        ? values.map((v) => options?.filter((o) => o.value === v)[0])
        : values
    );
  };

  const inputValue = useMemo(() => {
    if (saveAs === "option") {
      return (value as FormInputOptions[]).map(({ value }) => value);
    }
    return value;
  }, [value, options, saveAs]);

  return (
    <VariableFieldColumn variant={variant}>
      <FormLabel>{label}</FormLabel>
      <MultiSelectInput
        options={options!}
        value={inputValue}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
        disabled={!!disabled}
        multiValueDisplay="all"
        hideDropdown
      />
    </VariableFieldColumn>
  );
};

export default MultiSelectField;
