/** @jsxImportSource @emotion/react */
import React from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { MenuItem } from "../../LeftMenu/LeftMenu";
import Navigation from "../../components/Navigation";
import { theme } from "../../../../theme";
import ActionButtons from "../../LeftMenu/components/ActionButtons";

interface MobileMenuDropdownProps {
  menuItems: MenuItem[];
  open: boolean;
}

const getCSSRules: CSSRulesResolver<Pick<MobileMenuDropdownProps, "open">> = ({
  open,
}) => ({
  wrapper: {
    position: "absolute",
    top: "100%",
    width: "100%",
    height: open ? "calc(100vh - 60px)" : 0,
    transition: "height 0.3s ease-in-out",
    overflow: "hidden",
    backgroundColor: theme.colors.Grey[20],
    boxSizing: "border-box",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: 6,
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: theme.colors.Grey[40],
  },
});

const MobileMenuDropdown: React.FC<MobileMenuDropdownProps> = ({
  menuItems,
  open,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { open });
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <ActionButtons />
        <div css={styles.divider} />
        <Navigation menuItems={menuItems} />
      </div>
    </div>
  );
};

export default MobileMenuDropdown;
