import { FC, useMemo } from "react";
import Inspection, {
  InspectionTypeLabels,
  InspectionStatusLabels,
  MappedInspection,
} from "./components/Inspection";
import { GetCreateInspectionOnFormResponseDocument } from "../../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Table from "../../../../../../components/Table";
import useDetailsColor from "../../../../../../hooks/useDetailsColor";
import EditableSectionV2 from "../../../../../../components/EditableSectionV2";
import Icon from "../../../../../../components/Icons";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";

const Inspections: FC = () => {
  const {
    documentData: { _id, inspections },
    refetch,
  } = useDocumentDetails();

  const mappedInspections = useMemo<MappedInspection[]>(() => {
    return (
      inspections?.map((inspection) => ({
        ...inspection,
        claimsLabel: inspection.claims!.map((claim) => claim!.name).join(", "),
      })) || []
    );
  }, [inspections]);

  const { color } = useDetailsColor();

  return (
    <EditableSectionV2
      title="Vistorias"
      icon={<Icon name={IconNames.Inspection} />}
      createPayload={{ documentId: _id! }}
      createQuery={GetCreateInspectionOnFormResponseDocument}
      addFlag="admin-only"
      addText="Adicionar Vistoria"
      onSubmit={refetch}
    >
      <Table
        data={mappedInspections}
        color={color}
        withBorder
        emptyText="Não há vistorias agendadas."
        columns={[
          { key: "date", label: "Data", special: "date" },
          {
            key: "type",
            label: "Tipo de vistoria",
            options: InspectionTypeLabels,
          },
          {
            key: "claimsLabel",
            label: "Sinistros",
          },
          { key: "inspector", label: "Perito", special: "many2one" },
          { key: "status", label: "status", options: InspectionStatusLabels },
        ]}
        DetailsComponent={Inspection}
      />
    </EditableSectionV2>
  );
};

export default Inspections;
