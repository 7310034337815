/** @jsxImportSource @emotion/react */

import {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useMemo,
  HTMLProps,
} from "react";
import {
  ColorAlias,
  CustomTheme,
  getThemeColorFromAlias,
  Spacings,
} from "../../theme";
import { useTheme, css, jsx } from "@emotion/react";

interface TypographyProps extends HTMLProps<HTMLElement> {
  variant?: TypographyVariants;
  uppercase?: boolean;
  lowercase?: boolean;
  weight?: FontWeight;
  size?: number;
  margin?: Spacings;
  align?: TextAlign;
  color?: ColorAlias;
  style?: CSSProperties;
  component?: string | FunctionComponent<TypographyProps>;
  className?: string;
  children: ReactNode;
  forceComponent?: boolean;
  noWrap?: boolean;
}

const defaultProps = {
  color: "none",
};

export const BaseFontSize = 16;

export enum FontSizes {
  ExtraSmall = 0.5 * BaseFontSize,
  Small = 0.8 * BaseFontSize,
  Medium = BaseFontSize,
  Large = 1.2 * BaseFontSize,
  ExtraLarge = 1.5 * BaseFontSize,
}

export enum FontWeights {
  Light = 300,
  Regular = 400,
  Bold = 600,
  ExtraBold = 800,
}

export type FontWeight = "light" | "regular" | "bold" | "extra-bold";
export type TextAlign = "center" | "left" | "right";
export type TypographyVariants =
  | "h0"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "textExtraLarge"
  | "textLarge"
  | "textMedium"
  | "textSmall"
  | "textExtraSmall"
  | "link";

const weightMap: Record<FontWeight, number> = {
  light: FontWeights.Light,
  regular: FontWeights.Regular,
  bold: FontWeights.Bold,
  "extra-bold": FontWeights.ExtraBold,
};

const getCSSRules = (
  props: TypographyProps & { textColor?: string },
  theme: CustomTheme
) => ({
  root: {
    // "& a": {
    //   color: theme.colors.Hannibal["600"],
    //   textDecoration: "none",
    // },
    // "&::selection": {
    //   backgroundColor: theme.colors.Hannibal["400"],
    //   color: theme.colors.Grey["0"],
    // },
  },
  typography: {
    fontFamily: "inherit",
    textAlign: props.align,
    fontSize: props.size,
    margin: `${theme.spacings[props.margin || "none"]}px 0`,
    fontWeight: weightMap[props.weight || "regular"],
  },
  noWrap: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  uppercase: {
    textTransform: "uppercase" as CSSProperties["textTransform"],
  },
  lowercase: {
    textTransform: "lowercase" as CSSProperties["textTransform"],
  },
  h0: {
    fontSize: props.size || 2.25 * BaseFontSize,
    lineHeight: "126%",
    fontWeight: weightMap[props.weight || "extra-bold"],
    margin: `${theme.spacings[props.margin || "md"]}px 0`,
  },
  h1: {
    fontSize: props.size || 1.9 * BaseFontSize,
    lineHeight: "126%",
    fontWeight: weightMap[props.weight || "bold"],
    margin: `${theme.spacings[props.margin || "md"]}px 0`,
  },
  h2: {
    fontSize: props.size || 1.25 * BaseFontSize,
    lineHeight: "125%",
    fontWeight: weightMap[props.weight || "bold"],
    margin: `${theme.spacings[props.margin || "md"]}px 0`,
  },
  h3: {
    fontSize: props.size || BaseFontSize,
    lineHeight: "137%",
    fontWeight: weightMap[props.weight || "bold"],
    margin: `${theme.spacings[props.margin || "md"]}px 0`,
  },
  h4: {
    fontSize: props.size || 0.7 * BaseFontSize,
    lineHeight: "143%",
    fontWeight: weightMap[props.weight || "bold"],
    margin: `${theme.spacings[props.margin || "md"]}px 0`,
  },
  textExtraLarge: {
    fontSize: props.size || FontSizes.ExtraLarge,
    lineHeight: "127%",
  },
  textLarge: {
    fontSize: props.size || FontSizes.Large,
  },
  textMedium: {
    fontSize: props.size || BaseFontSize,
    lineHeight: "143%",
  },
  textSmall: {
    fontSize: props.size || FontSizes.Small,
    lineHeight: "150%",
  },
  textExtraSmall: {
    fontSize: props.size || FontSizes.ExtraSmall,
    lineHeight: "160%",
    letterSpacing: 0.2,
  },
  link: {
    fontSize: props.size || BaseFontSize,
    color: theme.colors.Black[80],
    cursor: "pointer",
    position: "relative",
    width: "fit-content",
    lineHeight: "143%",
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      height: "2px",
      width: "100%",
      backgroundColor: theme.colors.Prisma[100],
    },
  },
});

const Typography = (props: TypographyProps): ReactElement => {
  const theme = useTheme() as CustomTheme;
  const {
    variant = "textMedium",
    component,
    color,
    style,
    children,
    uppercase,
    lowercase,
    forceComponent,
    noWrap,
    ...rest
  } = props;
  const textColor = useMemo(() => {
    if (!color) return "inherit";
    return getThemeColorFromAlias(color, theme.colors);
  }, [color, theme.colors]);

  const styles = getCSSRules({ ...props, textColor }, theme);

  const element = useMemo(() => {
    let elementType = component || "span";
    // forceComponent é útil quando queremos utilizar um typography dentro de outro
    // por exemplo, um span dentro de um título
    if (!forceComponent) {
      switch (variant) {
        case "h0":
        case "h1":
          elementType = "h1";
          break;
        case "h2":
          elementType = "h2";
          break;
        case "h3":
          elementType = "h3";
          break;
        case "h4":
          elementType = "h4";
          break;
      }
    }
    return elementType;
  }, [component, variant]);

  return jsx(
    element,
    {
      ...rest,
      variant,
      children,
      style: { color: textColor, ...style },
      //@ts-ignore
      css: css(
        styles.typography,
        styles.root,
        styles[variant],
        uppercase ? styles.uppercase : undefined,
        lowercase ? styles.lowercase : undefined,
        noWrap ? styles.noWrap : undefined
      ),
    },
    children
  );
};

Typography.defaultProps = defaultProps;
export default Typography;
