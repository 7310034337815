import { FC } from "react";
import FormFieldSummary from "./FormFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import Column from "../../../../components/Grid/Column";
import Row from "../../../../components/Grid/Row";
type ObjectFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectListItemFieldSummary: FC<ObjectFieldSummaryProps> = ({
  field: { fields },
}) => {
  return (
    <>
      <Column xs={12}>
        <Row noBottomMargin>
          {fields!.map((field) => (
            <FormFieldSummary field={field} key={field.fieldPath} />
          ))}
        </Row>
      </Column>
    </>
  );
};

export default ObjectListItemFieldSummary;
