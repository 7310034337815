import { FC } from "react";

const SentProposal: FC = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7360_26098)">
        <path
          d="M3 3H9V7.5C9 8.32508 9.67492 9 10.5 9H15V13.5H16.5V7.5C16.5 7.27515 16.4249 7.125 16.275 6.97485L11.025 1.72485C10.875 1.57507 10.7251 1.5 10.5 1.5H3C2.17492 1.5 1.5 2.17492 1.5 3V21C1.5 21.8251 2.17492 22.5 3 22.5H8.25V21H3V3ZM10.5 3.29992L14.7001 7.5H10.5V3.29992Z"
          fill="black"
        />
        <path
          d="M24.4424 15.4425L19.5 20.3849L17.5575 18.4424L16.5 19.5L19.5 22.5L25.5 16.5L24.4424 15.4425Z"
          fill="black"
        />
        <path d="M12.75 15H7.96875V16.5H12.75V15Z" fill="black" />
        <path d="M14.3438 12H7.96875V13.5H14.3438V12Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_7360_26098">
          <rect width="25.5" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SentProposal;
