import moment from "moment";
import { dateFilterMask } from "../../shared/dateFilter";

export enum DateOperator {
  "gte" = "gte",
  "lte" = "lte",
}

export enum DateDisplays {
  onlyDate = "onlyDate",
  dateWithTime = "dateWithTime",
}

export type DatesObj = Record<DateOperator, Date | null>;

export const formatDateToFilterQueryDate = (date?: Date) => {
  if (!date) return "";
  return moment(date).format(dateFilterMask);
};

export const parseQueryToDate = (queryDate: string) =>
  moment(queryDate, dateFilterMask).toDate();

function adjustDateForOnlyDateDisplay(date: Date, operator: DateOperator) {
  const m = moment(date);
  if (operator === DateOperator.gte) {
    return m.startOf("day").toDate();
  } else if (operator === DateOperator.lte) {
    return m.endOf("day").toDate();
  }
  return date;
}

/**
 * Converts the query parameter value (string[]) into DatesObj.
 * If `dateDisplay` is `onlyDate`, we adjust selected dates to midnight UTC or end-of-day UTC.
 */
export const valueToDates = (
  value: string[],
  dateDisplay: DateDisplays
): DatesObj => {
  const dates: DatesObj = {
    gte: null,
    lte: null,
  };

  if (!value || !value[0]) return dates;

  const values = value[0].split("~");
  values.forEach((val) => {
    const [operator, dateStr] = val.split("_");
    if (dateStr) {
      let parsedDate = parseQueryToDate(dateStr);
      if (dateDisplay === DateDisplays.onlyDate && parsedDate) {
        parsedDate = adjustDateForOnlyDateDisplay(
          parsedDate,
          operator as DateOperator
        );
      }
      dates[operator as DateOperator] = parsedDate;
    }
  });
  return dates;
};

/**
 * Converts DatesObj back into query parameter value (string[]).
 * If `dateDisplay` is `onlyDate`, we normalize them to start/end of the day in UTC before formatting.
 */
export const datesToValue = (
  dates: DatesObj,
  dateDisplay: DateDisplays
): string[] => {
  let stringFilters: string[] = [];

  for (const [operator, date] of Object.entries(dates) as [
    DateOperator,
    Date | null
  ][]) {
    if (date) {
      let finalDate = date;
      if (dateDisplay === DateDisplays.onlyDate) {
        finalDate = adjustDateForOnlyDateDisplay(finalDate, operator);
      }
      stringFilters.push(
        `${operator}_${formatDateToFilterQueryDate(finalDate)}`
      );
    }
  }
  return stringFilters.length ? [stringFilters.join("~")] : [];
};
