import { Services, UserRoles } from "../graphql/generated/types";
import { TFilter } from "./types";

export const genericToFilter = (key: string) => (value: any) => ({
  [key]: value,
});

export const responsibleFilter: TFilter = {
  label: "Responsável",
  value: [],
  urlParam: "responsavel",
  name: "responsible",
  selectOptionDisplay: "avatar",
  selectMenuListDisplay: "buttons",
  selectMultiValueDisplay: "all",
  many2Many: {
    serviceName: Services.Users,
    domain: { role: UserRoles.Admin },
  },
  toFilter: genericToFilter("responsibleId"),
  options: [
    {
      value: "nenhum",
      label: "Sem Responsável",
      filter: {
        responsibleId: null,
      },
    },
  ],
};

export const stateFilter: TFilter = {
  label: "Estado",
  value: [],
  urlParam: "estado",
  name: "state",
  selectMultiValueDisplay: "all",
  options: [
    { value: "AC", label: "Acre", filter: { state: "AC" } },
    { value: "AL", label: "Alagoas", filter: { state: "AL" } },
    { value: "AM", label: "Amazonas", filter: { state: "AM" } },
    { value: "AP", label: "Amapá", filter: { state: "AP" } },
    { value: "BA", label: "Bahia", filter: { state: "BA" } },
    { value: "CE", label: "Ceará", filter: { state: "CE" } },
    { value: "DF", label: "Distrito Federal", filter: { state: "DF" } },
    { value: "ES", label: "Espírito Santo", filter: { state: "ES" } },
    { value: "GO", label: "Goiás", filter: { state: "GO" } },
    { value: "MA", label: "Maranhão", filter: { state: "MA" } },
    { value: "MG", label: "Minas Gerais", filter: { state: "MG" } },
    { value: "MS", label: "Mato Grosso do Sul", filter: { state: "MS" } },
    { value: "MT", label: "Mato Grosso", filter: { state: "MT" } },
    { value: "PA", label: "Pará", filter: { state: "PA" } },
    { value: "PB", label: "Paraíba", filter: { state: "PB" } },
    { value: "PE", label: "Pernambuco", filter: { state: "PE" } },
    { value: "PI", label: "Piauí", filter: { state: "PI" } },
    { value: "PR", label: "Paraná", filter: { state: "PR" } },
    { value: "RJ", label: "Rio de Janeiro", filter: { state: "RJ" } },
    { value: "RN", label: "Rio Grande do Norte", filter: { state: "RN" } },
    { value: "RO", label: "Rondônia", filter: { state: "RO" } },
    { value: "RR", label: "Roraima", filter: { state: "RR" } },
    { value: "RS", label: "Rio Grande do Sul", filter: { state: "RS" } },
    { value: "SC", label: "Santa Catarina", filter: { state: "SC" } },
    { value: "SP", label: "São Paulo", filter: { state: "SP" } },
    { value: "SE", label: "Sergipe", filter: { state: "SE" } },
    { value: "TO", label: "Tocantins", filter: { state: "TO" } },
  ],
};
