/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { DetailsWrapper } from "../../../../../components/ModelDetails/DetailsWrapper";
import { Grid } from "../../../../../components/Grid/Grid";
import { DetailsItem } from "../../../../../components/ModelDetails/DetailsItem";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import FarmerData from "../FarmerData";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import BeneficiaryData from "../BeneficiaryData";
import {
  ClaimStatus,
  Contact,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  useCreateDocumentRenewalFormResponseMutation,
  useGetDocumentRejectionFormResponseLazyQuery,
} from "../../../../../graphql/generated/types";
import { getDocumentPropertyDescription } from "../../../utils";
import CropData from "../CropData";
import EquipmentsTable from "../Equipments/EquipmentsTable";
import ForestItemsTable from "../ForestItems/ForestItemsTable";
import LivestockFarmingData from "../Livestock/LivestockFarmingData";
import PropertiesTable from "../Properties/PropertiesTable";
import PropertyImprovementsTable from "../PropertyImprovements/PropertyImprovementsTable";
import { Maybe } from "graphql/jsutils/Maybe";
import { DetailsDivision } from "../../../../../components/ModelDetails/DetailsDivision";
import Chip from "../../../../../components/Chip";
import documentColors from "../../../../../utils/documentColors";
import { documentStageLabels } from "../../../../../shared/documents/documentStage";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import Icon from "../../../../../components/Icons";
import { ColorNames, theme } from "../../../../../theme";
import Typography, { FontSizes } from "../../../../../components/Typography";
import { documentStatusLabels } from "../../../../../shared/documents/documentStatus";
import { CSSObject } from "@emotion/react";
import {
  consultantDocumentStatusIcons,
  documentStatusIcons,
  insuranceTypeGroupsIcons,
} from "../../../../../utils/icons";
import { IconSizes } from "../../../../../components/Icons/styles/iconSizes";
import { insuranceTypeGroupLabels } from "../../../../../shared/insurance/insuranceTypeGroup";
import { formatPrice } from "../../../../../utils/formatNumber";
import { formatISODate } from "../../../../../utils/dateUtils";
import { claimStatusLabels } from "../../../../../shared/claims/claimStatus";
import { useFlags } from "../../../../../hooks/useFlags";
import { ResponsiblesSelects } from "../../../../../components/ResponsiblesSelects";
import { consultantDocumentStatusLabels } from "../../../../../shared/documents/consultantDocumentStatus";
import NestedMenu from "../../../../../components/NestedMenu/NestedMenu";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import { useNavigate } from "react-router-dom";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";
import useConfirmedAction from "../../../../../hooks/useConfirmedAction";
import { ToDocumentDetailsLocation } from "../../../../../Locations";
import FormTitle from "../../../../../modules/Forms/components/FormTitle";
import FormV2 from "../../../../../modules/Forms/form.v2";
import Form from "../../../../../modules/Forms";

const getContactRow = (label: string, contact?: Maybe<Contact>) => ({
  label,
  value: contact && { text: contact!.name!, Component: FarmerData },
  variant: contact ? DetailsRowValueVariants.Modal : undefined,
});

const styles: CSSObject = {
  sameRowCentralized: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "start",
    gap: "4px",
  },
  manualDivision: {
    marginTop: "12px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    paddingBottom: 12,
    "> button": {
      height: "auto",
    },
  },
  title: { width: "100%" },
  leftSideHeader: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  menuIcon: {
    marginTop: -4,
    padding: 4,
    paddingTop: 6,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.colors.LightGrey[60],
    },
  },
};

const iconSize = 18 as IconSizes;

export const DocumentSummary: FC = () => {
  const {
    documentData: doc,
    refetch,
    handleToggleArchived,
    handleDuplicateDocument,
  } = useDocumentDetails();
  const { isAdmin } = useFlags();
  const {
    _id,
    farmer,
    beneficiary,
    consultantStatus,
    crop,
    properties,
    description,
    insuranceTypeGroup,
    originator,
    contact,
    stage,
    status,
    archived,
  } = doc;

  const itens = [
    getContactRow("Produtor", farmer),
    getContactRow("Parceiro", originator),
    getContactRow("Contato", contact),
    beneficiary && {
      label: "Beneficiário",
      value: { text: beneficiary?.name!, Component: BeneficiaryData },
      variant: DetailsRowValueVariants.Modal,
    },
    crop && {
      label: "Lavoura",
      value: {
        text: `${crop?.cultureName} // ${crop?.area} ha`,
        Component: CropData,
      },
      variant: DetailsRowValueVariants.Modal,
    },
    insuranceTypeGroup === InsuranceTypeGroupEnum.Machinery && {
      label: "Equipamentos",
      value: {
        text: description!,
        Component: EquipmentsTable,
      },
      variant: DetailsRowValueVariants.Modal,
    },
    insuranceTypeGroup === InsuranceTypeGroupEnum.Forest && {
      label: "Florestas",
      value: {
        text: description!,
        Component: ForestItemsTable,
      },
      variant: DetailsRowValueVariants.Modal,
    },
    insuranceTypeGroup === InsuranceTypeGroupEnum.PropertyImprovement && {
      label: "Benfeitorias",
      value: {
        text: description!,
        Component: PropertyImprovementsTable,
      },
      variant: DetailsRowValueVariants.Modal,
    },
    insuranceTypeGroup === InsuranceTypeGroupEnum.Livestock && {
      label: "Rebanho",
      value: {
        text: description!,
        Component: LivestockFarmingData,
      },
      variant: DetailsRowValueVariants.Modal,
    },
    {
      label: "Propriedade",
      value: {
        text: getDocumentPropertyDescription(properties)!,
        Component: PropertiesTable,
      },
      variant: DetailsRowValueVariants.Modal,
    },
  ].filter(Boolean) as DetailsRowProps[];

  const statusIcon = archived
    ? IconNames.Archive
    : documentStatusIcons[status!];

  const consultantStatusIcon = archived
    ? IconNames.Archive
    : consultantDocumentStatusIcons[consultantStatus!];

  const currentStage = useDocumentOrder(stage!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalStore();

  const onArchive = useConfirmedAction(
    `Tem certeza de que deseja ${
      archived ? "desarquivar" : "arquivar"
    } este documento?`,
    {
      onConfirm: [{ onClick: handleToggleArchived }],
    }
  );
  const [renewDocumentMutation] =
    useCreateDocumentRenewalFormResponseMutation();

  const handleRenewDocument = async () => {
    const { data } = await renewDocumentMutation({
      variables: {
        documentId: _id!,
      },
    });
    const { formResponse, documentId } =
      data!.createDocumentRenewalFormResponse;
    if (documentId) {
      navigate(ToDocumentDetailsLocation(documentId));
    }
    if (formResponse) {
      openModal(
        <div style={{ width: "100%", height: "100%" }}>
          <FormV2
            formResponseId={formResponse!.formResponseId}
            onSubmit={({ objectId }: { objectId: string }) => {
              closeModal();
              navigate(ToDocumentDetailsLocation(objectId));
            }}
            onCancel={closeModal}
          />
        </div>
      );
    }
  };

  const [getDocumentRejectionFormResponse] =
    useGetDocumentRejectionFormResponseLazyQuery();
  const handleRejectDocument = async () => {
    const { data } = await getDocumentRejectionFormResponse({
      variables: {
        documentId: _id!,
      },
    });
    openModal(
      <>
        <FormTitle title="Recusar cotação" />
        <Form
          formId="documentRejection"
          docId={data!.getDocumentRejectionFormResponse!.formResponseId!}
          onCancel={closeModal}
          onSubmit={() => {
            refetch(), closeModal();
          }}
        />
      </>
    );
  };

  const nestedMenuOptions = [
    {
      label: archived ? "Desarquivar" : "Arquivar",
      iconName: archived ? IconNames.Unarchive : IconNames.Archive,
      onClick: onArchive,
      disabled: currentStage >= policyStage,
      flag: "admin-only",
    },
    {
      label: "Recusar",
      iconName: IconNames.Rejected,
      onClick: handleRejectDocument,
      disabled:
        status === DocumentStatus.Rejected || currentStage >= policyStage,
      flag: "admin-only",
    },
    {
      label: "Duplicar",
      iconName: IconNames.Duplicate,
      onClick: handleDuplicateDocument,
      flag: "admin-only",
    },
    {
      label: "Renovar",
      iconName: IconNames.Redo,
      onClick: handleRenewDocument,
      flag: "admin-only",
      disabled: currentStage < policyStage,
    },
  ];

  return (
    <DetailsWrapper>
      <div css={styles.header}>
        <Typography
          weight="bold"
          size={FontSizes.Large}
          align="left"
          component="div"
          css={styles.title}
        >
          DADOS GERAIS
        </Typography>
        <div css={styles.leftSideHeader}>
          {isAdmin() && <ResponsiblesSelects />}
          <NestedMenu
            id={"Geral"}
            menuIcon={
              <div css={styles.menuIcon}>
                <Icon name={IconNames.OverflowMenu} pointer />
              </div>
            }
            options={nestedMenuOptions}
          />
        </div>
      </div>
      <Grid>
        {itens.filter(Boolean).map((item, index) => (
          <DetailsItem {...item} key={index} />
        ))}
      </Grid>
      <DetailsDivision />
      <Grid>
        <DetailsItem
          label="Etapa"
          value={
            <Chip
              color={documentColors[stage!]}
              label={documentStageLabels[stage!].toUpperCase()}
            />
          }
        />
      </Grid>
      <Grid css={styles.manualDivision}>
        {isAdmin() && (
          <DetailsItem
            label="Status"
            value={
              <div css={styles.sameRowCentralized}>
                <Icon
                  name={statusIcon}
                  color={archived ? ColorNames.ERROR_RED : ColorNames.GREEN}
                  size={iconSize}
                />
                <Typography>
                  {archived
                    ? "ARQUIVADO"
                    : documentStatusLabels[status!].toUpperCase()}
                </Typography>
              </div>
            }
          />
        )}
        {!isAdmin() && (
          <DetailsItem
            label="Status"
            value={
              <div css={styles.sameRowCentralized}>
                <Icon
                  name={consultantStatusIcon}
                  color={archived ? ColorNames.ERROR_RED : ColorNames.GREEN}
                  size={iconSize}
                />
                <Typography uppercase>
                  {consultantDocumentStatusLabels[consultantStatus!]}
                </Typography>
              </div>
            }
          />
        )}

        <DetailsItem
          label="Tipo de seguro"
          value={
            <div css={styles.sameRowCentralized}>
              <Icon
                name={insuranceTypeGroupsIcons[insuranceTypeGroup!]}
                size={iconSize}
              />
              <Typography>
                {insuranceTypeGroupLabels[insuranceTypeGroup!].toUpperCase()}
              </Typography>
            </div>
          }
        />
        <DetailsItem label="Valor do Prêmio" value={formatPrice(doc.premium)} />
        <DetailsItem
          label="Sinistro"
          value={
            doc.claimStatus &&
            claimStatusLabels[doc.claimStatus as unknown as ClaimStatus]
          }
        />
        <DetailsItem
          label="Data da cotação"
          value={formatISODate(doc.createdAt)}
        />
      </Grid>
    </DetailsWrapper>
  );
};
