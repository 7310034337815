import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import { SelectInputOptionInterface } from "../../../../components/Input/SelectInputs/SelectInput";
import DefaultFieldWrapper from "../utils/DefaultFieldWrapper";
import ChipSelectInput from "../../../../components/Input/SelectInputs/ChipSelectInput/ChipSelectInput";

interface BooleanFieldProps {
  field: FormFieldV2;
}

const BooleanField: FC<BooleanFieldProps> = ({ field }) => {
  const { name, value, disabled } = field;

  const { handleChange, finishEditing, error } = useFormField(field);

  const booleanOptions = [
    { label: "Sim", value: "true" },
    { label: "Não", value: "false" },
  ];

  const handleSelect = (option: SelectInputOptionInterface) => {
    handleChange(option.value);
    finishEditing();
  };

  return (
    <DefaultFieldWrapper field={field}>
      <ChipSelectInput
        options={booleanOptions}
        values={value ? ["true"] : ["false"]}
        containerId={name}
        onChange={([newValue]) => {
          handleSelect({
            value: JSON.parse(newValue),
            label: newValue ? "Sim" : "Não",
          });
        }}
        isSingleOption
        error={error}
        disabled={!!disabled}
      />
    </DefaultFieldWrapper>
  );
};

export default BooleanField;
