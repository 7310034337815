/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC, useContext } from "react";
import { theme } from "../../theme";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ModalStoreContext } from "./context/ModalStore";

interface StylesParams {
  container?: React.CSSProperties;
  content?: React.CSSProperties;
}

const getStyles = ({
  customStyles = {},
}: {
  customStyles?: StylesParams;
}): CSSObject => ({
  modalContainer: {
    position: "absolute",
    height: "calc(100% - 1em)",
    width: "calc(100% - 1em)",
    margin: "0.5em",
    top: 0,
    left: 0,
    backgroundColor: theme.colors.White["100"],
    boxSizing: "border-box",
    boxShadow: theme.boxShadow.dark,
    zIndex: 500,
    borderRadius: theme.borderRadius.xxl,
    ...(customStyles.container || {}),
  },
  modalContent: {
    position: "relative",
    padding: "2.5em",
    overflow: "hidden",
    height: "100%",
    boxSizing: "border-box",
    ...(customStyles.content || {}),
  },
  close: {
    position: "absolute",
    top: "1em",
    right: "1em",
    cursor: "pointer",
  },
  backdrop: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#d9d9d980",
    zIndex: 400,
    top: 0,
    left: 0,
  },
});

interface ModalProps {
  children: React.ReactNode;
  hideCloseButton?: boolean;
  customStyles?: {
    container?: React.CSSProperties;
    content?: React.CSSProperties;
  };
}

const Modal: FC<ModalProps> = ({ children, hideCloseButton, customStyles }) => {
  const styles = getStyles({ customStyles });
  const { closeModal } = useContext(ModalStoreContext);
  return (
    <>
      <div css={styles.modalContainer}>
        <div css={styles.modalContent}>
          {!hideCloseButton && (
            <span css={styles.close}>
              <Icon name={IconNames.Close} onClick={closeModal} />
            </span>
          )}
          {children}
        </div>
      </div>
      <div css={styles.backdrop} onClick={closeModal} />
    </>
  );
};

export default Modal;
