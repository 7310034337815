import { ContactTags } from '../../graphql/generated/types';

export const contactTagLabels: Record<ContactTags, string> = {
  [ContactTags.Farmer]: 'Produtor',
  [ContactTags.Admin]: 'Administrador',
  [ContactTags.AllowedPerson]: 'Pessoa autorizada',
  [ContactTags.Beneficiary]: 'Beneficiário',
  [ContactTags.Consultant]: 'Consultor',
  [ContactTags.Inspector]: 'Inspetor',
  [ContactTags.PaymentResponsible]: 'Responsável financeiro',
  [ContactTags.Lead]: 'Lead',
  [ContactTags.Organization]: 'Organização',
  [ContactTags.OrgOwner]: 'Dono de organização',
  [ContactTags.OrgAdmin]: 'Administrador de organização',
  [ContactTags.OrgMember]: 'Membro de organização',
  [ContactTags.Other]: 'Outro',
  [ContactTags.Agronomist]: 'Agrônomo',
};
