import { FC } from "react";
import { useAuth } from "../../../hooks/useAuth";
import EditableSectionV2 from "../../../components/EditableSectionV2";
import { DetailsItem } from "../../../components/ModelDetails/DetailsItem";
import { DetailsWrapper } from "../../../components/ModelDetails/DetailsWrapper";
import { Grid } from "../../../components/Grid/Grid";
import { GetEditUserFormResponseDocument } from "../../../graphql/generated/types";
import { toast } from "react-toastify";

export const MyProfileSummary: FC = () => {
  const { user, loadCurrentUser } = useAuth();
  const summaryColumns = [
    {
      label: "Nome",
      value: user.name,
    },
    {
      label: "E-mail",
      value: user.email,
    },
    {
      label: "CPF",
      value: user.contact!.cpfCnpj,
    },
    {
      label: "RG",
      value: user.contact!.rg,
    },
    {
      label: "CEP",
      value: user.contact!.address?.cep,
    },
    {
      label: "Cidade",
      value: user.contact!.address?.city,
    },
    {
      label: "Estado",
      value: user.contact!.address?.state,
    },
  ];
  return (
    <DetailsWrapper>
      <EditableSectionV2
        title={"Dados pessoais"}
        editQuery={GetEditUserFormResponseDocument}
        editPayload={{ userId: user._id }}
        objectId={user._id}
        useV2
        onSubmit={() => {
          toast.success("Dados editados com sucesso!");
          loadCurrentUser();
        }}
      >
        <Grid>
          {summaryColumns.map((item) => (
            <DetailsItem key={item.label} {...item} />
          ))}
        </Grid>
      </EditableSectionV2>
    </DetailsWrapper>
  );
};
