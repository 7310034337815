import { DocumentStatus } from '../../graphql/generated/types';

export const documentStatusLabels = {
  [DocumentStatus.Pending]: 'Pendente',
  [DocumentStatus.Incomplete]: 'Incompleta',
  [DocumentStatus.Done]: 'Finalizada',
  [DocumentStatus.Sent]: 'Enviada',
  [DocumentStatus.Effective]: 'Vigente',
  [DocumentStatus.NonEffective]: 'Vigência Expirada',
  [DocumentStatus.Rejected]: 'Recusada',
  [DocumentStatus.UnderAnalysis]: 'Em análise',
  [DocumentStatus.Cancelled]: 'Cancelada',
};
