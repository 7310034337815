import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ToDirectDocumentDetailsLocation } from "../../../Locations";
import { useAuth } from "../../../hooks/useAuth";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import { useUIStore } from "../../../hooks/useUIStore";
import FormV2 from "../../../modules/Forms/form.v2";

const formId = "simulacao";

const NewDirectSimulation = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { insuranceTypeSlug, insuranceTypeDetail } = params;
  const formResponseId = searchParams.get("formId");

  const { loaded, updateToken } = useAuth();
  const { openSubmittedModal } = useUIStore();
  const navigate = useNavigate();

  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();

  useEffect(() => {
    if (!formResponseId && loaded) {
      asyncCreateFormResponse();
    }
  }, [loaded]);

  const asyncCreateFormResponse = async () => {
    try {
      const { data } = await createFormResponseMutation({
        variables: {
          formId,
          metadata: {
            originatorSlug: localStorage.getItem("originatorSlug"),
            insuranceTypeSlug,
            insuranceTypeDetail,
          },
        },
      });
      const formResponseId = data!.createFormResponseV2;
      const params = new URLSearchParams();
      params.append("formId", formResponseId);
      navigate(`?${params.toString()}`, {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const onSubmit = async ({
    documentId,
    token,
    hasResults,
  }: {
    documentId: string;
    token: string;
    hasResults: boolean;
  }) => {
    if (token) {
      await updateToken(token);
    }
    if (hasResults) {
      navigate(ToDirectDocumentDetailsLocation(documentId));
    } else {
      openSubmittedModal(
        "Suas informações foram registradas! Entraremos em contato em breve."
      );
    }
  };

  return (
    <>
      {formResponseId && (
        <FormV2
          formId={formId}
          formResponseId={formResponseId}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default NewDirectSimulation;
