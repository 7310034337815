/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Task from "./Task";
import { Task as ObjectTask } from "../../../../../../graphql/generated/types";
import { ColorNames } from "../../../../../../theme";
import HR from "../../../../../HorizontalLine";
import Typography from "../../../../../Typography";

interface ITaskGroup {
  label: string;
  tasks: ObjectTask[];
}

interface TaskGroupProps {
  taskGroup: ITaskGroup;
  redirectLocation: (docId: string) => string;
}

const TaskGroup: FC<TaskGroupProps> = ({ taskGroup, redirectLocation }) => {
  return (
    <>
      <Typography
        variant="textMedium"
        align="left"
        component="div"
        weight="bold"
        uppercase
      >
        {taskGroup.label} [{taskGroup.tasks.length}]
      </Typography>
      <HR dashedLine color={ColorNames.BLACK} spacingSize="small" />
      {taskGroup.tasks.map((task, index) => (
        <Task
          task={task}
          key={`task-${index}`}
          redirectLocation={redirectLocation}
        />
      ))}
    </>
  );
};

export default TaskGroup;
