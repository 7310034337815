/** @jsxImportSource @emotion/react */
import { FC, useCallback, useEffect, useMemo } from "react";
import {
  useGetTwilioConversationsQuery,
  useOnConversationUpdatedSubscription,
} from "../../graphql/generated/types";
import ChatInput from "./components/ChatInput";
import Message from "./components/Message";
import ScrollContainer from "../ScrollContainer";
import { formatPhone } from "../../utils/formatNumber";
import { ColorNames, theme } from "../../theme";
import PageTitle from "../PageTitle";
import { CSSObject } from "@emotion/react";
import ChatList from "./components/ChatList";
import LoadingSpinner from "../Loading/LoadingSpinner";
import LoadingOverlay from "../Loading/LoadingOverlay";
import { useParams } from "react-router-dom";
import Carousel from "../Carousel";
import { Visible } from "react-grid-system";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { useFlags } from "../../hooks/useFlags";
import { useConversationFilter } from "../../hooks/useConversationFilter";
import { ContactLink } from "./components/ContactLink";
import { SubscriptionToggle } from "./components/SubscriptionToggle";
import HR from "../HorizontalLine";

import { useChat } from "../../hooks/useChat"; // <--- our new hook

const styles: CSSObject = {
  chatWrapper: { height: "100%" },
  conversationWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "6px",
    width: "100%",
    position: "relative",
    paddingLeft: "20px",
    boxSizing: "border-box",
  },
  chatListWrapper: {
    borderRight: `1px solid ${theme.colors.LightGrey[100]}`,
    boxSizing: "border-box",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
};

interface WAChatProps {
  contactId?: string;
  showChatList?: boolean;
}

const WAChat: FC<WAChatProps> = ({ contactId: _contactId, showChatList }) => {
  const params = useParams();
  const contactId = (_contactId || params.contactId)!;
  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");
  const {
    onlySubscribeds,
    searchTerm,
    limit,
    skip,
    setOnlySubscribeds,
    setPage,
    page,
    setSearchTerm,
  } = useConversationFilter();

  const skipQueries = !isAdmin;

  const {
    message,
    setMessage,
    files,
    setFiles,
    sendingMessage,
    sendMessage,
    onFilesAccepted,
    fetchMediaUrl,
    toggleSubscription,
    refetch,
    data: chatData,
    loading: chatLoading,
  } = useChat({ contactId: contactId, skip: skipQueries });

  // Query for the conversation list
  const getConversationsQuery = useGetTwilioConversationsQuery({
    variables: { filter: { onlySubscribeds, searchTerm, skip, limit } },
    skip: skipQueries,
  });
  const totalPages = Math.ceil(
    (getConversationsQuery.data?.getTwilioConversations?.numberOfRecords || 0) /
      limit
  );

  // Helper to refetch everything
  const fullyRefresh = useCallback(async () => {
    await refetch();
    await getConversationsQuery.refetch();
  }, [refetch, getConversationsQuery]);

  // Subscription to real-time updates
  useOnConversationUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (
        subData?.conversationUpdated?.conversationId ===
        chatData?.contact?.twilioConversationId
      ) {
        fullyRefresh();
      }
    },
    skip: skipQueries,
  });

  // Re-fetch on contactId changes
  useEffect(() => {
    fullyRefresh();
  }, [contactId]);

  const { messages, isSubscribed, contact } = chatData || {};

  const chatColumn = useMemo(() => {
    return (
      <div css={styles.conversationWrapper}>
        {/* If no data yet, show overlay */}
        {chatLoading && (
          <LoadingOverlay>
            <LoadingSpinner />
          </LoadingOverlay>
        )}
        {contact && (
          <>
            <div css={styles.header}>
              <ContactLink contact={contact} />
              <SubscriptionToggle
                isSubscribed={!!isSubscribed}
                onToggle={async () => {
                  await toggleSubscription();
                  await fullyRefresh();
                }}
              />
            </div>
            <HR
              color={ColorNames.LIGHTGREY}
              borderWidth={3}
              spacingSize="none"
            />
          </>
        )}
        <ScrollContainer useAnchor>
          {messages?.map((msg) => (
            <Message
              message={msg}
              key={msg._id}
              getMediaUrl={(msg) => fetchMediaUrl(msg!)}
            />
          ))}
        </ScrollContainer>

        <ChatInput
          message={message}
          setMessage={setMessage}
          sendMessage={async () => {
            await sendMessage();
            fullyRefresh();
          }}
          loading={sendingMessage}
          onFilesAccepted={onFilesAccepted}
          files={files}
          setFiles={setFiles}
        />
      </div>
    );
  }, [chatData, files, message, messages, sendingMessage]);

  // ChatList Column
  const chatListColumn = useMemo(() => {
    if (!showChatList) return null;
    return (
      <div css={styles.chatListWrapper}>
        <ScrollContainer>
          <ChatList
            selectedContactId={contactId}
            query={getConversationsQuery}
            setOnlySubscribeds={async (value) => {
              setOnlySubscribeds(value);
              await getConversationsQuery.refetch({
                filter: { onlySubscribeds: value, searchTerm, skip, limit },
              });
            }}
            onlySubscribeds={onlySubscribeds}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </ScrollContainer>
      </div>
    );
  }, [
    getConversationsQuery,
    showChatList,
    contactId,
    onlySubscribeds,
    searchTerm,
    page,
    totalPages,
  ]);

  const contactLabel = contact
    ? formatPhone(contact.mobilePhone!) +
      (contact.name ? ` - ${contact.name}` : "")
    : "";

  if (!isAdmin) return null;

  return (
    <div css={styles.chatWrapper}>
      <PageTitle title={`Conversa  - ${contactLabel}`} />
      <Visible xs sm>
        <Carousel
          columns={[chatColumn, chatListColumn]}
          rowProps={{ noGutters: true, noMargins: true }}
        />
      </Visible>
      <Visible md lg xl xxl>
        <Row noMargins noGutters noWrap css={{ height: "100%" }}>
          {showChatList && <Column md={3}>{chatListColumn}</Column>}
          <Column md={showChatList ? 9 : 12}>{chatColumn}</Column>
        </Row>
      </Visible>
    </div>
  );
};

export default WAChat;
