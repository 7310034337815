import { FC, useLayoutEffect } from "react";
import { useTableContext } from "../../../../contexts/table";
import {
  ListContactsQuery,
  useCountContactsQuery,
  useListContactsQuery,
} from "../../../../graphql/generated/types";
import ListView from "../../../../components/ListView/ListView";
import { ContactsTableData, mapTableData } from "./tableColumns";
import ContactCard from "../ContactCard";
import {
  NewContactLocation,
  ToContactDetailsLocation,
} from "../../../../Locations";
import ContactListHeader from "../../ContactListHeader";
import { TModelFilter } from "../../../../hooks/useModelFilter";
import { useNavigate } from "react-router-dom";

interface ContactsTableProps {
  filter: TModelFilter;
}

const ContactsTable: FC<ContactsTableProps> = ({ filter }) => {
  const { paginator, actions, setData } = useTableContext();
  const navigate = useNavigate();

  const contactsQuery = useListContactsQuery({
    variables: {
      filter: filter.composeQuery(),
      sort: { [actions.sort.sortKey]: actions.sort.direction },
      limit: paginator.limit,
      skip: paginator.skip,
    },
  });

  const countContactsQuery = useCountContactsQuery({
    variables: {
      filter: filter.composeQuery(),
    },
  });

  useLayoutEffect(() => {
    if (contactsQuery.data?.contacts) {
      setData(contactsQuery.data?.contacts);
    }
  }, [contactsQuery]);

  return (
    <ListView<ContactsTableData, ListContactsQuery["contacts"][number]>
      CardComponent={ContactCard}
      loading={contactsQuery.loading}
      redirectLocation={ToContactDetailsLocation}
      mapTableData={mapTableData}
      total={countContactsQuery.data?.countContacts || 0}
      header={
        <ContactListHeader
          filters={filter}
          nestedMenuOptions={[
            {
              label: "Novo contato",
              onClick: () => navigate(NewContactLocation),
            },
          ]}
        />
      }
    />
  );
};

export default ContactsTable;
