/** @jsxImportSource @emotion/react */
import React from "react";
import { CSSObject } from "@emotion/react";
import { TModelFilter } from "../../hooks/useModelFilter";
import Totals from "./components/Totals";
import ChartsList from "./components/ChartsList";
import { MetricsProvider } from "./MetricsContext";
import MetricsLoading from "./components/MetricsLoading";

interface MetricsProps {
  filter: TModelFilter;
}

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const Metrics: React.FC<MetricsProps> = ({ filter }) => {
  return (
    <MetricsProvider filter={filter}>
      <div css={styles.wrapper}>
        <MetricsLoading />
        <Totals />
        <ChartsList />
      </div>
    </MetricsProvider>
  );
};

export default Metrics;
