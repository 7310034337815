/** @jsxImportSource @emotion/react */
import React from "react";
import { useAuth } from "../../../../hooks/useAuth";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { ToSimulationLocation } from "../../../../Locations";
import { ColorNames } from "../../../../theme";
import Button from "../../../Button";
import CustomLink from "../../../CustomLink";
import Icon from "../../../Icons";
import { IconNames } from "../../../Icons/styles/iconNames";

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginTop: 30,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      marginTop: 0,
    },
    padding: "0 12px",
  },
});

const ActionButtons: React.FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { shareProviderLink } = useAuth();
  return (
    <div css={styles.actions}>
      <CustomLink to={ToSimulationLocation("new", "simulacao")}>
        <Button
          text="Nova Cotação"
          iconBefore
          icon={<Icon pointer name={IconNames.Add} color={ColorNames.GREEN} />}
          style="action"
        />
      </CustomLink>
      <Button
        text="Copiar Link"
        iconBefore
        icon={<Icon pointer name={IconNames.Share} color={ColorNames.GREEN} />}
        style="action"
        onClick={shareProviderLink}
      />
    </div>
  );
};

export default ActionButtons;
