/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { TModelFilter } from "../../../hooks/useModelFilter";
import { TFilter } from "../../../filters/types";
import Button from "../../Button";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";

interface FilterDropdownProps extends PropsWithChildren {
  filter: TModelFilter;
}

const getCSSRules: CSSRulesResolver<{ open: boolean }> = ({ colors }) => ({
  container: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: 16,
    margin: "0 -6px",
    border: `1px solid ${colors.Grey[20]}`,
    boxSizing: "border-box",
    borderRadius: 18,
    height: "100%",
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const parseFilters = (filters: TFilter[]) => {
  const obj: Record<string, TModelFilter["filters"][number]> = {};
  filters.forEach((filter) => (obj[filter.name] = filter));
  return obj;
};

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  filter,
  children,
}) => {
  const { filters, setFilterValue } = filter;
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.container}>
      {children}
      <div css={styles.button}>
        <Button
          width="fit-content"
          text="Limpar Filtros"
          style="cancelNew"
          onClick={() =>
            filters.map((filter) => setFilterValue(filter.name, []))
          }
          icon={<Icon name={IconNames.Close} />}
        />
      </div>
    </div>
  );
};

export default FilterDropdown;
