import { assetsBucketUrl } from "..";

const bgImagesUrl = `${assetsBucketUrl}bgImages/`;

export const soyBeansUrl = `${bgImagesUrl}soyBeans.png`;
export const farmFieldsUrl = `${bgImagesUrl}farmFields.png`;
export const semiAradaLight = `${bgImagesUrl}semi-arada-70kb.jpg`;
export const semiAradaMedium = `${bgImagesUrl}semi-arada-1mb.jpg`;
export const semiAradaFull = `${bgImagesUrl}semi-arada-full.jpg`;
export const tratorArando = `${bgImagesUrl}trator-arando.jpg`;
