import React, { useEffect, useState } from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import FilterDropdown, {
  parseFilters,
} from "../../components/SearchBar/FilterDropdown/FilterDropdown";
import ChipSelectInput from "../../components/Input/SelectInputs/ChipSelectInput/ChipSelectInput";
import MultiSelectInput from "../../components/Input/SelectInputs/MultiSelectInput";
import SelectInput from "../../components/Input/SelectInputs/SelectInput";
import Many2ManyInput from "../../components/Input/SelectInputs/Many2ManyInput";

interface ContactsListFilterProps {
  filter: TModelFilter;
}

const ContactsListFilter: React.FC<ContactsListFilterProps> = ({ filter }) => {
  const { filters, setFilterValue } = filter;
  const [filterObj, setFilterObj] = useState(parseFilters(filters));

  useEffect(() => {
    setFilterObj(parseFilters(filters));
  }, [filters]);

  const { hasDocuments, responsible, state, status, tipo } = filterObj;
  return (
    <FilterDropdown filter={filter}>
      <ChipSelectInput
        containerId={status.name}
        title={status.label}
        values={status.value}
        options={status.options}
        onChange={(value) => setFilterValue(status.name, value)}
      />
      <ChipSelectInput
        containerId={tipo.name}
        title={tipo.label}
        values={tipo.value}
        options={tipo.options}
        onChange={(value) => setFilterValue(tipo.name, value)}
      />
      <MultiSelectInput
        containerId={state.name}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        label={state.label}
        options={state.options}
        inputName={state.name}
        onSelect={(value) => setFilterValue(state.name, value)}
        value={state.value}
        optionDisplay={state.selectOptionDisplay}
        placeholder="Todos"
        key={state.name}
      />
      <Many2ManyInput
        containerId={responsible.name}
        label={responsible.label}
        closeMenuOnSelect={false}
        serviceName={responsible.many2Many!.serviceName}
        domain={responsible.many2Many?.domain}
        additionalOptions={responsible.options}
        inputName={responsible.name}
        onSelect={(value) => setFilterValue(responsible.name, value)}
        value={responsible.value}
        optionDisplay={responsible.selectOptionDisplay}
        placeholder="Todos"
        key={responsible.name}
        hideSelectedOptions={false}
        multiValueDisplay="default"
      />
      <SelectInput
        containerId={hasDocuments.name}
        placeholder=""
        inputName={hasDocuments.name}
        label={hasDocuments.label}
        options={hasDocuments.options}
        value={hasDocuments.value}
        onSelect={({ value }) => setFilterValue(hasDocuments.name, [value])}
        disableSearch
      />
    </FilterDropdown>
  );
};

export default ContactsListFilter;
