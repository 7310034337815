/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import Checkbox from "../../../../../components/Input/CheckboxInput";
import { css } from "@emotion/react";

const getStyles = () => ({
  content: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
});

interface SendDocumentsModalProps {
  onClick: (silent: boolean) => void;
}

const SendDocumentsModal: React.FC<SendDocumentsModalProps> = ({ onClick }) => {
  const [silent, setSilent] = useState(false);
  const styles = getStyles();
  return (
    <div>
      <Typography style={{ marginBottom: 30 }} variant="h2" margin="none">
        ENVIAR COTAÇÕES/PROPOSTAS
      </Typography>
      <div css={styles.content}>
        <Checkbox
          checked={!silent}
          onChange={() => setSilent(!silent)}
          label="Enviar e-mails?"
        />
        <Button text="Salvar" onClick={() => onClick(silent)} />
      </div>
    </div>
  );
};

export default SendDocumentsModal;
