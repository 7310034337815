/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AuthContext from "../../contexts/auth";
import { HomeLocation, RegisterLocation } from "../../Locations";
import Button from "../../components/Button";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import TextInput from "../../components/Input/TextInput";
import Typography from "../../components/Typography";
import Footer from "../../components/Layout/components/Footer";

import * as bgImages from "../../s3/bgImages";
import logo from "../../components/AuthLayout/assets/logos/logo.svg";

enum LoginAlertMessages {
  INVALID_EMAIL = "Usuário não encontrado!",
  CODE_EMAIL_SENT = "Insira o código que enviamos ao seu e-mail.",
  INVALID_CODE = "Código inválido",
}

enum AlertTypes {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

const getCSSRules: CSSRulesResolver = () => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  background: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    boxSizing: "border-box",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  wrapper: {
    borderRadius: 12,
    border: "1px solid grey",
    padding: "30px 40px",
    boxSizing: "border-box",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    maxWidth: "420px",
    width: "100%",
    boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.15)",
    zIndex: 2,
    textAlign: "center",
  },

  title: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    marginBottom: "8px",
    textAlign: "center",
    width: "100%",
  },

  logo: {
    height: 60,
    marginBottom: 8,
  },

  footer: {
    flexShrink: 0,
  },
});

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [step, setStep] = useState(0);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAlertType] = useState<any>(undefined);
  const { requestCode, signIn } = useContext(AuthContext);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleFirstStepSubmit = async () => {
    try {
      await requestCode({ email });
      setStep(1);
      setAlertMessage(LoginAlertMessages.CODE_EMAIL_SENT);
      setAlertType(AlertTypes.INFO);
      setShowAlert(true);
    } catch (error) {
      setAlertMessage(LoginAlertMessages.INVALID_EMAIL);
      setAlertType(AlertTypes.ERROR);
      setShowAlert(true);
    }
  };

  const handleSecondStepSubmit = async () => {
    try {
      if (code) {
        await signIn({ email, code });
      }
      const urlParams = new URLSearchParams(location.search);
      const redirectPath = urlParams.get("redirect");
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      } else {
        navigate(HomeLocation);
      }
    } catch (error) {
      setAlertMessage(LoginAlertMessages.INVALID_CODE);
      setAlertType(AlertTypes.ERROR);
      setShowAlert(true);
    }
  };

  const handleSubmit = async () => {
    if (step === 0) {
      await handleFirstStepSubmit();
    } else {
      await handleSecondStepSubmit();
    }
  };

  // 1) We start with the low-resolution (or lighter) image
  const [backgroundUrl, setBackgroundUrl] = useState(bgImages.semiAradaMedium);

  // 2) Optional: track if we’ve swapped to the high-res (for fade, etc.)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHighResLoaded, setIsHighResLoaded] = useState(false);

  // 3) Preload high-res after mounting
  useEffect(() => {
    const highResImage = new Image();
    highResImage.src = bgImages.semiAradaFull;
    highResImage.onload = () => {
      setBackgroundUrl(bgImages.semiAradaFull);
      setIsHighResLoaded(true);
    };
  }, []);
  const dynamicBackgroundStyle = {
    background: `
      linear-gradient(
        rgba(20, 20, 20, 0.5),
        rgba(20, 20, 20, 0.3)
      ), 
      url(${backgroundUrl})
    `,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // opacity: isHighResLoaded ? 1 : 0.8, // start slightly dimmed or blurred if you want
  };

  return (
    <>
      <div css={styles.pageContainer}>
        <div css={styles.mainContent}>
          <div css={[styles.background, dynamicBackgroundStyle]}>
            <div css={styles.wrapper}>
              <img src={logo} css={styles.logo} alt="Logo" />
              <TextInput
                value={email}
                name="nome"
                placeholder="E-mail"
                onChange={handleEmailChange}
                type="email"
              />
              {step === 1 && (
                <TextInput
                  value={code}
                  name="codigo"
                  placeholder="Código"
                  onChange={handleCodeChange}
                />
              )}

              <Button text="Entrar" style="action" onClick={handleSubmit} />

              {showAlert && (
                <Typography
                  variant="textMedium"
                  margin="sm"
                  component="div"
                  style={{}}
                >
                  {alertMessage}
                </Typography>
              )}
              <Typography variant="textMedium" margin="sm" component="div">
                Não tem uma conta?{" "}
                <Link to={RegisterLocation}>Cadastre-se</Link>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
