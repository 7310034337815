/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ToContactDetailsLocation } from "../../../Locations";
import CustomLink from "../../CustomLink";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import Typography, { FontSizes } from "../../Typography";
import { Contact } from "../../../graphql/generated/types";
import { CSSObject } from "@emotion/react";
import { formatPhone } from "../../../utils/formatNumber";

const styles: CSSObject = {
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    cursor: "pointer",
  },
};

export const ContactLink: FC<{ contact: Contact }> = ({ contact }) => {
  const title = contact.name || formatPhone(contact.mobilePhone!);
  return (
    <CustomLink to={ToContactDetailsLocation(contact._id!)}>
      <div css={styles.titleWrapper}>
        <Icon name={IconNames.User} pointer />
        <Typography
          weight="bold"
          size={FontSizes.Large}
          align="left"
          component="div"
        >
          {title}
        </Typography>
      </div>
    </CustomLink>
  );
};
