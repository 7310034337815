import { useMemo } from "react";
import { Contact } from "../../../graphql/generated/types";
import Card, { CardFC } from "../../../components/CardList/components/Card";
import { ColorNames } from "../../../theme";
import Responsibles from "../../Documents/DocumentCards/components/Responsibles";
import { formatISODate } from "../../../utils/dateUtils";
import { userStatusLabels } from "../../../shared/user/userStatus";
import Icon from "../../../components/Icons";
import { IconNames } from "../../../components/Icons/styles/iconNames";
import { getStatusIcon } from "../../../filters/users";

const ContactCard: CardFC<Contact> = ({ data, active, onClick }) => {
  const cardData = useMemo(() => {
    return {
      line1: data.name || "",
      line2: data.cpfCnpj || "",
      line3: data.email || "",
      line4: formatISODate(data.createdAt) || "",
    };
  }, [data]);

  const responsibles = useMemo(() => {
    const respName = data?.responsible?.name;
    return respName ? [respName] : [];
  }, [data]);

  const { status } = data;
  const archived = data.user?.archived;

  return (
    <Card
      cardData={cardData}
      detailBottom={
        <Responsibles
          responsibles={responsibles}
          color={ColorNames.VALID_GREEN}
          docId={data._id!}
        />
      }
      color={ColorNames.GREEN}
      active={active}
      onClick={onClick}
      detailTop={
        status ? (
          <Icon
            name={archived ? IconNames.Archive : getStatusIcon(status)!}
            color={archived ? ColorNames.ERROR_RED : ColorNames.GREEN}
            tooltipText={
              archived ? "Desabilitado" : userStatusLabels[data.status!]
            }
          />
        ) : undefined
      }
    />
  );
};

export default ContactCard;
