/** @jsxImportSource @emotion/react */

import React from "react";
import LoadingOverlay from "../../Loading/LoadingOverlay";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import { CSSObject } from "@emotion/react";
import { useMetricsContext } from "../MetricsContext";

const styles: CSSObject = {
  loadingWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
    zIndex: 100000,
  },
  loadingSpinner: { position: "relative", height: "100%" },
};

const MetricsLoading: React.FC = () => {
  const { loading } = useMetricsContext();

  if (!loading) return null;

  return (
    <div css={styles.loadingWrapper}>
      <div css={styles.loadingSpinner}>
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default MetricsLoading;
