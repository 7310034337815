import { useEffect, useState } from "react";
import { useGetMany2XOptionsLazyQuery } from "../graphql/generated/types";
import { TModelFilter } from "./useModelFilter";
import { valueToDates } from "../components/DateFilter/dateFilterUtils";
import { DateDisplays } from "../filters/types";
import { formatDate, formatDateWithTime } from "../utils/dateUtils";

export type FilterTag = {
  filterName: string;
  value: string;
  label: string;
};

const useTags = (filter: TModelFilter) => {
  const [tags, setTags] = useState<FilterTag[]>([]);
  const [getMany2xOptions] = useGetMany2XOptionsLazyQuery();

  useEffect(() => {
    let isMounted = true; // To ensure component is still mounted when setting state.

    async function fetchTags() {
      // Wrap the entire logic in an async function
      const allTagGroups = await Promise.all(
        filter.filters.map(async (currentFilter) => {
          const items = await Promise.all(
            currentFilter.value.map(async (value) => {
              const option = filter.getOption(currentFilter.name, value);
              let item = {
                filterName: currentFilter.name,
                value,
                label: "",
              };

              if (option) {
                // We have a local option
                item.label = option.label;
              } else if (currentFilter.many2Many) {
                // We need to fetch from getMany2xOptions
                const m2mRes = await getMany2xOptions({
                  variables: {
                    serviceName: currentFilter.many2Many.serviceName,
                    domain: [],
                    searchTerm: "",
                    currentValue: value,
                  },
                });
                if (m2mRes.data?.getMany2XOptions?.length) {
                  item.label = `${currentFilter.label}: ${m2mRes.data.getMany2XOptions[0].label}`;
                }
              }
              // If there's a date display override
              else if (currentFilter.dateDisplay) {
                const dateValues = valueToDates(
                  [value],
                  currentFilter.dateDisplay
                );
                const dateLabelValues: string[] = [];
                if (dateValues.gte) {
                  const formatedDate =
                    currentFilter.dateDisplay === DateDisplays.onlyDate
                      ? formatDate(dateValues.gte)
                      : formatDateWithTime(dateValues.gte);
                  dateLabelValues.push(`a partir de ${formatedDate}`);
                }
                if (dateValues.lte) {
                  const formatedDate =
                    currentFilter.dateDisplay === DateDisplays.onlyDate
                      ? formatDate(dateValues.lte)
                      : formatDateWithTime(dateValues.lte);
                  dateLabelValues.push(`até ${formatedDate}`);
                }
                if (dateLabelValues.length) {
                  item.label = `${currentFilter.label}: ${dateLabelValues.join(
                    " e "
                  )}`;
                }
              }

              return item;
            })
          );

          return items.filter(Boolean); // Remove any undefined or null items
        })
      );

      const tags = allTagGroups.flat();
      if (isMounted) {
        setTags(tags);
      }
    }

    fetchTags();

    return () => {
      isMounted = false;
    };
  }, [filter, getMany2xOptions]);

  return { tags };
};

export default useTags;
