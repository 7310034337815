/** @jsxImportSource @emotion/react */
import { ToPaymentDetailsLocation } from "../../Locations";
import { Services } from "../../graphql/generated/types";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import AppLayout from "../../components/AppLayout/AppLayout";
import { FC, ReactNode } from "react";
import { useUIStore } from "../../hooks/useUIStore";
import RightMenu from "../../components/Menu/RightMenu/RightMenu";

export const PaymentsWrapper: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { isConfirmationModalOpen, renderConfirmationModal } = useUIStore();

  return (
    <AppLayout
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(paymentId) =>
            ToPaymentDetailsLocation(paymentId)
          }
          serviceName={Services.Payments}
        />
      }
    >
      <ModalStoreProvider>
        {isConfirmationModalOpen && renderConfirmationModal()}
        {children}
      </ModalStoreProvider>
    </AppLayout>
  );
};
