/** @jsxImportSource @emotion/react */

import { FC } from "react";

import { useListContactsLazyQuery } from "../../../graphql/generated/types";
import useContactsFilter from "../../../hooks/useContactsFilter";
import TableProvider from "../../../contexts/table";
import contactsTableColumns, {
  mapTableData,
} from "./ContactsTable/tableColumns";
import ContactsTable from "./ContactsTable/ContactsTable";
import { ContactsWrapper } from "../ContactsWrapper";

const Contacts: FC = () => {
  const contactsFilter = useContactsFilter();
  const filter = contactsFilter.composeQuery();
  const [listContactsQuery] = useListContactsLazyQuery();

  const fetchSheetData = async () => {
    const result = await listContactsQuery({
      variables: {
        filter,
      },
    });

    if (result.data?.contacts) {
      return mapTableData(result.data.contacts);
    }

    return [];
  };

  return (
    <ContactsWrapper>
      <TableProvider
        allColumns={contactsTableColumns}
        initialSort={{ id: "date", sortKey: "createdAt", direction: -1 }}
        fetchSheetData={fetchSheetData}
        tableId="contacts"
      >
        <ContactsTable filter={contactsFilter} />
      </TableProvider>
    </ContactsWrapper>
  );
};

export default Contacts;
