/** @jsxImportSource @emotion/react */
import { Services } from "../../graphql/generated/types";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import AppLayout from "../../components/AppLayout/AppLayout";
import { FC, ReactNode } from "react";
import { ToContactDetailsLocation } from "../../Locations";
import { useUIStore } from "../../hooks/useUIStore";
import RightMenu from "../../components/Menu/RightMenu/RightMenu";

export const ContactsWrapper: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    isConfirmationModalOpen,
    renderConfirmationModal,
    isSubmittedModalOpen,
    renderSubmittedModal,
  } = useUIStore();
  return (
    <AppLayout
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(contactId) =>
            ToContactDetailsLocation(contactId)
          }
          serviceName={Services.Contacts}
        />
      }
    >
      <ModalStoreProvider>
        {isConfirmationModalOpen && renderConfirmationModal()}
        {isSubmittedModalOpen && renderSubmittedModal()}
        {children}
      </ModalStoreProvider>
    </AppLayout>
  );
};
