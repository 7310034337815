/** @jsxImportSource @emotion/react */

import { FC, useEffect } from "react";
import {
  Services,
  useNotificationsQuery,
  NotificationResponse,
} from "../../../../../graphql/generated/types";
import NotificationGroup from "./components/NotificationsGroup";
import SkeletonNotifications from "./components/SkeletonNotifications";

interface NotificationsProps {
  serviceName: Services;
  redirectLocation: (docId: string) => string;
}

const Notifications: FC<NotificationsProps> = ({
  serviceName,
  redirectLocation,
}) => {
  const { data, loading, refetch } = useNotificationsQuery({
    variables: { serviceName },
  });

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, []);

  const notifications: NotificationResponse[] = data?.notifications || [];

  if (loading) return <SkeletonNotifications />;

  return (
    <>
      {notifications.map((dailyNotifications) => (
        <NotificationGroup
          key={dailyNotifications.notificationDate}
          notifications={dailyNotifications!}
          redirectLocation={redirectLocation}
        />
      ))}
    </>
  );
};

export default Notifications;
