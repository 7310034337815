/** @jsxImportSource @emotion/react */
import React from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";

const getCSSRules: CSSRulesResolver = (props) => ({
  wrapper: {
    position: "relative",
    width: "100%",
  },
  input: {
    fontFamily: "inherit",
    fontWeight: "bold",
    fontSize: 24,
    border: "1px solid #dddddd",
    borderRadius: 24,
    height: 46,
    boxSizing: "border-box",
    paddingLeft: 20,
    width: "100%",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: props.colors.Grey[40],
    },
  },
  icon: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    left: 13,
    top: 0,
    bottom: 0,
    margin: "auto",
  },
});

const FilterControlInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  return (
    <div css={styles.wrapper}>
      <input css={styles.input} {...props} autoFocus />
    </div>
  );
};

export default FilterControlInput;
