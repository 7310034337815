/** @jsxImportSource @emotion/react */
import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import Modal from "..";

interface ModalOptions {
  styles?: {
    container?: React.CSSProperties;
    content?: React.CSSProperties;
  };
}

interface ModalStoreContextValue {
  openModal: (
    modal: ReactNode,
    onClose?: () => void,
    options?: ModalOptions
  ) => void;
  closeModal: () => void;
}

export const ModalStoreContext = createContext<ModalStoreContextValue>({
  openModal: () => {},
  closeModal: () => {},
});

interface ModalStoreProviderProps extends PropsWithChildren {}

const ModalStoreProvider: FC<ModalStoreProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const [options, setOptions] = useState<ModalOptions>({});
  const [onCloseCallback, setOnCloseCallback] = useState<(() => void) | null>(
    null
  );
  const openModal = (
    modal: ReactNode,
    onClose?: () => void,
    opts: ModalOptions = {}
  ) => {
    setModal(modal);
    setOnCloseCallback(() => onClose || null);
    setOptions(opts);
  };

  const closeModal = () => {
    setModal(null);
    onCloseCallback && onCloseCallback();
    setOnCloseCallback(null);
  };
  return (
    <ModalStoreContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <div style={{ position: "relative", height: "100%" }}>
        {children}
        {modal && <Modal customStyles={options?.styles}>{modal}</Modal>}
      </div>
    </ModalStoreContext.Provider>
  );
};

export default ModalStoreProvider;

export const useModalStore = () => useContext(ModalStoreContext);
