import Register from ".";
import { OrgRegisterLocation, RegisterLocation } from "../../Locations";
import { RouteObject } from "../../types";
import { OrgRegister } from "./OrgRegister";

export const registerRoutes: RouteObject[] = [
  {
    path: RegisterLocation,
    element: <Register />,
    withAuth: false,
  },
  {
    path: OrgRegisterLocation,
    element: <OrgRegister />,
    withAuth: false,
  },
];
