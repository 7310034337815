import { FC } from "react";
import {
  GetUpsertHarvestFormResponseDocument,
  GetUpsertHarvestFormResponseQueryVariables,
  Harvest,
  useToggleHarvestActiveMutation,
} from "../../../../../graphql/generated/types";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { toast } from "react-toastify";
import useCultureDetails from "../../../hooks/useCulture";
import EditableSectionV2 from "../../../../../components/EditableSectionV2";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

export const HarvestDetails: FC<{ data: Harvest }> = ({ data }) => {
  const { refetch, culture } = useCultureDetails();
  const [toggleHarvestActive] = useToggleHarvestActiveMutation();
  const harvest = (culture.harvests || []).find(
    (harvest) => data._id === harvest._id
  )!;

  return (
    <EditableSectionV2<{}, GetUpsertHarvestFormResponseQueryVariables>
      title="Safra"
      icon={<Icon name={IconNames.Agriculture} />}
      editQuery={GetUpsertHarvestFormResponseDocument}
      editPayload={{ cultureId: harvest.cultureId, harvestId: harvest._id }}
      objectId={harvest._id}
      editText="Editar"
      useV2
      actions={[
        {
          text: harvest.active ? "Desativar" : "Ativar",
          onClick: async () => {
            const isActivating = !harvest.active;
            await toggleHarvestActive({
              variables: {
                harvestId: harvest._id,
              },
            });
            toast.success(
              `Safra ${isActivating ? "ativada" : "desativada"} com sucesso`
            );
            await refetch();
          },
        },
      ]}
      onSubmit={refetch}
    >
      <DetailsSectionColumns
        columns={[
          [
            { value: harvest.name, label: "Nome" },
            { value: harvest.active ? "Sim" : "Não", label: "Ativo" },
          ],
        ]}
      ></DetailsSectionColumns>
    </EditableSectionV2>
  );
};
