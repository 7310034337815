/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { useCSSRulesWithTheme } from "../../../hooks/useCSSRulesWithTheme";
import Select, { InputActionMeta } from "react-select";
import DropdownIndicator from "./components/DropdownIndicator";
import Typography from "../../Typography";
import {
  SelectInputProps,
  SelectInputOptionInterface,
  getSelectStyles,
} from "./SelectInput";
import MultiValue from "./components/MultiValue";
import ClearIndicator from "./components/ClearIndicator";
import MenuList from "./components/MenuList";
import Option from "./components/Option";
import useColor from "../../../hooks/useColor";
import { ColorNames, getThemeColorFromAlias, theme } from "../../../theme";

export type MultiSelectValueDisplay = "default" | "all";
export type MultiSelectMenuListDisplay = "default" | "buttons";

export interface MultiSelectInputProps
  extends Omit<Omit<SelectInputProps, "onSelect">, "onInputChange"> {
  multiValueDisplay?: MultiSelectValueDisplay;
  menuListDisplay?: MultiSelectMenuListDisplay;
  hideSelectedOptions?: boolean;
  closeMenuOnSelect?: boolean;
  onSelect: (value: string[], inputName: string) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
}

const MultiSelectInput: FC<MultiSelectInputProps> = ({
  multiValueDisplay = "default",
  menuListDisplay = "default",
  optionDisplay = "default",
  options,
  color,
  value,
  errorMessage,
  inputName,
  hideDropdown,
  hideSelectedOptions = true,
  closeMenuOnSelect = true,
  onSelect,
  onInputChange,
  label,
  containerId,
  ...props
}) => {
  const multiValueStyles = {
    menu: (baseStyle: any) => ({
      ...baseStyle,
      borderColor: color,
      width: menuListDisplay === "buttons" ? "auto" : "100%",
      zIndex: 1000,
      borderRadius: theme.borderRadius.xl,
      overflow: "hidden",
      padding: 0,
    }),
    multiValue: (baseStyle: any) => ({
      ...baseStyle,
      backgroundColor: "transparent",
      height: "100%",
      margin: 0,
    }),
    valueContainer: (baseStyle: any) => ({
      ...baseStyle,
      margin: 0,
      width: "100%",
    }),
    indicatorContainer: (baseStyle: any) => ({
      ...baseStyle,
      padding: 0,
    }),
  };

  const textColor = useColor(color || ColorNames.BLACK);
  const backgroundColor = getThemeColorFromAlias(
    color || ColorNames.GREY,
    theme.colors,
    40
  );

  const selectStyles = useCSSRulesWithTheme(getSelectStyles, {
    color: textColor,
    backgroundColor,
    optionDisplay,
    errorMessage,
  });

  const multiSelectStyles = {
    ...selectStyles,
    ...multiValueStyles,
  };

  const selectedValues: SelectInputOptionInterface[] = useMemo(() => {
    return options.filter((op) => value.includes(op.value));
  }, [options, value]);

  return (
    <div id={containerId}>
      {label && (
        <Typography
          width="100%"
          variant="h3"
          margin="xxs"
          size={22}
          align="left"
          weight="bold"
        >
          {label}
        </Typography>
      )}
      <Select<SelectInputOptionInterface, true>
        isMulti
        styles={multiSelectStyles}
        options={options}
        value={selectedValues}
        color={color}
        hideSelectedOptions={hideSelectedOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={(selectedOptions) =>
          onSelect(
            selectedOptions.map((op) => op.value),
            inputName
          )
        }
        onInputChange={onInputChange}
        multiValueDisplay={multiValueDisplay}
        menuListDisplay={menuListDisplay}
        optionDisplay={optionDisplay}
        {...props}
        // @ts-ignore
        components={{
          MultiValue,
          MenuList,
          Option,
          ClearIndicator,
          MultiValueRemove: () => null,
          IndicatorSeparator: () => null,
          // IndicatorsContainer: IndicatorsContainer as any,
          ...(hideDropdown
            ? { DropdownIndicator: () => null }
            : { DropdownIndicator }),
        }}
      />
      {errorMessage && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="right"
          component="div"
          css={{ width: "100%" }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default MultiSelectInput;
