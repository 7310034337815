/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ContactsWrapper } from "../../Contact/ContactsWrapper";
import { CSSObject } from "@emotion/react";
import { DetailsHeader } from "../../../components/Details/components/Header/DetailsHeader";
import ScrollContainer from "../../../components/ScrollContainer";
import { DetailsTabs } from "../../../components/Tabs/DetailsTabs";
import { Outlet } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import { useMyOrgTabs } from "../useMyOrgTabs";
import { MyOrgSummary } from "./MyOrgSummary";
import { MyOrgLocation } from "../../../Locations";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    height: "100%",
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    flex: 1,
    padding: "0 12px",
  },
};

export const MyOrgLayout: FC = () => {
  const tabs = useMyOrgTabs();
  return (
    <ContactsWrapper>
      <PageTitle title={"Minha organização"} />
      <div css={styles.wrapper}>
        <DetailsHeader title={"Minha organização"} />
        <ScrollContainer css={styles.pageContent}>
          <MyOrgSummary />
          <DetailsTabs
            tabs={tabs.map((tab) => ({
              ...tab,
              location: `${MyOrgLocation}/${tab.path}`,
            }))}
          >
            <Outlet />
          </DetailsTabs>
        </ScrollContainer>
      </div>
    </ContactsWrapper>
  );
};
