import { FC, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { MyOrgLayout } from "./components/MyOrgLayout";
import { useMyOrgTabs } from "./useMyOrgTabs";
import { useAuth } from "../../hooks/useAuth";
import { isOrgOwnerOrAdmin } from "../Contact/utils/tags";
import { MyOrgLocation } from "../../Locations";

export const MyOrg: FC = () => {
  const { user } = useAuth();
  const tabs = useMyOrgTabs();
  const navigate = useNavigate();
  useEffect(() => {
    if (user._id && !(user.orgId && isOrgOwnerOrAdmin(user.contact!))) {
      navigate("/");
    }
  }, [user]);
  if (!user.orgId) return null;

  const baseUrl = MyOrgLocation;

  return (
    <Routes>
      <Route element={<MyOrgLayout />}>
        <Route
          index
          element={<Navigate to={`${baseUrl}/${tabs[0].path}`} replace />}
        />
        {tabs.map((tab) => (
          <Route key={tab.path} path={tab.path} element={<tab.element />} />
        ))}
      </Route>
    </Routes>
  );
};
