import documentFilters from "../filters/documents";
import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import { CountDocumentsDocument, Services } from "../graphql/generated/types";

const useDocumentsFilter = () => {
  const filters = useModelFilter(
    documentFilters,
    Services.Documents,
    [],
    CountDocumentsDocument
  );

  useUrlFilter(filters);

  return filters;
};

export default useDocumentsFilter;
