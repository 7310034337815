/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { css } from "@emotion/react";
import Typography, { BaseFontSize } from "../Typography";
import { SelectInputOptionInterface } from "./SelectInputs/SelectInput";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { ColorNames, CustomTheme, theme } from "../../theme";
import { FormInputOptions } from "../../graphql/generated/types";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";

const getStyles = ({ colors }: CustomTheme) => ({
  /* A simple grid container that ensures items will 
     display in a row and wrap if space is insufficient. */
  gridContainer: {
    display: "grid",
    gap: 16,
    // Adjust minmax values to suit your desired sizing/breakpoints:
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
  },
  option: {
    backgroundColor: "white",
    textOverflow: "ellipsis",
    height: 45,
    borderRadius: theme.borderRadius.lg,
    boxSizing: "border-box" as const,
    border: "0.2px solid rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap" as const,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      border: `1px solid ${colors.Green[100]}`,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  content: {
    lineHeight: "45px",
  },
  active: {
    color: colors.White[100],
    backgroundColor: colors.Green[100],
    border: `1px solid ${colors.Green[100]}`,
    fontWeight: "bold",
  },
});

export interface ButtonSelectInputProps {
  error: string;
  options: FormInputOptions[];
  name: string;
  onSelect: (option: SelectInputOptionInterface, inputName: string) => void;
  value: string;
}

const ButtonSelectInput: FC<ButtonSelectInputProps> = ({
  name,
  options,
  onSelect,
  value,
  error,
}) => {
  const styles = useCSSRulesWithTheme(getStyles, {});

  // Identify which option (if any) is currently selected
  const selectedOptionIndex = options?.findIndex(
    (option) => option.value === value
  );

  return (
    <div>
      <div css={styles.gridContainer}>
        {options?.map((option, i) => (
          <div
            key={option.value}
            css={css(styles.option, selectedOptionIndex === i && styles.active)}
            onClick={() => onSelect(option, name)}
          >
            {!!option.icon && (
              <Icon
                name={option.icon as IconNames}
                size={BaseFontSize * 1.1}
                color={
                  selectedOptionIndex === i
                    ? ColorNames.WHITE
                    : ColorNames.BLACK
                }
              />
            )}
            <Typography
              variant="textMedium"
              component={"div"}
              css={styles.content}
              align="center"
              weight={selectedOptionIndex === i ? "bold" : "regular"}
            >
              {option.label}
            </Typography>
          </div>
        ))}
      </div>

      {/* Error message (if any) goes below the grid */}
      {error && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="left"
          component="div"
          css={{ marginTop: 8 }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default ButtonSelectInput;
