/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC } from "react";
import Typography, {
  BaseFontSize,
  FontSizes,
} from "../../../../../components/Typography";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import FileDetails from "../../../../../components/Details/components/Main/Grid/valueVariants/FileDetails";
import Row from "../../../../../components/Grid/Row";
import Column from "../../../../../components/Grid/Column";
import {
  getClaimFields,
  getClaimSpecificFields,
  getContactFields,
  getGeneralFields,
  getInspectionFields,
  getPaymentsFields,
  getPolicyFields,
  getProposalFields,
  Section,
} from "./sections";
import { OtherAttachments } from "./OtherAttachments";
import { formatISODate } from "../../../../../utils/dateUtils";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import Icon from "../../../../../components/Icons";
import HR from "../../../../../components/HorizontalLine";
import { ColorNames } from "../../../../../theme";

const styles: CSSObject = {
  wrapper: {
    padding: "24px",
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    boxSizing: "border-box",
    padding: "8px 0",
  },
  subsection: {
    boxShadow: "none",
    marginTop: 6,
    padding: 16,
  },
  fileCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    marginTop: 12,
  },
  row: { width: "100%" },
  fileDetails: {
    marginTop: 8,
  },

  titleWithIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  mainTitle: { marginBottom: 24 },
  hr: {
    marginTop: 24,
    width: "100%",
  },
};

export const Attachments: FC<{}> = () => {
  const { documentData: doc } = useDocumentDetails();
  const renderSection = (
    { fields, title, subsections, titleIcon }: Section,
    isSubSection = false
  ) => (
    <div
      css={[
        styles.sectionWrapper,
        isSubSection ? styles.subsection : undefined,
      ]}
      key={title}
    >
      <div css={styles.titleWithIcon}>
        {titleIcon && <Icon name={titleIcon} size={BaseFontSize * 1.3} />}
        <Typography weight="bold" as="h2" size={FontSizes.Medium}>
          {title}
        </Typography>
      </div>
      <Row justify="start" css={styles.row} noMargins>
        {fields.map((file) => (
          <Column
            md={12}
            xl={6}
            xxl={4}
            css={styles.fileCard}
            key={file.label}
            noPadding
          >
            <Typography weight="bold" size={BaseFontSize * 0.9}>
              {file.label}
            </Typography>
            {file.value ? (
              <FileDetails css={styles.fileDetails} {...file.value} />
            ) : (
              <Typography>-</Typography>
            )}
          </Column>
        ))}
      </Row>
      {!!subsections?.length &&
        subsections.map((subsection) => renderSection(subsection, true))}
      {!isSubSection && (
        <div css={styles.hr}>
          <HR
            color={ColorNames.LIGHTGREY}
            borderWidth={2}
            spacingSize="none"
            width={"100%"}
          />
        </div>
      )}
    </div>
  );
  const generalFields = getGeneralFields(doc);
  return (
    <div css={styles.wrapper}>
      <div css={[styles.titleWithIcon, styles.mainTitle]}>
        <Icon name={IconNames.Attachments} />
        <Typography size={FontSizes.Large} weight="bold">
          TODOS OS ANEXOS
        </Typography>
      </div>
      {!!generalFields?.length &&
        renderSection({
          fields: generalFields,
          title: "GERAL",
          titleIcon: IconNames.Details,
        })}
      {doc.proposalResult &&
        renderSection({
          fields: getProposalFields(doc.proposalResult),
          title: "PROPOSTA",
          titleIcon: IconNames.Proposal,
        })}
      {!!doc.payments?.length &&
        renderSection({
          fields: getPaymentsFields(doc),
          title: "PAGAMENTOS",
          titleIcon: IconNames.Money,
        })}
      {doc.policy &&
        renderSection({
          fields: getPolicyFields(doc.policy),
          title: "APÓLICE",
          titleIcon: IconNames.Policy,
        })}
      {!!doc.claims?.length &&
        renderSection({
          fields: getClaimFields(doc),
          title: "SINISTROS",
          titleIcon: IconNames.Claim,
          subsections: doc.claims.map((claim) => ({
            fields: getClaimSpecificFields(claim),
            title: `SINISTRO ${claim.name.toUpperCase()}`,
            subsections: doc.inspections
              ?.filter((inspection) =>
                inspection.claims?.some((c) => c!._id === claim._id)
              )
              .map((inspection) => ({
                fields: getInspectionFields(doc, inspection),
                title: `Vistoria - ${formatISODate(inspection.date)}`,
              })),
          })),
        })}
      {renderSection({
        fields: [],
        title: "CONTATOS",
        titleIcon: IconNames.Community,
        subsections: [
          {
            fields: getContactFields(doc.farmer!),
            title: "PRODUTOR",
          },
          doc.beneficiary &&
            doc.beneficiary._id !== doc.farmer?._id && {
              fields: getContactFields(doc.beneficiary),
              title: "BENEFICIÁRIO",
            },
          doc.paymentData?.responsible &&
            doc.paymentData.responsible._id !== doc.farmer?._id && {
              fields: getContactFields(doc.paymentData.responsible),
              title: "RESPONSÁVEL FINANCEIRO",
            },
        ].filter(Boolean) as Section[],
      })}
      <OtherAttachments />
    </div>
  );
};
