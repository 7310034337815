/** @jsxImportSource @emotion/react */
import React from "react";
import { TModelFilter } from "../../../../../hooks/useModelFilter";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import { NestedMenuOption } from "../../../../../components/NestedMenu/NestedMenu";
import { useTableContext } from "../../../../../contexts/table";
import { TableMenu } from "../../../../../components/ResizableTable/components/TableMenu";
import Filter from "../../../../../components/Filter/Filter";
import DocumentsFilter from "../DocumentsFilter/DocumentsFilter";

interface DocumentsTableHeaderProps {
  filters: TModelFilter;
  nestedMenuOptions: NestedMenuOption[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: 12,
  },
  right: {
    display: "flex",
    gap: 12,
  },
});

const DocumentsTableHeader: React.FC<DocumentsTableHeaderProps> = ({
  filters,
  nestedMenuOptions,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { actions } = useTableContext();
  return (
    <div css={styles.container}>
      <Filter
        filters={filters}
        placeholder="Todos os Documentos"
        filterDropdown={<DocumentsFilter filter={filters} />}
      />
      {actions.activeView === "table" && (
        <div css={styles.right}>
          <TableMenu id="documents-menu" options={nestedMenuOptions} />
        </div>
      )}
    </div>
  );
};

export default DocumentsTableHeader;
