/** @jsxImportSource @emotion/react */
import React from "react";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { BaseFontSize } from "../../Typography";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";

interface ControlButtonProps {
  icon: IconNames;
  tooltipText: string;
  onClick: () => void;
}

const getCSSRules: CSSRulesResolver<{ tooltipText: string }> = (props) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    height: 20,
    width: 20,

    "&:hover > *:after": {
      visibility: "visible",
    },

    "> *": {
      position: "relative",
      "&:after": {
        visibility: "hidden",
        content: `'${props.tooltipText}'`,
        display: "block",
        backgroundColor: "white",
        border: `1px solid ${props.colors.Grey[20]}`,
        position: "absolute",
        padding: "3px 6px",
        borderRadius: 8,
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        top: "calc(100% + 8px)",
        left: "-50%",
        zIndex: 1000,
      },
    },
  },
});

const ControlButton: React.FC<ControlButtonProps> = ({
  onClick,
  icon,
  tooltipText,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules, { tooltipText });
  return (
    <div css={styles.container} onClick={onClick}>
      <Icon name={icon} size={BaseFontSize} pointer />
    </div>
  );
};

export default ControlButton;
