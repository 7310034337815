/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import {
  DetailsTab,
  DetailsTabs,
} from "../../../../components/Tabs/DetailsTabs";
import { toCultureDetailsLocation } from "../../../../Locations";
import useCultureDetails from "../../hooks/useCulture";
import Summary from "./Summary";
import { DetailsHeader } from "../../../../components/Details/components/Header/DetailsHeader";
import ScrollContainer from "../../../../components/ScrollContainer";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    paddingBottom: 24,
    height: "100%",
  },
  pageContent: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    flex: 1,
  },
};

export const CultureDetailsLayout: FC = () => {
  const { culture } = useCultureDetails();
  const baseUrl = toCultureDetailsLocation(culture._id!);
  const tabs: DetailsTab[] = [
    { title: "Allianz", location: baseUrl + "/allianz" },
    { title: "Swiss", location: baseUrl + "/swiss" },
    { title: "Mapfre", location: baseUrl + "/mapfre" },
    { title: "Safras", location: baseUrl + "/safras" },
  ];
  return (
    <div css={styles.wrapper}>
      <DetailsHeader title={culture.name!} />
      <ScrollContainer css={styles.pageContent}>
        <Summary />
        <DetailsTabs tabs={tabs}>
          <Outlet />
        </DetailsTabs>
      </ScrollContainer>
    </div>
  );
};
