/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Services } from "../../../graphql/generated/types";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { useFlags } from "../../../hooks/useFlags";
import { IconNames } from "../../Icons/styles/iconNames";
import ScrollContainer from "../../ScrollContainer";
import Typography, { FontSizes } from "../../Typography";
import ChatListQueryWrapper from "../../WAChat/components/ChatListQueryWrapper";
import PanelButton from "./components/PanelButton";
import Notifications from "./panels/Notifications";
import Tasks from "./panels/Tasks";
import UserMenu from "./components/UserMenu";

interface StyleParams {
  selectedPanel: number;
}

interface RightMenuProps {
  serviceName?: Services;
  docIdRedirectLocation?: (id: string) => string;
}

const getCSSRules: CSSRulesResolver<StyleParams> = ({
  selectedPanel,
  ...props
}) => ({
  container: {
    backgroundColor: props.colors.LightGrey[60],
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "start",
    paddingTop: 12,
    boxSizing: "border-box",
    overflow: "hidden",
    height: "100%",
    width: selectedPanel !== -1 ? 460 : 60,
    transition: "padding .1s ease, width .1s ease",
  },
  menu: {
    width: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    height: "100%",
  },
  menuGroup: {
    position: "relative",
    display: "flex",
    zIndex: 1,
    backgroundColor: props.colors.LightGrey[60],
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    paddingTop: 12,
    height: "100%",
    "> *:first-of-type": {
      marginBottom: 12,
    },
  },

  panel: {
    width: 394,
    backgroundColor: "white",
    position: "absolute",
    left: 6,
    top: 12,
    bottom: 12,
    borderRadius: 30,
    padding: 12,
    boxSizing: "border-box",

    display: "flex",
    flexDirection: "column",
  },
  panelHeader: {
    paddingLeft: 12,
    marginBottom: 12,
  },
  scrollableContent: {
    flex: 1,
    minHeight: 0,
    overflow: "hidden",
  },
});

const RightMenu: React.FC<RightMenuProps> = ({
  serviceName,
  docIdRedirectLocation,
}) => {
  const [selectedPanel, setSelectedPanel] = useState(-1);
  const styles = useCSSRulesWithTheme(getCSSRules, { selectedPanel });

  const onPanelButtonClick = (idx: number) => {
    setSelectedPanel(idx === selectedPanel ? -1 : idx);
  };

  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");

  const panels = [
    {
      icon: IconNames.Chat,
      title: "Chat",
      element: <ChatListQueryWrapper />,
    },
  ];
  if (serviceName && docIdRedirectLocation) {
    panels.push(
      {
        icon: IconNames.Task,
        title: "Tarefas",
        element: (
          <Tasks
            serviceName={serviceName}
            redirectLocation={docIdRedirectLocation}
          />
        ),
      },
      {
        icon: IconNames.Notification,
        title: "Notificações",
        element: (
          <Notifications
            serviceName={serviceName}
            redirectLocation={docIdRedirectLocation}
          />
        ),
      }
    );
  }

  return (
    <div css={styles.container}>
      {/* LEFT MENU */}
      <div css={styles.menu}>
        <div css={styles.menuGroup}>
          <UserMenu />
          {isAdmin &&
            panels.map(({ icon }, idx) => (
              <PanelButton
                onClick={() => onPanelButtonClick(idx)}
                selected={selectedPanel === idx}
                icon={icon}
                key={idx}
              />
            ))}
        </div>
      </div>
      <div css={styles.panel}>
        {selectedPanel !== -1 && (
          <div css={styles.panelHeader}>
            <Typography
              variant="h2"
              uppercase
              size={FontSizes.ExtraLarge}
              weight="bold"
              align="left"
            >
              {panels[selectedPanel].title}
            </Typography>
          </div>
        )}

        <div css={styles.scrollableContent}>
          <ScrollContainer>
            {selectedPanel !== -1 && panels[selectedPanel].element}
          </ScrollContainer>
        </div>
      </div>
    </div>
  );
};

export default RightMenu;
