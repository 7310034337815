/** @jsxImportSource @emotion/react */

import { CSSProperties, FC, PropsWithChildren } from "react";
import { theme } from "../../theme";
import { CSSObject } from "@emotion/react";

const styles: CSSObject = {
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.LightGrey[80],
    opacity: 0.9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
};

interface LoadingOverlayProps extends PropsWithChildren {
  style?: CSSProperties;
  className?: string;
}

// this component uses position absolute to position itself, so don't forget to use position relative in ur desired parent
const LoadingOverlay: FC<LoadingOverlayProps> = ({ children, ...rest }) => {
  return (
    <div css={styles.loadingOverlay} {...rest}>
      {children}
    </div>
  );
};

export default LoadingOverlay;
