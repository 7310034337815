import { FC } from "react";
import { useAuth } from "../../../hooks/useAuth";
import EditableSectionV2 from "../../../components/EditableSectionV2";
import { DetailsItem } from "../../../components/ModelDetails/DetailsItem";
import { DetailsWrapper } from "../../../components/ModelDetails/DetailsWrapper";
import { Grid } from "../../../components/Grid/Grid";
import { Services } from "../../../graphql/generated/types";
import { industryLabels } from "../../../shared/org/industries";
import { DetailsRowValueVariants } from "../../../components/Details/components/Main/Grid/DetailsRowValue";

export const MyOrgSummary: FC = () => {
  const { user } = useAuth();
  const summaryColumns = [
    {
      label: "Nome",
      value: user.org?.name,
    },
    {
      label: "Setor",
      value: industryLabels[user.org?.orgDetails!.industry!],
    },
    {
      label: "Cidades",
      value: user.org?.orgDetails!.citiesOfOperation?.join(", "),
    },
    {
      label: "Número de filiais",
      value: user.org?.orgDetails!.subsidiariesCount,
    },
    {
      label: "Nº de integrantes",
      value: user.org?.orgDetails!.membershipCount,
    },
    {
      label: "Link",
      value: user.org?.slug,
    },
    {
      label: "Contrato assinado",
      variant: DetailsRowValueVariants.File as const,
      value: {
        file: {
          fileName: user.org?.orgDetails?.contractFile?.fileName,
        },
        objectId: user.orgId,
        fieldPath: "orgDetails.contractFile",
        serviceName: Services.Contacts,
      },
    },
  ];

  return (
    <DetailsWrapper>
      <EditableSectionV2 title={"Dados gerais"}>
        <Grid>
          {summaryColumns.map((item) => (
            <DetailsItem key={item.label} {...item} />
          ))}
        </Grid>
      </EditableSectionV2>
    </DetailsWrapper>
  );
};
