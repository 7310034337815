import { useState, useEffect, useCallback } from "react";
import { ObjectId } from "bson";
import { useErrorHandler } from "../hooks/useErrorHandler";
import serviceFetch from "../services/fetch";
import { useAuth } from "../hooks/useAuth";

import {
  useGetTwilioWaMessagesQuery,
  useGetTwilioMediaUrlLazyQuery,
  useSendTwilioWaMessageMutation,
  useToggleUserConversationSubscriptionMutation,
} from "../graphql/generated/types";
import useDebounce from "./useDebounce";

const DRAFT_MESSAGES_KEY = "DRAFT_MESSAGES";

function getLocalStorageJsonData() {
  return JSON.parse(localStorage.getItem(DRAFT_MESSAGES_KEY) || "{}") as Record<
    string,
    string
  >;
}

interface UseChatOptions {
  contactId: string;
  skip?: boolean; // e.g. for skipping queries if not an admin
}

export function useChat({ contactId, skip = false }: UseChatOptions) {
  const auth = useAuth();
  const { errorHandler } = useErrorHandler();

  // Local state
  const [message, setMessage] = useState(() => {
    const draftMessages = getLocalStorageJsonData();
    return draftMessages[contactId] || "";
  });
  const [sendingMessage, setSendingMessage] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  // Debounce so we only save to localStorage once every X ms
  const { debouncedValue: debouncedMessage } = useDebounce(message, 500);

  // Queries & Mutations
  const { data, refetch, loading } = useGetTwilioWaMessagesQuery({
    variables: { contactId },
    skip,
  });
  const [getTwilioMediaUrl] = useGetTwilioMediaUrlLazyQuery();
  const [sendMessageMutation] = useSendTwilioWaMessageMutation();
  const [toggleSubscriptionMutation] =
    useToggleUserConversationSubscriptionMutation();

  // Sync message with localStorage
  useEffect(() => {
    const draftMessages = getLocalStorageJsonData();
    draftMessages[contactId] = debouncedMessage;
    localStorage.setItem(DRAFT_MESSAGES_KEY, JSON.stringify(draftMessages));
  }, [debouncedMessage, contactId]);

  const uploadFile = useCallback(
    async (file: File, fileKey: string): Promise<void> => {
      await serviceFetch(auth, `upload-file`, null, "post", { fileKey }, file);
    },
    [auth]
  );

  const sendMessage = useCallback(async () => {
    setSendingMessage(true);
    try {
      const filePayload = await Promise.all(
        files.map(async (file) => {
          const fileKey = `twilio/conversation/${contactId}/${new ObjectId().toString()}`;
          await uploadFile(file, fileKey);
          return { fileKey, fileName: file.name };
        })
      );
      await sendMessageMutation({
        variables: {
          contactId: contactId,
          content: message,
          files: filePayload,
        },
      });

      // Clear local storage draft
      const draftMessages = getLocalStorageJsonData();
      draftMessages[contactId] = "";
      localStorage.setItem(DRAFT_MESSAGES_KEY, JSON.stringify(draftMessages));

      setMessage("");
      setFiles([]);
      await refetch();
    } catch (e) {
      errorHandler(new Error("Não foi possível enviar sua mensagem"), e);
    } finally {
      setSendingMessage(false);
    }
  }, [
    contactId,
    message,
    files,
    uploadFile,
    sendMessageMutation,
    refetch,
    errorHandler,
  ]);

  const onFilesAccepted = useCallback((filesAdded: File[]) => {
    setFiles((prev) => [...prev, ...filesAdded]);
  }, []);

  const fetchMediaUrl = useCallback(
    async (fileKey: string) => {
      try {
        const result = await getTwilioMediaUrl({ variables: { fileKey } });
        if (result?.data?.getTwilioMediaUrl) {
          window.open(result.data.getTwilioMediaUrl, "_blank");
        }
      } catch (e) {
        errorHandler(
          new Error("Não foi possível fazer o download desse arquivo"),
          e
        );
      }
    },
    [getTwilioMediaUrl, errorHandler]
  );

  const toggleSubscription = useCallback(async () => {
    try {
      await toggleSubscriptionMutation({
        variables: {
          contactId,
          userId: auth.user._id!,
          // invert the subscription status
          shouldAdd: !data?.getTwilioWAMessages.isSubscribed,
        },
      });
      await refetch();
    } catch (e) {
      errorHandler(new Error("Não foi possível alterar assinatura"), e);
    }
  }, [
    toggleSubscriptionMutation,
    contactId,
    auth.user._id,
    data,
    refetch,
    errorHandler,
  ]);

  return {
    message,
    setMessage,
    files,
    setFiles,
    sendingMessage,
    sendMessage,
    onFilesAccepted,
    fetchMediaUrl,
    toggleSubscription,
    refetch,
    loading,
    data: data?.getTwilioWAMessages,
  };
}
