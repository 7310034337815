/** @jsxImportSource @emotion/react */

import { FC, PropsWithChildren, ReactNode } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { TOverflowMenuOptions } from "../../OverflowMenu";
import Typography from "../../Typography";
import NestedMenu from "../../NestedMenu/NestedMenu";
import { IconNames } from "../../Icons/styles/iconNames";
import Icon from "../../Icons";
import { IconSizes } from "../../Icons/styles/iconSizes";
import { ColorNames, theme } from "../../../theme";
import { TypedDocumentNode } from "@apollo/client";
import { FormRequestResponse } from "../../../graphql/generated/types";
import IconLabel from "../../IconLabel";
import { useFlags } from "../../../hooks/useFlags";

export interface SectionActions {
  text: string;
  iconName?: IconNames;
  disabled?: boolean;
  onClick?: () => void;
  flag?: string;
  formQuery?: {
    query: TypedDocumentNode<{ [key: string]: FormRequestResponse }, any>;
    variables: any;
    onSubmit: (response: any) => void;
  };
  useV2?: boolean;
  link?: string;
}

export interface DetailsSectionProps extends PropsWithChildren {
  title: string;
  icon?: ReactNode;
  subtitle?: boolean;
  button?: ReactNode;
  sectionWrap?: boolean;
  sectionOpen?: boolean;
  disabled?: boolean;
  alwaysOpen?: boolean;
  sectionPending?: boolean;
  options?: TOverflowMenuOptions[];
  actions?: SectionActions[];
}

const getStyles: CSSRulesResolver = () => ({
  section: {
    display: "flex",
    flexDirection: "column",
    gap: 18,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    display: "flex",
    gap: 12,
  },
  menuIcon: {
    height: 32,
    width: 32,
    padding: 4,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.colors.LightGrey[60],
    },
  },
});

const DetailsSection: FC<DetailsSectionProps> = (props) => {
  const { title, actions, children, icon } = props;

  const styles = useCSSRulesWithTheme(getStyles);
  const { isFlagEnabled } = useFlags();
  return (
    <>
      <div css={styles.section}>
        <div css={styles.header}>
          <div css={styles.title}>
            {icon}
            <Typography variant="h2" margin="none" uppercase>
              {title}
            </Typography>
          </div>
          {actions?.length ? (
            <NestedMenu
              id={title}
              menuIcon={
                <div css={styles.menuIcon}>
                  <Icon
                    name={IconNames.OverflowMenu}
                    pointer
                    size={IconSizes.Large}
                  />
                </div>
              }
              options={actions
                .filter(({ flag }) => !flag || isFlagEnabled(flag))
                .map(({ text, iconName, disabled, ...rest }) => ({
                  label: iconName ? (
                    <IconLabel
                      padding="8px"
                      label={text}
                      icon={iconName}
                      color={disabled ? ColorNames.GREY : ColorNames.BLACK}
                    />
                  ) : (
                    text
                  ),
                  disabled,
                  ...rest,
                }))}
            />
          ) : null}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default DetailsSection;
