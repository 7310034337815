/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { theme } from "../../theme";
import { useDefaultMenuItems } from "../../hooks/useDefaultMenuItems";
import useIsMobile from "../../hooks/useIsMobile";
import LeftMenu from "../Menu/LeftMenu/LeftMenu";
import MobileMenu from "../Menu/MobileMenu/MobileMenu";

interface AppLayoutProps extends PropsWithChildren {
  rightMenu: React.ReactNode;
}

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.colors.LightGrey[60],
  },
  content: {
    width: "100%",
    backgroundColor: theme.colors.White[100],
    borderRadius: 30,
    overflow: "hidden",
    margin: "12px 0",
    paddingBottom: "12px",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      marginTop: 60,
      marginBottom: 0,
      borderRadius: 0,
      padding: 0,
    },
  },
});

const AppLayout: React.FC<AppLayoutProps> = ({ rightMenu, children }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const isMobile = useIsMobile();

  const menuItem = useDefaultMenuItems();

  return (
    <div css={styles.container}>
      <div css={styles.leftMenu}>
        {isMobile ? (
          <MobileMenu menuItems={menuItem} />
        ) : (
          <LeftMenu menuItems={menuItem} />
        )}
      </div>
      <div css={styles.content}>{children}</div>
      {isMobile ? null : <div css={styles.rightMenu}>{rightMenu}</div>}
    </div>
  );
};

export default AppLayout;
