/** @jsxImportSource @emotion/react */

import { FC, Fragment } from "react";
import { ColorNames } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbItem } from "./components/Breadcrumb";

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  drawerParamKey?: string;
}

const breadcrumbsMargin = 24;
const breadcrumbsHeight = 45;
export const totalBreadcrumbsHeight = breadcrumbsHeight + breadcrumbsMargin;

const styles = {
  breadcrumbs: {
    position: "relative" as "relative",
    zIndex: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
    height: breadcrumbsHeight,
    marginBottom: breadcrumbsMargin,
  },
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  drawerParamKey = "docId",
}) => {
  const location = useLocation();
  const params = useParams();
  const { formId } = params;

  const drawerParamValue: string = params[drawerParamKey]!;

  const pathSegments = location.pathname.split("/").map((path) => {
    if (path === drawerParamValue) {
      return `:${drawerParamKey}`;
    }
    if (path === formId) {
      return ":formId";
    }
    return path;
  });

  const locations = pathSegments.map((_, index) => {
    if (index === 0) {
      return "/";
    }
    return pathSegments.slice(0, index + 1).join("/");
  });

  const getLocationWithParams = (location: string) => {
    return location
      .replace(":docId", drawerParamValue || "")
      .replace(":formId", params.formId || "");
  };

  const lastIndex = locations.length - 1;

  return (
    <div css={styles.breadcrumbs}>
      {locations.map((path, index) => {
        const breadcrumbItem = items.find((item) => item.value === path);
        if (breadcrumbItem) {
          return (
            <Fragment key={breadcrumbItem.label}>
              {index !== 0 && (
                <Icon name={IconNames.ChevronRight} color={ColorNames.GREY} />
              )}
              <Breadcrumb
                label={breadcrumbItem.label}
                value={getLocationWithParams(path)}
                active={index === lastIndex}
              />
            </Fragment>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Breadcrumbs;
