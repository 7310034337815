/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { Contact, Services } from "../../../../graphql/generated/types";
import DetailsSectionColumns from "../Main/Grid/DetailsSectionColumns";
import { formatCpfCnpj } from "../../../../utils/formatNumber";
import { DetailsRowValueVariants } from "../Main/Grid/DetailsRowValue";
import OrgMembersData from "../../../../views/Contact/ContactDetails/components/OrgMemberData";
import { industryLabels } from "../../../../shared/org/industries";

interface OrgDataProps {
  org: Contact;
}

const OrgData: FC<OrgDataProps> = ({ org }) => {
  const orgColumns = useMemo(() => {
    const orgDetails = org.orgDetails!;
    const leftSide = [
      {
        label: "Razão Social",
        value: orgDetails.legalName,
      },
      {
        label: "Setor da organização",
        value: industryLabels[orgDetails.industry!],
      },
      {
        label: "CNPJ",
        value: formatCpfCnpj(org.cpfCnpj!),
      },
      {
        label: "Número de integrantes",
        value: orgDetails.membershipCount,
      },
      {
        label: "Número de filiais",
        value: orgDetails.subsidiariesCount,
      },
      {
        label: "Cidades",
        value: orgDetails.citiesOfOperation?.join(", "),
      },
    ];
    const rightSide = [
      {
        label: "SUSEP",
        value: orgDetails.susep,
      },
      {
        label: "Ramos de atuação",
        value: orgDetails.linesOfBusiness,
      },
      {
        label: "Setor",
        value: orgDetails.sector,
      },
      {
        label: "Público alvo",
        value: orgDetails.targetAudience,
      },
      {
        label: "Membros",
        value: {
          text: `${org.members!.length!} membro(s)`,
          Component: OrgMembersData,
        },
        variant: DetailsRowValueVariants.Modal as const,
      },
      {
        label: "Contrato assinado",
        variant: DetailsRowValueVariants.File as const,
        value: {
          file: {
            fileName: orgDetails.contractFile?.fileName,
          },
          objectId: org._id,
          fieldPath: "orgDetails.contractFile",
          serviceName: Services.Contacts,
        },
      },
    ];
    return [leftSide, rightSide];
  }, [org.orgDetails, org.members, org._id]);

  return <DetailsSectionColumns columns={orgColumns!} />;
};

export default OrgData;
