import {
  ConsultantsLocation,
  ContactDetailsChatLocation,
  ContactDetailsLocation,
  ContactsLocation,
  CultureDetailLocation,
  CultureListLocation,
  DocumentDetailsLocation,
  DocumentsDashboardLocation,
  DocumentsLocation,
  HomeLocation,
  PartnersDashboardLocation,
  PaymentsLocation,
  SimulationLocation,
} from "../../../Locations";
import { BreadcrumbItem } from "../../Breadcrumbs/components/Breadcrumb";
import { locationLabels } from "../../../utils/pageLabels";

const toLabelAndValueJson = (value: string) => ({
  value,
  label: locationLabels[value] || "Desconhecido",
});

const commonBreadCrumbs = [
  HomeLocation,
  DocumentsDashboardLocation,
  PartnersDashboardLocation,
  DocumentsLocation,
  ConsultantsLocation,
  DocumentDetailsLocation,
  SimulationLocation,
  ContactsLocation,
  ContactDetailsLocation,
  ContactDetailsChatLocation,
  CultureListLocation,
  CultureDetailLocation,
  PaymentsLocation,
];

export const mainFrameBreadCrumbs: BreadcrumbItem[] = [
  ...commonBreadCrumbs.map((bc) => toLabelAndValueJson(bc)),
  {
    label: "Tabs do Documento",
    value: "",
  },
  {
    label: "Tabs do Contato",
    value: "",
  },
  {
    label: "Tabs da Cultura",
    value: "",
  },
];
