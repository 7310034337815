import { ListContactsQuery } from "../../../../graphql/generated/types";
import { TableColumn } from "../../../../hooks/useResizeableTable";
import { contactTagLabels } from "../../../../shared/contacts/contactTags";
import { NullPartial } from "../../../../shared/types";
import { userStatusLabels } from "../../../../shared/user/userStatus";

type ContactsTableColumns =
  | "id"
  | "name"
  | "mobilePhone"
  | "type"
  | "uf"
  | "city"
  | "status"
  | "responsible"
  | "cnpj"
  | "email"
  | "date"
  | "documentCount";

export interface ContactsTableData
  extends NullPartial<Record<ContactsTableColumns, string | number>> {
  id: string;
}

const contactsTableColumns: TableColumn<ContactsTableData>[] = [
  { id: "name", label: "PRODUTOR" },
  { id: "mobilePhone", label: "TELEFONE" },
  { id: "type", label: "TIPO" },
  { id: "uf", label: "UF", sortKey: "address.state" },
  { id: "city", label: "CIDADE", sortKey: "address.city" },
  { id: "status", label: "STATUS" },
  { id: "responsible", label: "RESPONSÁVEL" },
  { id: "cnpj", label: "CPF/CNPJ" },
  { id: "email", label: "EMAIL" },
  { id: "date", label: "DATA" },
  { id: "documentCount", label: "Nº DOCUMENTOS" },
];

export const mapTableData = (
  contacts?: ListContactsQuery["contacts"]
): ContactsTableData[] => {
  return (
    contacts?.map((contact) => ({
      id: contact._id,
      name: contact.name,
      type: contact.tags?.[0] && contactTagLabels[contact.tags?.[0]],
      uf: contact.address?.state,
      city: contact.address?.city,
      status: contact.status && userStatusLabels[contact.status],
      responsible: contact.responsible?.name,
      cnpj: contact.cpfCnpj,
      email: contact.email,
      date: new Date(contact.createdAt).toLocaleDateString("pt-br"),
      documentCount: contact.documentCount ?? "",
      mobilePhone: contact.mobilePhone,
    })) || []
  );
};

export default contactsTableColumns;
