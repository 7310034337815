/** @jsxImportSource @emotion/react */
import React from "react";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { css } from "@emotion/react";
import { ColorNames } from "../../theme";

interface IconLabelProps {
  label: string;
  icon: IconNames;
  color?: ColorNames;
  padding?: string;
}

const getStyes = ({ padding }: { padding?: string }) => ({
  container: css`
    display: flex;
    align-items: center;
    gap: 5px;
    padding: ${padding};
  `,
});

const IconLabel: React.FC<IconLabelProps> = ({
  label,
  icon,
  color,
  padding,
}) => {
  const styles = getStyes({ padding });
  return (
    <div css={styles.container}>
      <Icon name={icon} color={color} />
      {label}
    </div>
  );
};

export default IconLabel;
