import { FC, useMemo } from "react";
import { AllianzSettings } from "../../../../../graphql/generated/types";
import { AllianzSettingsModelLabels } from "../../../../../shared/settings/allianz";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { formatPrice } from "../../../../../utils/formatNumber";
import DetailsSection from "../../../../../components/EditableSectionV2/components/DetailSection";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

export type AllianzSettingsData = AllianzSettings & { _id: string };

interface AllianzSettingsDetailsProps {
  data: AllianzSettingsData;
}

const AllianzSettingsDetails: FC<AllianzSettingsDetailsProps> = ({ data }) => {
  const quotationInfo = useMemo(
    () => [
      [
        {
          label: AllianzSettingsModelLabels["cultura"],
          value: data.cultura.label,
        },
        {
          label: AllianzSettingsModelLabels["culturaAnterior"],
          value: data.culturaAnterior.label,
        },
        {
          label: AllianzSettingsModelLabels["tipoProduto"],
          value: data.tipoProduto.label,
        },
        {
          label: AllianzSettingsModelLabels["coberturas"],
          value: data.coberturas.map((c) => c.label).join(", "),
        },
      ],
      [
        {
          label: AllianzSettingsModelLabels["percentualComissao"],
          value: `${data.percentualComissao}`,
        },
        {
          label: AllianzSettingsModelLabels["valorSaca"],
          value: formatPrice(data.valorSaca),
        },
        {
          label: AllianzSettingsModelLabels["custoEstimado"],
          value: formatPrice(data.custoEstimado),
        },
        {
          label: AllianzSettingsModelLabels["formaPagamento"],
          value: data.formaPagamento.label,
        },
        {
          label: AllianzSettingsModelLabels["quantidadeParcelas"],
          value: data.quantidadeParcelas,
        },
      ],
    ],
    [data]
  );
  const generalInfo = useMemo(
    () => [
      [
        {
          label: AllianzSettingsModelLabels["tecnicaPlantio"],
          value: data.tecnicaPlantio.label,
        },
        {
          label: AllianzSettingsModelLabels["nivelCobertura"],
          value: `${data.nivelCobertura}%`,
        },
        {
          label: AllianzSettingsModelLabels["texturaSolo"],
          value: data.texturaSolo.label,
        },
        {
          label: AllianzSettingsModelLabels["grupoCultivar"],
          value: data.grupoCultivar.label,
        },
      ],
      [
        {
          label: AllianzSettingsModelLabels["regulacaoSinistro"],
          value: data.regulacaoSinistro.label,
        },
        {
          label: AllianzSettingsModelLabels["custeioProvenienteCreditoRurual"],
          value: data.custeioProvenienteCreditoRurual.label,
        },
        {
          label: AllianzSettingsModelLabels["realizadoControleFitosantitario"],
          value: data.realizadoControleFitosantitario.label,
        },
        {
          label: AllianzSettingsModelLabels["franquia"],
          value: data.franquia?.label,
        },
        {
          label: AllianzSettingsModelLabels["percentualDesconto"],
          value: data.percentualDesconto,
        },
        {
          label: AllianzSettingsModelLabels["corte"],
          value: data.corte?.value,
        },
      ],
    ],
    [data]
  );

  return (
    <DetailsSection
      title="Configurações - Allianz"
      icon={<Icon name={IconNames.Config} />}
    >
      <DetailsSectionColumns columns={quotationInfo} />
      <DetailsSectionColumns columns={generalInfo} />
    </DetailsSection>
  );
};

export default AllianzSettingsDetails;
