/** @jsxImportSource @emotion/react */

import { FC } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";

import {
  Claim,
  GetCreateClaimOnFormResponseDocument,
} from "../../../../../../graphql/generated/types";
import Table from "../../../../../../components/Table";
import useDetailsColor from "../../../../../../hooks/useDetailsColor";
import ClaimComponent from "./components/Claim";
import { claimStatusLabels } from "../../../../../../shared/claims/claimStatus";
import { toast } from "react-toastify";
import FormWarning from "../../../../../../modules/Forms/components/FormWarning";
import { CSSObject } from "@emotion/react";
import { useFlags } from "../../../../../../hooks/useFlags";

const styles: CSSObject = {
  formWarningWrapper: {
    marginBottom: 16,
  },
};
import EditableSectionV2 from "../../../../../../components/EditableSectionV2";
import Icon from "../../../../../../components/Icons";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";

const ClaimList: FC = () => {
  const {
    documentData: { claims, _id, hasPendingClaimNotice },
    refetch,
  } = useDocumentDetails();

  const { color } = useDetailsColor();
  const { isAdmin } = useFlags();
  return (
    <EditableSectionV2
      title="Sinistros"
      icon={<Icon name={IconNames.Proposal} />}
      createPayload={{ documentId: _id! }}
      createQuery={GetCreateClaimOnFormResponseDocument}
      actions={[]}
      addText={isAdmin() ? "Abrir Sinistro" : "Avisar Sinistro"}
      onSubmit={(res) => {
        if (res.successMsg) {
          toast.success(res.successMsg);
        }
        refetch();
      }}
      useV2
    >
      {hasPendingClaimNotice && (
        <div css={styles.formWarningWrapper}>
          <FormWarning warning="Existem aviso(s) de sinistro pendentes para esse documento" />
        </div>
      )}
      <Table<Claim>
        data={claims || []}
        color={color}
        withBorder
        DetailsComponent={ClaimComponent}
        emptyText="Não há sinistros registrados."
        columns={[
          {
            label: "ID na Seguradora",
            key: "externalId",
          },
          {
            label: "Data de aviso na Seguradora",
            key: "noticeDate",
            special: "date",
          },
          {
            label: "Eventos",
            key: "events",
            special: "many2many",
          },
          {
            label: "Status",
            key: "status",
            options: claimStatusLabels,
          },
        ]}
      ></Table>
    </EditableSectionV2>
  );
};

export default ClaimList;
