/** @jsxImportSource @emotion/react */
import { FC } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Colors,
  TooltipItem,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CSSObject } from "@emotion/react";
import { theme } from "../../../theme";
import Typography from "../../Typography";
import { defaultBackgroundColors } from "../utils/colors";
import { generateLegendLabels, generateSliceLabel } from "../utils/labels";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(Colors);

interface DoughnutChartProps {
  chartId?: string;
  title: string;
  data: number[];
  labels: string[];
  sliceIds: string[];
  metricLabel: string;
  labelsObject?: Record<string, string>;
  formatCallback?: (value: number) => string;
  backgroundColor?: string[];
  onSliceClick?: (chart: string, slice: string) => void;
}

const styles: CSSObject = {
  wrapper: {
    border: "1px solid #e7e7e7",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
    borderRadius: 10,
    padding: "10px 15px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  title: {
    color: theme.colors.Black[80],
    textAlign: "left",
    fontSize: 20,
    width: "100%",
    fontWeight: "bold",
  },
  loadingMsg: {
    marginRight: 10,
  },
  fixedSizeStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
  },
};

export const DoughnutChart: FC<DoughnutChartProps> = ({
  title,
  metricLabel,
  data,
  labels,
  sliceIds,
  formatCallback,
  backgroundColor = defaultBackgroundColors,
  onSliceClick,
  chartId,
}) => {
  if (!data.length) {
    return null;
  }

  const chartData: ChartData<"doughnut", number[], string> = {
    labels,
    datasets: [
      {
        label: metricLabel,
        data,
        borderWidth: 1,
        backgroundColor,
      },
    ],
  };
  const options: ChartOptions<"doughnut"> = {
    responsive: false,
    cutout: "65%",
    layout: {
      padding: {
        left: 0, // Remove extra padding around the chart
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context: TooltipItem<"doughnut">) => {
            const dataset = context.dataset;
            const index = context.dataIndex;
            const rawData = dataset.data as number[];
            const total = rawData.reduce((acc, curr) => acc + curr, 0);
            const value = rawData[index];
            const labelName = context.label || "";

            return generateSliceLabel(
              labelName,
              value,
              total,
              true,
              formatCallback
            );
          },
        },
      },
      colors: {
        enabled: !backgroundColor,
        forceOverride: !backgroundColor,
      },
      legend: {
        position: "left",
        align: "end",
        maxWidth: 200,
        labels: {
          boxWidth: 12,
          padding: 4,
          font: {
            size: 11,
          },
          generateLabels: (chart) =>
            generateLegendLabels(chart, formatCallback),
        },
      },
    },
    onClick: (_, elements) => {
      if (elements.length > 0 && chartId && onSliceClick) {
        const dataIndex = elements[0].index;
        onSliceClick(chartId, sliceIds[dataIndex]);
      }
    },
    onHover: (event, elements) => {
      const target = event?.native?.target as HTMLCanvasElement;
      if (target) {
        target.style.cursor = elements.length > 0 ? "pointer" : "default";
      }
    },
  };
  return (
    <div css={styles.wrapper}>
      <Typography css={styles.title}>{title}</Typography>
      <div css={styles.fixedSizeStyles}>
        <Doughnut data={chartData} options={options} width="340" />
      </div>
    </div>
  );
};
