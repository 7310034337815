import { SortOption } from "../components/ResizableTable";
import { NullPartial } from "../shared/types";
import usePagination from "./usePagination";
import useResizeableTable, { TableColumn } from "./useResizeableTable";
import useTableActions from "./useTableActions";

const useTable = <C, D extends { _id: string }>(
  allColumns: TableColumn<C>[],
  initialSort: SortOption,
  tableId: string,
  data?: D[],
  fetchSheetData?: () => Promise<NullPartial<Record<string, any>>[]>
) => {
  const paginator = usePagination(50);
  const actions = useTableActions(
    [{ id: "checkbox", disableSort: true, width: 30 } as TableColumn<C>].concat(
      allColumns
    ),
    initialSort,
    data || [],
    tableId,
    fetchSheetData
  );
  const resize = useResizeableTable(actions.selectedColumns);

  return {
    paginator,
    actions,
    resize,
  };
};

export default useTable;
