import Typography from "../../components/Typography";
import { Link } from "react-router-dom";
import { LoginLocation } from "../../Locations";
import { DefaultPublicFormLayout } from "../../components/DefaultPublicFormLayout";
import { theme } from "../../theme";

const Register = () => {
  return (
    <DefaultPublicFormLayout
      formId="register"
      successMessage="Obrigado! Estamos analisando seu cadastro."
    >
      <Typography
        component="div"
        style={{
          fontWeight: theme.fontWeights.normal,
        }}
      >
        Já tem uma conta? <Link to={LoginLocation}>Faça login</Link>
      </Typography>
    </DefaultPublicFormLayout>
  );
};

export default Register;
