import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import useFormField from "../../hooks/useFormField";
import UploadInput from "../../../../components/Input/UploadInput";
import Typography from "../../../../components/Typography";
import { ColorNames } from "../../../../theme";
import DefaultFieldWrapper from "../utils/DefaultFieldWrapper";

interface FileFieldProps {
  field: FormFieldV2;
}

const FileField: FC<FileFieldProps> = ({ field }) => {
  const {
    name,
    label,
    value,
    fieldPath,
    accept,
    error,
    exampleUrl,
    placeholder,
  } = field;

  const { handleChange, handleFileChange } = useFormField(field);

  return (
    <DefaultFieldWrapper field={field}>
      <UploadInput
        label={placeholder || label}
        file={null}
        onDelete={() => handleChange(null)}
        fileName={value?.fileName || undefined}
        fileType={accept!}
        handleChange={(e) => handleFileChange(e.target.files![0])}
        name={name}
        id={fieldPath}
        exampleUrl={exampleUrl || undefined}
      />
      {error && (
        <Typography color={ColorNames.ERROR_RED} align="right" component="div">
          {error}
        </Typography>
      )}
    </DefaultFieldWrapper>
  );
};

export default FileField;
