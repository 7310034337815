/** @jsxImportSource @emotion/react */
import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { useUIStore } from "../../../hooks/useUIStore";
import NewDirectSimulation from "./NewDirectSimulation";
import Typography, {
  BaseFontSize,
  FontSizes,
} from "../../../components/Typography";
import logo from "../../../components/AuthLayout/assets/logos/logo.svg";
import CustomLink from "../../../components/CustomLink";
import { useParams } from "react-router-dom";
import { Hidden } from "react-grid-system";

const getCSSRules: CSSRulesResolver = (theme) => ({
  width: "100%",
  wrapper: {
    height: "100vh",
    display: "flex",
    padding: "30px",
    boxSizing: "border-box",
    backgroundColor: theme.colors.Grey[20],
    overflow: "hidden",
  },
  desktopLeftSide: {
    // The image stays pinned to the top
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "33%",
  },
  titleWrapper: {
    marginBottom: "20px",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "center",
  },
  mainContent: {
    marginTop: "-80px",
    // Takes remaining space and centers its children
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "80px",
  },
  bigHeadingLine: {
    display: "block",
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
  },
  underlinedGreen: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: -1,
      height: "3px",
      width: "100%",
      backgroundColor: "#BAD804",
    },
  },
  smallText: {
    textAlign: "center",
    maxWidth: "400px",
  },
  formContainer: {
    flex: 1,
    backgroundColor: theme.colors.White[100],
    borderRadius: "16px",
    padding: "20px",
    boxSizing: "border-box",
  },
  mobileWrapper: {
    backgroundColor: theme.colors.Grey[20],
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    gap: "10px",
    overflowY: "auto",
    padding: "20px",
    boxSizing: "border-box",
  },
  mobileFormContainer: {
    flex: 1,
    backgroundColor: theme.colors.White[100],
    borderRadius: "16px",
    padding: "10px",
    boxSizing: "border-box",
  },
});

const getContentForInsuranceType = (insuranceTypeSlug?: string) => {
  switch (insuranceTypeSlug) {
    case "agricola":
      return {
        prefix: "DA SUA",
        boldText: "LAVOURA",
        subtitle:
          " descontos na contratação de seguro rural com proteção contra riscos climáticos",
      };
    case "equipamentos":
      return { prefix: "DOS SEUS", boldText: "EQUIPAMENTOS" };
    case "pecuario":
      return { prefix: "DO SEU", boldText: "REBANHO" };
    case "florestas":
      return { prefix: "DAS SUAS", boldText: "FLORESTAS" };
    case "benfeitorias":
      return { prefix: "DAS SUAS", boldText: "BENFEITORIAS" };
    default:
      return { prefix: "", boldText: "AGRÍCOLA" };
  }
};

const DirectQuotations: FC = () => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { isSubmittedModalOpen, renderSubmittedModal } = useUIStore();

  const content = isSubmittedModalOpen ? (
    renderSubmittedModal()
  ) : (
    <NewDirectSimulation />
  );
  const { insuranceTypeSlug } = useParams();
  const { prefix, boldText, subtitle } =
    getContentForInsuranceType(insuranceTypeSlug);

  const getTitle = () => (
    <>
      <Typography css={styles.bigHeadingLine} size={2 * BaseFontSize}>
        FAÇA SEGURO
      </Typography>
      <Typography css={styles.bigHeadingLine} size={2 * BaseFontSize}>
        {prefix} <span css={styles.underlinedGreen}>{boldText}</span>
      </Typography>
    </>
  );

  const getLink = () => (
    <CustomLink to="/">
      <img src={logo} css={styles.logo} alt="Prisma Agro Logo" />
    </CustomLink>
  );

  const getSubtitle = () => (
    <Typography
      component="p"
      css={styles.smallText}
      size={FontSizes.ExtraLarge}
    >
      {subtitle}
    </Typography>
  );

  return (
    <>
      <Hidden xs sm md>
        <div css={styles.wrapper}>
          <div css={styles.desktopLeftSide}>
            <div css={styles.linkContainer}>{getLink()}</div>
            <div css={styles.mainContent}>
              <div css={styles.titleWrapper}>{getTitle()}</div>
              {getSubtitle()}
            </div>
          </div>
          <div css={styles.formContainer}>{content}</div>
        </div>
      </Hidden>
      <Hidden lg xl xxl xxxl>
        <div css={styles.mobileWrapper}>
          {getLink()}
          {getTitle()}
          {getSubtitle()}
          <div css={styles.mobileFormContainer}>{content}</div>
        </div>
      </Hidden>
    </>
  );
};

export default DirectQuotations;
