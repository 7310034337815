/** @jsxImportSource @emotion/react */
import React from "react";
import MenuButton from "./MenuButton";
import SubMenu from "./SubMenu";
import { useFlags } from "../../../hooks/useFlags";
import { MenuItem } from "../LeftMenu/LeftMenu";

interface NavigationProps {
  menuItems: MenuItem[];
  collapsed?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ collapsed, menuItems }) => {
  const { isFlagEnabled } = useFlags();

  return (
    <>
      {menuItems.map((item, index) => {
        // Respect feature flags, if specified
        if (item.flags && !item.flags.every((flag) => isFlagEnabled(flag))) {
          return null;
        }

        return (
          <React.Fragment key={`menuItem-${index}`}>
            <MenuButton collapsed={collapsed} menuItem={item} />
            {/* Only show submenus if not collapsed and if submenus exist */}
            {!collapsed && !!item.submenus?.length && (
              <SubMenu submenus={item.submenus} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Navigation;
