import { FC } from "react";
import ContactData from "../../../../components/Details/components/ContactData";
import {
  GetEditContactOnFormResponseQueryVariables,
  GetEditContactOnFormResponseDocument,
} from "../../../../graphql/generated/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { isOrg } from "../../utils/tags";
import OrgData from "../../../../components/Details/components/OrgData";
import EditableSectionV2 from "../../../../components/EditableSectionV2";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";

const ContactPersonalData: FC = () => {
  const { contactData, refetch } = useContactDetails();
  return (
    <EditableSectionV2<{}, GetEditContactOnFormResponseQueryVariables>
      title="Detalhes"
      icon={<Icon name={IconNames.Details} />}
      editQuery={GetEditContactOnFormResponseDocument}
      editPayload={{ contactId: contactData._id! }}
      objectId={contactData._id}
      onSubmit={refetch}
      useV2
    >
      {isOrg(contactData) ? (
        <OrgData org={contactData} />
      ) : (
        <ContactData contact={contactData!} />
      )}
    </EditableSectionV2>
  );
};

export default ContactPersonalData;
