/** @jsxImportSource @emotion/react */
import React from "react";
import { ViewFilterControl } from "../ViewFilterControl";
import SearchBar from "../SearchBar";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { TModelFilter } from "../../hooks/useModelFilter";

interface FilterProps {
  filters: TModelFilter;
  placeholder: string;
  filterDropdown: React.ReactNode;
}

const getCSSRules: CSSRulesResolver = ({ breakpoints }) => ({
  container: {
    width: "100%",
    paddingBottom: "40px",
    paddingRight: "12px",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    gap: 12,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      flexDirection: "column",
      padding: 0,
    },
  },
  control: {
    width: 400,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      width: "100%",
    },
  },
  search: {
    flexGrow: 1,
    maxWidth: 600,
  },
});

const Filter: React.FC<FilterProps> = ({
  filters,
  placeholder,
  filterDropdown,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);

  return (
    <div css={styles.container}>
      <div css={styles.control}>
        <ViewFilterControl placeholder={placeholder} filters={filters} />
      </div>
      <div css={styles.search}>
        <SearchBar filter={filters} dropdown={filterDropdown} />
      </div>
    </div>
  );
};

export default Filter;
