import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import {
  DistributionTotalParams,
  DocumentMetricsQuery,
  useDocumentMetricsQuery,
} from "../../graphql/generated/types";
import useChartData, { ChartData } from "./hooks/useChartData";
import { useFlags } from "../../hooks/useFlags";

interface MetricsContextType {
  distributionTotalParam: DistributionTotalParams;
  setDistributionTotalParam: (param: DistributionTotalParams) => void;
  loading: boolean;
  chartData: ChartData[];
  data?: DocumentMetricsQuery;
}

const MetricsContext = createContext<MetricsContextType | undefined>(undefined);

interface MetricsProviderProps extends PropsWithChildren {
  filter: TModelFilter;
}

export const MetricsProvider: React.FC<MetricsProviderProps> = ({
  children,
  filter,
}) => {
  const [distributionTotalParam, setDistributionTotalParam] =
    useState<DistributionTotalParams>(DistributionTotalParams.DocCount);

  const { isAdmin } = useFlags();

  const documentMetricsQuery = useDocumentMetricsQuery({
    variables: {
      filter: filter.composeQuery(),
      distributionTotalParam,
    },
  });

  const chartData = useChartData(
    filter,
    distributionTotalParam,
    isAdmin(),
    documentMetricsQuery.data?.documentMetrics.aggregations
  );

  const loading = documentMetricsQuery.loading;

  return (
    <MetricsContext.Provider
      value={{
        distributionTotalParam,
        setDistributionTotalParam,
        data: documentMetricsQuery.data,
        loading,
        chartData,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

export const useMetricsContext = (): MetricsContextType => {
  const context = useContext(MetricsContext);
  if (!context) {
    throw new Error("useMetrics must be used within a MetricsProvider");
  }
  return context;
};
