/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import logo from "./assets/logos/logo.svg";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { PublicSiteLocation } from "../../Locations";
import Loading from "../Loading";
import { useUIStore } from "../../hooks/useUIStore";
import { Hidden } from "react-grid-system";
import Footer from "../Layout/components/Footer";
import { CSSObject } from "@emotion/react";
import { tratorArando } from "../../s3/bgImages";
import { theme } from "../../theme";

const logoHeight = 80;
const padding = 25;

export const defaultBgImageStyle: CSSObject = {
  backgroundImage: `url(${tratorArando})`,
  backgroundSize: "cover",
  minHeight: "100%",
  width: "100%",
  [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
    minHeight: 250,
  },
};

const getCSSRules: CSSRulesResolver<AuthLayoutProps> = ({
  breakpoints,
  colors,
}) => ({
  container: {
    padding: padding,
    boxSizing: "border-box",
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: "100vh",
    },
  },
  containerRow: {
    height: "100%",
    boxSizing: "border-box",
    marginBottom: 0,
  },
  contentColumn: {
    boxSizing: "border-box",
    overflow: "hidden",
    height: "100%",
  },
  authArea: {
    padding: "0px 10px",
    overflow: "scroll" as "scroll",
    height: "80vh",
    "&:hover": {
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
      },
    },
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      height: `calc(100vh - ${logoHeight}px - ${2 * padding}px)`,
    },
  },
  logo: {
    cursor: "pointer",
    height: logoHeight,
  },
  rightSide: {
    borderRadius: 10,
    position: "relative",
    overflow: "hidden",
    backgroundColor: `${colors.White[100]}`,
    boxSizing: "border-box",
    height: "100%",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      height: `calc(100vh - ${logoHeight}px - ${2 * padding}px)`,
    },
  },
});

interface AuthLayoutProps {
  children: ReactNode;
  rightSideContent: ReactNode;
  isCoreOnRightSide?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = ({
  children,
  rightSideContent,
  isCoreOnRightSide,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { isLoading } = useUIStore();

  const navigateHome = () => {
    window.location.href = PublicSiteLocation;
  };

  return (
    <>
      <div css={styles.container}>
        <Row css={styles.containerRow}>
          <Column css={styles.contentColumn} xs={12} lg={5}>
            <img src={logo} css={styles.logo} onClick={navigateHome} />
            {isCoreOnRightSide ? (
              <Hidden xs sm md>
                <div css={styles.authArea}>{children}</div>
              </Hidden>
            ) : (
              <div css={styles.authArea}>{children}</div>
            )}
          </Column>
          <Column css={styles.contentColumn} xs={12} lg={7}>
            {isCoreOnRightSide ? (
              <div css={styles.rightSide}>
                {isLoading ? <Loading size="large" /> : rightSideContent}
              </div>
            ) : (
              <Hidden xs sm md>
                <div css={styles.rightSide}>
                  {isLoading ? <Loading size="large" /> : rightSideContent}
                </div>
              </Hidden>
            )}
          </Column>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default AuthLayout;
