import React, { useEffect, useState } from "react";
import FilterDropdown, {
  parseFilters,
} from "../../../../../components/SearchBar/FilterDropdown/FilterDropdown";
import { TModelFilter } from "../../../../../hooks/useModelFilter";
import ChipSelectInput from "../../../../../components/Input/SelectInputs/ChipSelectInput/ChipSelectInput";
import MultiSelectInput from "../../../../../components/Input/SelectInputs/MultiSelectInput";
import Many2ManyInput from "../../../../../components/Input/SelectInputs/Many2ManyInput";
import { DateFilter } from "../../../../../components/DateFilter";
import { DateDisplays, TFilter } from "../../../../../filters/types";
import { useFlags } from "../../../../../hooks/useFlags";

interface DocumentsFilterProps {
  filter: TModelFilter;
}

const DocumentsFilter: React.FC<DocumentsFilterProps> = ({ filter }) => {
  const { filters, setFilterValue } = filter;
  const [filterObj, setFilterObj] = useState(parseFilters(filters));

  const { isAdmin } = useFlags();

  useEffect(() => {
    setFilterObj(parseFilters(filters));
  }, [filters]);

  const getDetaulOptionsFilter = (optionFilter: TFilter) => (
    <ChipSelectInput
      containerId={optionFilter.name}
      title={optionFilter.label}
      values={optionFilter.value}
      options={optionFilter.options}
      onChange={(value) => setFilterValue(optionFilter.name, value)}
    />
  );

  const {
    insurance,
    stage,
    status,
    consultantStatus,
    state,
    culture,
    responsible,
    provider,
    claimStatus,
    harvestId,
    originator,
    policyEnd,
    createdAt,
    updatedAt,
    rejectReason,
    salesChannel,
  } = filterObj;
  return (
    <FilterDropdown filter={filter}>
      {getDetaulOptionsFilter(insurance)}
      {getDetaulOptionsFilter(stage)}
      {isAdmin() && getDetaulOptionsFilter(status)}
      {!isAdmin() && getDetaulOptionsFilter(consultantStatus)}
      <MultiSelectInput
        containerId={state.name}
        label={state.label}
        options={state.options}
        inputName={state.name}
        onSelect={(value) => setFilterValue(state.name, value)}
        value={state.value}
        multiValueDisplay="default"
        optionDisplay={state.selectOptionDisplay}
        placeholder="Todos"
        key={state.name}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
      <Many2ManyInput
        containerId={culture.name}
        label={culture.label}
        serviceName={culture.many2Many!.serviceName}
        domain={culture.many2Many?.domain}
        additionalOptions={culture.options}
        inputName={culture.name}
        onSelect={(value) => setFilterValue(culture.name, value)}
        value={culture.value}
        multiValueDisplay="default"
        optionDisplay={culture.selectOptionDisplay}
        placeholder="Todos"
        key={culture.name}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
      <Many2ManyInput
        containerId={responsible.name}
        label={responsible.label}
        serviceName={responsible.many2Many!.serviceName}
        domain={responsible.many2Many?.domain}
        additionalOptions={responsible.options}
        inputName={responsible.name}
        onSelect={(value) => setFilterValue(responsible.name, value)}
        value={responsible.value}
        multiValueDisplay="default"
        optionDisplay={responsible.selectOptionDisplay}
        placeholder="Todos"
        key={responsible.name}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
      {getDetaulOptionsFilter(provider)}
      {getDetaulOptionsFilter(claimStatus)}
      {getDetaulOptionsFilter(rejectReason)}
      {getDetaulOptionsFilter(salesChannel)}
      <Many2ManyInput
        containerId={harvestId.name}
        label={harvestId.label}
        serviceName={harvestId.many2Many!.serviceName}
        domain={harvestId.many2Many?.domain}
        additionalOptions={harvestId.options}
        inputName={harvestId.name}
        onSelect={(value) => setFilterValue(harvestId.name, value)}
        value={harvestId.value}
        multiValueDisplay="default"
        optionDisplay={harvestId.selectOptionDisplay}
        placeholder="Todos"
        key={harvestId.name}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
      <Many2ManyInput
        containerId={originator.name}
        label={originator.label}
        serviceName={originator.many2Many!.serviceName}
        domain={originator.many2Many?.domain}
        additionalOptions={originator.options}
        inputName={originator.name}
        onSelect={(value) => setFilterValue(originator.name, value)}
        value={originator.value}
        multiValueDisplay="default"
        optionDisplay={originator.selectOptionDisplay}
        placeholder="Todos"
        key={originator.name}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
      <DateFilter
        containerId={policyEnd.name}
        label={policyEnd.label}
        onChange={(value) => setFilterValue(policyEnd.name, value)}
        value={policyEnd.value}
        dateDisplay={DateDisplays.onlyDate}
      />
      <DateFilter
        containerId={createdAt.name}
        label={createdAt.label}
        onChange={(value) => setFilterValue(createdAt.name, value)}
        value={createdAt.value}
        dateDisplay={DateDisplays.onlyDate}
      />
      <DateFilter
        containerId={updatedAt.name}
        label={updatedAt.label}
        onChange={(value) => setFilterValue(updatedAt.name, value)}
        value={updatedAt.value}
        dateDisplay={DateDisplays.onlyDate}
      />
    </FilterDropdown>
  );
};

export default DocumentsFilter;
