import { PropostaProviders } from "../../../graphql/generated/types";
import Agrobrasil from "../assets/Agrobrasil";
import Allianz from "../assets/Allianz";
import Btg from "../assets/Btg";
import Essor from "../assets/Essor";
import Ezze from "../assets/Ezze";
import Excelsior from "../assets/Excelsior";
import Fairfax from "../assets/Fairfax";
import Kovr from "../assets/Kovr";
import Mapfre from "../assets/Mapfre";
import Porto from "../assets/Porto";
import Sancor from "../assets/Sancor";
import { Sombrero } from "../assets/Sombrero";
import Sompo from "../assets/Sompo";
import Swiss from "../assets/Swiss";
import Tokyo from "../assets/Tokyo";

export const LogoByName = {
  [PropostaProviders.Agrobrasil]: Agrobrasil,
  [PropostaProviders.Allianz]: Allianz,
  [PropostaProviders.Btg]: Btg,
  [PropostaProviders.Essor]: Essor,
  [PropostaProviders.Ezze]: Ezze,
  [PropostaProviders.Excelsior]: Excelsior,
  [PropostaProviders.Fairfax]: Fairfax,
  [PropostaProviders.Mapfre]: Mapfre,
  [PropostaProviders.Porto]: Porto,
  [PropostaProviders.Sancor]: Sancor,
  [PropostaProviders.Sompo]: Sompo,
  [PropostaProviders.Swiss]: Swiss,
  [PropostaProviders.Tokyo]: Tokyo,
  [PropostaProviders.Sombrero]: Sombrero,
  [PropostaProviders.Kovr]: Kovr,
};
