/** @jsxImportSource @emotion/react */

import { FC, useEffect, useMemo, useRef } from "react";
import Button from "../../../../components/Button";
import Column from "../../../../components/Grid/Column";
import Row from "../../../../components/Grid/Row";
import Typography from "../../../../components/Typography";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import {
  BasicCoverageTypes,
  ResultStatus,
} from "../../../../graphql/generated/types";
import { IconNames } from "../../../../components/Icons/styles/iconNames";

const ApprovedQuotation: FC = () => {
  const {
    documentData: { selectedSimulationResults, specialOperationId },
    redirectToQuotationFormUrl,
    isRestrictedData,
  } = useDocumentDetails();

  const approvedResult = useMemo(() => {
    return selectedSimulationResults?.find(
      (result) => result?.status === ResultStatus.Approved
    );
  }, [selectedSimulationResults]);

  const ref = useRef<HTMLDivElement>(null);

  // scroll to the form button when the user approves a quotation
  useEffect(() => {
    if (approvedResult) {
      ref.current?.scrollIntoView({
        block: "start",
      });
    }
  }, [approvedResult]);

  if (!approvedResult) return null;

  return (
    <div ref={ref}>
      <Row>
        <Column>
          <Typography variant="textLarge" component="p" align="left">
            Obrigado por aprovar a cotação!
          </Typography>
          {isRestrictedData && (
            <Typography variant="textLarge" component="p" align="left">
              Para darmos sequência na contratação, por favor acesse o
              formulário completo que acabamos de te enviar por whatsapp e
              email.
            </Typography>
          )}
          {!isRestrictedData &&
            (approvedResult.basicCoverageType ===
              BasicCoverageTypes.Parametrico || specialOperationId ? (
              <Typography variant="textLarge" component="p" align="left">
                Entraremos em contato para darmos sequência na contratação.
              </Typography>
            ) : (
              <Typography variant="textLarge" component="p" align="left">
                Preencha o <strong>formulário a seguir</strong> para darmos
                sequência na contratação:
              </Typography>
            ))}
        </Column>
      </Row>
      <Row>
        {approvedResult.basicCoverageType !== BasicCoverageTypes.Parametrico &&
          !specialOperationId &&
          !isRestrictedData && (
            <Column>
              <Button
                text="Preencher formulário"
                iconName={IconNames.Edit}
                width="auto"
                onClick={redirectToQuotationFormUrl}
              />
            </Column>
          )}
      </Row>
    </div>
  );
};

export default ApprovedQuotation;
