import { FC } from "react";
import EditableTable from "../../../../../components/EditableTable";
import useCultureDetails from "../../../hooks/useCulture";
import { GetUpsertHarvestFormResponseDocument } from "../../../../../graphql/generated/types";
import { HarvestDetails } from "./HarvestDetails";
import DetailsSection from "../../../../../components/EditableSectionV2/components/DetailSection";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

export const Harvests: FC<{}> = () => {
  const { culture, refetch } = useCultureDetails();
  return (
    <DetailsSection title="Safras" icon={<Icon name={IconNames.Agriculture} />}>
      <EditableTable
        withBorder
        formTitle="Criar safra"
        data={culture.harvests || []}
        createQuery={GetUpsertHarvestFormResponseDocument}
        createPayload={{ cultureId: culture._id }}
        formDisplay="modal"
        onSubmit={refetch}
        columns={[
          {
            label: "Nome",
            key: "name",
          },
          {
            label: "Ativo",
            key: "active",
            special: "boolean",
          },
        ]}
        useV2
        DetailsComponent={HarvestDetails}
      />
    </DetailsSection>
  );
};
