import React from "react";
import { toast } from "react-toastify";
import {
  Contact,
  UpsertComissionBankAccountFormResponseDocument,
} from "../../../../graphql/generated/types";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import EditableSectionV2 from "../../../../components/EditableSectionV2";
import { formatCpfCnpj } from "../../../../utils/formatNumber";

const ContactBankAccountData: React.FC<{
  contact: Contact;
  onSubmit?: () => void;
}> = ({ contact, onSubmit }) => {
  const commissionPaymentData = contact.commissionPaymentData || {};

  const handleSubmit = () => {
    toast.success("Dados salvos com sucesso!");
    onSubmit?.();
  };
  const columns = [
    [
      {
        label: "Nome",
        value: commissionPaymentData.name,
      },
      {
        label: "CPF / CNPJ",
        value:
          commissionPaymentData.cpfCnpj &&
          formatCpfCnpj(commissionPaymentData.cpfCnpj),
      },
      {
        label: "Banco",
        value: commissionPaymentData.bank,
      },
    ],
    [
      {
        label: "Agência",
        value: commissionPaymentData.agency,
      },
      {
        label: "Dígito da Agência",
        value: commissionPaymentData.agencyDigit,
      },
      {
        label: "Conta",
        value: commissionPaymentData.account,
      },
      {
        label: "Dígito da Conta",
        value: commissionPaymentData.accountDigit,
      },
    ],
  ];
  return (
    <EditableSectionV2
      title={"Dados de Pagamento"}
      objectId={commissionPaymentData._id}
      useV2
      onSubmit={handleSubmit}
      createQuery={UpsertComissionBankAccountFormResponseDocument}
      createPayload={{ contactId: contact._id }}
      editQuery={UpsertComissionBankAccountFormResponseDocument}
      editPayload={{ contactId: contact._id }}
      icon={<Icon name={IconNames.Money} />}
    >
      <DetailsSectionColumns columns={columns} />
    </EditableSectionV2>
  );
};

export default ContactBankAccountData;
