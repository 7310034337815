/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import ObjectListItemField from "./ObjectListItemField";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import useFormField from "../../hooks/useFormField";
import { ColorNames, theme } from "../../../../theme";
import { CSSObject } from "@emotion/react";
import Icon from "../../../../components/Icons";
import Typography from "../../../../components/Typography";

const styles: CSSObject = {
  clickable: {
    cursor: "pointer",
  },
  plusContainer: {
    border: "1px solid",
    borderRadius: "100%",
    overflow: "auto",
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    width: "30px",
    marginRight: "1.5em",
  },
  icons: {
    display: "flex",
    alignItems: "center",
  },
  buttons: {},
};

interface ObjectListFieldProps {
  field: FormFieldV2;
}

const ObjectListField: FC<ObjectListFieldProps> = ({ field }) => {
  const { fields, label, allowAdd, allowDelete, fieldPath, allowCopy, error } =
    field;
  const { addListItem, copyListItem } = useFormField(field);
  return (
    <Column xs={12} useYPaddings id={fieldPath}>
      {fields!.map((field) => (
        // Using fields.length as keys ensures that it doesnt get mix up after deleting an item
        <Row key={`${field.fieldPath}-${fields?.length}`} noMargins>
          <ObjectListItemField
            field={field}
            allowDelete={allowDelete}
            allowCopy={allowCopy}
            copyListItem={copyListItem}
          />
        </Row>
      ))}
      {error && (
        <Typography
          color={ColorNames.ERROR_RED}
          align="right"
          component="div"
          css={{ width: "100%" }}
        >
          {error}
        </Typography>
      )}
      {allowAdd && (
        <Row noMargins>
          <Column xs={12} lg={6}>
            <Row
              align="center"
              justify="start"
              css={styles.clickable}
              onClick={addListItem}
              noMargins
            >
              <div css={styles.plusContainer}>
                <Icon name={IconNames.Add} />
              </div>
              <Typography variant="textLarge">
                {`Adicionar ${label}`}
              </Typography>
            </Row>
          </Column>
        </Row>
      )}
    </Column>
  );
};

export default ObjectListField;
