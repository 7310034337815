import { DocumentStages } from "../graphql/generated/types";
import { ColorNames } from "../theme";

const documentColors: Record<DocumentStages, ColorNames> = {
  [DocumentStages.Simulation]: ColorNames.GREEN,
  [DocumentStages.Quotation]: ColorNames.GREEN,
  [DocumentStages.Proposal]: ColorNames.PINK,
  [DocumentStages.Policy]: ColorNames.BLUE,
};

export default documentColors;
