import { FC } from "react";
import TableProvider from "../../contexts/table";
import PaymentsList, {
  mapTableData,
  paymentsTableColumns,
} from "./PaymentsList";
import useModelFilter from "../../hooks/useModelFilter";
import useUrlFilter from "../../hooks/useUrlFilter";
import { paymentFilters } from "./paymentFilters";
import {
  Services,
  useListPaymentsLazyQuery,
} from "../../graphql/generated/types";
import { PaymentsWrapper } from "./PaymentsWrapper";

const Payments: FC = () => {
  const filter = useModelFilter(paymentFilters, Services.Payments, []);
  useUrlFilter(filter);

  const [listPayments] = useListPaymentsLazyQuery();

  const fetchSheetData = async () => {
    const result = await listPayments({
      variables: {
        filter: filter.composeQuery(),
      },
    });

    if (result.data?.payments) {
      return mapTableData(result.data.payments);
    }

    return [];
  };

  return (
    <PaymentsWrapper>
      <TableProvider
        allColumns={paymentsTableColumns}
        initialSort={{ id: "dateDue", sortKey: "dateDue", direction: -1 }}
        tableId="payments"
        fetchSheetData={fetchSheetData}
      >
        <PaymentsList filter={filter} />
      </TableProvider>
    </PaymentsWrapper>
  );
};

export default Payments;
