/** @jsxImportSource @emotion/react */

import React, { PropsWithChildren } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import Icon from "../../../../Icons";
import { IconNames } from "../../../../Icons/styles/iconNames";
import { BaseFontSize } from "../../../../Typography";
import { ColorNames } from "../../../../../theme";

interface ChipSelectOptionProps extends PropsWithChildren {
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const getStyles: CSSRulesResolver<
  Pick<ChipSelectOptionProps, "selected" | "disabled">
> = ({ colors, selected, disabled }) => ({
  container: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: 24,
    gap: 6,
    width: "fit-content",
    borderRadius: 12,
    border: `1px solid ${disabled ? colors.Grey[40] : colors.Grey[20]}`,
    paddingLeft: 2,
    paddingRight: 8,
    cursor: disabled ? "not-allowed" : "pointer",
    userSelect: "none",
    backgroundColor: disabled ? colors.Grey[20] : "transparent",
    opacity: disabled ? 0.6 : 1,
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: `1px solid ${disabled ? colors.Grey[40] : colors.Grey[20]}`,
    backgroundColor: selected
      ? disabled
        ? colors.Grey[40]
        : colors.Green[100]
      : disabled
      ? colors.Grey[20]
      : "white",
  },
});

const ChipSelectOption: React.FC<ChipSelectOptionProps> = ({
  children,
  selected,
  onClick,
  disabled,
}) => {
  const styles = useCSSRulesWithTheme(getStyles, { selected, disabled });
  return (
    <div css={styles.container} onClick={disabled ? undefined : onClick}>
      <div css={styles.checkbox}>
        <Icon
          name={IconNames.Check}
          size={BaseFontSize * 0.5}
          color={ColorNames.WHITE}
          pointer
        />
      </div>
      {children}
    </div>
  );
};

export default ChipSelectOption;
