/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { DocumentSummary } from "../sections/Summary/DocumentSummary";
import ScrollContainer from "../../../../components/ScrollContainer";
import { CSSObject } from "@emotion/react";
import { DetailsHeader } from "../../../../components/Details/components/Header/DetailsHeader";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import Icon from "../../../../components/Icons";
import Notes from "../../../../components/Notes";
import { Services } from "../../../../graphql/generated/types";
import { Conversation } from "../../../../components/WAChat/components/Conversation";
import { theme } from "../../../../theme";
import { DocumentTabs } from "./DocumentTabs";
import { useChatAndNotesActions } from "../../../../hooks/useChatAndNotesActions";

const styles: Record<string, CSSObject> = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    gap: "12px",
    marginBottom: 30,
    paddingBottom: 30,
  },
  actionsWrapper: {
    display: "flex",
    gap: "8px",
    padding: "8px 12px",
  },
  actionItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px 12px",
    borderRadius: theme.borderRadius.xxl,
    transition: "background-color 0.2s ease",
    ":hover": {
      backgroundColor: theme.colors.LightGrey[100],
    },
  },
  selectedActionItem: {
    backgroundColor: theme.colors.Grey[20],
    ".actionLabel": {
      opacity: 1,
      maxWidth: "10em",
      marginLeft: "6px",
    },
  },
  actionLabel: {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: 0, // start collapsed
    opacity: 0, // start transparent
    marginLeft: 0, // no gap until expanded
    transition: `
      max-width 0.2s ease,
      opacity 0.2s ease,
      margin-left 0.2s ease
    `,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
};

export const DocumentDetailsLayout: FC = () => {
  const { documentData } = useDocumentDetails();
  const { content, tabs, handleClick, setContent } = useChatAndNotesActions();
  return (
    <div css={styles.wrapper}>
      <DetailsHeader
        title={documentData.name}
        actions={
          <div css={styles.actionsWrapper}>
            {tabs.map((tab) => {
              const selected = tab.id === content;
              return (
                <div
                  key={tab.id}
                  onClick={() => handleClick(tab.id)}
                  css={[
                    styles.actionItem,
                    selected && styles.selectedActionItem,
                  ]}
                >
                  <Icon name={tab.icon} />
                  <span className="actionLabel" css={styles.actionLabel}>
                    {tab.label}
                  </span>
                </div>
              );
            })}
          </div>
        }
      />
      <ScrollContainer css={styles.content}>
        {content === "main" && (
          <>
            <DocumentSummary />
            <DocumentTabs />
          </>
        )}
        {content === "notes" && (
          <Notes
            serviceName={Services.Documents}
            onClose={() => setContent("main")}
          />
        )}
        {content === "chat" && (
          <Conversation
            contact={documentData.contact!}
            onClose={() => setContent("main")}
          />
        )}
      </ScrollContainer>
    </div>
  );
};
