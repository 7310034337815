import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import PolicyData from "./PolicyData";
import DetailsSection from "../../../../../components/EditableSectionV2/components/DetailSection";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

const ArchivedPolicies: FC = () => {
  const {
    documentData: { archivedPolicies },
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  return (
    <DetailsSection
      title="Apólices arquivadas"
      icon={<Icon name={IconNames.Archive} />}
    >
      <Table
        data={archivedPolicies || []}
        color={color}
        withBorder
        emptyText="Não há apólices arquivadas."
        columns={[
          {
            key: "externalId",
            label: "ID",
            textAlign: "center",
          },
          {
            key: "startDate",
            label: "Início da vigência",
            textAlign: "center",
            special: "date",
          },
          {
            key: "endDate",
            label: "Final da vigência",
            textAlign: "center",
            special: "date",
          },
        ]}
        DetailsComponent={(row) => <PolicyData policy={row.data} />}
      />
    </DetailsSection>
  );
};

export default ArchivedPolicies;
