import { FC } from "react";

const Community: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7761_23193)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9166 2.63199C16.5333 2.21993 17.2583 2 18 2C18.9946 2 19.9484 2.39509 20.6517 3.09835C21.3549 3.80161 21.75 4.75544 21.75 5.75C21.75 6.49168 21.5301 7.2167 21.118 7.83339C20.706 8.45007 20.1203 8.93072 19.4351 9.21455C18.7498 9.49838 17.9958 9.57264 17.2684 9.42795C16.541 9.28325 15.8728 8.9261 15.3484 8.40165C14.8239 7.8772 14.4668 7.20902 14.3221 6.48159C14.1774 5.75416 14.2516 5.00016 14.5355 4.31494C14.8193 3.62971 15.2999 3.04404 15.9166 2.63199ZM19.25 3.87919C18.88 3.63196 18.445 3.5 18 3.5C17.4033 3.5 16.831 3.73705 16.409 4.15901C15.9871 4.58097 15.75 5.15326 15.75 5.75C15.75 6.19501 15.882 6.63002 16.1292 7.00003C16.3764 7.37004 16.7278 7.65843 17.139 7.82873C17.5501 7.99903 18.0025 8.04358 18.439 7.95677C18.8754 7.86995 19.2763 7.65566 19.591 7.34099C19.9057 7.02632 20.12 6.62541 20.2068 6.18895C20.2936 5.7525 20.249 5.3001 20.0787 4.88896C19.9084 4.47783 19.62 4.12643 19.25 3.87919Z"
          fill="black"
        />
        <path
          d="M18 11H19.5C20.4942 11.0012 21.4474 11.3966 22.1504 12.0996C22.8534 12.8026 23.2488 13.7558 23.25 14.75V17.75H21.75V14.75C21.7493 14.1535 21.5121 13.5815 21.0903 13.1597C20.6685 12.7379 20.0965 12.5007 19.5 12.5H18V11Z"
          fill="black"
        />
        <path
          d="M17.25 23H15.75V21.5C15.7493 20.9035 15.5121 20.3315 15.0903 19.9097C14.6685 19.4879 14.0965 19.2507 13.5 19.25H10.5C9.90346 19.2507 9.33155 19.4879 8.90973 19.9097C8.48792 20.3315 8.25066 20.9035 8.25 21.5V23H6.75V21.5C6.75117 20.5058 7.14664 19.5527 7.84964 18.8496C8.55265 18.1466 9.5058 17.7512 10.5 17.75H13.5C14.4942 17.7512 15.4473 18.1466 16.1504 18.8496C16.8534 19.5527 17.2488 20.5058 17.25 21.5V23Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.91661 9.38199C10.5333 8.96993 11.2583 8.75 12 8.75C12.9946 8.75 13.9484 9.14509 14.6517 9.84835C15.3549 10.5516 15.75 11.5054 15.75 12.5C15.75 13.2417 15.5301 13.9667 15.118 14.5834C14.706 15.2001 14.1203 15.6807 13.4351 15.9645C12.7498 16.2484 11.9958 16.3226 11.2684 16.1779C10.541 16.0333 9.8728 15.6761 9.34835 15.1517C8.8239 14.6272 8.46675 13.959 8.32206 13.2316C8.17736 12.5042 8.25163 11.7502 8.53545 11.0649C8.81928 10.3797 9.29993 9.79404 9.91661 9.38199ZM13.25 10.6292C12.88 10.382 12.445 10.25 12 10.25C11.4033 10.25 10.831 10.4871 10.409 10.909C9.98705 11.331 9.75 11.9033 9.75 12.5C9.75 12.945 9.88196 13.38 10.1292 13.75C10.3764 14.12 10.7278 14.4084 11.139 14.5787C11.5501 14.749 12.0025 14.7936 12.439 14.7068C12.8754 14.62 13.2763 14.4057 13.591 14.091C13.9057 13.7763 14.12 13.3754 14.2068 12.939C14.2936 12.5025 14.249 12.0501 14.0787 11.639C13.9084 11.2278 13.62 10.8764 13.25 10.6292Z"
          fill="black"
        />
        <path
          d="M6 11H4.5C3.5058 11.0012 2.55265 11.3966 1.84964 12.0996C1.14664 12.8027 0.751171 13.7558 0.75 14.75V17.75H2.25V14.75C2.25066 14.1535 2.48792 13.5815 2.90973 13.1597C3.33155 12.7379 3.90346 12.5007 4.5 12.5H6V11Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.91661 2.63199C4.5333 2.21993 5.25832 2 6 2C6.99456 2 7.94839 2.39509 8.65165 3.09835C9.35491 3.80161 9.75 4.75544 9.75 5.75C9.75 6.49168 9.53007 7.2167 9.11801 7.83339C8.70596 8.45007 8.12029 8.93072 7.43506 9.21455C6.74984 9.49838 5.99584 9.57264 5.26841 9.42795C4.54098 9.28325 3.8728 8.9261 3.34835 8.40165C2.8239 7.8772 2.46675 7.20902 2.32206 6.48159C2.17736 5.75416 2.25162 5.00016 2.53545 4.31494C2.81928 3.62971 3.29993 3.04404 3.91661 2.63199ZM7.25004 3.87919C6.88002 3.63196 6.44501 3.5 6 3.5C5.40326 3.5 4.83097 3.73705 4.40901 4.15901C3.98705 4.58097 3.75 5.15326 3.75 5.75C3.75 6.19501 3.88196 6.63002 4.12919 7.00003C4.37643 7.37004 4.72783 7.65843 5.13896 7.82873C5.5501 7.99903 6.0025 8.04358 6.43896 7.95677C6.87541 7.86995 7.27632 7.65566 7.59099 7.34099C7.90566 7.02632 8.11995 6.62541 8.20677 6.18895C8.29359 5.7525 8.24903 5.3001 8.07873 4.88896C7.90843 4.47783 7.62005 4.12643 7.25004 3.87919Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7761_23193">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Community;
