import { DefaultPublicFormLayout } from "../../components/DefaultPublicFormLayout";
import Typography, { FontSizes } from "../../components/Typography";

export const OrgRegister = () => {
  return (
    <DefaultPublicFormLayout
      formId="orgRegister"
      successMessage="Formulário enviado com sucesso! Entraremos em contato em breve"
    >
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <Typography
          variant="textMedium"
          weight="bold"
          size={FontSizes.ExtraLarge}
        >
          Cadastro de organização
        </Typography>
      </div>
    </DefaultPublicFormLayout>
  );
};
