import { useLayoutEffect } from "react";
import ListView from "../../../components/ListView/ListView";
import { DocumentsTableData, mapTableData } from "./tableColumns";
import {
  DocumentRejectReason,
  ListDocumentsQuery,
  useBulkArchiveDocumentsMutation,
  useBulkRejectDocumentsMutation,
  useBulkSendDocumentsMutation,
  useBulkUpdateClaimResponsibleMutation,
  useBulkUpdateCommercialResponsibleMutation,
  useBulkUpdateDocumentsHarvestMutation,
  useBulkUpdateOperationalResponsibleMutation,
  useCountDocumentsQuery,
  useListDocumentsQuery,
} from "../../../graphql/generated/types";
import { useTableContext } from "../../../contexts/table";
import DocumentCard from "../DocumentCards/DocumentCard";
import { ToDocumentDetailsLocation } from "../../../Locations";
import UploadDocumentModal from "./components/UploadDocumentModal";
import { useModalStore } from "../../../components/Modal/context/ModalStore";
import UpdateResponsiblesModal from "./components/UpdateResponsiblesModal/UpdateResponsiblesModal";
import { MutationFunctionOptions } from "@apollo/client";
import RejectDocumentsModal from "./components/RejectDocumentsModal/RejectDocumentsModal";
import ConfirmedActionComponent from "../../../components/Modal/ConfirmedAction";
import SendDocumentsModal from "./components/SendDocumentsModal";
import { TModelFilter } from "../../../hooks/useModelFilter";
import { HarvestUpdateModal } from "./HarvestUpdateModal";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { toast } from "react-toastify";
import DocumentsTableHeader from "./components/DocumentsTableHeader/DocumentsTableHeader";
import { useFlags } from "../../../hooks/useFlags";

interface DocumentsTableProps {
  filter: TModelFilter;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  filter,
}: DocumentsTableProps) => {
  const { paginator, actions, setData } = useTableContext();
  const { openModal, closeModal } = useModalStore();
  const { withErrorHandler } = useErrorHandler();
  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");

  const queryFilters = filter.composeQuery();
  const documentsQuery = useListDocumentsQuery({
    variables: {
      filter: queryFilters,
      sort: { [actions.sort.sortKey]: actions.sort.direction },
      limit: paginator.limit,
      skip: paginator.skip,
    },
  });

  const countDocumentsQuery = useCountDocumentsQuery({
    variables: {
      filter: queryFilters,
    },
  });

  const selectedRowsIds = Object.keys(actions.selectedRows).filter(
    (key) => !!actions.selectedRows[key] && key !== "all"
  );

  useLayoutEffect(() => {
    if (documentsQuery.data?.documents) {
      setData(documentsQuery.data?.documents);
    }
  }, [documentsQuery]);

  const refetchData = async () => {
    await documentsQuery.refetch();
    await countDocumentsQuery.refetch();
  };

  const [bulkUpdateOperationalResponsible] =
    useBulkUpdateOperationalResponsibleMutation();

  const [bulkUpdateCommercialResponsible] =
    useBulkUpdateCommercialResponsibleMutation();

  const [bulkUpdateClaimResponsible] = useBulkUpdateClaimResponsibleMutation();

  const [bulkArchiveDocuments] = useBulkArchiveDocumentsMutation();

  const [bulkSendDocuments] = useBulkSendDocumentsMutation();

  const [bulkRejectDocuments] = useBulkRejectDocumentsMutation();

  const [bulkUpdateHarvest] = useBulkUpdateDocumentsHarvestMutation();

  const openResponsibleModal = <TData, TResponse>(
    label: string,
    mutation: (
      options: MutationFunctionOptions<
        TData,
        {
          responsibleId: string;
          documentIds: string | string[];
        }
      >
    ) => Promise<TResponse>
  ) => {
    openModal(
      <UpdateResponsiblesModal
        label={label}
        onClick={async (id: string) => {
          await mutation({
            variables: {
              documentIds: selectedRowsIds,
              responsibleId: id,
            },
          });
          await refetchData();
          closeModal();
        }}
      />,
      undefined,
      {
        styles: {
          container: {
            height: 250,
            width: 500,
            top: 0,
            left: 0,
            right: 0,
            margin: "auto",
          },
          content: {
            overflow: "visibile",
          },
        },
      }
    );
  };

  const openRejectDocumentsModal = () => {
    openModal(
      <RejectDocumentsModal
        onSubmit={async (rejectReason: DocumentRejectReason, other: string) => {
          await bulkRejectDocuments({
            variables: {
              documentIds: selectedRowsIds,
              rejectReason,
              other,
            },
          });
          await refetchData();
          toast.success("Documentos recusados com sucesso");
          closeModal();
        }}
      />,
      undefined,
      {
        styles: {
          container: {
            height: 350,
            width: 500,
            top: 0,
            left: 0,
            right: 0,
            margin: "auto",
          },
        },
      }
    );
  };

  const openConfirmationModal = (
    message: string,
    onClick: () => Promise<void>
  ) => {
    return openModal(
      <ConfirmedActionComponent
        message={message}
        actions={{
          onConfirm: [
            {
              text: "Sim",
              onClick,
            },
          ],
          onCancel: { onClick: async () => closeModal() },
        }}
      />
    );
  };

  const openSendDocumentsModal = () => {
    openModal(
      <SendDocumentsModal
        onClick={async (silent: boolean) => {
          await bulkSendDocuments({
            variables: {
              documentIds: selectedRowsIds,
              silent,
            },
          });
          await refetchData();
          closeModal();
        }}
      />,
      undefined,
      {
        styles: {
          container: {
            height: 250,
            width: 500,
            top: 0,
            left: 0,
            right: 0,
            margin: "auto",
          },
        },
      }
    );
  };

  const openUploadDocumentsModal = () => {
    openModal(<UploadDocumentModal refetch={refetchData} />, undefined, {
      styles: {
        container: {
          height: 430,
          width: 500,
          top: 0,
          left: 0,
          right: 0,
          margin: "auto",
        },
      },
    });
  };

  const openArchiveDocumentsModal = () => {
    openConfirmationModal(
      `Arquivar ${selectedRowsIds.length} documentos?`,
      async () => {
        await bulkArchiveDocuments({
          variables: {
            documentIds: selectedRowsIds,
          },
        });
        await refetchData();
        toast.success("Documentos arquivados com sucesso");
        closeModal();
      }
    );
  };

  const onSubmitHarvestUpdate = async (harvestId: string) => {
    await withErrorHandler(async () => {
      await bulkUpdateHarvest({
        variables: {
          documentIds: selectedRowsIds,
          harvestId,
        },
      });
      await refetchData();
      toast.success("Safra dos documentos atualizada com sucesso");
      closeModal();
    }, "Não foi possível atualizar a safra dos documentos")();
  };

  const openHarvestModal = () => {
    openModal(
      <HarvestUpdateModal onSubmit={onSubmitHarvestUpdate} />,
      undefined,
      {
        styles: {
          container: {
            height: 250,
            width: 500,
            top: 0,
            left: 0,
            right: 0,
            margin: "auto",
          },
          content: {
            overflow: "visible",
          },
        },
      }
    );
  };

  const adminOptions = [
    {
      label: "Recusar Cotações",
      onClick: openRejectDocumentsModal,
    },
    {
      label: "Arquivar Documentos",
      onClick: openArchiveDocumentsModal,
    },
    {
      label: "Enviar Cotação/Proposta",
      onClick: openSendDocumentsModal,
    },
    {
      label: "Alterar Safra",
      onClick: () => openHarvestModal(),
    },
    {
      label: "Importar Planílha",
      onClick: openUploadDocumentsModal,
    },
    {
      label: "Alterar Responsável",
      items: [
        {
          label: "Operacional",
          onClick: () =>
            openResponsibleModal(
              "OPERACIONAL",
              bulkUpdateOperationalResponsible
            ),
        },
        {
          label: "Comercial",
          onClick: () =>
            openResponsibleModal("COMERCIAL", bulkUpdateCommercialResponsible),
        },
        {
          label: "Sinistro",
          onClick: () =>
            openResponsibleModal("SINISTRO", bulkUpdateClaimResponsible),
        },
      ],
    },
  ];

  return (
    <ListView<DocumentsTableData, ListDocumentsQuery["documents"][number]>
      CardComponent={DocumentCard}
      loading={documentsQuery.loading}
      redirectLocation={ToDocumentDetailsLocation}
      mapTableData={mapTableData}
      total={countDocumentsQuery.data?.countDocuments || 0}
      uploadModal={<UploadDocumentModal refetch={refetchData} />}
      filter={filter}
      header={
        <DocumentsTableHeader
          filters={filter}
          nestedMenuOptions={isAdmin ? adminOptions : []}
        />
      }
    />
  );
};

export default DocumentsTable;
