import { FC } from "react";

const Ezze: FC = () => {
  return (
    <svg
      width="256"
      height="128"
      viewBox="0 0 256 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5589 64.5837C41.5589 72.1371 41.5589 79.4458 41.5589 87.0062C50.1469 87.0062 58.721 87.0062 67.4278 87.0062C67.4278 89.7151 67.4278 92.1794 67.4278 94.7729C56.2393 94.7729 45.0962 94.7729 33.8027 94.7729C33.8027 74.2972 33.8027 53.832 33.8027 33.2271C44.9459 33.2271 56.1449 33.2271 67.4523 33.2271C67.4523 35.7227 67.4523 38.1904 67.4523 40.8993C58.7874 40.8993 50.2028 40.8993 41.5379 40.8993C41.5379 46.2926 41.5379 51.4482 41.5379 56.7821C49.4478 56.7821 57.2809 56.7821 65.2747 56.7821C65.2747 59.4141 65.2747 61.8748 65.2747 64.5837C57.4137 64.5837 49.5387 64.5837 41.5589 64.5837Z"
        fill="black"
      />
      <path
        d="M108.805 40.9378C100.162 40.9378 91.8741 40.9378 83.4783 40.9378C83.4783 38.2534 83.4783 35.7857 83.4783 33.2131C96.792 33.2131 110.05 33.2131 123.633 33.2131C113.189 51.1512 102.825 68.9564 92.2795 87.0692C103.066 87.0692 113.458 87.0692 123.996 87.0692C123.996 89.7187 123.996 92.1829 123.996 94.7869C108.592 94.7869 93.1918 94.7869 77.4523 94.7869C87.8929 76.8594 98.2565 59.0541 108.805 40.9378Z"
        fill="black"
      />
      <path
        d="M178.146 33.2341C168.139 51.2036 158.257 68.9424 148.208 86.9923C158.464 86.9923 168.425 86.9923 178.534 86.9923C178.534 89.6872 178.534 92.1514 178.534 94.7659C163.787 94.7659 149.04 94.7659 133.996 94.7659C143.968 76.8558 153.867 59.0856 163.923 41.0252C155.765 41.0252 147.845 41.0252 139.788 41.0252C139.788 38.3163 139.788 35.8451 139.788 33.2341C152.462 33.2341 165.171 33.2341 178.146 33.2341Z"
        fill="black"
      />
      <path
        d="M196.349 40.8679C196.349 46.3311 196.349 51.4902 196.349 56.8346C204.277 56.8346 212.11 56.8346 220.051 56.8346C220.051 59.4875 220.051 61.9517 220.051 64.6082C212.141 64.6082 204.312 64.6082 196.367 64.6082C196.367 72.1581 196.367 79.5123 196.367 87.0587C204.983 87.0587 213.518 87.0587 222.173 87.0587C222.173 89.7187 222.173 92.1864 222.173 94.7764C210.97 94.7764 199.82 94.7764 188.534 94.7764C188.534 74.2938 188.534 53.8286 188.534 33.2236C199.712 33.2236 210.904 33.2236 222.197 33.2236C222.197 35.7367 222.197 38.2044 222.197 40.8644C213.574 40.8679 205.042 40.8679 196.349 40.8679Z"
        fill="black"
      />
    </svg>
  );
};

export default Ezze;
