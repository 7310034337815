/** @jsxImportSource @emotion/react */

import { useParams } from "react-router-dom";
import NotesContainer from "./components/NotesContainer";
import { FC } from "react";
import { NotesProvider } from "../../contexts/notes";
import { Services, useNotesQuery } from "../../graphql/generated/types";

interface NotesProps {
  serviceName: Services;
  drawerParamKey?: string;
  onClose?: () => void;
}

const Notes: FC<NotesProps> = ({
  serviceName,
  drawerParamKey = "docId",
  onClose,
}) => {
  const params = useParams();

  const docId = params[drawerParamKey]!;

  const { data, loading, refetch } = useNotesQuery({
    variables: {
      docId,
      serviceName,
    },
  });

  return (
    <NotesProvider
      documentId={docId}
      refetch={refetch}
      notes={data?.notes || []}
      loading={loading}
      serviceName={serviceName}
    >
      <NotesContainer onClose={onClose} />
    </NotesProvider>
  );
};

export default Notes;
