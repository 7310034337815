/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import Typography from "../../../../../components/Typography";
import { ColorNames } from "../../../../../theme";
import { css } from "@emotion/react";
import RadioInput from "../../../../../components/Input/RadioInput";
import { DocumentRejectReason } from "../../../../../graphql/generated/types";
import { documentRejectReasonLabels } from "../../../../../shared/documents/documentRejectReason";
import TextInput from "../../../../../components/Input/TextInput";
import Button from "../../../../../components/Button";

interface RejectDocumentsModalProps {
  onSubmit: (rejectReason: DocumentRejectReason, other: string) => void;
}

const getStyles = () => ({
  content: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
  `,
});

const documentReasonsList = [
  DocumentRejectReason.NotAvailable,
  DocumentRejectReason.OutOfCoveragePeriod,
  DocumentRejectReason.PriceTooHigh,
  DocumentRejectReason.ContactLost,
  DocumentRejectReason.Other,
];

const RejectDocumentsModal: React.FC<RejectDocumentsModalProps> = ({
  onSubmit,
}) => {
  const [reason, setReason] = useState<DocumentRejectReason | null>(null);
  const [other, setOther] = useState<string>("");
  const styles = getStyles();

  const handleSubmit = async () => {
    if (reason) {
      onSubmit(reason, other);
    }
  };

  return (
    <div>
      <Typography style={{ marginBottom: 30 }} variant="h2" margin="none">
        RECUSAR COTAÇÃO
      </Typography>
      <div css={styles.content}>
        <Typography color={ColorNames.GREEN} size={20} align="left">
          RAZÃO DA RECUSA:
        </Typography>
        <div>
          {documentReasonsList.map((documentReason) => {
            return (
              <RadioInput
                key={documentReason}
                name={documentReason}
                value={documentReason}
                checked={reason === documentReason}
                onChange={() => {
                  setReason(documentReason);
                }}
                label={documentRejectReasonLabels[documentReason]}
              />
            );
          })}
          {reason === DocumentRejectReason.Other ? (
            <TextInput
              value={other}
              placeholder="Razão"
              onChange={(e) => setOther(e.target.value)}
            />
          ) : null}
        </div>
        <Button disabled={!reason} text="Salvar" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default RejectDocumentsModal;
