/** @jsxImportSource @emotion/react */

import { useState } from "react";
import Many2OneInput from "../../../../components/Input/SelectInputs/Many2OneInput";
import { Services } from "../../../../graphql/generated/types";
import Button from "../../../../components/Button";
import { css } from "@emotion/react";
import Typography from "../../../../components/Typography";

type HarvestUpdateModalProps = {
  onSubmit: (harvestId: string) => void;
};
const getStyles = () => ({
  content: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    height: 100%;
  `,
});

export const HarvestUpdateModal: React.FC<HarvestUpdateModalProps> = ({
  onSubmit,
}) => {
  const [harvestId, setHarvestId] = useState<string | null>(null);
  const styles = getStyles();
  return (
    <div css={styles.content}>
      <Typography variant="h2" margin="none">
        ALTERAL SAFRA
      </Typography>
      <Many2OneInput
        onSelect={(option) => setHarvestId(option?.value)}
        inputName="harvest"
        value={harvestId}
        placeholder="Selecionar safra"
        serviceName={Services.Harvests}
        domain={{}}
        isClearable={true}
      />
      <Button
        disabled={!harvestId}
        text="Salvar"
        onClick={() => onSubmit(harvestId!)}
      />
    </div>
  );
};
