/** @jsxImportSource @emotion/react */
import { FC } from "react";
import DetailsRowValue from "../Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../Details/components/Main/Grid/DetailsRow";
import { CSSObject } from "@emotion/react";
import DetailsRowLabel from "../Details/components/Main/Grid/DetailsRowLabel";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: "6px",
    alignItems: "start",
  },
};

export const DetailsItem: FC<DetailsRowProps> = ({ label, ...rest }) => {
  return (
    <div css={styles.wrapper}>
      <DetailsRowLabel>{label}</DetailsRowLabel>
      <DetailsRowValue {...rest} />
    </div>
  );
};
