/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Row } from "react-grid-system";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import InsuranceInfo from "./components/InsuranceInfo";
import Selects from "./components/Selects";

const DocumentSummary: FC = () => {
  return (
    <DetailsSection title="" noTitle main>
      <Row>
        <InsuranceInfo />
        <Selects />
      </Row>
    </DetailsSection>
  );
};

export default DocumentSummary;
