/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { useParams } from "react-router-dom";

import WAChat from "../../../components/WAChat";
import { useFlags } from "../../../hooks/useFlags";
import AppLayout from "../../../components/AppLayout/AppLayout";
import RightMenu from "../../../components/Menu/RightMenu/RightMenu";

const ContactConversation: FC = () => {
  const { contactId } = useParams();
  const { isAdmin } = useFlags();
  if (!isAdmin()) {
    return null;
  }
  return (
    <AppLayout rightMenu={<RightMenu />}>
      <WAChat contactId={contactId!} showChatList />
    </AppLayout>
  );
};

export default ContactConversation;
