import { FC } from "react";

const Inspection: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="view" clipPath="url(#clip0_7667_2414)">
        <g id="Vector">
          <path
            d="M15 19.1429C15.8284 19.1429 16.5 18.5033 16.5 17.7143C16.5 16.9254 15.8284 16.2858 15 16.2858C14.1716 16.2858 13.5 16.9254 13.5 17.7143C13.5 18.5033 14.1716 19.1429 15 19.1429Z"
            fill="black"
          />
          <path
            d="M20.8327 17.3418C20.3692 16.2176 19.5695 15.2475 18.5327 14.5519C17.4959 13.8563 16.2676 13.4658 15 13.4287C13.7324 13.4658 12.5041 13.8563 11.4673 14.5519C10.4305 15.2475 9.63079 16.2176 9.16733 17.3418L9 17.7143L9.16733 18.0868C9.63079 19.2111 10.4305 20.1812 11.4673 20.8767C12.5041 21.5723 13.7324 21.9629 15 22C16.2676 21.9629 17.4959 21.5723 18.5327 20.8767C19.5695 20.1812 20.3692 19.2111 20.8327 18.0868L21 17.7143L20.8327 17.3418ZM15 20.5714C14.4067 20.5714 13.8266 20.4039 13.3333 20.0899C12.8399 19.776 12.4554 19.3298 12.2284 18.8077C12.0013 18.2856 11.9419 17.7112 12.0576 17.1569C12.1734 16.6027 12.4591 16.0936 12.8787 15.6941C13.2982 15.2945 13.8328 15.0224 14.4147 14.9121C14.9967 14.8019 15.5999 14.8585 16.1481 15.0747C16.6962 15.291 17.1648 15.6572 17.4944 16.127C17.8241 16.5969 18 17.1493 18 17.7143C17.9992 18.4719 17.6829 19.1982 17.1205 19.7338C16.558 20.2695 15.7954 20.5707 15 20.5714Z"
            fill="black"
          />
          <path
            d="M7.5 20.5714H4.5V3.4288H10.5V7.71446C10.5011 8.093 10.6596 8.45572 10.9406 8.72339C11.2217 8.99106 11.6025 9.14192 12 9.14301H16.5V12.0001H18V7.71446C18.0027 7.62058 17.984 7.52727 17.9451 7.44095C17.9063 7.35463 17.8482 7.27736 17.775 7.21447L12.525 2.21453C12.459 2.14476 12.3778 2.08948 12.2872 2.05248C12.1966 2.01548 12.0986 1.99766 12 2.00025H4.5C4.10253 2.00134 3.72166 2.1522 3.44061 2.41987C3.15955 2.68754 3.00115 3.05026 3 3.4288V20.5714C3.00115 20.95 3.15955 21.3127 3.44061 21.5804C3.72166 21.848 4.10253 21.9989 4.5 22H7.5V20.5714ZM12 3.71451L16.2 7.71446H12V3.71451Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7667_2414">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Inspection;
