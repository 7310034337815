import { FC } from "react";

const Attachments: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9997 20.5004C10.674 20.499 9.40306 19.9717 8.46565 19.0343C7.52824 18.0969 7.00097 16.8259 6.99951 15.5002V4.49968H8.9996V15.5002C8.9996 16.2959 9.31569 17.059 9.87832 17.6216C10.441 18.1842 11.2041 18.5003 11.9997 18.5003C12.7954 18.5003 13.5585 18.1842 14.1212 17.6216C14.6838 17.059 14.9999 16.2959 14.9999 15.5002V6.49977C14.9999 6.23454 14.8945 5.98018 14.707 5.79263C14.5194 5.60509 14.2651 5.49973 13.9998 5.49973C13.7346 5.49973 13.4802 5.60509 13.2927 5.79263C13.1051 5.98018 12.9998 6.23454 12.9998 6.49977V16.5002H10.9997V6.49977C10.9997 5.70409 11.3158 4.94099 11.8784 4.37835C12.4411 3.81572 13.2041 3.49963 13.9998 3.49963C14.7955 3.49963 15.5586 3.81572 16.1213 4.37835C16.6839 4.94099 17 5.70409 17 6.49977V15.5002C16.9985 16.8259 16.4712 18.0969 15.5338 19.0343C14.5964 19.9717 13.3254 20.499 11.9997 20.5004Z"
        fill="black"
      />
    </svg>
  );
};

export default Attachments;
