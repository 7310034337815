/** @jsxImportSource @emotion/react */
import { FC, ReactNode } from "react";
import Typography from "../../../../Typography";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import ArrowUpIcon from "../../../../Icons/arrowUpIcon";
import ArrowDownIcon from "../../../../Icons/arrowDownIcon";
import ExclamationIcon from "../../../../Icons/ExclamationIcon";
import OverflowMenu, { TOverflowMenuOptions } from "../../../../OverflowMenu";
import Icon from "../../../../Icons";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import { ColorNames } from "../../../../../theme";

interface DetailsSectionTitleProps {
  title: string;
  subtitle?: boolean;
  button?: ReactNode;
  sectionWrap?: boolean;
  sectionPending?: boolean;
  disabled?: boolean;
  showSectionContent?: boolean;
  onClick?: () => void;
  alwaysOpen?: boolean;
  options?: TOverflowMenuOptions[];
}

const getStyles: CSSRulesResolver<DetailsSectionTitleProps> = ({
  sectionWrap,
  disabled,
  alwaysOpen,
}) => ({
  wrapper: {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: disabled
      ? " not-allowed"
      : sectionWrap && !alwaysOpen
      ? "pointer"
      : "auto",
    width: "100%",
  },
});

const DetailsSectionTitle: FC<DetailsSectionTitleProps> = ({
  title,
  subtitle,
  button,
  showSectionContent,
  sectionWrap,
  sectionPending,
  disabled,
  onClick,
  alwaysOpen,
  options,
}) => {
  const styles = useCSSRulesWithTheme(getStyles, {
    title,
    subtitle,
    button,
    sectionWrap,
    disabled,
    alwaysOpen,
  });

  const handleClick = () =>
    sectionWrap && !disabled && !alwaysOpen && onClick ? onClick() : undefined;

  const { color } = useDetailsColor();

  return (
    <>
      <div css={styles.wrapper} onClick={handleClick}>
        <Typography
          variant={subtitle ? "h3" : "h2"}
          margin="none"
          color={ColorNames.BLACK}
          align="left"
          uppercase
        >
          {title} {sectionPending && <ExclamationIcon color="ErrorRed" />}
        </Typography>
        {options && options?.length > 1 && (
          <OverflowMenu hoverColor={color} options={options} />
        )}
        {options && options?.length === 1 && (
          <div css={{ position: "absolute", right: 0, height: "100%" }}>
            {options[0].iconName ? (
              <Icon
                name={options[0].iconName}
                color={color}
                onClick={options[0].onClick}
              />
            ) : (
              <span>{options[0].text}</span>
            )}
          </div>
        )}
        <>
          {sectionWrap && !alwaysOpen && (
            <>
              {showSectionContent && <ArrowUpIcon color={color} />}
              {!showSectionContent && <ArrowDownIcon color={color} />}
            </>
          )}
          {button && <span>{button}</span>}
        </>
      </div>
    </>
  );
};

export default DetailsSectionTitle;
