/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import TaskGroup from "./components/TaskGroup";
import SkeletonNotifications from "../Notifications/components/SkeletonNotifications";
import { useNavigate } from "react-router-dom";
import {
  Services,
  useModelTasksQuery,
  Task as ObjectTask,
} from "../../../../../graphql/generated/types";
import useGroupedChunk from "../../../../../hooks/useGroupedChunk";
import { getDaysInterval } from "../../../../../utils/getDaysInterval";

interface TasksProps {
  serviceName: Services;
  redirectLocation: (docId: string) => string;
}

const taskGroups: {
  label: string;
  filter: (task: ObjectTask) => boolean;
}[] = [
  {
    label: "Expiradas",
    filter: (task: ObjectTask) =>
      getDaysInterval(task?.dateDue, "task") < 0 &&
      getDaysInterval(task?.dateDue, "task") >= -30,
  },
  {
    label: "Próximas ao vencimento",
    filter: (task: ObjectTask) =>
      getDaysInterval(task?.dateDue, "task") > 0 &&
      getDaysInterval(task?.dateDue, "task") <= 3,
  },
  {
    label: "Hoje",
    filter: (task: ObjectTask) => getDaysInterval(task?.dateDue, "task") === 0,
  },
  {
    label: "Futuras",
    filter: (task: ObjectTask) => getDaysInterval(task?.dateDue, "task") > 3,
  },
  {
    label: "Expiradas há mais de 30 dias",
    filter: (task: ObjectTask) => getDaysInterval(task?.dateDue, "task") < -30,
  },
];

const Tasks: FC<TasksProps> = ({ serviceName, redirectLocation }) => {
  const navigate = useNavigate();

  const { data, loading } = useModelTasksQuery({
    variables: {
      serviceName,
    },
  });

  const { groupedChuncks } = useGroupedChunk<ObjectTask>(
    data?.modelTasks || [],
    taskGroups
  );

  const tasksByGroup = useMemo(
    () =>
      groupedChuncks.map((group) => ({
        label: group.label,
        tasks: group.list,
      })),
    [groupedChuncks, navigate, redirectLocation]
  );

  if (loading) return <SkeletonNotifications />;

  return (
    <>
      {tasksByGroup.map((tasks) => (
        <TaskGroup
          key={tasks.label}
          taskGroup={tasks}
          redirectLocation={redirectLocation}
        />
      ))}
    </>
  );
};

export default Tasks;
