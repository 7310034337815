/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { TwilioWaMessage } from "../../../graphql/generated/types";
import Typography from "../../Typography";
import Media from "./Media";
import { CSSObject } from "@emotion/react";
import Icon from "../../Icons";
import { IconSizes } from "../../Icons/styles/iconSizes";
import Tooltip from "../../Tooltip";
import { getStatusRepresentation } from "./utils/statusRepresentation";
import { theme } from "../../../theme";
import { formatDateWithTime } from "../../../utils/dateUtils";

const styles: CSSObject = {
  messageContainer: {
    display: "flex",
  },
  sentMessageContainer: {
    justifyContent: "flex-end",
  },
  receivedMessageContainer: {
    justifyContent: "flex-start",
  },
  messageBox: {
    backgroundColor: theme.colors.Green[20],
    padding: "8px 16px",
    borderRadius: theme.borderRadius.xl,
    marginBottom: "10px",
    maxWidth: "80%",
  },
  sentMessageBox: {
    textAlign: "right",
    backgroundColor: theme.colors.Green[20],
  },
  receivedMessageBox: {
    backgroundColor: theme.colors.LightGrey[100],
    textAlign: "left",
  },
  msgContent: {
    whiteSpace: "pre-wrap",
    textAlign: "left",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
};

const Message: FC<{
  message: TwilioWaMessage;
  getMediaUrl: (mediaId: string) => void;
}> = ({ message, getMediaUrl }) => {
  const statusRepresentation = getStatusRepresentation(message.status);
  return (
    <div
      css={[
        styles.messageContainer,
        message.type === "message"
          ? styles.sentMessageContainer
          : styles.receivedMessageContainer,
      ]}
    >
      <div
        css={[
          styles.messageBox,
          message.type === "message"
            ? styles.sentMessageBox
            : styles.receivedMessageBox,
        ]}
      >
        {message.content && (
          <Typography
            css={styles.msgContent}
            variant="textMedium"
            component="div"
            color="Black"
          >
            {message.content}
          </Typography>
        )}
        {!message.content && !message.medias?.length && (
          <Typography variant="textMedium" component="i" color="Black">
            Interação não reconhecida
          </Typography>
        )}
        {message.medias?.map(({ fileKey, fileName }) => (
          <Media
            key={fileKey}
            fileName={fileName || `Anexo`}
            getMediaUrl={() => getMediaUrl(fileKey)}
          />
        ))}
        {!!message.sentAt && (
          <Typography
            variant="textSmall"
            component="div"
            color="Grey"
            css={{ marginTop: "4px" }}
          >
            {formatDateWithTime(message.sentAt)}
          </Typography>
        )}
        {message.type === "message" && (
          <Tooltip
            id={message._id}
            text={statusRepresentation.tooltipText}
            position="left"
          >
            {statusRepresentation.icons.map((iconProps, i) => (
              <Icon {...iconProps} key={i} size={IconSizes.Small} />
            ))}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Message;
