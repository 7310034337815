import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import ProposalDetails from "./ProposalDetails";
import DetailsSection from "../../../../../components/EditableSectionV2/components/DetailSection";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

const ArchivedProposals: FC = () => {
  const {
    documentData: { archivedProposals },
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  return (
    <DetailsSection
      title="Propostas arquivadas"
      icon={<Icon name={IconNames.Archive} />}
    >
      <Table
        data={archivedProposals || []}
        color={color}
        withBorder
        emptyText="Não há propostas arquivadas."
        columns={[
          {
            key: "externalId",
            label: "ID",
            textAlign: "left",
          },
          {
            key: "provider",
            label: "Seguradora",
            textAlign: "left",
          },
          {
            key: "basicCoverageType",
            label: "Cobertura",
            textAlign: "left",
          },
        ]}
        DetailsComponent={({ data }) => (
          <ProposalDetails proposalResult={data} />
        )}
      />
    </DetailsSection>
  );
};

export default ArchivedProposals;
