/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Column from "../../../components/Grid/Column";
import Row from "../../../components/Grid/Row";
import { StepperAction } from "./FormContent";
import Button from "../../../components/Button";
import { IconNames } from "../../../components/Icons/styles/iconNames";

interface FormFooterProps {
  handleStepChange: (action: StepperAction) => void;
  handleFormSubmit: () => void;
  handleCancel?: () => void;
  shouldSubmit: boolean;
  sectionIsValid: boolean;
  singleSection?: boolean;
}

const styles = {
  btnRow: {
    padding: 8,
  },
  btnCol: {
    padding: 8,
  },
};
const FormFooter: FC<FormFooterProps> = ({
  handleStepChange,
  handleFormSubmit,
  handleCancel,
  shouldSubmit,
  sectionIsValid,
}) => {
  return (
    <Row css={styles.btnRow}>
      {handleCancel && (
        <Column xs={12} sm={6} css={styles.btnCol}>
          <Button
            text="Cancelar"
            iconName={IconNames.Close}
            onClick={handleCancel}
          />
        </Column>
      )}
      <Column xs={12} sm={handleCancel ? 6 : 12} css={styles.btnCol}>
        <Button
          text={shouldSubmit ? "Concluir" : "Enviar"}
          iconName={shouldSubmit ? IconNames.Valid : IconNames.ChevronRight}
          onClick={() =>
            shouldSubmit
              ? handleFormSubmit()
              : handleStepChange(StepperAction.NEXT)
          }
          asDisabled={!sectionIsValid}
        />
      </Column>
    </Row>
  );
};

export default FormFooter;
