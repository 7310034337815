import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/generated/types";
import { TInputMasks } from "../../models/formInterfaces";
import useFormField from "../../hooks/useFormField";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import DefaultFieldWrapper from "../utils/DefaultFieldWrapper";
import TextAreaInput from "../../../../components/Input/TextAreaInput";

interface TextAreaFieldProps {
  field: FormFieldV2;
}

const TextAreaField: FC<TextAreaFieldProps> = ({ field }) => {
  const { name, label, value, disabled, inputMask, type, placeholder } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { maskedValue, handleInputChange } = useDebouncedMaskedValue(
    value,
    editing,
    handleChange,
    inputMask as TInputMasks
  );

  return (
    <DefaultFieldWrapper field={field}>
      <TextAreaInput
        placeholder={placeholder || label.toLowerCase()}
        disabled={disabled!}
        type={type}
        name={name}
        value={maskedValue}
        onChange={(e) => handleInputChange(e.target.value)}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
      />
    </DefaultFieldWrapper>
  );
};

export default TextAreaField;
