import React from "react";
import Button from "../../Button";
import CustomLink from "../../CustomLink";
import Icon from "../../Icons";
import { MenuItem } from "../LeftMenu/LeftMenu";

interface MenuButtonProps {
  menuItem: MenuItem;
  collapsed?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({ menuItem, collapsed }) => {
  const renderButton = (item: MenuItem) => {
    const button = (
      <Button
        style="link"
        text={collapsed ? "" : item.title}
        align="left"
        selected={item.active}
        onClick={item.onClick}
        disabled={item.disabled}
        icon={item.icon ? <Icon name={item.icon} /> : undefined}
        iconBefore
      />
    );
    if (item.disabled || !item.location) {
      return button;
    } else {
      return (
        <CustomLink
          to={item.location}
          target={item.newTab ? "_blank" : undefined}
        >
          {button}
        </CustomLink>
      );
    }
  };
  return renderButton(menuItem);
};

export default MenuButton;
