/** @jsxImportSource @emotion/react */
import React from "react";
import Icon from "../../../../../../components/Icons";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import LoadingSpinner from "../../../../../../components/Loading/LoadingSpinner";
import { css } from "@emotion/react";
import Typography from "../../../../../../components/Typography";
import { ColorNames } from "../../../../../../theme";

export enum ProgressStatus {
  LOADING = "loading",
  DONE = "done",
  ERROR = "error",
}

export interface ProgressItemProps {
  status: ProgressStatus;
  label: string;
  error?: string;
}

const getStyles = () => ({
  container: css`
    display: flex;
    align-items: start;
    gap: 5px;
    text-align: left;
  `,
  icon: css`
    position: relative;
    width: 24px;
    height: 24px;
  `,
  spinner: css`
    position: absolute;
    width: 10px;
    height: 18px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  `,
});

const colorsMap = {
  loading: ColorNames.BLACK,
  done: ColorNames.GREEN,
  error: ColorNames.RED,
};

const ProgressItem: React.FC<ProgressItemProps> = ({
  label,
  status,
  error,
}) => {
  const styles = getStyles();
  const renderIcon = () => {
    switch (status) {
      case ProgressStatus.DONE:
        return <Icon name={IconNames.Valid} color={ColorNames.GREEN} />;
      case ProgressStatus.LOADING:
        return (
          <div css={styles.spinner}>
            <LoadingSpinner />
          </div>
        );
      case ProgressStatus.ERROR:
        return <Icon name={IconNames.Close} color={ColorNames.RED} />;
    }
  };

  return (
    <div css={styles.container}>
      <div css={styles.icon}>{renderIcon()}</div>
      <Typography
        color={colorsMap[status]}
        weight={status !== ProgressStatus.LOADING ? "bold" : undefined}
      >
        {label}
        {error && ` - Erro: ${error}`}
      </Typography>
    </div>
  );
};

export default ProgressItem;
