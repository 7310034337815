import {
  Contact,
  ContactTags,
  UserObjectFragment,
} from "../../../graphql/generated/types";

export const isDocumentRelatedContact = (tags: ContactTags[]) =>
  tags.some((tag) =>
    [
      ContactTags.Farmer,
      ContactTags.AllowedPerson,
      ContactTags.Beneficiary,
      ContactTags.PaymentResponsible,
    ].includes(tag)
  );

export const isOrg = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.Organization);
};

export const isConsultant = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.Consultant);
};

export const isOrgOrConsultant = (contact?: Contact) => {
  return isOrg(contact) || isConsultant(contact);
};

export const isFarmer = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.Farmer);
};

export const isOrgAdmin = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.OrgAdmin);
};

export const isOrgOwner = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.OrgOwner);
};

export const isOrgOwnerOrAdmin = (contact?: Contact) => {
  return isOrgAdmin(contact) || isOrgOwner(contact);
};

export const isOrgMember = (contact?: Contact) => {
  return !!contact?.tags?.includes(ContactTags.OrgMember);
};

export const isOrgUser = (contact?: Contact) => {
  return isOrgAdmin(contact) || isOrgMember(contact) || isOrgOwner(contact);
};

export const orgIsCompleted = (user?: UserObjectFragment) =>
  Boolean(user?.org?.cpfCnpj && user?.org?.address && user?.contact?.rg);
