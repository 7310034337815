/** @jsxImportSource @emotion/react */

import { FC } from "react";
import SkeletonAnimationContainer from "../../../../../Skeletons/components/SkeletonAnimationContainer";
import SkeletonMessage from "../../../../../Skeletons/components/SkeletonMessage";
import SkeletonTypography from "../../../../../Skeletons/components/SkeletonTypography";

const styles = {
  container: {
    position: "relative" as const,
    height: "100%",
    overflow: "hidden" as const,
  },
};

const SkeletonNotifications: FC = () => {
  const messages = Array(30).fill(<SkeletonMessage />);
  return (
    <div css={styles.container}>
      <SkeletonAnimationContainer />
      {messages.map((_, i) => (
        <div key={`skeleton-notification-${i}`}>
          <SkeletonTypography variant="textMedium" />
          <SkeletonTypography variant="textMedium" />
          <SkeletonTypography variant="textMedium" />
          <br />
        </div>
      ))}
    </div>
  );
};

export default SkeletonNotifications;
