import { useEffect, useState } from "react";
import {
  DistributionTotalParams,
  DocumentMetricAggregation,
  DocumentMetricsQuery,
  DocumentStages,
  MetricAggregation,
} from "../../../graphql/generated/types";
import { insuranceTypeGroupLabels } from "../../../shared/insurance/insuranceTypeGroup";
import { documentStatusLabels } from "../../../shared/documents/documentStatus";
import { documentStageLabels } from "../../../shared/documents/documentStage";
import { colorByStage } from "../utils/colors";
import {
  defaultFomarter,
  excludeEmpty,
  getTopN,
  replaceEmpty,
} from "../utils/formatter";
import { providerLabels } from "../../../shared/insurance/providers";
import { claimStatusLabels } from "../../../shared/claims/claimStatus";
import { documentRejectReasonLabels } from "../../../shared/documents/documentRejectReason";
import { useFlags } from "../../../hooks/useFlags";
import { TModelFilter } from "../../../hooks/useModelFilter";
import { formatPrice } from "../../../utils/formatNumber";
import { consultantDocumentStatusLabels } from "../../../shared/documents/consultantDocumentStatus";

interface ChartConfig {
  chartId: string;
  title: string;
  aggregations: MetricAggregation[];
  labelsObject?: Record<string, string>;
  backgroundColor?: string[];
  flag?: string;
}

export interface ChartData {
  chartId: string;
  data: number[];
  labels: string[];
  sliceIds: string[];
  title: string;
  metricLabel: string;
  backgroundColor?: string[];
  formatCallback?: (value: number) => string;
  onSliceClick: (chart: string, slice: string) => void;
}

const charts = (
  aggregations: DocumentMetricAggregation,
  isAdmin: boolean
): ChartConfig[] =>
  [
    {
      chartId: "insurance",
      title: "Tipo de seguro",
      aggregations: aggregations.insuranceTypeGroup,
      labelsObject: insuranceTypeGroupLabels,
    },
    aggregations?.stage.length > 1 && {
      chartId: "stage",
      title: "Etapa",
      aggregations: aggregations.stage,
      labelsObject: documentStageLabels,
      backgroundColor: aggregations.stage.map(
        (agg) => colorByStage[agg.value as any as DocumentStages]!
      ),
    },
    isAdmin
      ? {
          chartId: "status",
          title: "Status",
          aggregations: aggregations.status,
          labelsObject: documentStatusLabels,
        }
      : {
          chartId: "consultantStatus",
          title: "Status",
          aggregations: aggregations.consultantStatus,
          labelsObject: consultantDocumentStatusLabels,
        },
    {
      chartId: "provider",
      title: "Seguradora",
      aggregations: excludeEmpty(aggregations.provider),
      labelsObject: providerLabels,
    },
    {
      chartId: "culture",
      title: "Cultura",
      aggregations: excludeEmpty(aggregations.cultureId),
    },
    {
      chartId: "harvestId",
      title: "Safra",
      aggregations: excludeEmpty(aggregations.harvestId),
    },
    {
      chartId: "originator",
      title: "Parceiro",
      aggregations: getTopN(
        replaceEmpty(aggregations.originatorId, "Sem parceiro"),
        15
      ),
      flag: "admin-only",
    },
    {
      chartId: "state",
      title: "Estado",
      aggregations: aggregations.state,
    },
    {
      chartId: "claimStatus",
      title: "Sinistros",
      aggregations: excludeEmpty(aggregations.lastClaimStatus),
      labelsObject: claimStatusLabels,
    },
    {
      chartId: "rejectReason",
      title: "Razões de recusa",
      aggregations: excludeEmpty(aggregations.rejectReason),
      labelsObject: documentRejectReasonLabels,
    },
    {
      chartId: "salesChannel",
      title: "Canal de vendas",
      aggregations: excludeEmpty(aggregations.salesChannel),
    },
  ].filter(Boolean) as ChartConfig[];

const formatCallbacks: Partial<
  Record<DistributionTotalParams, (value: number) => string>
> = {
  [DistributionTotalParams.Premium]: formatPrice,
  [DistributionTotalParams.Area]: (value) => `${defaultFomarter(value)} ha(s)`,
  [DistributionTotalParams.ClaimsTotalPaid]: formatPrice,
};

// TODO: move to shared
const metricLabels: Record<DistributionTotalParams, string> = {
  [DistributionTotalParams.Area]: "Área",
  [DistributionTotalParams.Premium]: "Prêmio",
  [DistributionTotalParams.DocCount]: "Número de documentos",
  [DistributionTotalParams.ClaimsTotalPaid]: "Total indenizado",
  [DistributionTotalParams.ClaimCount]: "Número de sinistros",
};

const findFilterOptionValues = (
  filter: TModelFilter,
  aggregations: MetricAggregation[],
  filterName: string,
  filterId?: string
) => {
  const filters = filter.getFilter(filterName);
  return aggregations.map(({ value }) => {
    return (
      filters.options.find(
        (opt) => opt.filter[filterId || filterName] === value
      )?.value || ""
    );
  });
};

const getSliceIds = (
  chartId: string,
  filter: TModelFilter,
  aggregations: MetricAggregation[]
) => {
  switch (chartId) {
    case "insurance":
      return findFilterOptionValues(
        filter,
        aggregations,
        chartId,
        "insuranceTypeGroup"
      );
    case "stage":
    case "status":
    case "claimStatus":
      return findFilterOptionValues(filter, aggregations, chartId);

    case "originator":
      return aggregations.map(({ value }) =>
        value === "Sem parceiro" ? "nenhum" : value
      );
    case "rejectReason":
      return findFilterOptionValues(
        filter,
        aggregations,
        chartId,
        "rejectReason"
      );
    case "salesChannel":
      return findFilterOptionValues(
        filter,
        aggregations,
        chartId,
        "salesChannel"
      );
    default:
      return aggregations.map(({ value }) => value);
  }
};

const useChartData = (
  filter: TModelFilter,
  distributionTotalParam: DistributionTotalParams,
  isAdmin: boolean,
  data?: DocumentMetricsQuery["documentMetrics"]["aggregations"]
): ChartData[] => {
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const { isFlagEnabled } = useFlags();
  const metricLabel = metricLabels[distributionTotalParam];

  useEffect(() => {
    if (data) {
      const cData: Array<ChartData | null> = charts(data, isAdmin).map(
        ({
          chartId,
          title,
          aggregations,
          labelsObject,
          backgroundColor,
          flag,
        }) => {
          if (!!flag && !isFlagEnabled(flag)) {
            return null;
          }

          const values = aggregations.map((agg) => agg.total);
          const labels = aggregations.map(
            (agg) => agg.label || labelsObject?.[agg.value] || agg.value
          );
          const sliceIds = getSliceIds(chartId, filter, aggregations);

          return {
            chartId,
            data: values,
            labels,
            sliceIds,
            formatCallback: formatCallbacks[distributionTotalParam],
            title,
            metricLabel,
            backgroundColor,
            onSliceClick: (chart, slice) => {
              filter.setFilterValue(chart, [slice]);
            },
          };
        }
      );
      setChartData(cData.filter(Boolean) as ChartData[]);
    }
  }, [data, filter, distributionTotalParam, isFlagEnabled]);

  return chartData;
};

export default useChartData;
