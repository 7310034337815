import { useMemo } from "react";
import {
  DocumentStages,
  DocumentStatus,
} from "../../../../../../graphql/generated/types";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import { useUIStore } from "../../../../../../hooks/useUIStore";
import useConfirmedAction from "../../../../../../hooks/useConfirmedAction";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import useDocumentOrder from "../../../../../../hooks/useDocumentOrder";
import { useNavigate } from "react-router-dom";
import { ToDirectDocumentDetailsLocation } from "../../../../../../Locations";

const useQuotationFooter = () => {
  const { openSubmittedModal, closeSubmittedModal } = useUIStore();

  const {
    handleDownloadComparisonPdf,
    handleDownloadAllComparisonPdf,
    handleChangeDocumentStage,
    handleFinishQuotation,
    handleRedoQuotations,
    handleSendQuotation,
    handleResendQuotation,
    documentData: { _id, stage, status, selectedResultIds },
  } = useDocumentDetails();

  const navigate = useNavigate();

  const showSentQuotationsMessage = () => {
    openSubmittedModal("Cotações enviadas com sucesso!");
    const interval = setTimeout(() => {
      closeSubmittedModal();
    }, 3000);
    return () => clearInterval(interval);
  };

  const handleSendQuotations = useConfirmedAction(
    "Deseja realizar o envio da cotação?",
    {
      onConfirm: [
        {
          text: "Sim",
          onClick: async () => {
            await handleSendQuotation();
            showSentQuotationsMessage();
          },
        },
        {
          text: "Sim, sem notificar",
          onClick: () => handleSendQuotation(true),
        },
      ],
    }
  );

  const onResendQuotation = useConfirmedAction(
    "Deseja realizar o reenvio da cotação?",
    {
      onConfirm: [
        {
          text: "Sim",
          onClick: handleResendQuotation,
        },
      ],
    }
  );

  const currentStageStatus = useDocumentOrder(stage!, status!);
  const quotationPending = useDocumentOrder(
    DocumentStages.Simulation,
    DocumentStatus.Pending
  );
  const quotationDone = useDocumentOrder(
    DocumentStages.Simulation,
    DocumentStatus.Done
  );
  const quotationSent = useDocumentOrder(
    DocumentStages.Simulation,
    DocumentStatus.Sent
  );
  const policy = useDocumentOrder(DocumentStages.Policy);

  const { newStatus, newMessage } = useMemo(() => {
    let newStatus;
    let newMessage;
    if (currentStageStatus > quotationSent) {
      newStatus = DocumentStatus.Sent;
      newMessage = "COTAÇÃO ENVIADA";
    }
    if (currentStageStatus === quotationSent) {
      newStatus = DocumentStatus.Done;
      newMessage = "COTAÇÃO FINALIZADA";
    }
    if (currentStageStatus === quotationDone) {
      newStatus = DocumentStatus.Pending;
      newMessage = "COTAÇÃO PENDENTE";
    }
    return { newStatus, newMessage };
  }, [currentStageStatus, quotationSent, quotationDone]);

  const recedeDocument = useConfirmedAction(
    `Tem certeza? O documento retornará ao status de ${newMessage}.`,
    {
      onConfirm: [
        { onClick: async () => await handleChangeDocumentStage(newStatus!) },
      ],
    }
  );

  return useMemo(() => {
    const actions = [];
    if (currentStageStatus === quotationPending) {
      actions.push(
        {
          text: "Recalcular Cotações",
          onClick: handleRedoQuotations,
          iconName: IconNames.Redo,
          flag: "admin-only",
        },
        {
          text: "Finalizar cotação",
          disabled: !quotationPending,
          onClick: handleFinishQuotation,
          iconName: IconNames.Valid,
          flag: "admin-only",
        }
      );
    }
    if (currentStageStatus >= quotationDone && currentStageStatus < policy) {
      actions.push({
        text: "Editar",
        onClick: recedeDocument,
        iconName: IconNames.Edit,
        flag: "admin-only",
      });
    }
    if (currentStageStatus === quotationDone) {
      actions.push({
        text: "Enviar cotação",
        disabled: !selectedResultIds?.length,
        onClick: handleSendQuotations,
        iconName: IconNames.Sent,
        flag: "admin-only",
      });
    }
    if (currentStageStatus === quotationSent) {
      actions.push({
        text: "Reenviar cotação",
        onClick: onResendQuotation,
        iconName: IconNames.Sent,
      });
    }
    if (currentStageStatus >= quotationSent) {
      actions.push(
        {
          text: "Baixar enviadas",
          onClick: handleDownloadComparisonPdf,
          iconName: IconNames.Download,
        },
        {
          text: "Baixar todas",
          onClick: handleDownloadAllComparisonPdf,
          iconName: IconNames.Download,
        }
      );
      if (currentStageStatus < policy) {
        actions.push({
          text: "Ver apresentação",
          onClick: () => navigate(ToDirectDocumentDetailsLocation(_id!)),
          iconName: IconNames.Share,
        });
      }
    }
    return actions;
  }, [
    currentStageStatus,
    quotationPending,
    quotationDone,
    quotationSent,
    policy,
    selectedResultIds,
  ]);
};

export default useQuotationFooter;
