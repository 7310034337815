import { FC, useMemo } from "react";
import EditableTable from "../../../../../components/EditableTable";
import {
  GetCreateIntegrationSettingsFormResponseDocument,
  GetEditIntegrationSettingsFormResponseDocument,
} from "../../../../../graphql/generated/types";
import SwissSettingsDetails from "./Details";
import useCultureDetails from "../../../hooks/useCulture";
import DetailsSection from "../../../../../components/EditableSectionV2/components/DetailSection";
import Icon from "../../../../../components/Icons";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";

const SwissSettingsTable: FC = () => {
  const {
    culture: { swissSettings, _id: cultureId },
    refetch,
  } = useCultureDetails();

  const settings = useMemo(() => {
    return (swissSettings ?? []).map((item) => ({ ...item, ...item.swiss }));
  }, [swissSettings]);

  return (
    <DetailsSection
      title="Configurações - Swiss"
      icon={<Icon name={IconNames.Config} />}
    >
      <EditableTable
        withBorder
        data={settings}
        createQuery={GetCreateIntegrationSettingsFormResponseDocument}
        createPayload={{ formId: "swissSettings", cultureId }}
        editQuery={GetEditIntegrationSettingsFormResponseDocument}
        editPayload={{ formId: "swissSettings" }}
        onSubmit={refetch}
        DetailsComponent={SwissSettingsDetails}
        formDisplay="modal"
        useV2
        columns={[
          {
            label: "Cód. do Produto",
            key: "productCode",
          },
          {
            label: "Cód. do Sistema",
            key: "systemCode",
          },
          {
            label: "Cód. do Tipo de Solo",
            key: "soilTypeCode",
          },
          {
            label: "Valor da Saca",
            key: "valSaca",
            special: "monetary",
          },
          {
            label: "Risco/ha (R.N.)",
            key: "valRiscoNomeado",
            special: "monetary",
          },
          {
            label: "Data de Plantio",
            key: "plantDate",
            special: "date",
          },
        ]}
      />
    </DetailsSection>
  );
};

export default SwissSettingsTable;
