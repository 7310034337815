/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { theme } from "../../theme";

// Types for the generic switcher
export interface SwitcherOption {
  label: string;
  value: string;
}

export interface SwitcherProps {
  options: SwitcherOption[];
  active: string;
  onChange: (value: string) => void;
}

const getStyles = () => ({
  container: css`
    display: flex;
    padding: 4px;
    width: fit-content;
    border-radius: 16px;
    border: 1px solid ${theme.colors.Grey[20]};
    gap: 2px;

    @media (max-width: ${theme.breakpoints.sm}px) {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  `,
  button: css`
    font-size: 15px;
    padding: 6px 10px;
    border-radius: 12px;
    cursor: pointer;
    user-select: none;
    min-width: 40px;

    &.active {
      background-color: ${theme.colors.Grey[20]};
    }
  `,
});

export const Switcher: React.FC<SwitcherProps> = ({
  options,
  active,
  onChange,
}) => {
  const styles = getStyles();

  return (
    <div css={styles.container}>
      {options.map((option) => (
        <div
          key={option.value}
          css={styles.button}
          className={option.value === active ? "active" : undefined}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};
