import { FC } from "react";

const SignedProposal: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="policy" clipPath="url(#clip0_7758_26746)">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 23H9V21.5H4.5V3.5H16.5V11H18V3.5C17.9996 3.1023 17.8414 2.72101 17.5602 2.4398C17.279 2.15859 16.8977 2.00042 16.5 2H4.5C4.1023 2.00042 3.72101 2.15859 3.4398 2.4398C3.15859 2.72101 3.00042 3.1023 3 3.5V21.5C3.00042 21.8977 3.15859 22.279 3.4398 22.5602C3.72101 22.8414 4.1023 22.9996 4.5 23ZM6 5H15V6.5H6V5ZM6 8H15V9.5H6V8ZM10.5 11H6V12.5H10.5V11ZM6 18.5H9V20H6V18.5ZM22.2803 14.9697L20.0303 12.7197C19.9606 12.6501 19.878 12.5948 19.787 12.5571C19.696 12.5194 19.5985 12.5 19.5 12.5C19.4015 12.5 19.304 12.5194 19.213 12.5571C19.122 12.5948 19.0394 12.6501 18.9697 12.7197L12 19.6894V23H15.3106L22.2803 16.0302C22.3499 15.9606 22.4052 15.878 22.4429 15.787C22.4806 15.696 22.5 15.5985 22.5 15.5C22.5 15.4015 22.4806 15.304 22.4429 15.213C22.4052 15.122 22.3499 15.0394 22.2803 14.9697ZM14.6894 21.5H13.5V20.3106L17.25 16.5606L18.4394 17.75L14.6894 21.5ZM19.5 16.6894L18.3106 15.5L19.5 14.3106L20.6894 15.5L19.5 16.6894Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7758_26746">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignedProposal;
