import { FC } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import Table from "../../../../../components/Table";
import EndorsementComponent from "./components/Endorsement";
import { GetCreateEndorsementOnDocumentFormResponseDocument } from "../../../../../graphql/generated/types";
import { EndorsementStatusLabels } from "../../../../../shared/endorsements/endorsementStatus";
import { EndorsementReasonLabels } from "../../../../../shared/endorsements/endorsementReasons";
import EditableSectionV2 from "../../../../../components/EditableSectionV2";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import Icon from "../../../../../components/Icons";

const Endorsements: FC = () => {
  const {
    documentData: { _id, endorsements },
    refetch,
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  return (
    <EditableSectionV2
      title="Endossos"
      icon={<Icon name={IconNames.BlankDocument} />}
      useV2
      createPayload={{ documentId: _id! }}
      createQuery={GetCreateEndorsementOnDocumentFormResponseDocument}
      addFlag="admin-only"
      addText="Adicionar Endosso"
      onSubmit={refetch}
    >
      <Table
        data={endorsements || []}
        color={color}
        DetailsComponent={EndorsementComponent}
        withBorder
        emptyText="Não há endossos."
        columns={[
          { key: "externalId", label: "Número do endosso" },
          {
            key: "status",
            label: "Status",
            options: EndorsementStatusLabels,
          },
          {
            key: "reason",
            label: "Motivo do endosso",
            options: EndorsementReasonLabels,
          },
        ]}
      />
    </EditableSectionV2>
  );
};

export default Endorsements;
