/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import { ColorNames } from "../../../../theme";
import Avatar from "../../../Avatar";
import { IconSizes } from "../../../Icons/styles/iconSizes";
import UserMenuDropdown from "./UserMenuDropdown";

const getCSSRules: CSSRulesResolver = () => ({
  userMenu: {
    position: "relative",
  },
});

const UserMenu: React.FC = () => {
  const { user } = useAuth();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClickDropdown = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  return (
    <div>
      {user && (
        <div css={styles.userMenu}>
          <Avatar
            name={user.name!}
            size={IconSizes.Large}
            color={ColorNames.BLACK}
            filled
            onClick={handleClickDropdown}
          />
          {isMenuOpen && <UserMenuDropdown />}
        </div>
      )}
    </div>
  );
};

export default UserMenu;
