import React, { useEffect, useState } from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import FilterDropdown, {
  parseFilters,
} from "../../components/SearchBar/FilterDropdown/FilterDropdown";
import ChipSelectInput from "../../components/Input/SelectInputs/ChipSelectInput/ChipSelectInput";
import { DateFilter } from "../../components/DateFilter";
import { DateDisplays } from "../../filters/types";

interface PaymentListFilterProps {
  filter: TModelFilter;
}

const PaymentListFilter: React.FC<PaymentListFilterProps> = ({ filter }) => {
  const { filters, setFilterValue } = filter;
  const [filterObj, setFilterObj] = useState(parseFilters(filters));

  useEffect(() => {
    setFilterObj(parseFilters(filters));
  }, [filters]);

  const { status, dateDue } = filterObj;
  return (
    <FilterDropdown filter={filter}>
      <ChipSelectInput
        containerId={status.name}
        title={status.label}
        values={status.value}
        options={status.options}
        onChange={(value) => setFilterValue(status.name, value)}
      />
      <DateFilter
        containerId={dateDue.name}
        label={dateDue.label}
        onChange={(value) => setFilterValue(dateDue.name, value)}
        value={dateDue.value}
        dateDisplay={DateDisplays.onlyDate}
      />
    </FilterDropdown>
  );
};

export default PaymentListFilter;
