/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Row from "../../components/Grid/Row";
import Button from "../../components/Button";
import { IconNames } from "../../components/Icons/styles/iconNames";
import useForm from "./hooks/useForm.v2";
import { CSSObject } from "@emotion/react";
import Icon from "../../components/Icons";
import { BaseFontSize } from "../../components/Typography";
import { ColorNames } from "../../theme";

type FormFooterProps = {
  handleFormSubmit: () => void;
  disabled: boolean;
  submitText: string;
};

const styles: CSSObject = {
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px",
    gap: "12px",
  },
  buttons: {
    width: "fit-content",
    padding: "8px 20px",
    textAlign: "center",
  },
};

const FormFooter: FC<FormFooterProps> = ({
  handleFormSubmit,
  disabled,
  submitText,
}) => {
  const { onCancel } = useForm();
  return (
    <Row noMargins justify="center">
      <div css={styles.wrapper}>
        {onCancel && (
          <Button
            onClick={onCancel}
            css={styles.buttons}
            icon={<Icon name={IconNames.Close} size={BaseFontSize * 1.2} />}
            iconBefore
            text="Cancelar"
            noUpperCase
            style="cancelNew"
          />
        )}
        <Button
          onClick={handleFormSubmit}
          asDisabled={disabled}
          text={submitText}
          noUpperCase
          icon={
            <Icon
              name={IconNames.Send}
              color={ColorNames.GREEN}
              size={BaseFontSize * 1.2}
            />
          }
          iconBefore
          css={styles.buttons}
          style="action"
        />
      </div>
    </Row>
  );
};

export default FormFooter;
