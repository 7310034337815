/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Typography, { FontSizes } from "../../../../../../components/Typography";
import Row from "../../../../../../components/Grid/Row";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Button from "../../../../../../components/Button";
import { basicCoverageTypeLabels } from "../../../../../../shared/insurance/basicCoverageType";
import Icon from "../../../../../../components/Icons";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import { ColorNames, theme } from "../../../../../../theme";
import { css } from "@emotion/react";

const styles = {
  rowContainer: css({
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  }),
  textContainer: css({
    flex: 1, // allows the text to take up remaining space, pushing button to the right if needed
    display: "flex",
    alignItems: "center",
  }),
  errorCircleWrapper: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: theme.colors.ErrorRed[100],
    color: "white",
    fontSize: 20,
    marginLeft: 10,
    marginRight: 10,
  }),
  tryAgainButton: css({
    marginLeft: "auto", // pushes the button to the far right
    "&:hover": {
      "> span": {
        color: "white !important",
      },
    },
  }),
  title: css({
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  }),
};

const FailedQuotations: FC = () => {
  const {
    documentData: { failedSimulationResults },
    handleRetrySimulationResult,
  } = useDocumentDetails();

  if (!failedSimulationResults?.length) return null;

  const error = (
    <div css={styles.errorCircleWrapper}>
      <Icon name={IconNames.Close} size={12 as any} color={ColorNames.WHITE} />
    </div>
  );

  return (
    <div>
      <div css={styles.title}>
        {error}
        <Typography weight="bold" align="left" size={FontSizes.ExtraLarge}>
          Cotações com erro
        </Typography>
      </div>
      {failedSimulationResults.map((result, i) => (
        <Row
          key={`resultError-${i}`}
          noGutters
          justify="start"
          align="center"
          css={styles.rowContainer}
        >
          <div css={styles.textContainer}>
            <Typography
              variant="textMedium"
              component="span"
              align="left"
              uppercase
            >
              - {result!.provider}
              {result!.basicCoverageType
                ? ` - ${basicCoverageTypeLabels[result!.basicCoverageType]}`
                : ""}
              :{" "}
              <Typography
                variant="textMedium"
                component="span"
                color={ColorNames.ERROR_RED}
              >
                {result!.error}
              </Typography>
            </Typography>
            {error}
          </div>
          <Button
            color={ColorNames.ERROR_RED}
            text="Tentar novamente"
            width="auto"
            onClick={() => handleRetrySimulationResult(result!._id!)}
            css={styles.tryAgainButton}
          />
        </Row>
      ))}
    </div>
  );
};

export default FailedQuotations;
