/** @jsxImportSource @emotion/react */

import { FC } from "react";
import {
  ConversationsFilterInput,
  Exact,
  GetTwilioConversationsQuery,
  useGetOrCreateContactByPhoneMutation,
  useOnConversationUpdatedSubscription,
  useOnMsgSeenUpdatedSubscription,
} from "../../../graphql/generated/types";
import { ColorNames, theme } from "../../../theme";
import Typography from "../../Typography";
import CustomLink from "../../CustomLink";
import { useNavigate } from "react-router-dom";
import { containsOnlyNumbers, formatPhone } from "../../../utils/formatNumber";
import { useAuth } from "../../../hooks/useAuth";
import { QueryResult } from "@apollo/client";
import { CSSObject } from "@emotion/react";
import { ToContactConversationLocation } from "../../../Locations";
import { playNotificationSound } from "./utils/playNotificationSound";
import BasicSearch from "../../SearchBar/BasicSearch";
import ChatListPaginator from "./ChatListPaginator";
import ScrollContainer from "../../ScrollContainer";
import { Switcher } from "../../Switcher";
import { getConversationTimestamp } from "../../../utils/dateUtils";
import HR from "../../HorizontalLine";

const styles: CSSObject = {
  wrapper: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchBox: {
    margin: "10px 0",
    padding: 12,
    borderRadius: theme.borderRadius.xl,
    backgroundColor: theme.colors.LightGrey[100],
  },
  scrollArea: {
    flex: 1,
    minHeight: 0,
    overflow: "hidden",
  },
  scrollContainer: {
    padding: 0,
    overflow: "auto",
  },

  conversationCard: {
    borderBottom: `1px solid ${theme.colors.LightGrey[100]}`,
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px 4px",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:last-of-type": {
      borderBottom: "none",
    },
    position: "relative",
    borderRadius: theme.borderRadius.xl,
  },
  selectedConversationCard: {
    backgroundColor: theme.colors.LightGrey[100],
  },

  identificationAndTimestamp: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createContactButtonRow: {
    width: "100%",
    textAlign: "center",
  },
  createContactButton: {
    padding: "8px",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: theme.colors.Blue[60],
    },
  },
  msgContent: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: "4px",
  },
};

const ChatList: FC<{
  selectedContactId?: string;
  setOnlySubscribeds: (value: boolean) => Promise<void>;
  onlySubscribeds: boolean;
  query: QueryResult<
    GetTwilioConversationsQuery,
    Exact<{
      filter: ConversationsFilterInput;
    }>
  >;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
}> = ({
  selectedContactId,
  query,
  onlySubscribeds,
  setOnlySubscribeds,
  searchTerm,
  setSearchTerm,
  page,
  setPage,
  totalPages,
}) => {
  const { data, refetch } = query;
  const auth = useAuth();

  useOnConversationUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (subData?.conversationUpdated) {
        refetch();
        if (subData?.conversationUpdated?.newMsg) {
          playNotificationSound();
        }
      }
    },
  });

  useOnMsgSeenUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (subData?.msgSeenUpdated?.userId === auth.user._id) {
        refetch();
      }
    },
  });

  const navigate = useNavigate();

  const [getOrCreateContactByPhoneMutation] =
    useGetOrCreateContactByPhoneMutation();

  const getOrCreateContactByPhone = async () => {
    const contact = await getOrCreateContactByPhoneMutation({
      variables: {
        mobilePhone: searchTerm,
      },
    });

    navigate(
      ToContactConversationLocation(
        contact.data!.getOrCreateContactByPhone._id!
      )
    );
  };

  const filteredConversations = data?.getTwilioConversations.records || [];

  const shouldShowCreateButton =
    containsOnlyNumbers(searchTerm) &&
    filteredConversations.length === 0 &&
    [10, 11].includes(searchTerm.length);

  return (
    <div css={styles.wrapper}>
      <div css={styles.header}>
        <Typography weight="bold" variant="textLarge">
          Conversas
        </Typography>
        <Switcher
          options={[
            {
              label: "Todas",
              value: "all",
            },
            { label: "Seguindo", value: "subscribed" },
          ]}
          active={onlySubscribeds ? "subscribed" : "all"}
          onChange={(value) => setOnlySubscribeds(value === "subscribed")}
        />
      </div>
      <HR color={ColorNames.LIGHTGREY} borderWidth={2} spacingSize="small" />
      <div css={styles.searchBox}>
        <BasicSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>

      {shouldShowCreateButton && (
        <div css={styles.createContactButtonRow}>
          <Typography
            onClick={getOrCreateContactByPhone}
            css={styles.createContactButton}
          >
            Criar '{searchTerm}'
          </Typography>
        </div>
      )}

      <div css={styles.scrollArea}>
        <ScrollContainer css={styles.scrollContainer}>
          {filteredConversations.map((conv) => (
            <CustomLink
              to={ToContactConversationLocation(conv.contact._id!)}
              css={[
                styles.conversationCard,
                selectedContactId === conv.contact._id
                  ? styles.selectedConversationCard
                  : undefined,
              ]}
              key={conv.contact._id}
            >
              <div css={styles.identificationAndTimestamp}>
                <Typography
                  variant="textMedium"
                  color="Black"
                  weight="bold"
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    padding: "4px",
                  }}
                >
                  {conv.contact.name || formatPhone(conv.contact.mobilePhone!)}
                </Typography>
                <Typography variant="textMedium">
                  {getConversationTimestamp(conv.lastMsg.sentAt)}
                </Typography>
              </div>
              <Typography
                variant="textMedium"
                weight="light"
                css={styles.msgContent}
              >
                {conv.lastMsg.content ||
                  (conv.lastMsg.medias
                    ? "Midia enviada"
                    : "Mensagem template enviada")}
              </Typography>
            </CustomLink>
          ))}
        </ScrollContainer>
      </div>

      <ChatListPaginator
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default ChatList;
