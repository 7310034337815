import { FC } from "react";
import useCultureDetails from "../../../hooks/useCulture";
import { formatPrice } from "../../../../../utils/formatNumber";
import { GetEditCultureFormResponseDocument } from "../../../../../graphql/generated/types";
import { UnitSystemLabels } from "../../../../../shared/culture/unitSystem";
import { DetailsWrapper } from "../../../../../components/ModelDetails/DetailsWrapper";
import EditableSectionV2 from "../../../../../components/EditableSectionV2";
import { DetailsItem } from "../../../../../components/ModelDetails/DetailsItem";
import { Grid } from "../../../../../components/Grid/Grid";
import { formatDateWithTime } from "../../../../../utils/dateUtils";

const Summary: FC = () => {
  const { culture, refetch } = useCultureDetails();

  return (
    <DetailsWrapper>
      <EditableSectionV2
        title="DADOS GERAIS"
        editQuery={GetEditCultureFormResponseDocument}
        objectId={culture._id}
        onSubmit={() => refetch()}
        icon={null}
        useV2
      >
        <Grid>
          <DetailsItem label="Nome" value={culture.name!} />
          <DetailsItem
            label="Status"
            value={culture.active ? "Ativo" : "Inativo"}
          />
          <DetailsItem
            label="Valor de Mercado"
            value={
              culture.currentSackValue &&
              `${formatPrice(culture.currentSackValue)} / ${
                UnitSystemLabels[culture.unitOfMeasure!]
              }`
            }
          />
          <DetailsItem
            label="Data de Criação"
            value={formatDateWithTime(culture.createdAt)}
          />
          <DetailsItem
            label="Data de Atualização"
            value={formatDateWithTime(culture.updatedAt)}
          />
        </Grid>
      </EditableSectionV2>
    </DetailsWrapper>
  );
};

export default Summary;
