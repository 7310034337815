/** @jsxImportSource @emotion/react */
import { ToDocumentDetailsLocation } from "../../Locations";
import { Services } from "../../graphql/generated/types";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import AppLayout from "../../components/AppLayout/AppLayout";
import { FC, ReactNode } from "react";
import { useUIStore } from "../../hooks/useUIStore";
import RightMenu from "../../components/Menu/RightMenu/RightMenu";

export const DocumentsWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    isConfirmationModalOpen,
    renderConfirmationModal,
    isSubmittedModalOpen,
    renderSubmittedModal,
  } = useUIStore();

  return (
    <AppLayout
      rightMenu={
        <RightMenu
          docIdRedirectLocation={(docId) => ToDocumentDetailsLocation(docId)}
          serviceName={Services.Documents}
        />
      }
    >
      <ModalStoreProvider>
        {isConfirmationModalOpen && renderConfirmationModal()}
        {isSubmittedModalOpen && renderSubmittedModal()}
        {children}
      </ModalStoreProvider>
    </AppLayout>
  );
};
