/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TDefaultValues } from "../../../modules/Forms";
import {
  ToDocumentDetailsLocation,
  ToSimulationLocation,
} from "../../../Locations";
import { useAuth } from "../../../hooks/useAuth";
import { useCreateFormResponseV2Mutation } from "../../../graphql/generated/types";
import FormV2 from "../../../modules/Forms/form.v2";
import { CSSObject } from "@emotion/react";
import ScrollContainer from "../../../components/ScrollContainer";

const styles: CSSObject = {
  wrapper: {
    margin: 20,
    height: "calc(100% - 40px)",
  },
  limitWidth: {
    maxWidth: "1000px",
    margin: "auto",
    height: "100%",
  },
};

const NewSimulation = () => {
  const { formId, docId } = useParams();
  const authState = useAuth();
  const navigate = useNavigate();

  const [createFormResponseMutation] = useCreateFormResponseV2Mutation();
  const { user } = useAuth();

  useEffect(() => {
    if (formId && docId === "new" && authState.loaded) {
      asyncCreateFormResponse(formId);
    }
  }, [formId, docId, authState.loaded]);

  const asyncCreateFormResponse = async (formId: string) => {
    try {
      const { data } = await createFormResponseMutation({
        variables: {
          formId: formId!,
          metadata: {},
        },
      });
      navigate(ToSimulationLocation(data!.createFormResponseV2, formId), {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const onSubmit = async ({ documentId }: any) => {
    navigate(ToDocumentDetailsLocation(documentId));
  };

  const defaultValues: TDefaultValues = {};
  if (user && user._id) {
    defaultValues.userInfo = { originatorId: user._id };
  }

  return (
    <>
      {docId && formId && docId !== "new" && (
        <ScrollContainer css={styles.wrapper}>
          <div css={styles.limitWidth}>
            <FormV2
              formId={formId}
              formResponseId={docId}
              onSubmit={onSubmit}
              // defaultValues={defaultValues}
            />
          </div>
        </ScrollContainer>
      )}
    </>
  );
};

export default NewSimulation;
