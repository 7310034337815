import { useTableContext } from "../../../contexts/table";
import { SheetFormat } from "../../../hooks/useTableActions";
import NestedMenu, { NestedMenuProps } from "../../NestedMenu/NestedMenu";

export const TableMenu: React.FC<NestedMenuProps> = ({
  options,
  ...otherProps
}) => {
  const { actions } = useTableContext();
  const downloadData = actions.onDownloadSheetClick
    ? [
        {
          label: "Baixar Dados",
          items: [
            {
              label: "Excel",
              onClick: () => actions.onDownloadSheetClick!(SheetFormat.XLSX),
            },
            {
              label: "CSV",
              onClick: () => actions.onDownloadSheetClick!(SheetFormat.CSV),
            },
          ],
        },
      ]
    : [];
  const selectTableColumnsOptions = {
    // -1 because of the id column
    label: `Selecionar Colunas (${actions.selectedColumns.length - 1})`,
    items: [
      {
        label: "Selecionar Todas",
        keepOpen: true,
        onClick: () => actions.onTableColumnsSelect("all"),
      },
      ...actions.tableColumnsOptions.map((option) => ({
        label: option.label,
        keepOpen: true,
        onClick: () => actions.onTableColumnsSelect(option.value),
        selected: actions.selectedColumns
          .map(({ id }) => id)
          .includes(option.value),
      })),
    ],
  };

  return (
    <NestedMenu
      {...otherProps}
      options={[selectTableColumnsOptions, ...downloadData, ...options]}
    />
  );
};
