/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { FC, CSSProperties, PropsWithChildren } from "react";

interface GridProps extends PropsWithChildren {
  minChildWidth?: string;
  style?: CSSProperties;
  className?: string;
}

const getStyles = (minChildWidth: string): CSSObject => ({
  main: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(${minChildWidth}, 1fr))`,
    gap: "12px 80px",
    boxSizing: "border-box",
  },
});

export const Grid: FC<GridProps> = ({
  children,
  minChildWidth = "300px",
  ...rest
}) => {
  const styles = getStyles(minChildWidth);
  return (
    <div css={styles.main} {...rest}>
      {children}
    </div>
  );
};
