/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Services } from "../../../../graphql/generated/types";
import Many2OneInput, {
  Many2OneInputProps,
} from "../../../Input/SelectInputs/Many2OneInput";
import { ColorNames } from "../../../../theme";

interface ContactSelectProps
  extends Omit<Many2OneInputProps, "serviceName" | "optionDisplay" | "domain"> {
  domain?: any;
  isClearable?: boolean;
}

const ContactSelect: FC<ContactSelectProps> = (props) => {
  return (
    <Many2OneInput
      {...props}
      serviceName={Services.Contacts}
      optionDisplay="avatar"
      domain={props.domain || { name: { $ne: null } }}
      isClearable={props.isClearable}
      color={ColorNames.BLACK}
    />
  );
};

export default ContactSelect;
