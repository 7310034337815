/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC } from "react";
import { DetailsWrapper } from "../../ModelDetails/DetailsWrapper";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import LoadingSpinner from "../../Loading/LoadingSpinner";
import Message from "./Message";
import ScrollContainer from "../../ScrollContainer";
import ChatInput from "./ChatInput";
import { SubscriptionToggle } from "./SubscriptionToggle";
import { ContactLink } from "./ContactLink";

import {
  Contact,
  useOnConversationUpdatedSubscription,
} from "../../../graphql/generated/types";
import { useChat } from "../../../hooks/useChat"; // <--- Reuse the hook
import { useFlags } from "../../../hooks/useFlags";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  rightSide: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    alignItems: "center",
  },
  scrollContainer: {
    flex: 1,
    paddingLeft: 0,
  },
};

export const Conversation: FC<{
  onClose?: () => void;
  contact: Contact;
}> = ({ onClose, contact }) => {
  if (!contact?.mobilePhone) return null;
  const contactId = contact._id;
  const { isAdmin } = useFlags();
  const skipQueries = !isAdmin();

  useOnConversationUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (
        subData?.conversationUpdated?.conversationId ===
        chatData?.contact?.twilioConversationId
      ) {
        refetch();
      }
    },
    skip: skipQueries,
  });

  // Use our new hook
  const {
    message,
    setMessage,
    files,
    setFiles,
    sendingMessage,
    sendMessage,
    onFilesAccepted,
    fetchMediaUrl,
    toggleSubscription,
    refetch,
    data: chatData,
    loading,
  } = useChat({ contactId });

  const messages = chatData?.messages || [];

  const isSubscribed = !!chatData?.isSubscribed;

  return (
    <DetailsWrapper css={styles.wrapper}>
      {/* Header with contact name/link and close button */}
      <div css={styles.header}>
        <ContactLink contact={contact} />
        <div css={styles.rightSide}>
          <SubscriptionToggle
            isSubscribed={isSubscribed}
            onToggle={async () => {
              await toggleSubscription();
              await refetch();
            }}
          />
          {onClose && <Icon onClick={onClose} name={IconNames.Close} />}
        </div>
      </div>

      {/* Scrollable list of messages */}
      <ScrollContainer useAnchor css={styles.scrollContainer}>
        {loading && <LoadingSpinner />}
        {messages.map((msg) => (
          <Message message={msg} key={msg._id} getMediaUrl={fetchMediaUrl} />
        ))}
      </ScrollContainer>

      {/* Chat input with attachments */}
      <ChatInput
        message={message}
        setMessage={setMessage}
        sendMessage={async () => {
          await sendMessage();
          await refetch();
        }}
        loading={sendingMessage}
        onFilesAccepted={onFilesAccepted}
        files={files}
        setFiles={setFiles}
      />
    </DetailsWrapper>
  );
};
