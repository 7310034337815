/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import SectionTitle from "../SectionTitle";
import FormFieldSummary from "./FormFieldSummary";
import { FormSectionV2 } from "../../../../graphql/generated/types";
import { theme } from "../../../../theme";

type FormSectionSummaryProps = {
  section: FormSectionV2;
  handleEditSection: () => void;
};

const styles = {
  container: {
    boxShadow: theme.boxShadow.default,
    borderRadius: theme.borderRadius.lg,
    border: `1px solid ${theme.colors.Grey[20]}`,
    padding: "1rem 0",
    marginBottom: "1rem",
  },
};

const FormSectionSummary: FC<FormSectionSummaryProps> = ({
  section,
  handleEditSection,
}) => {
  return (
    <Container css={styles.container}>
      <Row noMargins>
        <Column useYPaddings xs={12}>
          <SectionTitle sectionData={section} handleEdit={handleEditSection} />
        </Column>
        <Column md={12} lg={9}>
          <Row noMargins>
            {section.fields.map((field) => (
              <FormFieldSummary key={field.fieldPath} field={field} />
            ))}
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

export default FormSectionSummary;
