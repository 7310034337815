import React, { useEffect, useMemo, useState } from "react";
import useObjectFormResponse from "../../../../../hooks/useObjectFormResponse";
import { GetCreatePaymentOnDocumentFormResponseDocument } from "../../../../../graphql/generated/types";
import FormApp from "../../../../../modules/Forms";

interface PaymentInstallmentModalProps {
  documentId: string;
  onSubmit: () => void;
}

const PaymentInstallmentModal: React.FC<PaymentInstallmentModalProps> = ({
  documentId,
  onSubmit,
}) => {
  const [formId, setFormId] = useState("");
  const [resId, setResId] = useState("");

  const createPayload = useMemo(() => ({ documentId }), [documentId]);

  const { handleNewObject } = useObjectFormResponse({
    createQuery: GetCreatePaymentOnDocumentFormResponseDocument,
    createPayload,
  });

  useEffect(() => {
    if (documentId) {
      handleNewObject().then((res) => {
        setResId(res.formResponseId);
        setFormId(res.formId);
      });
    }
  }, [handleNewObject, documentId]);

  const closeForm = () => {
    setFormId("");
    setResId("");
  };

  if (!formId || !resId) return null;

  return (
    <FormApp
      formId={formId}
      docId={resId}
      onSubmit={() => {
        closeForm();
        onSubmit();
      }}
      onCancel={closeForm}
    />
  );
};

export default PaymentInstallmentModal;
