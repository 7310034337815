/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import { FormFieldV2, Maybe } from "../../../../graphql/generated/types";
import ObjectField from "./ObjectField";
import Typography, { BaseFontSize } from "../../../../components/Typography";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import useFormField from "../../hooks/useFormField";
import { ColorNames, theme } from "../../../../theme";
import { CSSObject } from "@emotion/react";

const styles: Record<string, CSSObject> = {
  container: {
    boxShadow: theme.boxShadow.default,
    border: `1px solid ${theme.colors.Grey[20]}`,
    margin: "12px 0",
    borderRadius: theme.borderRadius.xl,
    padding: "8px",
    boxSizing: "border-box",
  },
  titleAndIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
  },
  labelWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "default", // so label area isn't clickable
  },
  bullet: {
    marginRight: 6,
  },
  label: {
    margin: 0,
  },
  icons: {
    display: "flex",
    alignItems: "center",
    // small spacing between icons
    "& > span, & > svg": {
      marginLeft: 8,
      cursor: "pointer",
    },
  },
  content: {
    padding: "8px",
  },
};

interface ObjectListItemFieldProps {
  field: FormFieldV2;
  allowDelete?: Maybe<boolean>;
  copyListItem: (itemIndex?: number) => Promise<void>;
  allowCopy?: Maybe<boolean>;
}

const ObjectListItemField: FC<ObjectListItemFieldProps> = ({
  field,
  allowDelete,
  allowCopy,
  copyListItem,
}) => {
  const { deleteListItem } = useFormField(field);
  const [show, setShow] = useState(true);

  return (
    <Column xs={12} css={styles.container}>
      <div css={styles.titleAndIcons}>
        <div css={styles.labelWrapper}>
          <Typography css={styles.label} variant="h3">
            {field.label}
          </Typography>
        </div>

        <div css={styles.icons}>
          <Icon
            name={show ? IconNames.ChevronUp : IconNames.ChevronDown}
            color={ColorNames.GREEN}
            size={BaseFontSize * 1.2}
            onClick={() => setShow(!show)}
          />
          {allowDelete && (
            <Icon
              name={IconNames.Delete}
              onClick={deleteListItem}
              color={ColorNames.GREEN}
              size={BaseFontSize * 1.2}
            />
          )}
          {allowCopy && (
            <Icon
              name={IconNames.Duplicate}
              onClick={() => copyListItem(parseInt(field.name))}
              color={ColorNames.GREEN}
              size={BaseFontSize * 1.2}
            />
          )}
        </div>
      </div>
      {show && (
        <Row css={styles.content}>
          <ObjectField field={field} />
        </Row>
      )}

      {/* The collapsible content */}
    </Column>
  );
};

export default ObjectListItemField;
