/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { useNotes } from "../../../hooks/useNotes";
import ScrollContainer from "../../ScrollContainer";
import Note from "./Note";
import NotesFooter from "./NotesFooter";
import { DetailsWrapper } from "../../ModelDetails/DetailsWrapper";
import { FC, Fragment } from "react";
import { ColorNames } from "../../../theme";
import HR from "../../HorizontalLine";
import Typography, { FontSizes } from "../../Typography";
import { IconNames } from "../../Icons/styles/iconNames";
import Icon from "../../Icons";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    paddingBottom: "20px",
  },
  scrollContainer: {
    flex: 1,
    paddingLeft: 0,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: "16px",
  },
};

const NotesContainer: FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const notes = useNotes();
  const { documentNotes } = notes;
  return (
    <DetailsWrapper css={styles.wrapper}>
      {onClose && (
        <Icon
          css={styles.closeButton}
          onClick={onClose}
          name={IconNames.Close}
        />
      )}
      <div css={styles.titleWrapper}>
        <Icon name={IconNames.Notes} />
        <Typography
          weight="bold"
          size={FontSizes.Large}
          align="left"
          component="div"
        >
          NOTAS
        </Typography>
      </div>
      <ScrollContainer useAnchor css={styles.scrollContainer}>
        {!documentNotes?.length && <div>Não há notas</div>}
        {documentNotes?.map((note, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <HR
                color={ColorNames.LIGHTGREY}
                borderWidth={2}
                spacingSize="none"
              />
            )}
            <Note {...note} />
          </Fragment>
        ))}
      </ScrollContainer>
      <NotesFooter />
    </DetailsWrapper>
  );
};

export default NotesContainer;
