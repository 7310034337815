import { FC, PropsWithChildren } from "react";
import Typography from "../../../../Typography";

const DetailsRowLabel: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      style={{
        fontWeight: "bold",
        textAlign: "left",
        width: "180px",
      }}
      component="div"
      align="left"
    >
      {children}
    </Typography>
  );
};

export default DetailsRowLabel;
