/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import FormLabel from "../../modules/Forms/components/FormLabel";
import { DateDisplays } from "../../filters/types";
import { valueToDates, DateOperator, datesToValue } from "./dateFilterUtils";
import Typography from "../Typography";
import { theme } from "../../theme";
import { formatDate } from "../../utils/dateUtils";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";

interface DateFilterProps {
  dateDisplay: DateDisplays;
  onChange: (value: string[]) => void;
  value: string[];
  label?: string;
  containerId?: string;
}

const dateFormat = "dd/MM/yyyy";

const styles = {
  wrapper: css`
    position: relative;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    flex-direction: column;
    gap: 4px;
  `,
  datePickerContainer: css`
    width: 100%;
    > div {
      width: 100%;
    }
  `,
  customInput: css`
    position: relative;
    height: 45px;
    width: 100%;
    border: 1px solid ${theme.colors.Grey[40]};
    border-radius: 15px;
    box-sizing: border-box;
    overflow: hidden;

    input {
      font-size: 16px;
      padding: 8px;
      font-family: inherit;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      border: none;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${theme.colors.Grey[80]};
      }
    }
  `,
  icon: css`
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    display: flex;
    margin-right: 8px;
    justify-content: center;
    align-items: center;

    &:before {
      content: " ";
      position: absolute;
      display: block;
      width: 1px;
      height: 45px;
      left: -8px;
      background-color: ${theme.colors.Grey[40]};
    }
  `,
};

const formatInputValue = (text: string) => {
  let number = text.replace(/\D+/g, "");
  if (number.length > 2) {
    number = `${number.slice(0, 2)}/${number.slice(2)}`;
  }

  if (number.length > 5) {
    number = `${number.slice(0, 5)}/${number.slice(5)}`;
  }
  return number.slice(0, 10);
};

const stringToDate = (dateStr: string): Date => {
  const [day, month, year] = dateStr.split("/").map(Number);
  return new Date(year, month - 1, day); // O mês no Date começa de 0 (Janeiro = 0)
};

export const DateFilter: FC<DateFilterProps> = ({
  value,
  onChange,
  dateDisplay,
  label,
  containerId,
}) => {
  const currentValues = valueToDates(value, dateDisplay);
  const [inputValues, setInputValues] = useState<Record<DateOperator, string>>({
    gte: "",
    lte: "",
  });

  const setDate = (type: DateOperator, newDateValue: Date | null) => {
    onChange(
      datesToValue(
        {
          ...currentValues,
          [type]: newDateValue,
        },
        dateDisplay
      )
    );
  };

  const onInputChange = (type: DateOperator, value: string) => {
    const newValue = formatInputValue(value);
    setInputValues({
      ...inputValues,
      [type]: newValue,
    });

    if (currentValues[type] && newValue.length < 10) {
      setDate(type, null);
    }
  };

  return (
    <div>
      {label && (
        <Typography
          width="100%"
          variant="h3"
          margin="none"
          size={22}
          align="left"
          weight="bold"
        >
          {label}
        </Typography>
      )}
      <div css={styles.wrapper}>
        {[
          {
            label: "De:",
            type: DateOperator.gte,
            placeholderText: "Selecione uma data de início",
          },
          {
            label: "Até:",
            type: DateOperator.lte,
            placeholderText: "Selecione uma data de fim",
          },
        ].map(({ label, type, placeholderText }) => (
          <div
            css={styles.datePickerContainer}
            id={`${containerId}-${type}`}
            key={`${containerId}-${type}`}
          >
            <FormLabel>{label}</FormLabel>
            <DatePicker
              customInput={(() => (
                <div css={styles.customInput}>
                  <input
                    placeholder={placeholderText}
                    value={
                      currentValues[type]
                        ? formatDate(currentValues[type])
                        : inputValues[type]
                    }
                    onChange={(e) => onInputChange(type, e.target.value)}
                  />
                  <div css={styles.icon}>
                    <Icon name={IconNames.Calendar} />
                  </div>
                </div>
              ))()}
              locale={ptBR}
              selected={currentValues[type] || null}
              placeholderText={placeholderText}
              onChange={(value, e) => {
                if (e?.target && Object.hasOwn(e?.target, "value")) {
                  const { value } = e.target as Record<any, any>;
                  if (value.length === 10) {
                    const nDate = stringToDate(value);
                    setDate(type, nDate);
                  }
                  return;
                }

                setDate(type, value as Date);
              }}
              dateFormat={dateFormat}
              showTimeInput={dateDisplay === DateDisplays.dateWithTime}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
