/** @jsxImportSource @emotion/react */
import React from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { NestedMenuOption } from "../../components/NestedMenu/NestedMenu";
import { useTableContext } from "../../contexts/table";
import { TableMenu } from "../../components/ResizableTable/components/TableMenu";
import Filter from "../../components/Filter/Filter";
import ContactsListFilter from "./ContactListFilter";

interface ContactListHeaderProps {
  filters: TModelFilter;
  nestedMenuOptions: NestedMenuOption[];
}

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: 12,
  },
  right: {
    display: "flex",
    gap: 12,
  },
});

const ContactListHeader: React.FC<ContactListHeaderProps> = ({
  filters,
  nestedMenuOptions,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { actions } = useTableContext();
  return (
    <div css={styles.container}>
      <Filter
        filters={filters}
        placeholder="Todos os Contatos"
        filterDropdown={<ContactsListFilter filter={filters} />}
      />
      {actions.activeView === "table" && !!nestedMenuOptions.length && (
        <div css={styles.right}>
          <TableMenu id="contacts-menu" options={nestedMenuOptions} />
        </div>
      )}
    </div>
  );
};

export default ContactListHeader;
