import { FC } from "react";
import FormFieldSummary from "./FormFieldSummary";
import { FormFieldV2 } from "../../../../graphql/generated/types";

type ObjectFieldSummaryProps = {
  field: FormFieldV2;
};

const ObjectFieldSummary: FC<ObjectFieldSummaryProps> = ({
  field: { fields },
}) => {
  return (
    <>
      {fields!.map((field) => (
        <FormFieldSummary field={field} key={field.fieldPath} />
      ))}
    </>
  );
};

export default ObjectFieldSummary;
