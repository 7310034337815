/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import { useNotes } from "../../../hooks/useNotes";
import MessageInput from "../../Input/MessageInput";
import { CSSObject } from "@emotion/react";
import { theme } from "../../../theme";

const styles: CSSObject = {
  messageWrapper: {
    backgroundColor: theme.colors.LightGrey[100],
    padding: "40px",
    borderRadius: theme.borderRadius.xxl,
  },
};

const NotesFooter: FC = () => {
  const [value, setNoteValue] = useState("");
  const { sendNote } = useNotes();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e.target.value);
  };

  const handleSend = () => {
    sendNote(value);
    setNoteValue("");
  };

  return (
    <>
      <div css={styles.messageWrapper}>
        <MessageInput
          value={value}
          placeholder="Adicionar nota neste documento"
          onChange={handleChange}
          onSend={handleSend}
        />
      </div>
    </>
  );
};

export default NotesFooter;
