/** @jsxImportSource @emotion/react */
import React from "react";
import { MenuItem } from "../LeftMenu/LeftMenu";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { theme } from "../../../theme";
import { useFlags } from "../../../hooks/useFlags";
import MenuButton from "./MenuButton";

interface SubMenuProps {
  submenus: MenuItem[];
}

const getCSSRules: CSSRulesResolver = () => ({
  submenu: {
    borderLeft: `1px solid ${theme.colors.Grey[40]}`,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginLeft: 6,
    paddingLeft: 6,
    marginTop: 4,
  },
});

const SubMenu: React.FC<SubMenuProps> = ({ submenus }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { isFlagEnabled } = useFlags();

  return (
    <div id="leftMenu-subMenu" css={styles.submenu}>
      {submenus.map((submenu, sIndex) => {
        // Respect feature flags in submenus
        if (
          submenu.flags &&
          !submenu.flags.every((flag) => isFlagEnabled(flag))
        ) {
          return null;
        }
        return (
          <span key={sIndex}>
            <MenuButton menuItem={submenu} />
          </span>
        );
      })}
    </div>
  );
};

export default SubMenu;
