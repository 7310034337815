/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { useCulturesQuery } from "../../../graphql/generated/types";
import Loading from "../../../components/Loading";
import CardList from "../../../components/CardList";
import CultureCard from "./Card";
import {
  CreateCultureLocation,
  toCultureDetailsLocation,
} from "../../../Locations";
import { CulturesWrapper } from "../Wrapper";
import Button from "../../../components/Button";
import CustomLink from "../../../components/CustomLink";
import { IconNames } from "../../../components/Icons/styles/iconNames";
import { CSSObject } from "@emotion/react";
import Content from "../../../components/Content/Content";

const styles: CSSObject = {
  buttonWraper: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 12,
    flexDirection: "row",
    marginBottom: 20,
  },
};

const CulturesList: FC = () => {
  const { data, loading } = useCulturesQuery();

  if (loading) {
    return <Loading size="extraLarge" />;
  }

  return (
    <CulturesWrapper>
      <Content
        header={
          <div css={styles.buttonWraper}>
            <CustomLink to={CreateCultureLocation}>
              <Button
                iconName={IconNames.Add}
                text="Nova cultura"
                width={190}
              />
            </CustomLink>
          </div>
        }
      >
        <CardList
          data={data?.cultures || []}
          redirectLocation={toCultureDetailsLocation}
          CardComponent={CultureCard}
          loading={loading}
        />
      </Content>
    </CulturesWrapper>
  );
};

export default CulturesList;
