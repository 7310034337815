/** @jsxImportSource @emotion/react */
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSSObject } from "@emotion/react";
import { theme } from "../../theme";
import Typography from "../Typography";
import CustomLink from "../CustomLink";
import { useLocation } from "react-router-dom";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { IconSizes } from "../Icons/styles/iconSizes";

export interface DetailsTab {
  title?: ReactNode;
  location: string;
  disabled?: boolean;
  icon?: IconNames;
}

interface TabsProps extends PropsWithChildren {
  tabs: DetailsTab[];
  tabMinWidth?: number;
}

interface StylesParams {
  tabsPosition: number;
  hasScroll: boolean;
  tabMinWidth: number;
}

const getStyles = ({
  tabsPosition,
  hasScroll,
  tabMinWidth,
}: StylesParams): CSSObject => ({
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: theme.borderRadius.xl,
    boxSizing: "border-box",
  },
  contentWrapper: {
    padding: "24px",
    flex: 1,
    width: "100%",
    boxSizing: "border-box",
    border: `1px solid ${theme.colors.LightGrey[100]}`,
    borderTop: "none",
    borderRadius: `0 0 ${theme.borderRadius.xl}px ${theme.borderRadius.xl}px`,
  },
  tabsWrapper: {
    width: "100%",
    height: "35px",
    position: "relative",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.colors.LightGrey[100],
    borderRadius: `${theme.borderRadius.xl}px ${theme.borderRadius.xl}px 0 0`,
  },
  tabsContainer: {
    position: "absolute",
    left: tabsPosition,
    top: 0,
    bottom: 0,
    display: "flex",
  },
  tab: {
    // alguma sugestao aqui @thiagoloddi?
    minWidth: tabMinWidth,
    padding: "0 6px",
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderBottom: `1px solid ${theme.colors.Grey[20]}`,
    cursor: "pointer",
    userSelect: "none",
    ":before": {
      content: '""',
      position: "absolute",
      right: "-1px",
      top: 4,
      bottom: 4,
      margin: "auto",
      width: "1px",
      background: theme.colors.Grey[20],
    },
  },
  activeTab: {
    backgroundColor: theme.colors.White[100],
    borderRadius: `${theme.borderRadius.md}px ${theme.borderRadius.md}px 0 0`,
    border: `1px solid ${theme.colors.Grey[20]}`,
    borderBottom: "none",
    "> span": {
      fontWeight: "bold",
    },
    "&:first-of-type": {
      borderTopLeftRadius: 0,
      borderLeft: "none",
    },
    "&:last-of-type": {
      borderTopRightRadius: hasScroll ? 0 : undefined,
      borderRight: hasScroll ? "none" : undefined,
    },
    ":before": {
      display: "none",
    },
  },
  iconTab: {
    minWidth: "50px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  rest: {
    flex: 1,
    borderBottom: `1px solid ${theme.colors.Grey[20]}`,
  },
  scroll: {
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    width: 30,
    backgroundColor: "white",
    border: `1px solid ${theme.colors.Grey[20]}`,
    borderTopLeftRadius: theme.borderRadius.xl,

    "&:last-of-type": {
      borderTopRightRadius: theme.borderRadius.xl,
      borderTopLeftRadius: 0,
    },
  },
  tabsViewport: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  disabled: {
    backgroundColor: "transparent",
  },
});

export const DetailsTabs: FC<TabsProps> = ({
  tabs,
  children,
  tabMinWidth = 140,
}) => {
  const location = useLocation();
  const tabsViewportRef = useRef<HTMLDivElement>(null);
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const [disableLeftScroll, setDisableLeftScroll] = useState(true);
  const [disableRightScroll, setDisableRightScroll] = useState(true);
  const [tabsPosition, setTabsPosition] = useState(0);
  const [tabsViewportWidth, setTabsViewportWidth] = useState(0);
  const [tabsContainerWidth, setTabsContainerWidth] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const styles = getStyles({ tabsPosition, hasScroll, tabMinWidth });

  useLayoutEffect(() => {
    const { current: viewportRef } = tabsViewportRef;
    const { current: containerRef } = tabsContainerRef;
    if (viewportRef && containerRef) {
      setTabsViewportWidth(viewportRef.clientWidth);
      setTabsContainerWidth(containerRef.clientWidth);
      setHasScroll(containerRef.clientWidth > viewportRef.clientWidth);

      // scroll to correct position if initial selected tab is not visible
      let width = 0;
      Array.from(containerRef.children).some((child) => {
        const rect = child.getBoundingClientRect();
        const parentRect = viewportRef.getBoundingClientRect();

        const isVisible =
          rect.left >= parentRect.left && rect.right <= parentRect.right;
        if (child.id === location.pathname && !isVisible) {
          const position = Math.max(
            -1 * width,
            -1 * (containerRef.clientWidth - viewportRef.clientWidth)
          );
          setTabsPosition(position);
          return true;
        }

        width += child.clientWidth;
        return false;
      });
    }
  }, [tabsViewportRef, tabsContainerRef]);

  useLayoutEffect(() => {
    setDisableLeftScroll(tabsPosition === 0);
    setDisableRightScroll(
      tabsPosition <= (tabsContainerWidth - tabsViewportWidth) * -1
    );
  }, [tabsPosition, tabsContainerWidth, tabsViewportWidth]);

  const onArrowClick = (direction: number) => {
    setTabsPosition(
      Math.min(
        Math.max(
          tabsPosition + direction * tabMinWidth,
          -1 * (tabsContainerWidth - tabsViewportWidth)
        ),
        0
      )
    );
  };

  return (
    <div css={styles.wrapper}>
      <div css={styles.tabsWrapper}>
        <div
          css={[styles.scroll, disableLeftScroll ? styles.disabled : undefined]}
          onClick={() => onArrowClick(1)}
        >
          <Icon pointer name={IconNames.ChevronLeft} size={IconSizes.Small} />
        </div>
        <div css={styles.tabsViewport} ref={tabsViewportRef}>
          <div css={styles.tabsContainer} ref={tabsContainerRef}>
            {tabs
              .filter((t) => !t.disabled)
              .map((tab, index) => (
                <CustomLink
                  id={tab.location}
                  to={tab.location}
                  key={index}
                  replace
                  css={[
                    styles.tab,
                    tab.icon && styles.iconTab,
                    tab.location === location.pathname
                      ? styles.activeTab
                      : undefined,
                  ]}
                >
                  {tab.icon ? (
                    <Icon id={tab.icon} pointer name={tab.icon} />
                  ) : (
                    <Typography>{tab.title}</Typography>
                  )}
                </CustomLink>
              ))}
            {/* placeholder to add border bottom to the rest of the space 
                when the tabs are smaller than the viewport */}
            {tabsViewportWidth > tabsContainerWidth ? (
              <div
                style={{
                  width: tabsViewportWidth - tabsContainerWidth,
                }}
                css={[styles.tab]}
              />
            ) : null}
          </div>
        </div>
        <div
          onClick={() => onArrowClick(-1)}
          id="placeholder"
          css={[
            styles.scroll,
            disableRightScroll ? styles.disabled : undefined,
          ]}
        >
          <Icon pointer name={IconNames.ChevronRight} size={IconSizes.Small} />
        </div>
      </div>
      <div css={styles.contentWrapper}>{children}</div>
    </div>
  );
};
