/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Services } from "../../../../graphql/generated/types";
import Many2OneInput from "../../../Input/SelectInputs/Many2OneInput";
import { SelectInputOptionInterface } from "../../../Input/SelectInputs/SelectInput";
import { ColorNames } from "../../../../theme";

interface UserSelectProps {
  domain: any;
  value: string;
  placeholder: string;
  onSelect: (option?: SelectInputOptionInterface) => void;
  inputName: string;
  isClearable?: boolean;
}

const UserSelect: FC<UserSelectProps> = (props) => {
  return (
    <Many2OneInput
      serviceName={Services.Users}
      optionDisplay="avatar"
      isClearable={props.isClearable}
      color={ColorNames.BLACK}
      {...props}
    />
  );
};

export default UserSelect;
