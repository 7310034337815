/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import React from "react";
import TotalCard from "./TotalCard";
import { DistributionTotalParams } from "../../../graphql/generated/types";
import { defaultFomarter } from "../utils/formatter";
import { formatPrice } from "../../../utils/formatNumber";
import { useMetricsContext } from "../MetricsContext";

const styles: CSSObject = {
  totalsWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
    gap: "30px",
    paddingBottom: "30px",
    padding: "auto",
  },
};

const Totals: React.FC = () => {
  const { data, distributionTotalParam, setDistributionTotalParam } =
    useMetricsContext();

  return (
    <div css={styles.totalsWrapper}>
      <TotalCard
        label="documentos"
        value={data?.documentMetrics.totals.docCount || 0}
        selected={distributionTotalParam === DistributionTotalParams.DocCount}
        onClick={() =>
          setDistributionTotalParam(DistributionTotalParams.DocCount)
        }
      />
      <TotalCard
        label="área segurada"
        value={`${defaultFomarter(
          data?.documentMetrics.totals.area || 0
        )} hectares`}
        selected={distributionTotalParam === DistributionTotalParams.Area}
        onClick={() => setDistributionTotalParam(DistributionTotalParams.Area)}
      />
      <TotalCard
        label="prêmio"
        value={formatPrice(data?.documentMetrics.totals.premium || 0)}
        selected={distributionTotalParam === DistributionTotalParams.Premium}
        onClick={() =>
          setDistributionTotalParam(DistributionTotalParams.Premium)
        }
      />
      <TotalCard
        label="total indenizado"
        value={formatPrice(data?.documentMetrics.totals.claimsTotalPaid || 0)}
        selected={
          distributionTotalParam === DistributionTotalParams.ClaimsTotalPaid
        }
        onClick={() =>
          setDistributionTotalParam(DistributionTotalParams.ClaimsTotalPaid)
        }
      />
      <TotalCard
        label="número de sinistros"
        value={data?.documentMetrics.totals.claimCount || 0}
        selected={distributionTotalParam === DistributionTotalParams.ClaimCount}
        onClick={() =>
          setDistributionTotalParam(DistributionTotalParams.ClaimCount)
        }
      />
    </div>
  );
};

export default Totals;
