import { FC } from "react";
import { MyProfileLayout } from "./components/MyProfileLayout";
import { Route, Routes } from "react-router-dom";
import { useProfileTabs } from "./useProfileTabs";

export const MyProfile: FC = () => {
  const tabs = useProfileTabs();
  return (
    <Routes>
      <Route element={<MyProfileLayout />}>
        {!tabs.length && <Route index element={<div />} />}
        {tabs.map((tab) => (
          <Route
            key={tab.location}
            path={tab.isIndex ? undefined : tab.location}
            index={tab.isIndex}
            element={<tab.element />}
          />
        ))}
      </Route>
    </Routes>
  );
};
