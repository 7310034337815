/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ScreenClassMap } from "../../../../Grid/Container";
import Column from "../../../../Grid/Column";
import DetailsRow, { DetailsRowProps } from "./DetailsRow";

export interface DetailsSectionColumnProps extends ScreenClassMap<number> {
  rows: DetailsRowProps[];
  colSpan?: [number, number];
}

const DetailsSectionColumn: FC<DetailsSectionColumnProps> = ({
  rows,
  colSpan,
  ...props
}) => {
  return (
    <Column {...props}>
      {rows.map((row, index) => {
        return <DetailsRow key={index} {...row} colSpan={colSpan} />;
      })}
    </Column>
  );
};

export default DetailsSectionColumn;
